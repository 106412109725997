import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Operation } from "../../models/operation";
import { Ouvrage } from "../../models/ouvrage";
import { Controle } from "../../models/sample";

export interface WorkspacePermissions {
    administrate: boolean;
    notify: boolean;
    upload: boolean;
    validate: boolean;
    write: boolean;
    read: boolean;
    localizedResults: boolean;
    materialResults: boolean;
    synoptiqueData: boolean;
    filter: boolean;
    controles: Controle[];
}

export enum WorkspaceType {
    CHAUSSES = "chausses",
    OUVRAGES = "ouvrages",
}

export const WorkspaceLabel = {
    [WorkspaceType.CHAUSSES]: "Chaussées",
    [WorkspaceType.OUVRAGES]: "Ouvrages d'art",
};

export const TOKEN_KEY = "oso-workspace";

export interface WorkspaceState {
    operation: Operation;
    isOperationLoaded: boolean;
    ouvrage: Ouvrage;
    isOuvrageLoaded: boolean;
    workspacePermissions: Partial<WorkspacePermissions>;
    workspace: WorkspaceType;
}

const DEFAULT_OPERATION: Operation = {} as Operation;
const DEFAULT_OUVRAGE: Ouvrage = {} as Ouvrage;

const initialState: WorkspaceState = {
    workspace: WorkspaceType.CHAUSSES,
    operation: DEFAULT_OPERATION,
    workspacePermissions: {},
    isOperationLoaded: false,
    ouvrage: DEFAULT_OUVRAGE,
    isOuvrageLoaded: false,
};

const workspaceSlice = createSlice({
    name: "workspace",
    initialState: (): WorkspaceState => ({
        ...initialState,
        workspace:
            localStorage.getItem(TOKEN_KEY) === "ouvrages"
                ? WorkspaceType.OUVRAGES
                : WorkspaceType.CHAUSSES,
    }),
    reducers: {
        setOperation: (
            state,
            action: PayloadAction<{
                operation: Operation;
                permissions: Partial<WorkspacePermissions>;
            }>
        ) => {
            state.workspace = WorkspaceType.CHAUSSES;
            state.operation = action.payload.operation;
            state.workspacePermissions = action.payload.permissions;
            state.isOperationLoaded = true;
            state.ouvrage = DEFAULT_OUVRAGE;
            state.isOuvrageLoaded = false;
        },
        setOuvrage: (
            state,
            action: PayloadAction<{
                ouvrage: Ouvrage;
                permissions: Partial<WorkspacePermissions>;
            }>
        ) => {
            state.workspace = WorkspaceType.OUVRAGES;
            state.ouvrage = action.payload.ouvrage;
            state.workspacePermissions = action.payload.permissions;
            state.isOuvrageLoaded = true;
            state.operation = DEFAULT_OPERATION;
            state.isOperationLoaded = false;
        },
        clearWorkspace: (state, _: PayloadAction<void>) => ({
            ...initialState,
            workspace: state.workspace,
        }),
        setWorkspace: (state, action: PayloadAction<WorkspaceType>) => {
            if (state.workspace === action.payload) return state;

            localStorage.setItem(TOKEN_KEY, action.payload);
            return {
                ...initialState,
                workspace: action.payload,
            };
        },
    },
});

export const { setOperation, clearWorkspace, setWorkspace, setOuvrage } =
    workspaceSlice.actions;
export default workspaceSlice.reducer;
