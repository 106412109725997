import { useEffect, useState } from 'react';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { OuvrageElement } from '../../../models/ouvrage';
import { OuvrageMarker } from '../../../models/ouvrage-marker';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import OuvrageMarkerForm from '../OuvrageMarkerForm';
import Visualization from '../Visualization';
import ElementMarkers from '../Visualization/components/ElementMarkers';
import VisualisationLinks from '../Visualization/components/VisualizationLinks';
import VisualizationMarker from '../Visualization/components/VisualizationMarker';
import OuvrageViewElement from './components/OuvrageViewElement';
import OuvrageViewPanel from './components/OuvrageViewPanel';
import './index.scss';

const OuvrageView = () => {
    const { ouvrage } = useWorkspace();
    const [selectedElement, setSelectedElement] = useState<OuvrageElement>();
    const [markerToEdit, setMarkerToEdit] = useState<Partial<OuvrageMarker>>();
    const [markers, setMarkers] = useState<OuvrageMarker[]>([]);

    const getMarkers = () => getRequest<OuvrageMarker[]>('/ouvrage-marker', { params: { ouvrage: ouvrage._id }, loader: true })
        .then(setMarkers)
        .catch(() => null);

    useEffect(() => {
        getMarkers();
    }, [ouvrage]);

    return (
        <ScrollableContent noPadding noScroll>
            <div id="ouvrage-view">
                <Visualization
                    ouvrage={ouvrage}
                    onMarker={markerToEdit && !markerToEdit?.coordinates ? (coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates }) : undefined}
                    onMarkerInit={() => setMarkerToEdit({ ouvrage: ouvrage._id, element: selectedElement?._id })}
                >
                    {!!selectedElement?.coordinates && <VisualisationLinks from={selectedElement?.coordinates} to={markers.filter(m => m.element === selectedElement._id)} />}
                    <ElementMarkers ouvrage={ouvrage} selected={selectedElement?._id} onSelect={!markerToEdit ? setSelectedElement : undefined} />
                    {markers.map(marker => (
                        <VisualizationMarker
                            key={marker._id}
                            selected={markerToEdit?._id === marker._id}
                            marker={marker}
                            onClick={!markerToEdit ? () => setMarkerToEdit(marker) : undefined}
                            hide={marker._id === markerToEdit?._id}
                        />
                    ))}
                    {!!markerToEdit?.coordinates && <VisualizationMarker marker={markerToEdit} onMove={(coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates })} />}
                </Visualization>
                {!selectedElement && !markerToEdit && (
                    <OuvrageViewPanel onClose={() => setSelectedElement(undefined)} />
                )}
                {!!selectedElement && !markerToEdit && (
                    <OuvrageViewElement element={selectedElement} onClose={() => setSelectedElement(undefined)} />
                )}
                {!!markerToEdit?.coordinates && <OuvrageMarkerForm marker={markerToEdit} onClose={() => setMarkerToEdit(undefined)} onSubmit={getMarkers} />}
            </div>
        </ScrollableContent>
    )
}
export default OuvrageView;