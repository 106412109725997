import { forwardRef } from 'react';
import './index.scss';

interface TabSectionTitlesProps {
    tabs: { key: string, label: string }[];
    current: string;
    onClick: (key: string) => void;
}

export const TabSectionTitles = forwardRef<HTMLDivElement, TabSectionTitlesProps>(
    ({ tabs, current, onClick }, ref) => {
        return (
            <div className="tab-section">
                <div className="hiddenRef" ref={ref} />
                {tabs.map(tab => (
                    <div key={tab.key} className={`tab-section-tab ${tab.key === current ? 'active' : ''}`} onClick={() => onClick(tab.key)}>
                        <span>{tab.label}</span>
                    </div>
                ))}
            </div>
        );
    }
);

interface TabSectionSubTitlesProps {
    tabs: { key: string, label: string }[];
    onClick: (key: string) => void;
}

export const TabSectionSubTitles = ({ tabs, onClick }: TabSectionSubTitlesProps) => (
    <div className="subtab-section">
        {tabs.map(tab => (
            <span key={tab.key} onClick={() => onClick(tab.key)}>{tab.label}</span>
        ))}
    </div>
);
