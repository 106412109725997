import { MaterialCharacteristic } from '../../../models/material';
import Dropdown from '../../ui/Dropdown';

export interface CharacSelectorSelected { key: string; label: string; seuil: number | null };

interface CharacSelectorProps {
    characteristics: MaterialCharacteristic[];
    selected: CharacSelectorSelected | null;
    onClick: (s: CharacSelectorSelected) => void;
}

const CharacSelector = ({
    characteristics,
    selected,
    onClick
}: CharacSelectorProps) => (
    <div className="charac-selector">
        {characteristics.map(c => (
            <Dropdown
                key={c.key}
                title={c.label}
                opened
            >
                {(c.elements ?? []).map(element => element.seuil
                    ? (
                        <Dropdown
                            key={element.key}
                            title={element.label}
                            plusMinus
                        >
                            {(element.seuils ?? []).map((s, index) => (
                                <span key={index} className={selected?.seuil === s.seuil && selected?.key === element.key ? 'active' : ''} onClick={() => onClick({ key: element.key, label: element.label, seuil: s.seuil })}>
                                    Seuil {s.seuil}
                                </span>
                            ))}
                        </Dropdown>
                    ) : (
                        <span key={element.key} className={selected?.key === element.key ? 'active' : ''} onClick={() => onClick({ key: element.key, label: element.label, seuil: null })}>
                            {element.label}
                        </span>
                    )
                )}
            </Dropdown>
        ))}
    </div>
);

export default CharacSelector;