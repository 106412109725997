import { Fragment, useMemo } from 'react';
import { Blueprint, Cuboid } from '../../../../models/ouvrage';
import { cuboidForVisualization } from '../functions';
import { VisualizationElement, VisualizationSelection } from '../types';
import Dimensions from './Dimensions';

export interface MurProps {
    blueprint: Blueprint;
    scale: number;
    view: string;
    onSelect: (element: VisualizationSelection) => void;
    selected?: VisualizationSelection;
}

const Mur = ({ blueprint, scale, view, onSelect, selected }: MurProps) => {

    const data = useMemo((): VisualizationElement<Cuboid> | undefined => blueprint.mur
        ? cuboidForVisualization({ ...blueprint.mur, x: 0, y: 0, z: 0 }, view, scale, blueprint.mur)
        : undefined,
        [blueprint, scale, view]);

    if (!data) return null;

    return (
        <Fragment>
            <path id="mur" onClick={() => onSelect({ mur: true })} className={`visualization-element ${!!selected?.mur ? 'selected' : ''}`} d={data.path} />
            {!!selected?.mur && <Dimensions dimensions={data.dimensions} bbox={data.bbox} />}
        </Fragment>
    )
}

export default Mur;