import { FormEvent, Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/ui/Button';
import { postRequest } from '../../services/request.service';

const Recovery = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleRecovery = async (e?: FormEvent) => {
        e?.preventDefault();
        postRequest('/user/recovery', { email }, { loader: true, successMessage: 'Demande de récupération effectuée', errorMessage: 'Une erreur est survenue. Veuillez contacter l\'administrateur.' })
            .then(() => navigate('/'))
            .catch(() => null);
    }

    return (
        <Fragment>
            <h4>Récupération de mot de passe</h4>
            <span className="info">Saisissez l’e-mail que vous avez utilisé lors de votre inscription. Nous vous enverrons les instructions pour réinitialiser votre mot de passe.</span>
            <form autoComplete="off" onSubmit={(e) => handleRecovery(e)}>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <TextInput
                        id="email"
                        value={email ?? ''}
                        onChange={(value) => setEmail(value ?? '')}
                        autoComplete
                    />
                </div>
                <div className="public-footer">
                    <Button
                        label="M'envoyer un email"
                        type="submit"
                    />
                    <Link to="/"> Retour au formulaire de connexion</Link>
                </div>
            </form>
        </Fragment>
    );
}

export default Recovery;
