import { Fragment, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { Role, Roles, User } from '../../../models/user';
import useAuth from '../../../services/hooks/use-auth.hook';
import { postRequest, putRequest } from '../../../services/request.service';
import { addToast } from '../../../services/slices/ui.slice';
import { ActionIcon } from '../../../utils/icons';
import List from '../../data/List';
import Select from '../../inputs/Select';
import SelectAutocomplete from '../../inputs/SelectAutocomplete';
import TextInput from '../../inputs/TextInput';
import Card from '../../ui/Card';
import { Modal } from '../../ui/Modal';
import './index.scss';

const VALIDATION = {
    firstName: [{ comparator: FormComparator.REQUIRED }],
    lastName: [{ comparator: FormComparator.REQUIRED }],
    email: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.EMAIL }],
    role: [{ comparator: FormComparator.REQUIRED }],
    business: [{ comparator: FormComparator.REQUIRED }],
};

interface UserSearchModalProps {
    mode?: 'user' | 'subcontractor';
    disabledUsers?: string[];
    onSubmit: (u: User[]) => void;
    onClose: () => void;
}

const UserSearchModal = ({
    mode,
    onSubmit,
    onClose,
}: UserSearchModalProps) => {
    const dispatch = useDispatch();
    const { user } = useAuth();
    const [selectedUser, setSelectedUser] = useState<User[]>([]);
    const [createMode, setCreateMode] = useState<boolean>(false);
    const { attachInput, validate } = useForm<User>({ active: true, role: mode === 'subcontractor' ? Role.SUBCONTRACTOR : Role.USER });

    const handleSubmit = useCallback(async () => {
        const entity = validate(VALIDATION)
        if (!entity) return;

        const create = !entity?._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<User>('/user', entity, {
            successMessage: create ? 'Utilisateur créé avec succès' : 'Utilisateur mis à jour avec succès',
            loader: true
        })
            .then((data) => onSubmit([data]))
            .catch((e) => {
                if ((e as any)?.error?.status === 409) {
                    dispatch(addToast({
                        type: 'error', message: 'Cet email est déjà utilisée par un utilisateur'
                    }));
                } else {
                    dispatch(addToast({
                        type: 'error', message: 'Une erreur est survenue lors de l\'enregistrement', error: e as any
                    }));
                }
            });

    }, [validate, onSubmit]);

    return (
        <Modal
            title={mode === 'subcontractor' ? ' Rechercher un prestataire' : 'Rechercher un utilisateur'}
            className="user-search-modal"
            size="medium"
            actions={createMode ? [
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit },
            ] : [
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', disabled: !selectedUser, onClick: () => selectedUser && onSubmit(selectedUser) },
            ]}
        >
            {createMode ? (
                <Fragment>
                    <Card title="Informations générales">
                        <div className="row">
                            <div className="input-column">
                                <label htmlFor="firstName">Prénom *</label>
                                <TextInput {...attachInput('firstName')} />
                            </div>
                            <div className="input-column">
                                <label htmlFor="lastName">Nom *</label>
                                <TextInput {...attachInput('lastName')} />
                            </div>
                        </div>
                        <div className="input-column">
                            <label htmlFor="email">Email *</label>
                            <TextInput {...attachInput('email')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="business">Entreprise *</label>
                            <SelectAutocomplete endpoint="/business/list" {...attachInput('business')} />
                        </div>
                    </Card>
                    <Card title="Permissions">
                        <div className="input-column">
                            <label htmlFor="role">Role *</label>
                            <Select<Role> items={user.role === Role.SUPER_ADMIN ? Roles : Roles.filter(r => r.key !== Role.SUPER_ADMIN)} {...attachInput('role')} />
                        </div>
                    </Card>
                </Fragment>
            ) : (
                <Fragment>
                    <List<User>
                        columns={[
                            { key: 'firstName', label: 'Nom', mapper: (element) => `${element.firstName ? element.firstName + ' ' : ''}${element.lastName ?? ' '}` },
                            { key: 'business', label: 'Entreprise', mapper: (element) => element.businessPopulated?.name ?? '' },
                        ]}
                        onSelectMultiple={setSelectedUser}
                        buttons={[{ label: 'Créer un utilisateur', icon: ActionIcon.ADD, onClick: () => setCreateMode(true) }]}
                        dataEndpoint={`/user/search${mode === 'subcontractor' ? '/subcontractor' : ''}`}
                        initialPagination={{ sortBy: 'firstName', sortDirection: 1 }}
                        withSearch
                    />
                </Fragment>
            )}
        </Modal>
    )
}

export default UserSearchModal;
