import './index.scss';

interface TagItem {
    key: string;
    label: string;
    color: string;
}

export interface TagProps {
    value: string;
    items: TagItem[];
}

const Tag = ({ value, items }: TagProps) => {

    const tag = items.find(i => i.key === value);

    return tag ? (
        <div className="tag" style={{ backgroundColor: tag.color }}>{tag.label}</div>
    ) : null;
}

interface TagsProps {
    values: string[];
    items: TagItem[];
}

export const Tags = ({ values, items }: TagsProps) => (
    <div className="row">{values?.map(v => <Tag key={v} value={v} items={items} />)}</div>
);

interface TagsListProps {
    tags: TagProps[];
}

export const TagsList = ({ tags }: TagsListProps) => (
    <div className="row tags-list">{tags?.map(tag => <Tag key={tag.value} {...tag} />)}</div>
);

export default Tag;