import { ReactNode, useState } from 'react';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { Diagnostic } from '../../../models/diagnostic';
import { OuvrageElement } from '../../../models/ouvrage';
import { OuvrageMarker } from '../../../models/ouvrage-marker';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import OuvrageMarkerForm from '../OuvrageMarkerForm';
import Visualization from '../Visualization';
import VisualizationMarker from '../Visualization/components/VisualizationMarker';
import './index.scss';

interface DiagnosticViewProps {
    diagnostic: Partial<Diagnostic>;
    onElementSelect: (element: OuvrageElement) => void;
    elementSelected?: OuvrageElement;
    children?: ReactNode;
}

const DiagnosticView = ({ diagnostic, onElementSelect, elementSelected, children }: DiagnosticViewProps) => {
    const { ouvrage } = useWorkspace();
    const [markerToEdit, setMarkerToEdit] = useState<Partial<OuvrageMarker>>();
    const [markers, setMarkers] = useState<OuvrageMarker[]>([]);

    const getMarkers = () => diagnostic._id ? getRequest<OuvrageMarker[]>('/ouvrage-marker', { params: { ouvrage: ouvrage._id, diagnostic: diagnostic._id }, loader: true })
        .then(setMarkers)
        .catch(() => null) : null;

    return (
        <ScrollableContent id="diagnostic-view" noScroll noPadding>
            <Visualization
                ouvrage={ouvrage}
                onMarker={markerToEdit && !markerToEdit?.coordinates ? (coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates }) : undefined}
                selected={elementSelected}
                onSelect={!markerToEdit ? onElementSelect : undefined}
            >
                {markers.map(marker => (
                    <VisualizationMarker
                        key={marker._id}
                        selected={markerToEdit?._id === marker._id}
                        marker={marker}
                        onClick={!markerToEdit ? () => setMarkerToEdit(marker) : undefined}
                        hide={marker._id === markerToEdit?._id}
                    />
                ))}
                {!!markerToEdit?.coordinates && <VisualizationMarker marker={markerToEdit} onMove={(coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates })} />}
            </Visualization>
            {children}
            {!!markerToEdit?.coordinates && <OuvrageMarkerForm marker={markerToEdit} onClose={() => setMarkerToEdit(undefined)} onSubmit={getMarkers} />}
        </ScrollableContent>
    )
}

export default DiagnosticView;