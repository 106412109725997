import { useEffect, useMemo, useState } from 'react';
import ImageList from '../../../components/ImageList';
import Checkbox from '../../../components/inputs/Checkbox';
import DatePicker from '../../../components/inputs/DatePicker';
import NumberInput from '../../../components/inputs/NumberInput';
import SearchInput from '../../../components/inputs/SearchInput';
import Select from '../../../components/inputs/Select';
import { Textarea } from '../../../components/inputs/Textarea';
import TextInput from '../../../components/inputs/TextInput';
import Card from '../../../components/ui/Card';
import Dropdown from '../../../components/ui/Dropdown';
import PanelRight from '../../../components/ui/PanelRight';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { OUVRAGE_PATHOLOGIES, OuvrageMarker, OuvragePathology, OuvragePathologyType } from '../../../models/ouvrage-marker';
import useWorkspace from '../../../services/hooks/use-workspace';
import { postRequest, putRequest } from '../../../services/request.service';
import { stringToSearchField } from '../../../utils/format';
import './index.scss';

interface OuvrageMarkerPathologyProps {
    type: string;
    label: string;
    pathology?: OuvragePathology;
    onChange: (p?: OuvragePathology) => void;
}

const OuvrageMarkerPathology = ({ type, label, pathology, onChange }: OuvrageMarkerPathologyProps) => {

    return (
        <div>
            <Checkbox id={type} label={label} value={!!pathology} onChange={(v) => v ? onChange({ present: true }) : onChange(undefined)} />
            {!!pathology && (
                <div className="row">
                    <div className="form-group">
                        <label htmlFor={`${type}_length`}>Longueur</label>
                        <NumberInput id={`${type}_length`} value={pathology.length} onChange={(length) => onChange({ ...pathology, length })} />
                    </div>
                    <div className="form-group">
                        <label htmlFor={`${type}_width`}>Largeur</label>
                        <NumberInput id={`${type}_width`} value={pathology.width} onChange={(width) => onChange({ ...pathology, width })} />
                    </div>
                </div>
            )}
        </div>
    );
}

export interface OuvrageMarkerFormProps {
    marker: Partial<OuvrageMarker>;
    onClose: () => void;
    onSubmit: () => void;
}

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    description: [{ comparator: FormComparator.REQUIRED }],
    date: [{ comparator: FormComparator.REQUIRED }],
};

const OuvrageMarkerForm = ({ marker, onClose, onSubmit }: OuvrageMarkerFormProps) => {
    const { ouvrage } = useWorkspace();
    const [pathologiesKeyword, setPathologiesKeyword] = useState<string>();
    const { attachInput, entity, onChange, validate } = useForm<OuvrageMarker>(marker);

    const elements = useMemo(() => ouvrage.elements?.map(e => ({ key: e._id, label: e.name })), [ouvrage]);
    const filteredPathologies = useMemo(() => {
        if (!pathologiesKeyword) return OUVRAGE_PATHOLOGIES;

        const pathologiesGroups: OuvragePathologyType[] = [];

        for (const type of OUVRAGE_PATHOLOGIES) {
            const pathologies = type.pathologies.filter(p => stringToSearchField(p.label).includes(stringToSearchField(pathologiesKeyword)));

            if (pathologies.length) {
                pathologiesGroups.push({ ...type, pathologies })
            }
        }

        return pathologiesGroups;
    }, [pathologiesKeyword])

    const handleSubmit = async () => {
        const entity = validate(VALIDATION);
        if (!entity) return;

        const create = !entity._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<OuvrageMarker>('/ouvrage-marker', entity, {
            successMessage: create ? 'Point d\'intérêt créé avec succès' : 'Point d\'intérêt modifié avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then(() => {
                onSubmit();
                onClose();
            })
            .catch(() => null);
    };

    const handleDelete = () => null;

    useEffect(() => {
        if (marker.coordinates) {
            onChange('coordinates', marker.coordinates);
        }
    }, [marker]);

    return (
        <PanelRight
            title={!entity._id ? 'Créer un point d\'intérêt' : 'Modifier un point d\'intérêt'}
            id="ouvrage-marker"
            actions={[
                { label: 'Fermer', color: 'secondary', onClick: onClose },
                { label: 'Confirmer', onClick: handleSubmit },
            ]}
            onDelete={handleDelete}
        >
            <div className="row">
                <div className="input-column lg8">
                    <label htmlFor="name">Nom *</label>
                    <TextInput placeholder="Nom du marker" {...attachInput('name')} />
                </div>
                <div className="input-column lg4">
                    <label htmlFor="date">Date *</label>
                    <DatePicker max={new Date()} withIcon {...attachInput('date')} />
                </div>
            </div>
            <div className="input-column">
                <label htmlFor="description">Elément</label>
                <Select items={elements} {...attachInput('element')} />
            </div>
            <div className="input-column">
                <label htmlFor="description">Description</label>
                <Textarea {...attachInput('description')} />
            </div>
            <div className="input-column">
                <label htmlFor="location">Localisation</label>
                <Textarea {...attachInput('location')} />
            </div>
            <div className="input-column">
                <label htmlFor="location">Pathologies</label>
                <Card id="ouvrage-marker-pathologies">
                    <SearchInput id="ouvrage-marker-pathologies-search" placeholder="Rechercher une pathologie..." minLength={2} value={pathologiesKeyword} onChange={setPathologiesKeyword} />
                    {filteredPathologies.map(group => (
                        <Dropdown key={group.type} title={`${group.label}${group.pathologies.some(p => entity.pathologies?.[group.type]?.[p.type]?.present) ? ` (${group.pathologies.filter(p => entity.pathologies?.[group.type]?.[p.type]?.present).length})` : ''}`}>
                            {group.pathologies.map(pathology => (
                                <OuvrageMarkerPathology
                                    key={pathology.type}
                                    type={pathology.type}
                                    pathology={entity.pathologies?.[group.type]?.[pathology.type]}
                                    label={pathology.label}
                                    onChange={(p) => onChange(`pathologies.${group.type}.${pathology.type}`, p)}
                                />
                            ))}
                        </Dropdown>
                    ))}
                </Card>
            </div>
            <div className="input-column">
                <label htmlFor="location">Photographies</label>
                <ImageList
                    column
                    entityId={entity._id}
                    url={entity._id ? `/ouvrage-marker/${entity._id}/images` : undefined}
                    editable
                    onChange={(storageFiles) => onChange('storageFiles', storageFiles)}
                />
            </div>
        </PanelRight>
    )
}
export default OuvrageMarkerForm;