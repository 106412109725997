import { useCallback, useEffect, useState } from 'react';
import { FormBaseProps, FormRule, validateOne } from '../../hooks/useForm';

interface TextareaProps extends FormBaseProps<string> {
    rows?: number;
    maxLength?: number;
    placeholder?: string;
    onBlur?: (v: string) => void;
    onBlurValidationError?: FormRule[];
    onBlurValidationWarning?: FormRule[];
    autoComplete?: boolean;
}

export const Textarea = ({
    id,
    rows = 3,
    value,
    maxLength,
    placeholder,
    disabled,
    onBlur,
    onChange,
    onBlurValidationError,
    onBlurValidationWarning,
    errors,
    autoComplete
}: TextareaProps) => {
    const [errorsToDisplay, setErrorsToDisplay] = useState<string[]>([]);
    const [warnings, setWarnings] = useState<string[]>([]);

    const handleBlur = useCallback((v: string) => {
        if (onBlurValidationError?.length) setErrorsToDisplay(validateOne(v, onBlurValidationError));
        if (onBlurValidationWarning?.length) setWarnings(validateOne(v, onBlurValidationWarning));
        if (onBlur) onBlur(v);
    }, [onBlurValidationError, onBlurValidationWarning, onBlur]);

    useEffect(() => {
        setErrorsToDisplay(errors ?? []);
    }, [errors]);

    return (
        <div className="form-input-text">
            <textarea
                autoComplete={autoComplete ? "on" : "off"}
                id={id}
                rows={rows}
                readOnly={!!disabled}
                onBlur={(e) => handleBlur(e.target.value)}
                onChange={(e) => onChange(e.target.value)}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
                maxLength={maxLength ? maxLength : undefined}
                className={`border-input${!!errorsToDisplay?.length ? ' input-error' : (!!warnings?.length ? ' input-warning' : '')}`}
            />

            <div className="form-error">{
                !!errorsToDisplay?.length
                    ? errorsToDisplay.join('. ') + '. '
                    : <span className="form-warning">{!!warnings?.length ? warnings.join('. ') + '. ' : ''}</span>
            }
            </div>
        </div>
    );
}