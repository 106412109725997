import { ReactNode } from 'react';
import Button, { ButtonProps } from '../Button';
import './index.scss';

interface ModalRightProps {
    children: ReactNode;
    title?: string;
    visible?: boolean;
    className?: string;
    actions?: ButtonProps[];
    icon?: ReactNode;
}

export const ModalRight = ({
    children,
    title,
    visible,
    className,
    actions,
    icon
}: ModalRightProps) => (
    <div className={`modal-right-backdrop ${visible ? 'visible' : ''}`}>
        <div className={`modal-right ${className ?? ''}`}>
            {title && <div className="modal-right-title"><span>{title}</span>{icon}</div>}
            <div className="modal-right-content">
                {children}
            </div>
            <div className="modal-right-footer">
                {actions?.map(a => <Button key={a.label} {...a} />)}
            </div>
        </div>
    </div>
);
