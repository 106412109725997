import { useEffect, useState } from 'react';
import { ActionIcon } from '../../../utils/icons';
import Button from '../../ui/Button';
import './index.scss';

interface ScaleInputProps {
    scale: { min: number; max: number };
    onChange: (s: { min: number; max: number }) => void;
}

const ScaleInput = ({
    scale,
    onChange
}: ScaleInputProps) => {
    const [min, setMin] = useState<number | null>(scale.min);
    const [max, setMax] = useState<number | null>(scale.max);

    const handleChange = () => {
        if (min !== null && max !== null) {
            onChange({
                min: min < max ? min : max,
                max: min < max ? max : min,
            })
        }
    }

    useEffect(() => {
        setMin(scale.min);
        setMax(scale.max);
    }, [scale]);

    return (
        <div className="scale-input">
            <span>Echelle : </span>
            <input type="number" value={min ?? ''} onWheel={(e) => e.currentTarget.blur()} onChange={(e) => setMin(e.target.value ? Number(e.target.value) : null)} />
            <input type="number" value={max ?? ''} onWheel={(e) => e.currentTarget.blur()} onChange={(e) => setMax(e.target.value ? Number(e.target.value) : null)} />
            <Button onClick={() => handleChange()} icon={ActionIcon.SAVE} />
        </div>
    );
}

export default ScaleInput;