import EntityCard from '../../../../components/entities/EntityCard';
import LocationPicker from '../../../../components/inputs/LocationPicker';
import { MultipleSelect } from '../../../../components/inputs/Select';
import { Textarea } from '../../../../components/inputs/Textarea';
import TextInput from '../../../../components/inputs/TextInput';
import Card from '../../../../components/ui/Card';
import ScrollableContent from '../../../../components/ui/ScrollableContent';
import { FormHookReturn } from '../../../../hooks/useForm';
import { Ouvrage } from '../../../../models/ouvrage';

export interface OuvrageInformationsProps extends Pick<FormHookReturn<Ouvrage>, 'entity' | 'onChange' | 'onChangeMultiple' | 'attachInput' | 'errors'> { }

const OuvrageInformations = ({ attachInput, entity, onChange, onChangeMultiple }: OuvrageInformationsProps) => {
    return (
        <ScrollableContent
            side={<EntityCard attachInput={attachInput} entity={entity} />}
        >
            <Card title="Informations générales">
                <div className="input-column">
                    <label htmlFor="name">Nom *</label>
                    <TextInput placeholder="Nom de l'ouvrage" {...attachInput('name')} />
                </div>
                <div className="input-column">
                    <label htmlFor="description">Description</label>
                    <Textarea {...attachInput('description')} />
                </div>
                <div className="input-column">
                    <label htmlFor="project">Dossier</label>
                    <TextInput placeholder="Numéro de dossier" {...attachInput('project')} />
                </div>
                <div className="input-column">
                    <label htmlFor="businesses">Entreprises</label>
                    <MultipleSelect endpoint="/business/list" {...attachInput('businesses')} />
                </div>
            </Card>
            <Card>
                <LocationPicker attachInput={attachInput} onChange={onChange} onChangeMultiple={onChangeMultiple} entity={entity} />
            </Card>
        </ScrollableContent>
    )
}

export default OuvrageInformations;