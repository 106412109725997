import 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { AnalysisStatistics } from '../../../models/analysis';
import { Phase, PhaseChartColor, PhaseLabel, Road, RoadPositionsList } from '../../../models/operation';
import { ControleChartColor, Controles } from '../../../models/sample';
import { ListItem } from '../../../models/shared';
import useWorkspace from '../../../services/hooks/use-workspace';
import { LayerColors } from '../../../synoptique/Synoptique.class';
import { StatisticsChartProps } from './StatisticsChart';

const COMMON_DATASET_OPTIONS = {
    borderWidth: 1,
    pointRadius: 2,
    spanGaps: true,
}


const getDatasets = (statistics: AnalysisStatistics, chartType: string, chartBy: string, phases: Phase[], materials: ListItem[], roadsObj: { [k: string]: Road }) => {
    const datasets = [];
    if (chartType === 'average') {
        if (chartBy === 'phase') {
            datasets.push(...phases.map((phase) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'line',
                label: PhaseLabel[phase],
                data: statistics.data?.map(d => ({ x: d.groupIndex, y: d.phase[phase]?.average ?? undefined })) ?? [],
                backgroundColor: PhaseChartColor[phase],
                borderColor: PhaseChartColor[phase]
            })));
        } else if (chartBy === 'controle') {
            datasets.push(...Controles.map((c) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'line',
                label: c.label,
                data: statistics.data?.map(d => ({ x: d.groupIndex, y: d.controle[c.key]?.average ?? undefined })) ?? [],
                backgroundColor: ControleChartColor[c.key],
                borderColor: ControleChartColor[c.key]
            })));
        } else if (chartBy === 'material') {
            datasets.push(...Object.keys(statistics.material).map((material, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'line',
                label: materials?.find(b => b.key === material)?.label ?? material,
                data: statistics.data?.map(d => ({ x: d.groupIndex, y: d.material[material]?.average ?? undefined })) ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        } else if (chartBy === 'road') {
            datasets.push(...Object.keys(statistics.road).map((road, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'line',
                label: roadsObj[road]?.label ?? road,
                data: statistics.data?.map(d => ({ x: d.groupIndex, y: d.road[road]?.average ?? undefined })) ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        } else if (chartBy === 'roadPosition') {
            datasets.push(...RoadPositionsList.map((p, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'line',
                label: p.label,
                data: statistics.data?.map(d => ({ x: d.groupIndex, y: d.roadPosition[p.key]?.average ?? undefined })) ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        }
    } else {
        if (chartBy === 'phase') {
            datasets.push(...phases.map((phase) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'scatter',
                label: PhaseLabel[phase],
                data: statistics.data?.map(d => d.phase[phase]?.values.map(v => ({ x: d.groupIndex, y: v })) ?? []).flat() ?? [],
                backgroundColor: PhaseChartColor[phase],
                borderColor: PhaseChartColor[phase]
            })));
        } else if (chartBy === 'controle') {
            datasets.push(...Controles.map((c) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'scatter',
                label: c.label,
                data: statistics.data?.map(d => d.controle[c.key]?.values.map(v => ({ x: d.groupIndex, y: v })) ?? []).flat() ?? [],
                backgroundColor: ControleChartColor[c.key],
                borderColor: ControleChartColor[c.key]
            })));
        } else if (chartBy === 'material') {
            datasets.push(...Object.keys(statistics.material).map((material, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'scatter',
                label: materials?.find(b => b.key === material)?.label ?? material,
                data: statistics.data?.map(d => d.material[material]?.values.map(v => ({ x: d.groupIndex, y: v })) ?? []).flat() ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        } else if (chartBy === 'roadPosition') {
            datasets.push(...RoadPositionsList.map((p, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'scatter',
                label: p.label,
                data: statistics.data?.map(d => d.roadPosition[p.key]?.values.map(v => ({ x: d.groupIndex, y: v })) ?? []).flat() ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        } else if (chartBy === 'road') {
            datasets.push(...Object.keys(statistics.road).map((road, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'scatter',
                label: roadsObj[road]?.label ?? road,
                data: statistics.data?.map(d => d.road[road]?.values.map(v => ({ x: d.groupIndex, y: v })) ?? []).flat() ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        }
    }
    return datasets;
}

const StatisticsLinearChart = ({
    data,
    type,
    by,
    scale,
    phases = [],
    materials = []
}: Omit<StatisticsChartProps, 'axis'>) => {
    const { operation } = useWorkspace();
    const [datasets, setDatasets] = useState<any[] | null>(null);

    useEffect(() => {
        setDatasets(getDatasets(data, type, by, phases, materials, operation.roadsObj));
    }, [data, type, by, operation]);

    if (!datasets) {
        return null;
    }

    return (
        <Chart
            type="line"
            data={{ datasets }}
            options={{
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom'
                    },
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'linear',
                        title: {
                            display: true,
                            text: 'PR',
                            align: 'start',
                            font: {
                                weight: 'bold',
                                size: 14,
                                style: 'italic',
                            }
                        }
                    },
                    y: {
                        min: scale.min,
                        max: scale.max,
                    }
                }
            }}
        />
    )
}

export default StatisticsLinearChart;