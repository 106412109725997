import { Pagination } from '../../../models/pagination';
import './index.scss';

interface PaginationProps {
    pagination: Partial<Pagination> | null;
    onPageChange: (page: number) => void;
}

const PaginationComponent = ({
    pagination,
    onPageChange
}: PaginationProps) => {
    if (!pagination || !pagination.nbPages || pagination.nbPages === 1 || !pagination.page) {
        return (null);
    }

    return (
        <div className="pagination">
            {pagination.page > 2 && <span onClick={() => onPageChange(1)}>&lt;&lt;</span>}
            {pagination.page > 1 && <span onClick={() => onPageChange(pagination.page! - 1)}><span className="arrow">&lt;</span>Précédent</span>}
            {pagination.page - 2 >= 1 && <span onClick={() => onPageChange(pagination.page! - 2)} className="dots">...</span>}
            {pagination.page >= 2 && <span onClick={() => onPageChange(pagination.page! - 1)} className="page">{pagination.page - 1}</span>}
            {pagination.page && <span className="page active">{pagination.page}</span>}
            {pagination.page + 1 <= pagination.nbPages && <span onClick={() => onPageChange(pagination.page! + 1)} className="page">{pagination.page + 1}</span>}
            {pagination.page + 2 <= pagination.nbPages && <span onClick={() => onPageChange(pagination.page! + 2)} className="dots">...</span>}
            {pagination.page < pagination.nbPages && <span onClick={() => onPageChange(pagination.page! + 1)}>Suivant<span className="arrow">&gt;</span></span>}
            {pagination.page < pagination.nbPages - 1 && <span onClick={() => onPageChange(pagination.nbPages!)}>&gt;&gt;</span>}
        </div>
    )
}

export default PaginationComponent;