import { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { buildSearchParams, filtersFromSearchParams } from "../../utils/format";
import { isArray } from "../../utils/objects";
import { FilterType, ListFilterSetting, ListFilters } from "../data/List";
import CheckboxList from "../inputs/CheckboxList";
import PrInput from "../inputs/PrInput";
import Switch from "../inputs/Switch";
import { ModalRight } from "../ui/Modal/ModalRight";
import './index.scss';

export const DEFAULT_FILTERS = { active: true };

interface FiltersPanelProps {
    onClose: () => void;
    visible: boolean;
    filtersSettings?: ListFilterSetting[]
}

const FiltersPanel = ({ filtersSettings, visible, onClose }: FiltersPanelProps) => {
    const [filters, setFilters] = useState<ListFilters>({ ...DEFAULT_FILTERS });
    const location = useLocation();
    const [URLSearchParams, setURLSearchParams] = useSearchParams();

    const handleChange = useCallback((filters: ListFilters) => {
        setURLSearchParams(buildSearchParams(filters), { replace: true });
    }, []);

    useEffect(() => {
        setFilters(filtersFromSearchParams(URLSearchParams, filtersSettings));
    }, [location]);

    return (
        <ModalRight
            title="Filtrer"
            visible={visible}
            className="list-filters"
            actions={[
                { label: 'Réinitialiser', color: 'secondary', onClick: () => handleChange({ ...DEFAULT_FILTERS }) },
                { label: 'Fermer', onClick: onClose },
            ]}
        >
            {filtersSettings?.map(f => {
                const filter = filters[f.field];

                if (f.field === 'active') {
                    return (
                        <Switch
                            key={f.field}
                            items={[{ key: true, label: 'Actif' }, { key: false, label: 'Inactif' }, { key: undefined, label: 'Indifférent' }]}
                            value={filter !== null ? filter : true}
                            onChange={(value) => handleChange({ ...(filters ?? {}), [f.field]: value })}
                            id="active"
                        />
                    );
                }
                switch (f.type) {
                    case FilterType.MULTIPLE_SELECT:
                        return (
                            <div
                                className={`list-filter ${(Array.isArray(filter) && filter.length) || (!Array.isArray(filter) && filter) ? 'active' : ''}`}
                                key={f.field}
                            >
                                <div className="list-filter-header">
                                    <label>{f.label}</label>
                                </div>
                                <CheckboxList
                                    id={f.field}
                                    value={isArray(filter) ? filter : []}
                                    endpoint={f.endpoint}
                                    items={f.items?.map(i => ({ key: String(i.key), label: i.label }))}
                                    onChange={(values) => handleChange({ ...(filters ?? {}), [f.field]: values })}
                                />
                            </div>
                        );
                    case FilterType.SWITCH:
                        return (
                            <div
                                className={`list-filter ${(Array.isArray(filter) && filter.length) || (!Array.isArray(filter) && filter) ? 'active' : ''}`}
                                key={f.field}
                            >
                                <div className="list-filter-header">
                                    <label>{f.label}</label>
                                    <Switch
                                        id={f.field}
                                        items={[...(f.items ?? []), { key: undefined, label: 'Indifférent' }]}
                                        value={filter !== null ? filter : undefined}
                                        onChange={(value) => handleChange({ ...(filters ?? {}), [f.field]: value })}
                                    />
                                </div>
                            </div>
                        );
                    case FilterType.PR:
                        return (
                            <div
                                className={`list-filter ${(Array.isArray(filter) && filter.length) || (!Array.isArray(filter) && filter) ? 'active' : ''}`}
                                key={f.field}
                            >
                                <div className="list-filter-header">
                                    <label>{f.label}</label>
                                    <PrInput
                                        id={f.field}
                                        value={!isNaN(Number(filter)) ? Number(filter) : undefined}
                                        onChange={(value) => handleChange({ ...(filters ?? {}), [f.field]: value })}
                                    />
                                </div>
                            </div>
                        );
                    default:
                        return null;
                }
            })}
        </ModalRight>
    );
}

export default FiltersPanel;