import { useCallback, useEffect, useState } from 'react';
import { FormBaseProps } from '../../../hooks/useForm';
import { getRequest } from '../../../services/request.service';
import { toggleInArray } from '../../../utils/objects';
import Checkbox from '../Checkbox';
import './index.scss';

interface CheckboxListItem<T> { key: T; label: string };

interface CheckboxListProps<T> extends FormBaseProps<T[]> {
    items?: CheckboxListItem<T>[];
    disabledItems?: T[];
    endpoint?: string;
}

const CheckboxList = <T,>({
    id,
    value,
    items,
    disabledItems,
    endpoint,
    onChange,
    errors
}: CheckboxListProps<T>) => {
    const [itemsOrData, setItemsOrData] = useState<CheckboxListItem<T>[]>([]);

    const handleChange = useCallback((item: CheckboxListItem<T>) => {
        const _value = toggleInArray(item.key, value ?? []);

        onChange(_value);
    }, [value, onChange, itemsOrData]);

    useEffect(() => {
        if (endpoint) {
            getRequest<CheckboxListItem<T>[]>(endpoint).then(setItemsOrData).catch(() => setItemsOrData([]));
        } else {
            setItemsOrData(items ?? []);
        }
    }, [endpoint, items]);

    return (
        <div className="checkbox-list">
            {!!itemsOrData?.length && itemsOrData.map(item => (
                <Checkbox
                    key={String(item.key)}
                    id={`${id}_${item.key}`}
                    value={!!value?.includes(item.key)}
                    label={item.label}
                    onChange={() => handleChange(item)}
                    disabled={!!disabledItems?.includes(item.key)}
                />
            ))}
            {!!errors?.length && <div className="form-error">{errors.join('. ')}</div>}
        </div>
    )
}

export default CheckboxList;