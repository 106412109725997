import { useSelector } from 'react-redux';
import logo from '../../../assets/images/logoCompact@2x.png';
import { RootState } from '../../../services/store';
import './index.scss';

const Spinner = () => {
    const loader = useSelector((state: RootState) => state.ui.loader);

    if (!loader) return null;

    return (
        <div id="loader">
            <div id="loader-bar" />
            <div id="loader-content">
                <img src={logo} alt="" />
                <span>Chargement des données, merci de patienter</span>
            </div>
        </div>
    );
}

export const SpinnerSmall = () => <div className="loader-small" />

export default Spinner;