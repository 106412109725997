import { MouseEvent, TouchEvent } from "react";
import { CoordinatesXY, CoordinatesXYZ } from "../../../models/location";
import {
    Barriere,
    Blueprint,
    Buse,
    Cuboid,
    OuvrageType,
    Pile,
} from "../../../models/ouvrage";
import {
    VisualizationDimension,
    VisualizationElement,
    VisualizationReducerAction,
    VisualizationReducerState,
} from "./types";

const PADDING = 40;

export const getEventCoordinates = (e: TouchEvent | MouseEvent) => {
    if ((e as any).touches) {
        const touch = e as TouchEvent;
        return { x: touch.touches[0].clientX, y: touch.touches[0].clientY };
    } else {
        const mouse = e as MouseEvent;
        return { x: mouse.clientX, y: mouse.clientY };
    }
};

const adaptView = (
    state: VisualizationReducerState
): VisualizationReducerState => {
    const width = ["left", "right"].includes(state.view)
        ? state.contentBbox.width
        : state.contentBbox.length;
    const height = ["top", "bottom"].includes(state.view)
        ? state.contentBbox.width
        : state.contentBbox.height;
    const zoom = Math.min(
        (state.viewBox.width - PADDING * 2) / width,
        (state.viewBox.height - PADDING * 2) / height
    );
    const offset = {
        x: -(state.viewBox.width - width * zoom) / 2,
        y:
            -(state.viewBox.height - height * zoom) / 2 -
            (["left", "right"].includes(state.view) ? state.contentBbox.z : 0),
    };

    return {
        ...state,
        zoom,
        viewBox: {
            ...state.viewBox,
            ...offset,
        },
        scale: 1,
    };
};

export const domCoordinatesToSvgCoordinates = (
    state: VisualizationReducerState,
    coordinates: CoordinatesXY
): CoordinatesXY => ({
    x: (coordinates.x + state.viewBox.x) / state.zoom,
    y: (coordinates.y + state.viewBox.y) / state.zoom,
});

export const updateCoordinates = (
    state: VisualizationReducerState,
    coordinates: CoordinatesXY,
    originalCoordinates: Partial<CoordinatesXYZ>
): CoordinatesXYZ => {
    const svgCoordinates = domCoordinatesToRealCoordinates(state, {
        x: coordinates.x - state.bbox.x,
        y: coordinates.y - state.bbox.y,
    });
    if (state.view === "front") {
        return {
            x: svgCoordinates.x,
            y: originalCoordinates?.y ?? 0,
            z: svgCoordinates.y,
        };
    } else if (state.view === "top") {
        return {
            x: svgCoordinates.x,
            y: svgCoordinates.y,
            z: originalCoordinates?.z ?? 0,
        };
    } else if (state.view === "left") {
        return {
            x: originalCoordinates?.x ?? 0,
            y: svgCoordinates.x,
            z: svgCoordinates.y,
        };
    }
    return { x: 0, y: 0, z: 0 };
};

export const svgCoordinatesToRealCoordinates = (
    state: VisualizationReducerState,
    coordinates: CoordinatesXY
): CoordinatesXY => ({
    x: coordinates.x * state.scale,
    y: coordinates.y * state.scale,
});

export const domCoordinatesToRealCoordinates = (
    state: VisualizationReducerState,
    coordinates: CoordinatesXY
): CoordinatesXY =>
    svgCoordinatesToRealCoordinates(
        state,
        domCoordinatesToSvgCoordinates(state, coordinates)
    );

export const markerToCoordinates = (marker: CoordinatesXYZ, view: string) => {
    if (view === "front") {
        return {
            x: marker.x,
            y: marker.z,
        };
    } else if (view === "left") {
        return {
            x: marker.y,
            y: marker.z,
        };
    } else {
        return {
            x: marker.x,
            y: marker.y,
        };
    }
};

export const visualizationReducer = (
    state: VisualizationReducerState,
    action: VisualizationReducerAction
): VisualizationReducerState => {
    switch (action.type) {
        case "setBbox":
            if (!action.payload?.bbox) return state;

            if (!state.isInit) {
                return adaptView({
                    ...state,
                    bbox: {
                        x: action.payload?.bbox.left,
                        y: action.payload?.bbox.top,
                        width: action.payload?.bbox.width,
                        height: action.payload?.bbox.height,
                    },
                    viewBox: {
                        x: 0,
                        y: 0,
                        width: action.payload.bbox.width,
                        height: action.payload.bbox.height,
                    },
                    isInit: true,
                });
            }

            return {
                ...state,
                bbox: {
                    x: action.payload?.bbox.left,
                    y: action.payload?.bbox.top,
                    width: action.payload?.bbox.width,
                    height: action.payload?.bbox.height,
                },
                viewBox: {
                    ...state.viewBox,
                    width: action.payload.bbox.width,
                    height: action.payload.bbox.height,
                },
            };
        case "setContentBbox":
            if (!action.payload?.content) return state;

            let contentBbox = { ...state.contentBbox };
            if (state.type === OuvrageType.PONT) {
                if (!action.payload?.content.tablier) return state;

                const offsetZ = action.payload?.content.barrieres?.length
                    ? Math.max(
                          ...action.payload?.content.barrieres?.map(
                              (p) => p.height
                          )
                      )
                    : 0;
                contentBbox.width = action.payload.content.tablier.width;
                contentBbox.length = action.payload.content.tablier.length;
                contentBbox.height =
                    action.payload?.content.tablier.height +
                    (action.payload?.content.piles?.length
                        ? Math.max(
                              ...action.payload?.content.piles?.map(
                                  (p) => p.height
                              )
                          )
                        : 0) +
                    offsetZ;
                contentBbox.z = offsetZ;
            } else if (state.type === OuvrageType.MUR) {
                if (!action.payload?.content.mur) return state;

                contentBbox.width = action.payload.content.mur.width;
                contentBbox.length = action.payload.content.mur.length;
                contentBbox.height = action.payload.content.mur.height;
            } else if (state.type === OuvrageType.BUSE) {
                if (!action.payload?.content.buse) return state;

                contentBbox.width = action.payload.content.buse.diameterH;
                contentBbox.length = action.payload.content.buse.length;
                contentBbox.height = action.payload.content.buse.diameterH;
            }

            return {
                ...state,
                contentBbox,
            };
        case "setView":
            if (!action.payload?.view) return state;

            return adaptView({
                ...state,
                view: action.payload?.view,
                zoom: 1,
            });
        case "addZoom":
            if (!action.payload?.zoom || !action.payload?.coordinates)
                return state;
            const zoom = state.zoom - action.payload?.zoom * 0.0008;

            if (zoom < 0.2 || zoom > 8.5) return state;
            const realCoordinates = domCoordinatesToSvgCoordinates(
                state,
                action.payload?.coordinates
            );
            const realCoordinatesAfter = domCoordinatesToSvgCoordinates(
                { ...state, zoom },
                action.payload?.coordinates
            );

            return {
                ...state,
                zoom,
                viewBox: {
                    ...state.viewBox,
                    x:
                        state.viewBox.x +
                        (realCoordinates.x - realCoordinatesAfter.x) *
                            state.zoom,
                    y:
                        state.viewBox.y +
                        (realCoordinates.y - realCoordinatesAfter.y) *
                            state.zoom,
                },
            };
        case "mouseDown":
            if (!action.payload?.coordinates) return state;
            return {
                ...state,
                mouseDown: action.payload.coordinates,
            };
        case "translate":
            if (!action.payload?.coordinates || !state.mouseDown) return state;

            return {
                ...state,
                translationInProgress: {
                    x: state.mouseDown.x - action.payload.coordinates.x,
                    y: state.mouseDown.y - action.payload.coordinates.y,
                },
            };
        case "mouseUp":
            return {
                ...state,
                mouseDown: undefined,
                viewBox: {
                    ...state.viewBox,
                    x: state.viewBox.x + (state.translationInProgress?.x ?? 0),
                    y: state.viewBox.y + (state.translationInProgress?.y ?? 0),
                },
                translationInProgress: undefined,
            };
        default:
            return state;
    }
};

export const scaleValue = (value: number, scale: number) =>
    Math.round(value / (scale !== 0 ? scale : 1));

export const cuboidForVisualization = (
    cuboid: Cuboid & { x: number; y: number; z: number },
    view: string,
    scale: number,
    bbox: Cuboid
): VisualizationElement<Cuboid> => {
    const cuboidScaled = {
        x:
            view === "left" || view === "right"
                ? scaleValue(
                      view === "left"
                          ? cuboid.y
                          : bbox.width - cuboid.y - cuboid.width,
                      scale
                  )
                : scaleValue(
                      view !== "back"
                          ? cuboid.x
                          : bbox.length - cuboid.x - cuboid.length,
                      scale
                  ),
        y:
            view === "top" || view === "bottom"
                ? scaleValue(
                      view === "top"
                          ? cuboid.y
                          : bbox.width - cuboid.y - cuboid.width,
                      scale
                  )
                : scaleValue(cuboid.z, scale),
        width:
            view === "left" || view === "right"
                ? scaleValue(cuboid.width, scale)
                : scaleValue(cuboid.length, scale),
        height:
            view === "top" || view === "bottom"
                ? scaleValue(cuboid.width, scale)
                : scaleValue(cuboid.height, scale),
    };

    return {
        initial: cuboid,
        path: [
            `M ${cuboidScaled.x} ${cuboidScaled.y}`,
            `h ${cuboidScaled.width}`,
            `v ${cuboidScaled.height}`,
            `h -${cuboidScaled.width} Z`,
        ].join(" "),
        bbox: cuboidScaled,
        dimensions: [
            {
                key: "width",
                side: "top",
                point1: {
                    x: cuboidScaled.x,
                    y: cuboidScaled.y,
                },
                point2: {
                    x: cuboidScaled.x + cuboidScaled.width,
                    y: cuboidScaled.y,
                },
                value:
                    view === "left" || view === "right"
                        ? cuboid.width
                        : cuboid.length,
                offset: 0,
            },
            {
                key: "height",
                side: "left",
                point1: {
                    x: cuboidScaled.x,
                    y: cuboidScaled.y,
                },
                point2: {
                    x: cuboidScaled.x,
                    y: cuboidScaled.y + cuboidScaled.height,
                },
                value:
                    view === "top" || view === "bottom"
                        ? cuboid.width
                        : cuboid.height,
                offset: 0,
            },
        ],
    };
};

export const pileForVisualization = (
    pile: Pile,
    view: string,
    scale: number,
    bbox: Cuboid
): VisualizationElement<Pile> => {
    if (
        view === "top" ||
        view === "bottom" ||
        view === "left" ||
        view === "right"
    ) {
        const withArcs =
            (view === "top" || view === "bottom") &&
            (pile.arcLeft || pile.arcRight)
                ? cuboidForVisualization(
                      {
                          length:
                              pile.length +
                              (pile.arcLeft?.rX ?? 0) +
                              (pile.arcRight?.rX ?? 0),
                          width: pile.width,
                          height: pile.height,
                          x: pile.x - (pile.arcLeft?.rX ?? 0),
                          y: pile.y,
                          z: pile.z ?? 0,
                      },
                      view,
                      scale,
                      bbox
                  )
                : undefined;
        const cuboid = cuboidForVisualization(
            {
                length: pile.length,
                width: pile.width,
                height: pile.height,
                x: pile.x,
                y: pile.y,
                z: pile.z ?? 0,
            },
            view,
            scale,
            bbox
        );
        const newBbox = withArcs?.bbox ?? cuboid.bbox;
        return {
            initial: pile,
            path: cuboid.path,
            bbox: newBbox,
            topPath: withArcs?.path,
            dimensions: [
                ...cuboid.dimensions,
                ...(((view === "top" || view === "bottom") && pile.arcLeft
                    ? [
                          {
                              key: "width-arcLeft",
                              side: "top",
                              point1: {
                                  x: newBbox.x,
                                  y: newBbox.y,
                              },
                              point2: {
                                  x:
                                      newBbox.x +
                                      scaleValue(pile.arcLeft.rX, scale),
                                  y: newBbox.y,
                              },
                              value: pile.arcLeft.rX,
                              offset: 0,
                          },
                      ]
                    : []) as (VisualizationDimension & { key: string })[]),
                ...(((view === "top" || view === "bottom") && pile.arcRight
                    ? [
                          {
                              key: "width-arcRight",
                              side: "top",
                              point1: {
                                  x:
                                      newBbox.x +
                                      newBbox.width -
                                      scaleValue(pile.arcRight.rX, scale),
                                  y: newBbox.y,
                              },
                              point2: {
                                  x: newBbox.x + newBbox.width,
                                  y: newBbox.y,
                              },
                              value: pile.arcRight.rX,
                              offset: 0,
                          },
                      ]
                    : []) as (VisualizationDimension & { key: string })[]),
            ],
        };
    }
    const pileScaled = {
        x:
            view === "front"
                ? scaleValue(pile.x - (pile.arcLeft?.rX ?? 0), scale)
                : scaleValue(
                      bbox.length -
                          (pile.x + pile.length + (pile.arcRight?.rY ?? 0)),
                      scale
                  ),
        y: scaleValue(pile.z ?? 0, scale),
        width: scaleValue(pile.length, scale),
        height: scaleValue(pile.height, scale),
        arcLeft:
            !!pile.arcLeft?.rX && !!pile.arcLeft?.rY
                ? {
                      rX: scaleValue(pile.arcLeft.rX, scale),
                      rY: scaleValue(pile.arcLeft.rY, scale),
                  }
                : { rX: 0, rY: 0 },
        arcRight:
            !!pile.arcRight?.rX && !!pile.arcRight?.rY
                ? {
                      rX: scaleValue(pile.arcRight.rX, scale),
                      rY: scaleValue(pile.arcRight.rY, scale),
                  }
                : { rX: 0, rY: 0 },
    };

    const firstArc =
        view === "front" ? pileScaled.arcLeft : pileScaled.arcRight;
    const secondArc =
        view === "front" ? pileScaled.arcRight : pileScaled.arcLeft;

    return {
        initial: pile,
        path: [
            `M ${pileScaled.x} ${pileScaled.y}`,
            `a ${firstArc.rX} ${firstArc.rY} 0 0 1 ${firstArc.rX} ${firstArc.rY}`,
            `V ${pileScaled.y + pileScaled.height}`,
            `h ${pileScaled.width}`,
            `v -${pileScaled.height - secondArc.rY}`,
            `a ${secondArc.rX} ${secondArc.rY} 0 0 1 ${secondArc.rX} -${secondArc.rY} Z`,
        ].join(" "),
        bbox: {
            ...pileScaled,
            width: pileScaled.width + firstArc.rX + secondArc.rX,
            height: pileScaled.height + firstArc.rY + secondArc.rY,
        },
        dimensions: [
            {
                key: "width",
                side: "top",
                point1: {
                    x: pileScaled.x + firstArc.rX,
                    y: pileScaled.y,
                },
                point2: {
                    x: pileScaled.x + firstArc.rX + pileScaled.width,
                    y: pileScaled.y,
                },
                value: pile.length,
                offset: 0,
            },
            ...((!!firstArc.rX
                ? [
                      {
                          key: "firstArcX",
                          side: "top",
                          point1: {
                              x: pileScaled.x,
                              y: pileScaled.y,
                          },
                          point2: {
                              x: pileScaled.x + firstArc.rX,
                              y: pileScaled.y,
                          },
                          value:
                              view === "front"
                                  ? pile.arcLeft?.rX ?? 0
                                  : pile.arcRight?.rX ?? 0,
                          offset: 0,
                      },
                  ]
                : []) as (VisualizationDimension & { key: string })[]),
            ...((!!secondArc.rX
                ? [
                      {
                          key: "secondArcX",
                          side: "top",
                          point1: {
                              x: pileScaled.x + pileScaled.width + firstArc.rX,
                              y: pileScaled.y,
                          },
                          point2: {
                              x:
                                  pileScaled.x +
                                  pileScaled.width +
                                  firstArc.rX +
                                  secondArc.rX,
                              y: pileScaled.y,
                          },
                          value:
                              view === "front"
                                  ? pile.arcRight?.rX ?? 0
                                  : pile.arcLeft?.rX ?? 0,
                          offset: 0,
                      },
                  ]
                : []) as (VisualizationDimension & { key: string })[]),
            ...((!!firstArc.rY
                ? [
                      {
                          key: "firstArcY",
                          side: "left",
                          point1: {
                              x: pileScaled.x,
                              y: pileScaled.y,
                          },
                          point2: {
                              x: pileScaled.x + firstArc.rX,
                              y: pileScaled.y + firstArc.rY,
                          },
                          value:
                              view === "front"
                                  ? pile.arcLeft?.rY ?? 0
                                  : pile.arcRight?.rY ?? 0,
                          offset: 1,
                      },
                  ]
                : []) as (VisualizationDimension & { key: string })[]),
            ...((!!secondArc.rY
                ? [
                      {
                          key: "secondArcY",
                          side: "right",
                          point1: {
                              x: pileScaled.x + pileScaled.width + firstArc.rX,
                              y: pileScaled.y,
                          },
                          point2: {
                              x: pileScaled.x + pileScaled.width + firstArc.rX,
                              y: pileScaled.y + secondArc.rY,
                          },
                          value:
                              view === "front"
                                  ? pile.arcRight?.rY ?? 0
                                  : pile.arcLeft?.rY ?? 0,
                          offset: 0,
                      },
                  ]
                : []) as (VisualizationDimension & { key: string })[]),
            {
                key: "height",
                side: "left",
                point1: {
                    x: pileScaled.x,
                    y: pileScaled.y,
                },
                point2: {
                    x: pileScaled.x + pileScaled.arcLeft.rX,
                    y: pileScaled.y + pileScaled.height,
                },
                value: pile.height,
                offset: 0,
            },
        ],
    };
};

export const barriereForVisualization = (
    barriere: Barriere,
    view: string,
    scale: number,
    bbox: Cuboid
): VisualizationElement<Barriere> => {
    const width = barriere.type !== "glissiere" ? 10 : 20;
    const cuboid = cuboidForVisualization(
        { ...barriere, width, z: -barriere.height },
        view,
        scale,
        bbox
    );
    const barriereScaled = cuboid.bbox;
    if (view === "front" || view === "back") {
        const tick = scaleValue(
            barriere.type !== "glissiere" ? 40 : 150,
            scale
        );
        const ticks = Math.floor(barriereScaled.width / tick) - 1;
        const offset = Math.round((barriereScaled.width - ticks * tick) / 2);
        const handle = scaleValue(
            Math.min(barriere.type !== "glissiere" ? 10 : 50, barriere.height),
            scale
        );
        const tickHeight = barriereScaled.height - handle;

        return {
            ...cuboid,
            initial: barriere,
            path: [
                `M ${barriereScaled.x} ${barriereScaled.y} v ${handle} h ${
                    offset - 2
                }`,
                Array.from(Array(ticks).keys()).map(
                    () => `v ${tickHeight} h 4 v ${-tickHeight} h ${tick - 4}`
                ),
                `v ${tickHeight} h 4 v ${-tickHeight} h ${
                    offset - 2
                } v ${-handle} Z`,
            ].join(" "),
        };
    } else if (view === "bottom" || view === "top") {
        return {
            ...cuboid,
            dimensions: cuboid.dimensions.filter((d) => d.key !== "height"),
            initial: barriere,
        };
    }

    const handle = scaleValue(
        Math.min(
            barriere.type !== "glissiere" ? 10 : 50,
            barriereScaled.height
        ),
        scale
    );
    return {
        ...cuboid,
        dimensions: cuboid.dimensions.filter((d) => d.key !== "width"),
        initial: barriere,
        path:
            barriere.type !== "glissiere"
                ? `M ${barriereScaled.x} ${barriereScaled.y} c 5 0 6 6 2 8 v ${
                      barriereScaled.height - 8
                  } h -4 v ${-barriereScaled.height + 8}  c -4 -2 -3 -8 2 -8 Z`
                : `M ${barriereScaled.x} ${
                      barriereScaled.y
                  } h -10 v ${handle} h 3 V ${
                      barriereScaled.y + barriereScaled.height
                  } h 4 v ${handle - barriereScaled.height} h 3 V ${
                      barriereScaled.y
                  } Z`,
    };
};

/*

left right

    const barrieres = blueprint.barrieres
        ?.sort((p1, p2) =>
            view === "left" ? (p1.x < p2.x ? 1 : -1) : p1.x < p2.x ? -1 : 1
        )
        .map((barriere) => {
            const barriereScaled = {
                x:
                    view === "left"
                        ? scaleValue(barriere.y, scale)
                        : tablierScaled.width - scaleValue(barriere.y, scale),
                y: tablierScaled.x - scaleValue(barriere.height, scale),
                width: 10,
                height: scaleValue(barriere.height, scale),
            };

            const handle = scaleValue(
                Math.min(
                    barriere.type !== "glissiere" ? 10 : 50,
                    barriereScaled.height
                ),
                scale
            );
            return {
                initial: barriere,
                path:
                    barriere.type !== "glissiere"
                        ? `M ${barriereScaled.x} ${
                              barriereScaled.y
                          } c 5 0 6 6 2 8 v ${
                              barriereScaled.height - 8
                          } h -4 v ${
                              -barriereScaled.height + 8
                          }  c -4 -2 -3 -8 2 -8 Z`
                        : `M ${barriereScaled.x} ${
                              barriereScaled.y
                          } h -10 v ${handle} h 3 V ${
                              barriereScaled.y + barriereScaled.height
                          } h 4 v ${handle - barriereScaled.height} h 3 V ${
                              barriereScaled.y
                          } Z`,
                bbox: {
                    xMin: barriereScaled.x,
                    yMin: barriereScaled.y,
                    xMax: barriereScaled.x + barriereScaled.width,
                    yMax: barriereScaled.y + barriereScaled.height,
                },
                dimensions: [],
            };
        });
*/

export const buseForVisualization = (
    blueprint: Blueprint,
    view: string,
    scale: number
): VisualizationElement<Buse> | undefined => {
    if (!blueprint.buse || !scale) return;

    const buseScaled = {
        x: 0,
        y: 0,
        width:
            view === "left" || view === "right"
                ? scaleValue(
                      blueprint.buse.diameterH + blueprint.buse.thicknessH * 2,
                      scale
                  )
                : scaleValue(blueprint.buse.length, scale),
        height:
            view === "top" || view === "bottom"
                ? scaleValue(
                      blueprint.buse.diameterH + blueprint.buse.thicknessH * 2,
                      scale
                  )
                : scaleValue(
                      blueprint.buse.diameterV + blueprint.buse.thicknessV * 2,
                      scale
                  ),
    };

    const buseInnerScaled = {
        x:
            view === "left" || view === "right"
                ? scaleValue(blueprint.buse.thicknessH, scale)
                : 0,
        y:
            view === "top" || view === "bottom"
                ? scaleValue(blueprint.buse.thicknessH, scale)
                : scaleValue(blueprint.buse.thicknessV, scale),
        width:
            view === "left" || view === "right"
                ? scaleValue(blueprint.buse.diameterH, scale)
                : scaleValue(blueprint.buse.length, scale),
        height:
            view === "top" || view === "bottom"
                ? scaleValue(blueprint.buse.diameterH, scale)
                : scaleValue(blueprint.buse.diameterV, scale),
    };

    const offsetStart = blueprint.buse.biseauStart
        ? scaleValue(
              (blueprint.buse.diameterV + blueprint.buse.thicknessV * 2) /
                  Math.tan(((90 - blueprint.buse.biseauStart) * Math.PI) / 180),
              scale
          )
        : 0;
    const offsetEnd = blueprint.buse.biseauEnd
        ? scaleValue(
              (blueprint.buse.diameterV + blueprint.buse.thicknessV * 2) /
                  Math.tan(((90 - blueprint.buse.biseauEnd) * Math.PI) / 180),
              scale
          )
        : 0;

    if (view === "top" || view === "bottom") {
        return {
            initial: blueprint.buse,
            path: [
                `M ${buseScaled.x} ${buseScaled.y}`,
                `h ${buseScaled.width}`,
                `v ${buseScaled.height}`,
                `h -${buseScaled.width} Z`,
            ].join(" "),
            bbox: buseScaled,
            topPath:
                offsetEnd || offsetStart
                    ? [
                          `M ${buseScaled.x + offsetStart} ${buseScaled.y}`,
                          `h ${buseScaled.width - (offsetEnd + offsetStart)}`,
                          `v ${buseScaled.height}`,
                          `h -${
                              buseScaled.width - (offsetEnd + offsetStart)
                          } Z`,
                      ].join(" ")
                    : undefined,
            innerPath: [
                `M ${buseInnerScaled.x} ${buseInnerScaled.y}`,
                `h ${buseInnerScaled.width}`,
                `v ${buseInnerScaled.height}`,
                `h -${buseInnerScaled.width} Z`,
            ].join(" "),
            dimensions: [
                {
                    key: "width",
                    side: "top",
                    point1: {
                        x: buseScaled.x,
                        y: buseScaled.y,
                    },
                    point2: {
                        x: buseScaled.x + buseScaled.width,
                        y: buseScaled.y,
                    },
                    value: blueprint.buse.length,
                    offset: 0,
                },
                {
                    key: "height",
                    side: "left",
                    point1: {
                        x: buseScaled.x,
                        y: buseScaled.y,
                    },
                    point2: {
                        x: buseScaled.x,
                        y: buseScaled.y + buseScaled.height,
                    },
                    value:
                        blueprint.buse.diameterH +
                        blueprint.buse.thicknessH * 2,
                    offset: 1,
                },
                {
                    key: "inner-height",
                    side: "left",
                    point1: {
                        x: buseInnerScaled.x,
                        y: buseInnerScaled.y,
                    },
                    point2: {
                        x: buseInnerScaled.x,
                        y: buseInnerScaled.y + buseInnerScaled.height,
                    },
                    value: blueprint.buse.diameterH,
                    offset: 0,
                },
            ],
        };
    }
    if (view === "left" || view === "right") {
        return {
            initial: blueprint.buse,
            bbox: buseScaled,
            path: [buseScaled, buseInnerScaled]
                .map((bs) =>
                    [
                        `M ${bs.x + bs.width / 2} ${bs.y}`,
                        `a ${bs.width / 2} ${bs.height / 2} 0 0 1 ${
                            bs.width / 2
                        } ${bs.height / 2}`,
                        `a ${bs.width / 2} ${bs.height / 2} 0 0 1 ${
                            -bs.width / 2
                        } ${bs.height / 2}`,
                        `a ${bs.width / 2} ${bs.height / 2} 0 0 1 ${
                            -bs.width / 2
                        } ${-bs.height / 2}`,
                        `a ${bs.width / 2} ${bs.height / 2} 0 0 1 ${
                            bs.width / 2
                        } ${-bs.height / 2}`,
                    ].join(" ")
                )
                .join(" Z "),
            dimensions: [
                {
                    key: "width",
                    side: "top",
                    point1: {
                        x: buseScaled.x,
                        y: buseScaled.height / 2,
                    },
                    point2: {
                        x: buseScaled.x + buseScaled.width,
                        y: buseScaled.height / 2,
                    },
                    value:
                        blueprint.buse.diameterH +
                        blueprint.buse.thicknessH * 2,
                    offset: 1,
                },
                {
                    key: "height",
                    side: "left",
                    point1: {
                        x: buseScaled.width / 2,
                        y: buseScaled.y,
                    },
                    point2: {
                        x: buseScaled.width / 2,
                        y: buseScaled.y + buseScaled.height,
                    },
                    value:
                        blueprint.buse.diameterV +
                        blueprint.buse.thicknessV * 2,
                    offset: 1,
                },
                {
                    key: "inner-width",
                    side: "top",
                    point1: {
                        x: buseInnerScaled.x,
                        y: buseScaled.height / 2,
                    },
                    point2: {
                        x: buseInnerScaled.x + buseInnerScaled.width,
                        y: buseScaled.height / 2,
                    },
                    value: blueprint.buse.diameterH,
                    offset: 0,
                },
                {
                    key: "inner-height",
                    side: "left",
                    point1: {
                        x: buseScaled.width / 2,
                        y: buseInnerScaled.y,
                    },
                    point2: {
                        x: buseScaled.width / 2,
                        y: buseInnerScaled.y + buseInnerScaled.height,
                    },
                    value: blueprint.buse.diameterV,
                    offset: 0,
                },
            ],
        };
    }

    const innerOffsetStart = blueprint.buse.biseauStart
        ? scaleValue(
              blueprint.buse.diameterV /
                  Math.tan(((90 - blueprint.buse.biseauStart) * Math.PI) / 180),
              scale
          )
        : 0;
    const innerOffsetEnd = blueprint.buse.biseauEnd
        ? scaleValue(
              blueprint.buse.diameterV /
                  Math.tan(((90 - blueprint.buse.biseauEnd) * Math.PI) / 180),
              scale
          )
        : 0;

    const innerOffsetStartX = blueprint.buse.biseauStart
        ? scaleValue(
              blueprint.buse.thicknessH /
                  Math.tan(((90 - blueprint.buse.biseauStart) * Math.PI) / 180),
              scale
          )
        : 0;
    const innerOffsetEndX = blueprint.buse.biseauEnd
        ? scaleValue(
              blueprint.buse.thicknessH /
                  Math.tan(((90 - blueprint.buse.biseauEnd) * Math.PI) / 180),
              scale
          )
        : 0;

    return {
        initial: blueprint.buse,
        bbox: buseScaled,
        path: [
            `M ${buseScaled.x + (view === "front" ? offsetStart : offsetEnd)} ${
                buseScaled.y
            }`,
            `h ${buseScaled.width - (offsetEnd + offsetStart)}`,
            `l ${view === "front" ? offsetEnd : offsetStart} ${
                buseScaled.height
            }`,
            `h -${buseScaled.width} Z`,
        ].join(" "),
        innerPath: [
            `M ${
                buseInnerScaled.x +
                (view === "front"
                    ? innerOffsetStart + innerOffsetStartX
                    : innerOffsetEnd + innerOffsetEndX)
            } ${buseInnerScaled.y}`,
            `h ${
                buseInnerScaled.width -
                (innerOffsetEnd +
                    innerOffsetEndX +
                    innerOffsetStart +
                    innerOffsetStartX)
            }`,
            `l ${view === "front" ? innerOffsetEnd : innerOffsetStart} ${
                buseInnerScaled.height
            }`,
            `h -${
                buseInnerScaled.width - (innerOffsetEndX + innerOffsetStartX)
            } Z`,
        ].join(" "),
        dimensions: [
            {
                key: "width",
                side: "top",
                point1: {
                    x: buseScaled.x,
                    y: buseScaled.y,
                },
                point2: {
                    x: buseScaled.x + buseScaled.width,
                    y: buseScaled.y,
                },
                value: blueprint.buse.length,
                offset: 0,
            },
            {
                key: "height",
                side: "left",
                point1: {
                    x: buseScaled.x,
                    y: buseScaled.y,
                },
                point2: {
                    x: buseScaled.x,
                    y: buseScaled.y + buseScaled.height,
                },
                value: blueprint.buse.diameterV + blueprint.buse.thicknessV * 2,
                offset: 1,
            },
            {
                key: "inner-height",
                side: "left",
                point1: {
                    x: buseInnerScaled.x,
                    y: buseInnerScaled.y,
                },
                point2: {
                    x: buseInnerScaled.x,
                    y: buseInnerScaled.y + buseInnerScaled.height,
                },
                value: blueprint.buse.diameterH,
                offset: 0,
            },
        ],
    };
};
