// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-duplicates {
  padding-top: 24px;
  width: 410px;
}
.modal-duplicates .modal-duplicates-content {
  padding: 0 12px 24px;
  flex-shrink: 0;
}
.modal-duplicates .modal-duplicates-content > p {
  padding-bottom: 12px;
}
.modal-duplicates .modal-duplicates-content > * {
  margin-bottom: 8px;
}
.modal-duplicates .footer {
  padding: 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
}
.modal-duplicates .footer button {
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalDuplicates/index.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;AACF;AACE;EACE,oBAAA;EACA,cAAA;AACJ;AACI;EACE,oBAAA;AACN;AAEI;EACE,kBAAA;AAAN;AAIE;EACE,aAAA;EACA,aAAA;EACA,WAAA;EACA,yBAAA;EACA,4CAAA;AAFJ;AAII;EACE,gBAAA;AAFN","sourcesContent":[".modal-duplicates {\n  padding-top: 24px;\n  width: 410px;\n\n  & .modal-duplicates-content {\n    padding: 0 12px 24px;\n    flex-shrink: 0;\n\n    & > p {\n      padding-bottom: 12px;\n    }\n\n    & > * {\n      margin-bottom: 8px;\n    }\n  }\n\n  & .footer {\n    padding: 24px;\n    display: flex;\n    width: 100%;\n    justify-content: flex-end;\n    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);\n\n    & button {\n      margin-left: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
