import { Link } from 'react-router-dom';
import noResult from '../../../assets/images/no-result.svg';
import './index.scss';

interface NoResultProps {
    text?: string,
    linkText?: string,
    linkHref?: string,
}

const NoResult = ({
    text,
    linkText,
    linkHref
}: NoResultProps) => (
    <div className="no-result">
        <img src={noResult} alt="" />
        {!!text && <div className="no-result-text">{text}</div>}
        {!!linkText && !!linkHref && <Link to={linkHref}><div className="no-result-link">{linkText}</div></Link>}
    </div>
);

export default NoResult;