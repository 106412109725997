import { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { AnalysisStatistics } from '../../../models/analysis';
import { Phase, PhaseChartColor, PhaseChartColorTransparency, PhaseLabel } from '../../../models/operation';
import { ControleChartColor, ControleChartColorTransparency, Controles } from '../../../models/sample';
import { ListItem } from '../../../models/shared';
import { LayerColors } from '../../../synoptique/Synoptique.class';
import { average } from '../../../utils/maths';
import { StatisticsChartProps } from './StatisticsChart';
import { useMatch } from 'react-router-dom';

const COMMON_DATASET_OPTIONS_TYPE_1 = {
    borderWidth: 1,
    pointRadius: 2,
    spanGaps: true,
}

const COMMON_DATASET_OPTIONS_TYPE_2 = {
    borderWidth: 3,
    pointRadius: 2,
    spanGaps: true,
    type: 'bubble',
}

const getBubbleRadius = (value: number, min?: number, max?: number) => min !== undefined && max !== undefined ? Math.abs((value - min) * 7 / (max - min)) + 3 : 2;

const getDatasets = (statistics: AnalysisStatistics, chartType: string, chartBy: string, phases: Phase[], materials: ListItem[]) => {
    const datasets: any[] = [];
    if (chartType === 'bubble') {
        if (chartBy === 'phase') {
            datasets.push(...phases.map((phase) => ({
                ...COMMON_DATASET_OPTIONS_TYPE_2,
                data: statistics.dataRows.filter(row => row.phase === phase).map(row => ({
                    x: row.coordinates?.longitude,
                    y: row.coordinates?.latitude,
                    r: getBubbleRadius(row.value, statistics.min, statistics.max),
                    value: row.value,
                    problematic: row.problematic,
                    location: row.location,
                })),
                label: PhaseLabel[phase],
                backgroundColor: PhaseChartColorTransparency[phase],
                borderColor: PhaseChartColor[phase],
                pointBackgroundColor: (context: any) => context?.raw?.problematic ? 'rgba(255, 14, 9, 0.7)' : PhaseChartColorTransparency[phase]
            })));
        } else if (chartBy === 'controle') {
            datasets.push(...Controles.map((c) => ({
                ...COMMON_DATASET_OPTIONS_TYPE_2,
                data: statistics.dataRows.filter(row => row.controle === c.key).map(row => ({
                    x: row.coordinates?.longitude,
                    y: row.coordinates?.latitude,
                    r: getBubbleRadius(row.value, statistics.min, statistics.max),
                    value: row.value,
                    problematic: row.problematic,
                    location: row.location,
                })),
                label: c.label,
                backgroundColor: ControleChartColorTransparency[c.key],
                borderColor: ControleChartColor[c.key],
                pointBackgroundColor: (context: any) => context?.raw?.problematic ? 'rgba(255, 14, 9, 0.7)' : ControleChartColorTransparency[c.key]
            })));
        } else if (chartBy === 'material') {
            datasets.push(...Object.keys(statistics.material).map((material, index) => ({
                ...COMMON_DATASET_OPTIONS_TYPE_2,
                data: statistics.dataRows.filter(row => row.material === material).map(row => ({
                    x: row.coordinates?.longitude,
                    y: row.coordinates?.latitude,
                    r: getBubbleRadius(row.value, statistics.min, statistics.max),
                    value: row.value,
                    problematic: row.problematic,
                    location: row.location,
                })),
                label: materials?.find(b => b.key === material)?.label ?? material,
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1],
                pointBackgroundColor: (context: any) => context?.raw?.problematic ? 'rgba(255, 14, 9, 0.7)' : LayerColors[index + 1]
            })));
        }
        return { datasets };
    } else {
        const labels: string[] = [];
        const data: { [k: string | number]: any } = {};
        const dataPerSample: { [k: string | number]: any } = {};
        for (const row of statistics.dataRows) {
            const discrim = row.sample ?? row.location;
            if (!discrim) continue;
            if (!dataPerSample[discrim]) {
                labels.push(row.name ?? row.location ?? '');
                dataPerSample[discrim] = {
                    controle: row.controle,
                    phase: row.phase,
                    material: row.material,
                    name: row.name,
                    values: [],
                }
            }
            dataPerSample[discrim].values.push(row.value);
        }

        if (chartBy === 'phase') {
            for (const sample in dataPerSample) {
                for (const phase of phases) {
                    if (!data[phase]) {
                        data[phase] = [];
                    }
                    data[phase].push(phase === dataPerSample[sample].phase
                        ? average(dataPerSample[sample].values)
                        : null);
                }
            }

            datasets.push(...phases.map((phase) => ({
                ...COMMON_DATASET_OPTIONS_TYPE_1,
                label: PhaseLabel[phase],
                data: data[phase],
                backgroundColor: PhaseChartColor[phase],
                borderColor: PhaseChartColor[phase]
            })));
        } else if (chartBy === 'controle') {
            for (const sample in dataPerSample) {
                for (const controle of Controles) {
                    if (!data[controle.key]) {
                        data[controle.key] = [];
                    }
                    data[controle.key].push(controle.key === dataPerSample[sample].controle
                        ? average(dataPerSample[sample].values)
                        : null);
                }
            }

            datasets.push(...Controles.map((c) => ({
                ...COMMON_DATASET_OPTIONS_TYPE_1,
                label: c.label,
                data: data[c.key],
                backgroundColor: ControleChartColor[c.key],
                borderColor: ControleChartColor[c.key]
            })));
        } else if (chartBy === 'material') {
            for (const sample in dataPerSample) {
                for (const material in statistics.material) {
                    if (!data[material]) {
                        data[material] = [];
                    }
                    data[material].push(material === dataPerSample[sample].material
                        ? average(dataPerSample[sample].values)
                        : null);
                }
            }

            datasets.push(...Object.keys(statistics.material).map((material, index) => ({
                ...COMMON_DATASET_OPTIONS_TYPE_1,
                label: materials?.find(b => b.key === material)?.label ?? material,
                data: data[material],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1],
            })));
        }
        return { labels, datasets };
    }
}

const StatisticsCartesianChart = ({
    data,
    type,
    by,
    scale,
    phases = [],
    materials = []
}: Omit<StatisticsChartProps, 'axis'>) => {
    const { datasets, labels } = useMemo(() => getDatasets(data, type, by, phases, materials), [data, type, by])

    if (!datasets) {
        return null;
    }

    return (
        <Chart
            type={type === 'bubble' ? 'bubble' : 'line'}
            data={{
                datasets: datasets ?? [],
                labels: labels ?? [],
            }}
            options={{
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    tooltip: {
                        callbacks: type === 'bubble' ? {
                            label: (item) => `${(item.raw as any).location}: ${(item.raw as any).value} (${item.dataset?.label})`
                        } : {}
                    }
                },
                scales: type === 'average' ? {
                    y: {
                        min: scale.min,
                        max: scale.max,
                    },
                    x: {
                        title: {
                            display: true,
                            text: 'Référence',
                            align: 'start',
                            font: {
                                weight: 'bold',
                                size: 14,
                                style: 'italic',
                            }
                        }
                    }
                } : {},
                maintainAspectRatio: false,
            }}
        />
    )
}

export default StatisticsCartesianChart;