import { Icon } from '@iconify/react';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PaginatedResults, Pagination } from '../../../models/pagination';
import { deleteRequest, getRequest, patchRequest } from '../../../services/request.service';
import { filtersFromSearchParams, getStringValue, paginationFromSearchParams } from '../../../utils/format';
import { ActionIcon } from '../../../utils/icons';
import { getNestedField, toggleInArray } from '../../../utils/objects';
import FiltersPanel from '../../FiltersPanel';
import Checkbox from '../../inputs/Checkbox';
import SearchInput from '../../inputs/SearchInput';
import Button, { ButtonProps } from '../../ui/Button';
import Menu from '../../ui/Menu';
import ModalDelete from '../../ui/Modal/ModalDelete';
import NoResult from '../../ui/NoResult';
import Tooltip from '../../ui/Tooltip';
import OptionsMenu from '../OptionsMenu';
import PaginationComponent from '../Pagination';
import './index.scss';

type ListAction<T> = boolean | ((e: T) => boolean | undefined);

export interface ListActions<T> {
    view?: ListAction<T>;
    edit?: ListAction<T>;
    activate?: ListAction<T>;
    duplicate?: ListAction<T>;
    delete?: ListAction<T>;
    comment?: ListAction<T>;
}

export enum FilterType {
    MULTIPLE_SELECT = 'multipleSelect',
    SWITCH = 'switch',
    PR = 'pr'
}

export type ListFilter = boolean | string | string[] | number;
export interface ListFilters { [id: string]: ListFilter | undefined };
export interface ListFilterSetting {
    field: string;
    label: string;
    type?: FilterType;
    items?: { key: string | boolean, label: string }[];
    endpoint?: string;
}

export interface ListColumns<T> {
    key: string,
    label: string,
    mapper?: (e: T) => ReactNode
}

export interface ListRef {
    refresh?: () => void;
}

export interface ListProps<T> {
    columns: ListColumns<T>[];
    actions?: ListActions<T>;
    dataEndpoint: string;
    crudEndpoint?: string;
    baseUrl?: string;
    warningBeforeDelete?: boolean;
    withSearch?: boolean;
    filters?: ListFilterSetting[];
    buttons?: ButtonProps[];
    header?: ReactNode;
    onEdit?: (e: T) => void;
    onView?: (e: T) => void;
    onSelect?: (e: T) => void;
    onSelectMultiple?: (e: T[]) => void;
    initialPagination?: Partial<Pagination>;
}

const List = <T extends { _id: string }>({
    columns,
    actions,
    dataEndpoint,
    crudEndpoint,
    baseUrl,
    buttons,
    header,
    warningBeforeDelete,
    withSearch,
    filters: filtersSettings,
    onEdit,
    onView,
    onSelect,
    onSelectMultiple,
    initialPagination = {},
}: ListProps<T>) => {
    const [data, setData] = useState<T[]>([]);
    const [pagination, setPagination] = useState<Partial<Pagination> | null>(null);
    const [toDelete, setToDelete] = useState<string | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [selectedElements, setSelectedElements] = useState<T[]>([]);
    const [filtersPanelOpened, setFiltersPanelOpened] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [URLSearchParams, setURLSearchParams] = useSearchParams();

    const getData = useCallback(async () => {
        if (isLoading) return;

        getRequest<PaginatedResults<T>>(dataEndpoint, {
            params: {
                ...initialPagination,
                ...filtersFromSearchParams(URLSearchParams, filtersSettings),
                ...paginationFromSearchParams(URLSearchParams),
                search: URLSearchParams.get('search')
            },
            errorMessage: 'Une erreur est survenue. Veuillez contacter l\'administrateur.',
            loader: true
        })
            .then((data) => {
                setData(data.data);
                setPagination(data.pagination);
            })
            .catch(() => null)
            .finally(() => setLoading(false));
    }, [dataEndpoint, isLoading, filtersSettings, URLSearchParams, location, initialPagination]);

    const onSearch = useCallback((search: string | undefined) => {
        if (!search) {
            URLSearchParams.delete('search');
            URLSearchParams.delete('page');
            setURLSearchParams(URLSearchParams, { replace: true });
        } else {
            URLSearchParams.set('search', search);
            URLSearchParams.delete('page');
            setURLSearchParams(URLSearchParams, { replace: true });
        }
    }, [URLSearchParams]);

    const onPageChange = useCallback((page: number) => {
        URLSearchParams.set('page', String(page));
        setURLSearchParams(URLSearchParams, { replace: true });
    }, [URLSearchParams]);

    const onPerPageChange = useCallback((perPage: number) => {
        URLSearchParams.set('perPage', String(perPage));
        URLSearchParams.delete('page');
        setURLSearchParams(URLSearchParams, { replace: true });
    }, [URLSearchParams]);

    const onSort = useCallback((sortBy: string) => {
        URLSearchParams.set('sortBy', sortBy);
        URLSearchParams.set('sortDirection', String(pagination?.sortBy !== sortBy || pagination.sortDirection === -1 ? 1 : -1));
        setURLSearchParams(URLSearchParams, { replace: true });
    }, [URLSearchParams, pagination]);

    const handleDelete = useCallback((_id: string) => {
        deleteRequest(`${crudEndpoint}/${_id}`, {
            successMessage: 'Suppression réalisée avec succès',
            errorMessage: 'Une erreur est survenue. Veuillez contacter l\'administrateur.'
        })
            .then(() => getData())
            .catch(() => null)
            .finally(() => {
                setToDelete(null);
            });
    }, [crudEndpoint, getData, pagination]);

    const onAction = useCallback(async (action: keyof ListActions<T>, e: T) => {
        switch (action) {
            case 'view':
                if (typeof onView === 'function') {
                    onView(e);
                } else if (baseUrl) {
                    if (actions?.view === true || (typeof actions?.view === 'function' && actions.view(e))) {
                        navigate(`${baseUrl}/${e._id}`);
                    }
                }
                break;
            case 'edit':
                if (typeof onEdit === 'function') {
                    onEdit(e);
                } else if (baseUrl) {
                    navigate(`${baseUrl}/${e._id}/editer`);
                }
                break;
            case 'activate':
                if (crudEndpoint) {
                    const active = !(e as any).active;
                    patchRequest(`${crudEndpoint}/${e._id}/active`, { active }, {
                        successMessage: (active ? 'Activation' : 'Désactivation') + ' réalisée avec succès',
                        errorMessage: 'Une erreur est survenue. Veuillez contacter l\'administrateur.'
                    })
                        .then(() => getData())
                        .catch(() => null);
                }
                break;
            case 'duplicate':
                if (baseUrl) {
                    navigate(`${baseUrl}/${e._id}/dupliquer`);
                }
                break;
            case 'delete':
                if (crudEndpoint) {
                    if (warningBeforeDelete) {
                        setToDelete(e._id);
                    } else {
                        handleDelete(e._id);
                    }
                }
                break;
            default:
        }
    }, [getData, onView, onEdit, baseUrl, crudEndpoint, handleDelete, warningBeforeDelete]);

    const handleSelect = useCallback((e: T) => {
        if (typeof onSelect === 'function') {
            setSelectedElements([e]);
            onSelect(e);
        } else if (typeof onSelectMultiple === 'function') {
            const _selectedElements = toggleInArray(e, selectedElements, (e1, e2) => e1._id === e2._id);
            setSelectedElements(_selectedElements);
            onSelectMultiple(_selectedElements);
        }
    }, [onSelect, onSelectMultiple, selectedElements]);

    useEffect(() => {
        getData();
    }, [URLSearchParams, dataEndpoint]);

    const headers = useMemo(() => {
        return (
            <div className="list-tr">
                {(!!onSelect || !!onSelectMultiple) && <div className="list-td list-select"></div>}
                {!!columns?.length && columns.map(c => (
                    <div key={c.key} className={`list-td sort${pagination?.sortBy === c.key ? ' active' : ''}`}>
                        <Menu
                            label={c.label}
                            icon={pagination?.sortBy === c.key
                                ? (pagination?.sortDirection === -1 ? "mdi:chevron-down" : "mdi:chevron-up")
                                : "mdi:chevron-up-down"
                            }
                            iconPosition="right"
                            onClick={() => onSort(c.key)}
                        />
                    </div>
                ))}
                {!!actions?.comment && (
                    <div className="list-td list-action md-hide">
                        <Menu
                            label="Infos"
                            onClick={() => onSort('comment')}
                            iconPosition="right"
                            icon={pagination?.sortBy === 'comment'
                                ? (pagination?.sortDirection === -1 ? "mdi:chevron-down" : "mdi:chevron-up")
                                : "mdi:chevron-up-down"
                            }
                        />
                    </div>
                )}
                {!!actions?.activate && (
                    <div className="list-td list-action md-hide">
                        <Menu
                            label="Actif"
                            onClick={() => onSort('active')}
                            iconPosition="right"
                            icon={pagination?.sortBy === 'active'
                                ? (pagination?.sortDirection === -1 ? "mdi:chevron-down" : "mdi:chevron-up")
                                : "mdi:chevron-up-down"
                            }
                        />
                    </div>
                )}
                {!!actions?.edit && <div className="list-td list-action md-hide"><Menu label="Editer" /></div>}
                {(actions?.duplicate || actions?.delete) && <div className="list-td list-action md-hide"></div>}
            </div>
        )
    }, [actions, pagination, columns, onSelect, onSelectMultiple, onSort]);

    const rows = useMemo(() => data.map(entity => {
        return (
            <div
                className="list-tr"
                key={entity._id}
                onClick={(e) => { e.stopPropagation(); onAction('view', entity); }}
            >
                {(!!onSelect || !!onSelectMultiple) && (
                    <div className="list-td list-select">
                        <Checkbox
                            id={`select.${entity._id}`}
                            value={!!selectedElements?.some(s => s._id === entity._id)}
                            onChange={() => handleSelect(entity)} />
                    </div>
                )}
                {columns.map(c => (
                    <div className={`list-td ${!getNestedField(entity, c.key) ? 'no-content' : ''}`} key={`${entity._id}_${c.key}`}>
                        <span className="column-name">{c.label} : </span>
                        {c.mapper && typeof c.mapper === 'function'
                            ? c.mapper(entity)
                            : getStringValue(getNestedField(entity, c.key))}
                    </div>
                ))}
                {!!actions?.comment &&
                    <div className="list-td list-action md-hide">
                        {(actions?.comment === true || (typeof actions?.comment === 'function' && actions.comment(entity))) && !!(entity as any).comment && (
                            <Tooltip text={(entity as any).comment}><Icon icon={ActionIcon.COMMENT_ON} className="icon-button" /></Tooltip>
                        )}
                    </div>}
                {!!actions?.activate === true &&
                    <div className="list-td list-action md-hide">
                        {(actions?.activate === true || (typeof actions?.activate === 'function' && actions.activate(entity))) && (
                            <Checkbox id="active" value={(entity as any)?.active} onChange={() => onAction('activate', entity)} />
                        )}
                    </div>}
                {!!actions?.edit &&
                    <div className="list-td list-action md-hide" onClick={(e) => { e.stopPropagation(); onAction('edit', entity); }}>
                        {(actions?.edit === true || (typeof actions?.edit === 'function' && actions.edit(entity))) && (
                            <Icon icon={ActionIcon.EDIT} className="icon-button" />
                        )}
                    </div>}
                {((actions?.delete === true || (typeof actions?.delete === 'function' && actions.delete(entity))) || (actions?.duplicate === true || (typeof actions?.duplicate === 'function' && actions.duplicate(entity)))) &&
                    <div className="list-td list-action md-hide">
                        <OptionsMenu
                            onDelete={actions?.delete ? () => onAction('delete', entity) : undefined}
                            onDuplicate={actions?.duplicate ? () => onAction('duplicate', entity) : undefined} />
                    </div>}
            </div>
        );
    })
        , [data, actions, selectedElements, columns, onAction, handleSelect, onSelect, onSelectMultiple]);

    return (
        <div className="list-container">
            {(!!header || !!withSearch || !!buttons?.length || !!filtersSettings) && (
                <div className="list-header">
                    <div className="list-header-children">
                        {header}
                        {!!withSearch && (
                            <SearchInput
                                id="search"
                                placeholder="Rechercher..."
                                onChange={onSearch}
                            />
                        )}
                    </div>
                    <div className="list-actions">
                        {buttons?.map(b => <Button className="sm-hide" key={b.label} {...b} />)}
                        {!!filtersSettings && (
                            <Button
                                color="black"
                                onClick={() => setFiltersPanelOpened(!filtersPanelOpened)}
                                icon={ActionIcon.FILTER}
                                label="Filtrer"
                            />
                        )}
                    </div>
                </div>
            )}
            <div className="list-content">
                <div className="list-table">
                    <div className="list-thead">
                        {headers}
                    </div>
                    <div className="list-tbody">
                        {rows}
                    </div>
                </div>
                {!isLoading && !data.length && <NoResult text="Aucune donnée trouvée avec ces paramètres" />}
            </div>
            <FiltersPanel
                filtersSettings={filtersSettings}
                visible={filtersPanelOpened}
                onClose={() => setFiltersPanelOpened(false)}
            />
            <div className="list-footer">
                <div className="list-per-page">
                    <span>Résultats par page :</span>
                    <select className="input-long" value={pagination?.perPage ?? 10} onChange={(event) => onPerPageChange(Number(event.target.value))}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <PaginationComponent pagination={pagination} onPageChange={onPageChange} />
            </div>
            {!!toDelete && (
                <ModalDelete onCancel={() => setToDelete(null)} onSubmit={() => handleDelete(toDelete)} />
            )}
        </div>
    )
}

export default List;