import { CoordinatesXY } from "../models/location";

export type CanvasStyle = {
    strokeStyle: string;
    fillStyle: string;
    lineWidth: number;
    lineDash: number[];
    lineCap: CanvasLineCap;
    font: string;
    textAlign: CanvasTextAlign;
};

class Canvas {
    private defaultStyle: CanvasStyle = {
        strokeStyle: "#0c2c40",
        fillStyle: "#ffffff",
        lineWidth: 2,
        lineDash: [],
        lineCap: "butt",
        font: "12px Roboto",
        textAlign: "start",
    };

    // 2D context, operation and dom ref from React
    public ctx: CanvasRenderingContext2D;
    protected canvasRef: HTMLCanvasElement;

    // Canvas dimensions
    protected canvas = { width: 0, height: 0 };
    // Canvas original dimensions (dimensions before first resize)
    protected canvasOriginal = { width: 0, height: 0 };

    constructor(canvasRef: HTMLCanvasElement) {
        this.canvasRef = canvasRef;
        const ctx = canvasRef.getContext("2d");
        if (!ctx) throw new Error();
        this.ctx = ctx;
        this.canvasOriginal = {
            width: this.canvasRef.offsetWidth,
            height: this.canvasRef.offsetHeight,
        };
    }

    private setCanvasDrawingWidth(width: number) {
        this.ctx.canvas.width = width;
    }

    private setCanvasDrawingHeight(height: number) {
        this.ctx.canvas.height = height;
    }

    protected sizeCanvas() {
        this.setCanvasDrawingHeight(this.canvas.height);
        this.setCanvasDrawingWidth(this.canvas.width);
    }

    public setCanvasSize() {
        this.canvas = {
            width: this.canvasRef.offsetWidth,
            height: this.canvasRef.offsetHeight,
        };

        return this.canvas;
    }

    render() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.sizeCanvas();
        this.setCanvasSize();
    }

    public getCursorPosFromXY(point: CoordinatesXY) {
        return {
            x: point.x - (this.canvasRef?.getBoundingClientRect().x ?? 0),
            y: point.y - (this.canvasRef?.getBoundingClientRect().y ?? 0),
        };
    }

    public getPosFromEvent(event: MouseEvent | TouchEvent) {
        let x = 0;
        let y = 0;
        if (["touchstart", "touchmove", "touchend"].includes(event.type)) {
            const touches = (event as TouchEvent).touches;
            if (touches?.length) {
                const touch = touches[0];
                x = touch.pageX;
                y = touch.pageY;
            }
        } else {
            x = (event as MouseEvent).clientX;
            y = (event as MouseEvent).clientY;
        }
        return { x, y };
    }

    public getCanvasPosFromEvent(event: MouseEvent | TouchEvent) {
        return this.getCursorPosFromXY(this.getPosFromEvent(event));
    }

    protected isInsidePolygon(
        { x, y }: CoordinatesXY,
        polygon: CoordinatesXY[]
    ) {
        // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html#Testing%20a%20Point%20Against%20Many%20Polygons

        let inside = false;
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i]?.x;
            const yi = polygon[i]?.y;
            const xj = polygon[j]?.x;
            const yj = polygon[j]?.y;

            const intersect =
                yi > y !== yj > y &&
                x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) inside = !inside;
        }

        return inside;
    }

    protected isAroundClick(
        clickX: number,
        clickY: number,
        x: number,
        y: number,
        precisionX: number,
        precisionY: number
    ) {
        return this.containsClick(
            clickX,
            clickY,
            x - precisionX,
            y - precisionY,
            precisionX * 2,
            precisionY * 2
        );
    }

    public containsClick(
        clickX: number,
        clickY: number,
        x: number,
        y: number,
        width: number,
        height: number
    ) {
        return (
            clickX >= x &&
            clickY >= y &&
            clickX <= x + width &&
            clickY <= y + height
        );
    }

    public setStyle(style = {}) {
        const s = { ...this.defaultStyle, ...style };
        this.ctx.strokeStyle = s.strokeStyle;
        this.ctx.lineWidth = s.lineWidth;
        this.ctx.fillStyle = s.fillStyle;
        this.ctx.setLineDash(s.lineDash);
        this.ctx.lineCap = s.lineCap;
        this.ctx.font = s.font;
        this.ctx.textAlign = s.textAlign;
    }

    private strokeAndFill(stroke: boolean = true, fill: boolean = false) {
        if (stroke) {
            this.ctx.stroke();
        }
        if (fill) {
            this.ctx.fill();
        }
    }

    public drawRect(
        x: number,
        y: number,
        width: number,
        length: number,
        stroke: boolean = false,
        fill: boolean = false
    ) {
        this.ctx.beginPath();
        this.ctx.rect(
            Math.floor(x),
            Math.floor(y),
            Math.floor(width),
            Math.floor(length)
        );
        this.ctx.closePath();
        this.strokeAndFill(stroke, fill);
    }

    public drawLosange(
        x: number,
        y: number,
        width: number,
        length: number,
        stroke: boolean = false,
        fill: boolean = false
    ) {
        this.ctx.beginPath();
        this.ctx.moveTo(Math.floor(x + length / 2), Math.floor(y));
        this.ctx.lineTo(Math.floor(x + length), Math.floor(y + width / 2));
        this.ctx.lineTo(Math.floor(x + length / 2), Math.floor(y + width));
        this.ctx.lineTo(Math.floor(x), Math.floor(y + width / 2));
        this.ctx.closePath();
        this.strokeAndFill(stroke, fill);
    }

    protected drawPolygon(
        points: CoordinatesXY[],
        stroke: boolean = false,
        fill: boolean = false
    ) {
        if (points.length > 1) {
            this.ctx.beginPath();
            this.ctx.moveTo(Math.floor(points[0].x), Math.floor(points[0].y));
            for (let i = 1; i < points.length; i++) {
                this.ctx.lineTo(
                    Math.floor(points[i].x),
                    Math.floor(points[i].y)
                );
                this.ctx.stroke();
            }
            this.ctx.lineTo(Math.floor(points[0].x), Math.floor(points[0].y));
            this.ctx.stroke();
            this.ctx.closePath();
            this.strokeAndFill(stroke, fill);
        }
    }

    protected drawCircle(
        x: number,
        y: number,
        radius: number,
        stroke: boolean = false,
        fill: boolean = false
    ) {
        this.ctx.beginPath();
        this.ctx.arc(
            Math.floor(x),
            Math.floor(y),
            Math.floor(radius),
            0,
            2 * Math.PI
        );
        this.ctx.closePath();
        this.strokeAndFill(stroke, fill);
    }

    public drawLine(
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        stroke: boolean = true,
        fill: boolean = false
    ) {
        this.ctx.beginPath();
        this.ctx.moveTo(Math.floor(x1), Math.floor(y1));
        this.ctx.lineTo(Math.floor(x2), Math.floor(y2));
        this.strokeAndFill(stroke, fill);
        this.ctx.closePath();
    }

    public drawText(x: number, y: number, text: string) {
        this.ctx.fillText(text, Math.floor(x), Math.floor(y));
    }
}

export default Canvas;
