import ImageList from "../../../../components/ImageList";
import PanelRight from "../../../../components/ui/PanelRight";
import useWorkspace from "../../../../services/hooks/use-workspace";


export interface OuvrageViewPanelProps {
    onClose: () => void;
}

const OuvrageViewPanel = ({ onClose }: OuvrageViewPanelProps) => {
    const { ouvrage } = useWorkspace();

    return (
        <PanelRight
            id="ouvrage-view-panel"
            title={ouvrage.name}
        >
            <ImageList column entityId={ouvrage._id} url={`/ouvrage/${ouvrage._id}/images`} editable />
        </PanelRight>
    )
}
export default OuvrageViewPanel;