import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/ui/Header';
import { MenuBarNavigation } from '../../../components/ui/MenuBar';
import { Lot } from '../../../models/lot';
import { Phase } from '../../../models/operation';
import { SampleType } from '../../../models/sample';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import PopulationList from '../Population/PopulationList';
import SampleList from '../Sample/SampleList';

const PAGES = [{ key: 'prelevement', label: 'Prélèvements' }, { key: 'carotte', label: 'Carottes' }, { key: 'population', label: 'Populations' }]

const LotView = () => {
    const { operation } = useWorkspace();
    const { lotId } = useParams();
    const navigate = useNavigate();
    const [page, setPage] = useState<string | null>(null);
    const [lot, setLot] = useState<Lot | null>(null);

    useEffect(() => {
        getRequest<Lot>(`/lot/${lotId}`, { loader: true })
            .then(setLot)
            .catch(() => navigate(-1));
    }, [lotId]);

    if (!lot) {
        return (null)
    }

    return (
        <Fragment>
            <Header breadcrumbs={[
                { label: operation?.name, href: `/operation/${operation?._id}` },
                { label: 'Travaux', href: `/operation/${operation?._id}/travaux/lot` },
                { label: `Lot ${lot?.fullLot}${lot?.materialPopulated?.name ? ' - ' + lot?.materialPopulated?.name : ''}` }
            ]} />
            <MenuBarNavigation pages={PAGES} setPage={setPage} />
            {!!page && page !== 'population' && <SampleList type={page as SampleType} phase={Phase.TRAVAUX} lot={lot} />}
            {page === 'population' && <PopulationList lot={lot} />}
        </Fragment>
    )
}

export default LotView;