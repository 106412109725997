import { Icon } from '@iconify/react';
import { ReactNode, useState } from 'react';
import './index.scss';

interface DropDownProps {
    title: ReactNode,
    subTitle?: ReactNode,
    className?: string,
    plusMinus?: boolean,
    opened?: boolean,
    children: ReactNode
}

const Dropdown = ({
    title,
    subTitle,
    className,
    plusMinus = false,
    opened = false,
    children
}: DropDownProps) => {
    const [isOpened, setOpened] = useState(opened);
    return (
        <div className={`dropdown-container ${className ?? ''}`}>
            <div className="dropdown-header" onClick={() => setOpened(!isOpened)}>
                <div>
                    <span className="dropdown-title">{title}</span>
                    <span className="dropdown-subtitle">{subTitle}</span>
                </div>
                {!plusMinus && isOpened && <Icon icon="mdi:chevron-up" className="icon-small" />}
                {!plusMinus && !isOpened && <Icon icon="mdi:chevron-down" className="icon-small" />}
                {plusMinus && <div className={`plus-minus${isOpened ? ' opened' : ''}`} />}
            </div>
            <div className={`dropdown-content${isOpened ? ' opened' : ''}`}>
                {children}
            </div>
        </div>
    );
}

export default Dropdown;