import { AnalysisStatistics } from '../../../models/analysis';
import { Phase } from '../../../models/operation';
import { ListItem } from '../../../models/shared';
import useWorkspace from '../../../services/hooks/use-workspace';
import StatisticsCartesianChart from './StatisticsCartesianChart';
import StatisticsLinearChart from './StatisticsLinearChart';
import StatisticsNonLinearChart from './StatisticsNonLinearChart';

export interface StatisticsChartProps {
    axis: string;
    scale: { min: number; max: number };
    data: AnalysisStatistics;
    type: string;
    by: string;
    phases?: Phase[];
    materials?: ListItem[];
    businesses?: ListItem[];
}

const StatisticsChart = ({ axis, ...props }: StatisticsChartProps) => {
    const { operation } = useWorkspace();

    // Chart by pr for road operation
    if (axis === 'pr' && operation.synoptique === 1) {
        return <StatisticsLinearChart {...props} />
    }

    // Chart by pr for zone operation
    if (axis === 'pr' && operation.synoptique !== 1) {
        return <StatisticsCartesianChart {...props} />
    }

    return <StatisticsNonLinearChart axis={axis} {...props} />
}

export default StatisticsChart;