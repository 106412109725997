import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '../../../components/data/List';
import { MenuBarNavigation } from '../../../components/ui/MenuBar';
import { ModalBottom } from '../../../components/ui/Modal/ModalBottom';
import { Material, MaterialListParams, MaterialType, MaterialTypes } from '../../../models/material';
import useWorkspace from '../../../services/hooks/use-workspace';
import './index.scss';

const Requirement = () => {
    const { operation, workspacePermissions } = useWorkspace();
    const navigate = useNavigate();
    const [isExigencePanelVisible, setExigencePanelVisible] = useState(false);
    const [materialType, setMaterialType] = useState<MaterialType>(MaterialType.GRANULAT);

    return (
        <Fragment>
            <MenuBarNavigation pages={MaterialTypes} setPage={(p) => setMaterialType(p as MaterialType)} />
            {!!materialType && !!MaterialListParams[materialType] && (
                <List<Material>
                    columns={MaterialListParams[materialType].columns}
                    filters={MaterialListParams[materialType].filters}
                    dataEndpoint={`/material/operation/${operation._id}/${materialType}`}
                    crudEndpoint={`/material`}
                    baseUrl={`/operation/${operation._id}/exigences/${materialType}`}
                    actions={{
                        comment: workspacePermissions.administrate,
                        edit: workspacePermissions.administrate,
                        duplicate: workspacePermissions.administrate,
                        delete: workspacePermissions.administrate
                    }}
                    warningBeforeDelete
                    buttons={[{ label: "Nouvelle exigence", onClick: () => setExigencePanelVisible(true) }]}
                />
            )}
            {!!isExigencePanelVisible && !!materialType && !!MaterialListParams[materialType] && (
                <ModalBottom
                    visible={isExigencePanelVisible}
                    title="Sélectionner une norme"
                    className="modal-standard-choice"
                    onClose={() => setExigencePanelVisible(false)}
                >
                    <List<Material>
                        columns={MaterialListParams[materialType].columns}
                        dataEndpoint={`/material/standard/${materialType}`}
                        onView={(m) => navigate(`/operation/${operation._id}/exigences/${materialType}/creer/${m._id}`)}
                    />
                </ModalBottom>
            )}
        </Fragment>
    )
}

export default Requirement;