import { Business } from "./business";
import { License } from "./license";

export type User = {
    _id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    business: string;
    businessPopulated?: Business;
    managedLicense?: License;
    licenses?: License[];
    role: Role;
    permissions: Permission[];
    token: string;
    active: boolean;
    connectedAt: number;
};

export type UserLogin = {
    email: string;
    password?: string;
    newPassword?: string;
    newPasswordRepeat?: string;
};

export type UserProfile = User & UserLogin;

export enum Role {
    SUPER_ADMIN = "super_admin",
    USER = "user",
    SUBCONTRACTOR = "subcontractor",
}

export enum WorkspaceRole {
    ADMIN = "admin",
    VALIDATE = "validate",
    WRITE = "write",
    READ = "read",
    // Subcontractors
    EXTERIEUR = "exterieur",
    EXTERNE = "externe",
    AUTRE = "autre",
}

export type UserWorkspaceRole = {
    user: string;
    userPopulated?: User;
    role: WorkspaceRole;
};

export const WorkspaceRoleLabel: { [key in WorkspaceRole]: string } = {
    [WorkspaceRole.ADMIN]: "Gérant",
    [WorkspaceRole.VALIDATE]: "Validateur",
    [WorkspaceRole.WRITE]: "Rédacteur",
    [WorkspaceRole.READ]: "Lecteur",
    [WorkspaceRole.EXTERIEUR]: "Exterieur",
    [WorkspaceRole.EXTERNE]: "Externe",
    [WorkspaceRole.AUTRE]: "Autre",
};

export const WorkspaceRoles = [
    {
        key: WorkspaceRole.ADMIN,
        label: WorkspaceRoleLabel[WorkspaceRole.ADMIN],
    },
    {
        key: WorkspaceRole.VALIDATE,
        label: WorkspaceRoleLabel[WorkspaceRole.VALIDATE],
    },
    {
        key: WorkspaceRole.WRITE,
        label: WorkspaceRoleLabel[WorkspaceRole.WRITE],
    },
    {
        key: WorkspaceRole.READ,
        label: WorkspaceRoleLabel[WorkspaceRole.READ],
    },
];

export const WorkspaceRolesSubcontractors = [
    {
        key: WorkspaceRole.EXTERIEUR,
        label: WorkspaceRoleLabel[WorkspaceRole.EXTERIEUR],
    },
    {
        key: WorkspaceRole.EXTERNE,
        label: WorkspaceRoleLabel[WorkspaceRole.EXTERNE],
    },
    {
        key: WorkspaceRole.AUTRE,
        label: WorkspaceRoleLabel[WorkspaceRole.AUTRE],
    },
];

export const RoleLabel: { [key in Role]: string } = {
    [Role.SUPER_ADMIN]: "Super administrateur",
    [Role.USER]: "Utilisateur",
    [Role.SUBCONTRACTOR]: "Prestataire",
};

export const Roles = [
    {
        key: Role.SUPER_ADMIN,
        label: RoleLabel[Role.SUPER_ADMIN],
        color: "#645150",
    },
    { key: Role.USER, label: RoleLabel[Role.USER], color: "#645150" },
    {
        key: Role.SUBCONTRACTOR,
        label: RoleLabel[Role.SUBCONTRACTOR],
        color: "#645150",
    },
];

export enum Permission {
    LOCALIZED_RESULTS = "localized_results",
    MATERIAL_RESULTS = "material_results",
    SYNOPTIQUE_DATA = "synoptique_data",
    PRINT = "print",
    FILTER = "filter",
    LIBRARY = "library",
}

export const PermissionLabel: { [key in Permission]: string } = {
    [Permission.LOCALIZED_RESULTS]: "Résultats localisés",
    [Permission.MATERIAL_RESULTS]: "Résultats de matériaux",
    [Permission.SYNOPTIQUE_DATA]:
        "Données du synoptique (épaisseurs et populations)",
    [Permission.PRINT]: "Imprimer",
    [Permission.FILTER]: "Filtrer",
    [Permission.LIBRARY]: "Bibliothèque",
};

export const Permissions = [
    {
        key: Permission.LOCALIZED_RESULTS,
        label: PermissionLabel[Permission.LOCALIZED_RESULTS],
    },
    {
        key: Permission.MATERIAL_RESULTS,
        label: PermissionLabel[Permission.MATERIAL_RESULTS],
    },
    {
        key: Permission.SYNOPTIQUE_DATA,
        label: PermissionLabel[Permission.SYNOPTIQUE_DATA],
    },
    { key: Permission.PRINT, label: PermissionLabel[Permission.PRINT] },
    { key: Permission.FILTER, label: PermissionLabel[Permission.FILTER] },
    { key: Permission.LIBRARY, label: PermissionLabel[Permission.LIBRARY] },
];

export const UserIcon = "mdi:user";
