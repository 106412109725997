import { Fragment, useCallback, useEffect, useState } from 'react';
import CharacAddRemove, { SelectedMaterialCharacteristicElement } from '../../../components/CharacAddRemove';
import StatisticsPicker from '../../../components/data/Statistics/StatisticsPicker';
import NumberInput from '../../../components/inputs/NumberInput';
import PrInput from '../../../components/inputs/PrInput';
import Select from '../../../components/inputs/Select';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Header from '../../../components/ui/Header';
import NoResult from '../../../components/ui/NoResult';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { AnalysisStatistics } from '../../../models/analysis';
import { MaterialCharacteristic, UnknownMaterialCharacteristics } from '../../../models/material';
import { RoadPosition, RoadPositionsList } from '../../../models/operation';
import { Permission } from '../../../models/user';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import { ActionIcon } from '../../../utils/icons';

const VALIDATION = {
    way: [{ comparator: FormComparator.REQUIRED }],
    prStart: [{ comparator: FormComparator.PR }],
    prEnd: [{ comparator: FormComparator.PR }],
};

const TEXT_ROAD_OPERATION = "Les résultats issus de mélanges apparaissent ici.";
const TEXT_ZONE_OPERATION = "Les champs de localisation permettent d'affiner les résultats par zone. Ils s'appliquent sur les échantillons utilisés par les mélanges : si le mélange contient au moins un échantillon satisfaisant les paramètres de localisation, alors le mélange est retourné par la requête.";

type MelangesSearch = {
    way: number;
    road: string;
    roadPosition: RoadPosition;
    layer: number;
    prStart: number;
    prEnd: number;
}

const Melanges = () => {
    const { operation, workspacePermissions } = useWorkspace();
    const [availableCharacs, setAvailableCharacs] = useState<MaterialCharacteristic[]>([]);
    const [selectedCharacs, setSelectedCharacs] = useState<SelectedMaterialCharacteristicElement[]>([]);
    const [statistics, setStatistics] = useState<AnalysisStatistics[] | null>(null);
    const { entity, attachInput, validate, setEntity } = useForm<MelangesSearch>({});
    const [areParametersOpened, setParametersOpened] = useState<boolean>(true);

    const clear = useCallback(() => {
        setEntity({});
        setStatistics(null);
        setSelectedCharacs([]);
        setAvailableCharacs([{ key: 'general', label: 'Général', elements: UnknownMaterialCharacteristics }]);
        setParametersOpened(true);
    }, []);

    const handleSearch = useCallback(async () => {
        const entity = validate(operation.synoptique === 1 ? VALIDATION : {});

        if (!entity) return;

        const params = {
            ...entity,
            operation: operation._id,
            characteristics: selectedCharacs.map(charac => ({
                key: charac.key,
                min: charac.searchParams.min,
                max: charac.searchParams.max,
                seuil: charac.searchParams.seuil,
                tolerance: charac.searchParams.tolerance
            }))
        }

        getRequest<AnalysisStatistics[]>('/analysis/melanges-statistics', { params, loader: true, errorMessage: 'Une erreur est survenue lors de la recherche.' })
            .then((data) => {
                setStatistics(data);
                setParametersOpened(false);
            })
            .catch(() => setStatistics(null));
    }, [entity, operation.synoptique === 1, operation, selectedCharacs, validate]);

    useEffect(() => {
        setAvailableCharacs([{ key: 'general', label: 'Général', elements: UnknownMaterialCharacteristics }]);
    }, []);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: operation.name, href: `/operation/${operation._id}` }, { label: 'Résultats mélanges' }]}>
                <Button color="secondary" label="Réinitialiser" onClick={clear} />
                <Button color="primary" icon={ActionIcon.SEARCH} label="Rechercher" onClick={handleSearch} />
            </Header>
            <ScrollableContent
                side={<span className="info">{operation.synoptique === 1 ? TEXT_ROAD_OPERATION : TEXT_ZONE_OPERATION}</span>}
            >
                <Card title="Paramètres" opened={areParametersOpened}>
                    {operation.synoptique === 1 ? (
                        <Fragment>
                            <div className="row">
                                <div className="input-column">
                                    <label htmlFor="way">Sens</label>
                                    <Select items={operation.ways} {...attachInput('way')} />
                                </div>
                                <div className="input-column">
                                    <label htmlFor="road">Voie</label>
                                    <Select items={entity?.way ? operation.roads.filter(r => r.way === entity.way) : operation.roads} {...attachInput('road')} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-column">
                                    <label htmlFor="roadPosition">Emplacement</label>
                                    <Select items={RoadPositionsList} {...attachInput('roadPosition')} />
                                </div>
                                <div className="input-column">
                                    <label htmlFor="layer">Position de couche</label>
                                    <NumberInput {...attachInput('layer')} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-column">
                                    <label htmlFor="prStart">Du PR</label>
                                    <PrInput {...attachInput('prStart')} />
                                </div>
                                <div className="input-column">
                                    <label htmlFor="prEnd">Au PR</label>
                                    <PrInput {...attachInput('prEnd')} />
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="row">
                            <div className="input-column">
                                <label htmlFor="layer">Position de couche</label>
                                <NumberInput {...attachInput('layer')} />
                            </div>
                        </div>
                    )}
                </Card>
                <Card title="Caractéristiques" opened={areParametersOpened}>
                    <CharacAddRemove
                        availableCharacs={availableCharacs}
                        setAvailableCharacs={setAvailableCharacs}
                        selectedCharacs={selectedCharacs}
                        setSelectedCharacs={setSelectedCharacs}
                    />
                </Card>
                {!!statistics?.length && (
                    <Card title="Résultats">
                        <StatisticsPicker
                            statistics={statistics}
                            subtitle={operation.name}
                            columns={['name', 'controle']}
                            chartAxis="name"
                            chartByOptions={[]}
                            permission={Permission.LOCALIZED_RESULTS}
                            isAuthorized={workspacePermissions.localizedResults}
                        />
                    </Card>
                )}
                {statistics && !statistics.length && (
                    <NoResult
                        text="Aucun résultat pour ces paramètres"
                    />
                )}
            </ScrollableContent>
        </Fragment>
    )
}

export default Melanges;