import { Icon } from "@iconify/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DiagnosticIcon } from "../../models/diagnostic";
import { Notification, NotificationType } from "../../models/notification";
import { OperationIcon } from "../../models/operation";
import { PaginatedResults, Pagination } from "../../models/pagination";
import { getRequest } from "../../services/request.service";
import { RootState } from "../../services/store";
import { formatTimestampToLocalString, getFullName } from "../../utils/format";
import PaginationComponent from "../data/Pagination";
import Card from "../ui/Card";
import './index.scss';

const Notifications = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const navigate = useNavigate();
    const { isNotificationsPanelVisible } = useSelector((state: RootState) => state.ui);
    const trackingRefresh = useRef<NodeJS.Timeout | null>(null);

    const getNotifications = async (page?: number) => {
        getRequest<PaginatedResults<Notification>>('/notification', { params: { page: page ?? 1, perPage: 6 } })
            .then((data) => {
                setNotifications(data.data);
                setPagination(data.pagination);
            })
            .catch(() => null);
    }

    const notificationsComponents = useMemo(() => notifications.map(notification => {
        switch (notification.type) {
            case NotificationType.FILE:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}/boite-aux-lettres`)}
                    >
                        <Icon icon="mdi:file" />
                        <div>
                            <span>Un nouveau fichier <strong>{notification.filePopulated?.name ?? ''}</strong> a été publié dans l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong></span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.MESSAGE:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}/boite-aux-lettres`)}
                    >
                        <Icon icon="mdi:message-reply-text" />
                        <div>
                            <span>Nouveau message de <strong>{getFullName(notification.createdByPopulated)}</strong>{notification.operationPopulated ? <span> concernant l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong></span> : ''}</span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.INCIDENT:
                // TODO page
                return (
                    <div
                        key={notification._id}
                        className="notification"
                    >
                        <Icon icon="mdi:bug" />
                        <div>
                            <span>Demande d'étude d'incident {notification.data?.loggingId ?? ''} par <strong>{getFullName(notification.createdByPopulated)}</strong> : {notification.data?.message}</span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.PERMISSION:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/utilisateurs/${notification.createdBy}/editer`)}
                    >
                        <Icon icon="mdi:key" />
                        <div>
                            <span>Demande d'accès à la permission <strong>{notification.data}</strong> par {getFullName(notification.createdByPopulated)}</span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.OPERATION_UPDATE:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}/synoptique`)}
                    >
                        <Icon icon={OperationIcon.OPERATION} />
                        <div>
                            <span>Le synoptique de l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong> a été mis à jour par {getFullName(notification.createdByPopulated)}</span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.OPERATION_DATA:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}/tableau-de-bord`)}
                    >
                        <Icon icon={OperationIcon.OPERATION} />
                        <div>
                            <span><strong>{getFullName(notification.createdByPopulated)}</strong> a rédigé de nouvelles données en attente de validation dans l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong></span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.OPERATION_ADD:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}`)}
                    >
                        <Icon icon={OperationIcon.OPERATION} />
                        <div>
                            <span><strong>{getFullName(notification.createdByPopulated)}</strong> vous a invité a collaborer à l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong></span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                ); case NotificationType.OUVRAGE_DIAGNOSTIC:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/ouvrage/${notification.ouvrage}`)}
                    >
                        <Icon icon={DiagnosticIcon} />
                        <div>
                            <span><strong>{getFullName(notification.createdByPopulated)}</strong> a terminé le diagnostic de l'ouvrage <strong>{notification.ouvragePopulated?.name ?? ''}</strong></span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
        }
    })
        , [notifications]);

    useEffect(() => {
        getNotifications();
        trackingRefresh.current = setInterval(getNotifications, 300000);

        return () => {
            trackingRefresh.current && clearInterval(trackingRefresh.current);
        }
    }, []);

    if (!isNotificationsPanelVisible) return null;

    return (
        <Card id="notifications-container">
            <div id="notifications-title">
                <span>Notifications</span>

            </div>
            <div id="notifications">
                {notificationsComponents}
            </div>
            <PaginationComponent pagination={pagination} onPageChange={(page) => getNotifications(page)} />
        </Card>
    )
}

export default Notifications;