import { FormBaseProps } from '../../../hooks/useForm';
import './index.scss';

interface ToggleProps extends FormBaseProps<boolean> {
    label?: string;
    className?: string;
    disabled?: boolean;
}

const Toggle = ({
    id,
    value,
    label,
    onChange,
    className,
    disabled
}: ToggleProps) => (
    <div className={`toggle ${className ?? ''}`} onClick={!disabled ? (e) => { e.stopPropagation(); onChange(!value, label) } : undefined}>
        {label && <label>{label}</label>}
        <div className={`toggle-input ${value ? 'active' : ''}`}>
            <input
                type="checkbox"
                id={id}
                name={id}
            />
        </div>
    </div>
);

export default Toggle;
