export enum ActionIcon {
    EDIT = "mdi:edit",
    ADD = "mdi:add",
    DELETE = "mdi:trash-can-outline",
    FILTER = "mdi:filter-cog-outline",
    CLOSE = "mdi:close",
    COPY = "mdi:content-copy",
    COMMENT_OFF = "mdi:comment-text-outline",
    COMMENT_ON = "mdi:comment-text",
    VIEW = "mdi:eye-outline",
    SEARCH = "mdi:search",
    PRINT = "mdi:printer",
    GOTO = "mdi:external-link",
    OPEN = "mdi:folder-open",
    SAVE = "mdi:content-save-outline",
    SAVE_CLOSE = "mdi:content-save-move-outline",
    SEND = "mdi:send-variant-outline",
    SELECT = "mdi:select",
}
