import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Permission, Role, User } from "../../models/user";

export interface GlobalPermissions {
    superAdministrate: boolean;
    administrate: boolean;
    print: boolean;
    filter: boolean;
    library: boolean;
    localizedResults: boolean;
    materialResults: boolean;
    synoptiqueData: boolean;
}

export const TOKEN_KEY = "oso-token";

export interface AuthState {
    isAuthenticated: boolean;
    user: User;
    permissions: Partial<GlobalPermissions>;
}

const DEFAULT_USER: User = {
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    business: "",
    role: Role.USER,
    active: false,
    connectedAt: 0,
} as User;

const authSlice = createSlice({
    name: "auth",
    initialState: (): AuthState => ({
        isAuthenticated: !!localStorage.getItem(TOKEN_KEY),
        user: DEFAULT_USER,
        permissions: {},
    }),
    reducers: {
        authenticate: (state, action: PayloadAction<string>) => {
            state.isAuthenticated = true;
            localStorage.setItem(TOKEN_KEY, action.payload);
        },
        setCurrentUser: (state, action: PayloadAction<User>) => {
            const user = action.payload;
            state.user = user;
            state.permissions = {
                superAdministrate: user.role === Role.SUPER_ADMIN,
                administrate:
                    user.role === Role.SUPER_ADMIN || !!user.managedLicense,
                print:
                    user.role === Role.SUPER_ADMIN ||
                    !!user.managedLicense?.permissions?.includes(
                        Permission.PRINT
                    ),
                filter:
                    user.role === Role.SUPER_ADMIN ||
                    !!user.managedLicense?.permissions?.includes(
                        Permission.FILTER
                    ),
                library:
                    user.role === Role.SUPER_ADMIN ||
                    !!user.managedLicense?.permissions?.includes(
                        Permission.LIBRARY
                    ),
                localizedResults:
                    user.role === Role.SUPER_ADMIN ||
                    !!user.managedLicense?.permissions?.includes(
                        Permission.LOCALIZED_RESULTS
                    ),
                materialResults:
                    user.role === Role.SUPER_ADMIN ||
                    !!user.managedLicense?.permissions?.includes(
                        Permission.MATERIAL_RESULTS
                    ),
                synoptiqueData:
                    user.role === Role.SUPER_ADMIN ||
                    !!user.managedLicense?.permissions?.includes(
                        Permission.SYNOPTIQUE_DATA
                    ),
            };
        },
        logout: (state, _: PayloadAction<void>) => {
            state.isAuthenticated = false;
            state.user = DEFAULT_USER;
            state.permissions = {};
            localStorage.removeItem(TOKEN_KEY);
        },
    },
});

export const { authenticate, logout, setCurrentUser } = authSlice.actions;
export default authSlice.reducer;
