import Axios from "axios";

const handleDates = (body: any) => {
    if (body === null || body === undefined) return body;

    if (Array.isArray(body)) {
        for (const element of body) {
            handleDates(element);
        }
    } else {
        for (const key of Object.keys(body)) {
            const value = body[key];

            if (
                value &&
                typeof value === "string" &&
                /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(value)
            )
                body[key] = new Date(value);
            else if (typeof value === "object") handleDates(value);
        }
    }
    return body;
};

const AxiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:3005",
    timeout: 60000,
    headers: { "Content-Type": "application/json" },
    transformResponse: [
        ...(!Axios.defaults.transformResponse
            ? []
            : Array.isArray(Axios.defaults.transformResponse)
            ? Axios.defaults.transformResponse
            : [Axios.defaults.transformResponse]),
        handleDates,
    ],
});

AxiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        // Don't log if logging doesn't work
        if (error.response.config.url !== "/logging") {
            let _id: string | undefined = undefined;
            try {
                const response = await AxiosInstance.post("/logging", {
                    error: error.response,
                });
                _id = response.data._id;
            } catch {}
            return Promise.reject({
                error: error.response,
                _id,
            });
        }

        return Promise.reject({ error: error.response });
    }
);

export default AxiosInstance;
