import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Icon } from "@iconify/react";
import { Fragment, useEffect } from 'react';
import SampleAnalysis from '../../../components/Analysis/SampleAnalysis';
import SampleUnknownAnalysis from '../../../components/Analysis/SampleUnknowAnalysis';
import Checkbox from '../../../components/inputs/Checkbox';
import CommentPopup from '../../../components/inputs/CommentPopup';
import NumberInput from "../../../components/inputs/NumberInput";
import Select from '../../../components/inputs/Select';
import Switch from '../../../components/inputs/Switch';
import TextInput from '../../../components/inputs/TextInput';
import { Analysis } from '../../../models/analysis';
import { CoucheType, CoucheTypes } from '../../../models/lot';
import { TypeGranulat, TypeGranulats } from '../../../models/material';
import { Phase } from '../../../models/operation';
import { Fissure, Sample, SampleLayer, SampleType } from '../../../models/sample';
import useWorkspace from "../../../services/hooks/use-workspace";
import { LayerColors } from '../../../synoptique/Synoptique.class';
import { ActionIcon } from "../../../utils/icons";
import './index.scss';

export type LayerAction = 'copy' | 'delete';

interface LayerProps {
    sample: Partial<Sample>;
    layer: SampleLayer;
    onAction: (a: LayerAction) => void;
    onChange: (l: Partial<SampleLayer>) => void;

}

const LayerComponent = ({
    sample,
    layer,
    onAction,
    onChange
}: LayerProps) => {
    const { operation } = useWorkspace();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: layer.order + 1 });
    const isSingleLayer = sample?.type === SampleType.PRELEVEMENT || !!sample?.lot;
    const withMaterialChoice = !sample?.lot;
    const isEtude = sample.phase && [Phase.ETUDE, Phase.PREPARATOIRE].includes(sample.phase);

    useEffect(() => {
        if (!layer?.material && sample?.lotPopulated?.material) {
            onChange({ ...layer, material: sample.lotPopulated.material });
        }
    }, [layer]);

    return (
        <div
            className="sample-layer"
            ref={setNodeRef}
            style={{
                borderLeft: `${LayerColors[layer.order] ?? '#d94032'} 24px solid`,
                transform: CSS.Transform.toString(transform),
                transition
            }}
        >
            <Icon icon="mdi:drag" className="sample-layer-handler" {...attributes} {...listeners} />
            <div className="sample-layer-title">
                <h4>{isSingleLayer ? 'Couche unique' : `Couche ${layer.order + 1}`}</h4>
                <div className="layer-actions">
                    {!isSingleLayer
                        ? (
                            <Fragment>
                                <CommentPopup id="comment" value={layer?.comment} onChange={(comment) => onChange({ ...layer, comment })} />
                                <Icon icon={ActionIcon.COPY} className="icon-button" onClick={() => onAction('copy')} />
                                <Icon icon={ActionIcon.DELETE} className="icon-button" onClick={() => onAction('delete')} />
                            </Fragment>
                        ) : (
                            <CommentPopup id="comment" value={layer?.comment} onChange={(comment) => onChange({ ...layer, comment })} />
                        )}
                </div>
            </div>
            <div className="row">
                <div className="input-column">
                    <label htmlFor="thickness">Epaisseur (cm) *</label>
                    <NumberInput
                        id="thickness"
                        value={layer?.thickness}
                        onChange={(thickness) => onChange({ ...layer, thickness })}
                    />
                </div>
                {isEtude && (
                    <Fragment>
                        <div className="input-column">
                            <label htmlFor="supposedMaterial">Produit supposé</label>
                            <TextInput
                                id="supposedMaterial"
                                value={layer?.supposedMaterial ?? ''}
                                onChange={(supposedMaterial) => onChange({ ...layer, supposedMaterial: supposedMaterial as string })}
                            />
                        </div>
                        <div className="input-column">
                            <label htmlFor="typeCouche">Type de couche</label>
                            <Select
                                id="typeCouche"
                                value={layer?.typeCouche ?? ''}
                                items={CoucheTypes}
                                onChange={(typeCouche) => onChange({ ...layer, typeCouche: typeCouche as CoucheType })}
                            />
                        </div>
                        <div className="input-column">
                            <label htmlFor="typeCouche">Type de granulat</label>
                            <Select
                                id="typeGranulat"
                                value={layer?.typeGranulat ?? ''}
                                items={TypeGranulats}
                                onChange={(typeGranulat) => onChange({ ...layer, typeGranulat: typeGranulat as TypeGranulat })}
                            />
                        </div>
                    </Fragment>
                )}
                {!isEtude && (
                    <div className="input-column">
                        <label htmlFor="material">Produit</label>
                        <Select
                            id="material"
                            value={layer?.material ?? ''}
                            disabled={!withMaterialChoice}
                            endpoint={`/material/list/product/${operation?._id}`}
                            onChange={(material) => onChange({ ...layer, material })}
                        />
                    </div>
                )}
            </div>
            {!isEtude && layer?.material && (
                <Fragment>
                    <h5>Analyses</h5>
                    <SampleAnalysis
                        materialId={layer.material}
                        analysis={layer.analysisPopulated ?? {}}
                        onChange={(analysisPopulated) => onChange({ ...layer, analysisPopulated: analysisPopulated as Analysis })}
                        options={{ minMax: true }}
                    />
                </Fragment>
            )}
            {isEtude && (
                <Fragment>
                    <h5>Analyses</h5>
                    <SampleUnknownAnalysis
                        analysis={layer.analysisPopulated ?? {}}
                        onChange={(analysisPopulated) => onChange({ ...layer, analysisPopulated: analysisPopulated as Analysis })}
                    />
                </Fragment>
            )}
            <h5>Pathologies</h5>
            <div className="row">
                {sample?.type === SampleType.CAROTTE && (
                    <div className="input-column">
                        <label htmlFor="collage">Collage</label>
                        <Switch<boolean>
                            id="collage"
                            className="form-switch"
                            items={[{ key: true, label: 'Oui' }, { key: false, label: 'Non' }]}
                            value={!!layer?.collage}
                            onChange={(collage) => onChange({ ...layer, collage })}
                        />
                    </div>
                )}
                <div className="input-column">
                    <label htmlFor="fracture">Fracture</label>
                    <Switch<Fissure | undefined>
                        id="fracture"
                        className="form-switch"
                        items={[{ key: Fissure.TOTAL, label: 'Totale' }, { key: Fissure.PARTIAL, label: 'Partielle' }, { key: undefined, label: 'Non' }]}
                        value={layer?.fracture ?? undefined}
                        onChange={(fracture) => onChange({ ...layer, fracture })}
                    />
                </div>
                <div className="input-column">
                    <label htmlFor="fissure">Fissure</label>
                    <Switch<Fissure | undefined>
                        id="fissure"
                        className="form-switch"
                        items={[{ key: Fissure.TOTAL, label: 'Totale' }, { key: Fissure.PARTIAL, label: 'Partielle' }, { key: undefined, label: 'Non' }]}
                        value={layer?.fissure ?? undefined}
                        onChange={(fissure) => onChange({ ...layer, fissure })}
                    />
                </div>
            </div>
            {!isSingleLayer && (
                <div className="row">
                    <Checkbox
                        id="problematic"
                        value={!!layer.problematic || layer.collage === false || !!layer.fissure || !!layer.fracture}
                        disabled={!isEtude || !!layer.analysisPopulated?.problematic || layer.collage === false || !!layer.fissure || !!layer.fracture}
                        label="Couche problématique"
                        className="form-checkbox"
                        onChange={(problematic) => problematic !== undefined ? onChange({ ...layer, problematic }) : null}
                    />
                </div>
            )}
        </div>
    )
}

export default LayerComponent;