import { EntityFormRules, FormComparator } from "../hooks/useForm";
import { BorneType, RoadPosition } from "./operation";

export type Coordinates = {
    latitude: number;
    longitude: number;
    zoom?: number;
    manual?: boolean;
};

export interface CoordinatesXY {
    x: number;
    y: number;
}

export interface CoordinatesXYZ extends CoordinatesXY {
    z: number;
}

export type OperationLocation = {
    location?: string;
    coordinates?: Coordinates;
    coordinatesXY?: CoordinatesXY;
    pr?: number;
    way?: number;
    road?: string;
    roadPosition?: RoadPosition;
};

export type OperationZone = {
    prStart?: number;
    prEnd?: number;
    location?: string;
    marker?: Coordinates;
    markerXY?: CoordinatesXY;
    polygon?: Coordinates[];
    polygonXY?: CoordinatesXY[];
};

export type PrCoordinates = {
    pr: number;
    latitude: number;
    longitude: number;
};

export type LocatedEntity =
    | { longitude: number; latitude: number }
    | { line: PrCoordinates[] }
    | { zone: OperationZone }
    | { location: OperationLocation };

export interface GpsLocation {
    latitude: number;
    longitude: number;
    zoom?: number;
    manual?: boolean;
    projection: CoordinatesXY;
}

export interface PrLocation {
    way?: number;
    pr?: number;
    prEnd?: number;
}

export interface NetworkLocation {
    referential?: string;
    type?: BorneType;
    way1?: PrLocation;
    way2?: PrLocation;
}

export interface Address {
    streetNumber?: string;
    route?: string;
    city?: string;
    country?: string;
    postalCode?: string;
}

export interface Location {
    address?: Address;
    gps: GpsLocation;
    network?: NetworkLocation;
}

export const LOCATION_VALIDATION: EntityFormRules = {
    "location.gps.latitude": [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.NUMBER },
    ],
    "location.gps.longitude": [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.NUMBER },
    ],
    "location.address.country": [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.MIN_LENGTH, compareTo: 3 },
    ],
    "location.address.city": [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.MIN_LENGTH, compareTo: 3 },
    ],
};
