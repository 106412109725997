import { Operation } from "./operation";
import { BasicEntity } from "./shared";
import { Permission, User } from "./user";

export type License = BasicEntity & {
    reference: string;
    start: Date;
    end: Date;
    manager: string;
    managerPopulated?: User;
    users: string[];
    usersPopulated?: User[];
    permissions: Permission[];
    operationsQuota: number;
    operationsCount?: number;
    operationsPopulated?: Operation[];
    usersPerOperationQuota: number;
    ouvragesQuota: number;
    ouvragesCount?: number;
    ouvragesPopulated?: Operation[];
    usersPerouvrageQuota: number;
};

export interface PermissionActive {
    _id: string;
    active: boolean;
}

export interface QuotaCurrent {
    _id: string;
    quota: number;
}

export const LicenseIcon = "mdi:id-card-outline";
