import { Icon } from '@iconify/react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Statistics from '../../../../components/data/Statistics';
import IconLink from '../../../../components/ui/IconLink';
import { Modal } from '../../../../components/ui/Modal';
import { ModalBottom } from '../../../../components/ui/Modal/ModalBottom';
import NoResult from '../../../../components/ui/NoResult';
import Tooltip from '../../../../components/ui/Tooltip';
import { AnalysisStatistics } from '../../../../models/analysis';
import { Lot } from '../../../../models/lot';
import { Population, PopulationColor, PopulationTypeLabel } from '../../../../models/population';
import { Permission } from '../../../../models/user';
import useWorkspace from '../../../../services/hooks/use-workspace';
import { getRequest } from '../../../../services/request.service';
import { Direction } from '../../../../synoptique/Synoptique.class';
import { formatNumberToFixedDecimal } from '../../../../utils/format';
import { ActionIcon } from '../../../../utils/icons';
import LotCanvas from './LotCanvas';
import PopulationStatistics from './PopulationStatistics';

interface LotViewProps {
    layers: Lot[];
    selectedLayer?: string;
    currentDirection: Direction;
    onSelectLayer: (l: Lot | null) => void;
    onClose: () => void;
}

const LotView = ({
    layers,
    selectedLayer,
    currentDirection,
    onSelectLayer,
    onClose,
}: LotViewProps) => {
    const { operation, workspacePermissions } = useWorkspace();
    const navigate = useNavigate();
    const [selectedPopulation, setSelectedPopulation] = useState<Population | null>(null);
    const [thicknesses, setThicknesses] = useState<AnalysisStatistics | null>(null);
    const [populations, setPopulations] = useState<Population[]>([]);

    const selectedLot = useMemo(() => layers.find(l => l._id === selectedLayer), [selectedLayer, layers]);

    const getLotData = async (_id: string) => {
        getRequest<{
            populations: Population[];
            thicknesses?: AnalysisStatistics;
        }>(`/lot/data/${_id}`)
            .then(data => {
                setPopulations(data.populations);
                setThicknesses(data?.thicknesses?.dataCount ? data.thicknesses : null);
                setSelectedPopulation(data.populations?.length ? data.populations[0] : null);
            })
            .catch(() => {
                setPopulations([]);
                setThicknesses(null);
                setSelectedPopulation(null);
            });
    }

    useEffect(() => {
        if (selectedLayer) {
            getLotData(selectedLayer);
        }
    }, [selectedLayer]);

    return (
        <ModalBottom visible={true} onClose={onClose} title="Lots sélectionnés">
            <div id='lot-view-container'>
                <div className="title">Sélectionner une couche pour voir le détail des données :</div>
                <LotCanvas
                    layers={layers}
                    currentDirection={currentDirection}
                    onSelectLayer={onSelectLayer}
                />
                {selectedLayer && (
                    <Modal
                        title={`Données lot ${selectedLot?.fullLot} (${selectedLot?.materialPopulated?.name})`}
                        className="lot-data"
                        actions={[
                            { label: 'Fermer', color: 'secondary', onClick: () => onSelectLayer(null) }
                        ]}
                    >
                        {!populations?.length && !thicknesses?.dataCount
                            ? (
                                <NoResult
                                    text="Aucune donnée pour ce lot"
                                    linkText={workspacePermissions.write ? "Ajouter une population" : ""}
                                    linkHref={`/operation/${operation?._id}/travaux/lot/${selectedLayer}/population/creer`}
                                />
                            ) : (
                                <Fragment>
                                    <div className="lot-data-side">
                                        <div>
                                            <h5>Populations</h5>
                                            {workspacePermissions.write && <IconLink type="add" label="Ajouter" onClick={() => navigate(`/operation/${operation?._id}/travaux/lot/${selectedLayer}/population/creer`)} />}
                                        </div>
                                        {populations.map(p => (
                                            <div key={p._id} onClick={() => setSelectedPopulation(p)} className={`lot-side-item ${selectedPopulation?._id === p._id ? 'active' : ''}`} style={{ borderLeft: `${PopulationColor[p.type]} 16px solid` }}>
                                                <div className="lot-side-item-title"><strong>{PopulationTypeLabel[p.type]}</strong></div>
                                                <div className="lot-side-item-statistics">
                                                    {p.statistics?.average ? <span>Moy: <strong>{formatNumberToFixedDecimal(p.statistics.average)}</strong></span> : ''}
                                                    {p.statistics?.min ? <span>Min: <strong>{formatNumberToFixedDecimal(p.statistics.min)}</strong></span> : ''}
                                                    {p.statistics?.max ? <span>Max: <strong>{formatNumberToFixedDecimal(p.statistics.max)}</strong></span> : ''}
                                                </div>
                                                <div className={`lot-side-item-problematic ${p.problematicDataCount ? 'problematic' : ''}`}>
                                                    {!p.problematicDataCount ? 'Aucune donnée problématique' : `${p.problematicDataCount} données problématiques`}
                                                </div>
                                                <div className="lot-population-actions">
                                                    {!!p.comment && <Tooltip text={p.comment}><Icon icon={ActionIcon.COMMENT_ON} className="icon-button" /></Tooltip>}
                                                    {workspacePermissions.write && <Icon icon={ActionIcon.EDIT} className="icon-button" onClick={() => navigate(`/operation/${operation?._id}/travaux/population/${p._id}/editer`)} />}
                                                </div>
                                            </div>
                                        ))}
                                        {!!thicknesses?.dataCount && (
                                            <Fragment>
                                                <h5>Epaisseurs</h5>
                                                <div onClick={() => setSelectedPopulation(null)} className={`lot-side-item ${!selectedPopulation ? 'active' : ''}`} style={{ borderLeft: 'lightgrey 16px solid' }}>
                                                    <div className="lot-side-item-title"><strong>Carottages ({thicknesses.dataCount})</strong></div>
                                                    <div className="lot-side-item-statistics">
                                                        <span>Moy: <strong>{formatNumberToFixedDecimal(thicknesses.average)}</strong></span>
                                                        <span>Min: <strong>{formatNumberToFixedDecimal(thicknesses.min)}</strong></span>
                                                        <span>Max: <strong>{formatNumberToFixedDecimal(thicknesses.max)}</strong></span>
                                                    </div>
                                                    <div className={`lot-side-item-problematic ${thicknesses.problematicDataCount ? 'problematic' : ''}`}>
                                                        {!thicknesses.problematicDataCount ? 'Aucune donnée problématique' : `${thicknesses.problematicDataCount} données problématiques`}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                    <div className="lot-data-content">
                                        {selectedPopulation && selectedLot && <PopulationStatistics population={selectedPopulation} lot={selectedLot} />}
                                        {!selectedPopulation && thicknesses && (
                                            <div className="lot-population-content" style={{ borderLeft: 'lightgrey 4px solid' }}>
                                                <Statistics
                                                    data={thicknesses}
                                                    columns={['pr', 'road', 'roadPosition', 'material', 'controle']}
                                                    title={`Epaisseurs lot ${selectedLot?.fullLot}`}
                                                    subtitle={operation.name}
                                                    filename={`Epaisseurs lot ${selectedLot?.fullLot}`}
                                                    chartByOptions={['controle', 'material']}
                                                    chartAxis="pr"
                                                    isAuthorized={workspacePermissions.synoptiqueData}
                                                    permission={Permission.SYNOPTIQUE_DATA}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Fragment>
                            )}
                    </Modal>
                )}
            </div>
        </ModalBottom>
    )
}

export default LotView;