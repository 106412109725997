import { ReactNode, useState } from 'react';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { Diagnostic, getFinalGrade } from '../../../models/diagnostic';
import { OuvrageElement } from '../../../models/ouvrage';
import { OuvrageMarker } from '../../../models/ouvrage-marker';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import OuvrageMarkerForm from '../OuvrageMarkerForm';
import Visualization from '../Visualization';
import { ElementMarker } from '../Visualization/components/ElementMarkers';
import VisualisationLinks from '../Visualization/components/VisualizationLinks';
import VisualizationMarker from '../Visualization/components/VisualizationMarker';
import './index.scss';

interface DiagnosticViewProps {
    diagnostic: Partial<Diagnostic>;
    onElementSelect: (element: OuvrageElement) => void;
    elementSelected?: OuvrageElement;
    children?: ReactNode;
}

const DiagnosticView = ({ diagnostic, onElementSelect, elementSelected, children }: DiagnosticViewProps) => {
    const { ouvrage } = useWorkspace();
    const [markerToEdit, setMarkerToEdit] = useState<Partial<OuvrageMarker>>();
    const [markers, setMarkers] = useState<OuvrageMarker[]>([]);

    const getMarkers = () => diagnostic._id ? getRequest<OuvrageMarker[]>('/ouvrage-marker', { params: { ouvrage: ouvrage._id, diagnostic: diagnostic._id }, loader: true })
        .then(setMarkers)
        .catch(() => null) : null;

    return (
        <ScrollableContent id="diagnostic-view" noScroll noPadding>
            <Visualization
                ouvrage={ouvrage}
                onMarker={markerToEdit && !markerToEdit?.coordinates ? (coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates }) : undefined}
                onMarkerInit={() => setMarkerToEdit({ ouvrage: ouvrage._id, element: elementSelected?._id, diagnostic: diagnostic._id })}
            >
                {!!elementSelected?.coordinates && <VisualisationLinks from={elementSelected?.coordinates} to={markers.filter(m => m.element === elementSelected._id)} />}
                {ouvrage.elements.map(element => {
                    const diagnosticElement = diagnostic.elements?.find(e => e.elementId === element._id);

                    return (
                        <ElementMarker
                            key={element._id}
                            element={element}
                            selected={elementSelected?._id === element._id}
                            onClick={!markerToEdit ? () => onElementSelect(element) : undefined}
                            className={diagnosticElement ? `element-marker-grade-${getFinalGrade(diagnosticElement)}` : undefined}
                        />
                    )
                })}
                {markers.map(marker => (
                    <VisualizationMarker
                        key={marker._id}
                        selected={markerToEdit?._id === marker._id}
                        marker={marker}
                        onClick={!markerToEdit ? () => setMarkerToEdit(marker) : undefined}
                        hide={marker._id === markerToEdit?._id}
                    />
                ))}
                {!!markerToEdit?.coordinates && <VisualizationMarker marker={markerToEdit} onMove={(coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates })} />}
            </Visualization>
            {children}
            {!!markerToEdit?.coordinates && <OuvrageMarkerForm marker={markerToEdit} onClose={() => setMarkerToEdit(undefined)} onSubmit={getMarkers} />}
        </ScrollableContent>
    )
}

export default DiagnosticView;