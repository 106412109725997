import { Icon } from '@iconify/react';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.scss';

export type Breadcrumb = { label: string, href?: string };

interface BreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
}

const Breadcrumbs = ({
    breadcrumbs
}: BreadcrumbsProps) => {
    const navigate = useNavigate()

    return (
        <div id="breadcrumbs" className="sm-hide">
            <Icon icon="mdi:arrow-back" className="icon-button color-black" onClick={() => navigate(-1)} />
            <Icon icon="mdi:home-variant" className="icon-button color-silver-three" onClick={() => navigate('/')} />
            <div id="breacrumbs-breadcrumbs">
                <Icon icon="mdi:chevron-right" className="icon-small breadcrumb-delimiter" />
                {breadcrumbs.map((breadcrumb, i) => i === breadcrumbs.length - 1
                    ? <span key={i}>{breadcrumb.label}</span>
                    : (
                        <Fragment key={i}>
                            <Link to={breadcrumb.href ?? '.'} key={i}>{breadcrumb.label}</Link>
                            <Icon icon="mdi:chevron-right" className="icon-small breadcrumb-delimiter" />
                        </Fragment>
                    )
                )}
            </div>
        </div>
    );
}

export default Breadcrumbs;