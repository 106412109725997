import { useCallback, useState } from "react";
import { RevisionEntity, RevisionStatus, RevisionStatusColor, RevisionStatusLabel } from "../../../models/shared";
import { patchRequest } from "../../../services/request.service";
import CommentModal from "../../CommentModal";
import Button from "../../ui/Button";
import Card from "../../ui/Card";
import './index.scss';

interface ValidationCardProps<T extends RevisionEntity> {
    entity: Partial<T>;
    endpoint: string;
    canValidate?: boolean;
    onSubmit: () => void;
}

const ValidationCard = <T extends RevisionEntity>({ entity, canValidate, endpoint, onSubmit }: ValidationCardProps<T>) => {
    const [validation, setValidation] = useState<boolean | null>(null);

    const handleSubmit = useCallback((validation: boolean, comment: string | undefined) => {
        patchRequest(`${endpoint}/${entity._id}/validate`, { validation, comment }, {
            loader: true,
            successMessage: 'Validation enregistrée avec succès',
            errorMessage: 'Une erreur est survenue lors de la validation',
        })
            .then(() => {
                setValidation(null);
                onSubmit();
            })
            .catch(() => null);
    }, [endpoint, entity]);

    return (
        <Card className="validation-card">
            {entity.status && <div>Status: <span style={{ color: RevisionStatusColor[entity.status] }}>{RevisionStatusLabel[entity.status]}</span></div>}
            {!!canValidate && entity.status === RevisionStatus.PENDING && (
                <div className="validation-actions">
                    <Button color="error" label="Rejeter" onClick={() => setValidation(false)} />
                    <Button color="success" label="Valider" onClick={() => setValidation(true)} />
                </div>
            )}
            {validation !== null && (
                <CommentModal
                    title={validation ? 'Valider' : 'Rejeter'}
                    allowEmpty
                    placeholder="Commenter la décision..."
                    onClose={() => setValidation(null)}
                    onSubmit={(comment) => handleSubmit(validation, comment)}
                />
            )}
        </Card>
    )
}

export default ValidationCard;