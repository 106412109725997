import { Fragment } from 'react';
import { ModalHelperModal } from '../components/ui/ModalHelper';
import Spinner from '../components/ui/Spinner';
import { ToastQueue } from '../components/ui/Toast';
import useAuth from '../services/hooks/use-auth.hook';
import './index.scss';
import Private from './Private';
import Public from './Public';

const Index = () => {
    const { isAuthenticated } = useAuth();

    return (
        <Fragment>
            <Spinner />
            <ToastQueue />
            <ModalHelperModal />
            {!isAuthenticated ? <Public /> : <Private />}
        </Fragment>
    )
}
export default Index;