// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-standard {
  width: 400px;
  transition: width 0.2s ease-in-out;
}
.modal-standard > .modal-content {
  min-height: 300px;
}
.modal-standard.modal-standard-list {
  width: 80%;
}
.modal-standard.modal-standard-list > .modal-content {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalStandard/index.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kCAAA;AACJ;AACI;EACI,iBAAA;AACR;AAEI;EACI,UAAA;AAAR;AAEQ;EACI,UAAA;AAAZ","sourcesContent":[".modal-standard {\n    width: 400px;\n    transition: width 0.2s ease-in-out;\n\n    & > .modal-content {\n        min-height: 300px;\n    }\n\n    &.modal-standard-list {\n        width: 80%;\n\n        & > .modal-content {\n            padding: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
