import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { SynoptiqueAdvancedFilters, SynoptiqueFilters } from '..';
import CheckboxList from '../../../../components/inputs/CheckboxList';
import Button from '../../../../components/ui/Button';
import MenuBar from '../../../../components/ui/MenuBar';
import { LotIcon } from '../../../../models/lot';
import { UnknownMaterialCharacteristics } from '../../../../models/material';
import { MelangeIcon } from '../../../../models/melange';
import { OperationIcon, Phases } from '../../../../models/operation';
import { PopulationTypes } from '../../../../models/population';
import useWorkspace from '../../../../services/hooks/use-workspace';
import { SynoptiqueAction } from '../../../../synoptique/Synoptique.class';
import { ActionIcon } from '../../../../utils/icons';
import { changeFieldValue } from '../../../../utils/objects';
import { prToFloat } from '../../../../utils/pr';

interface MenuProps {
    currentAction?: SynoptiqueAction;
    onActionChange: (a: SynoptiqueAction) => void;
    onGoToPr: (pr: number) => void;
    filters: SynoptiqueFilters;
    onChange: (f: SynoptiqueFilters) => void;
    advancedFilters: SynoptiqueAdvancedFilters;
    toggleFilters: () => void;
    onAdvancedFiltersSubmit: (f: SynoptiqueAdvancedFilters) => void;
}

/* 
<Button
color="black"
onClick={() => setPanelVisible(!isPanelVisible)}
icon={ActionIcon.FILTER}
label="Filtrer"
/> */

const SynoptiqueMenu = ({
    currentAction,
    onActionChange,
    onChange,
    onGoToPr,
    toggleFilters,
    filters,
    advancedFilters: advancedFiltersFromProps,
    onAdvancedFiltersSubmit
}: MenuProps) => {
    const { operation } = useWorkspace();
    const [pr, setPr] = useState('');
    const [advancedFilters, setAdvancedFilters] = useState<SynoptiqueAdvancedFilters>(advancedFiltersFromProps);

    const handleAdvancedFiltersChange = (id: string, value: any) => setAdvancedFilters(changeFieldValue(advancedFilters, id, value));

    const handleGoToPr = (event: any) => {
        if (event?.preventDefault) {
            event.preventDefault();
        }
        onGoToPr(prToFloat(pr));
    }

    useEffect(() => {
        setAdvancedFilters(advancedFiltersFromProps);
    }, [advancedFiltersFromProps]);

    return (
        <div id="synoptique-menu" className="no-print">
            <MenuBar card>
                {operation.synoptique === 1 && (
                    <MenuBar.Item label="Aller au PR" icon={ActionIcon.SEARCH} stopPropagation>
                        <form id="goto-input" onSubmit={handleGoToPr}>
                            <input type="number" placeholder="PR" onWheel={(e) => e.currentTarget.blur()} onChange={(e) => setPr(e.target.value)} />
                            <Icon icon={ActionIcon.SEARCH} className="icon-button" onClick={handleGoToPr} onTouchEnd={handleGoToPr} />
                        </form>
                    </MenuBar.Item>
                )}
                <MenuBar.Item label="Phases" icon={OperationIcon.PHASE}>
                    <CheckboxList id="synoptique-phases" value={filters.phase} items={Phases.filter(p => operation.phases.includes(p.key))} onChange={(phase) => onChange({ ...filters, phase })} />
                </MenuBar.Item>
                <MenuBar.Item className="sm-hide" label="Filtres" icon={ActionIcon.FILTER} onClick={toggleFilters} />
                <MenuBar.Item className="sm-hide" label="Filtres avancés" icon={ActionIcon.FILTER}>
                    <div className="advanced-filter">
                        <label>Echantillons</label>
                        <select value={advancedFilters?.sampleValue?.key ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.key', e.target.value)}>
                            <option value=""></option>
                            {UnknownMaterialCharacteristics.map(c => <option key={c.key} value={c.key}>{c.label}</option>)}
                        </select>
                        <div>
                            <span>entre</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleValue?.min ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.min', e.target.value)} />
                            <span>et</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleValue?.max ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.max', e.target.value)} />
                            <span>sur la couche</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleValue?.layer ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.layer', e.target.value)} />
                        </div>
                    </div>
                    <div className="advanced-filter">
                        <label>Epaisseur</label>
                        <div>
                            <span>entre</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleThickness?.min ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleThickness.min', e.target.value)} />
                            <span>et</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleThickness?.max ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleThickness.max', e.target.value)} />
                            <span>sur la couche</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleThickness?.layer ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleThickness.layer', e.target.value)} />
                        </div>
                    </div>
                    <div className="advanced-filter">
                        <label>Populations</label>
                        <select value={advancedFilters?.populationValue?.key ?? ''} onChange={(e) => handleAdvancedFiltersChange('populationValue.key', e.target.value)}>
                            <option value=""></option>
                            {PopulationTypes.map(p => <option key={p.key} value={p.key}>{p.label}</option>)}
                        </select>
                        <div>
                            <span>entre</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.populationValue?.min ?? ''} onChange={(e) => handleAdvancedFiltersChange('populationValue.min', e.target.value)} />
                            <span>et</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.populationValue?.max ?? ''} onChange={(e) => handleAdvancedFiltersChange('populationValue.max', e.target.value)} />
                        </div>
                    </div>
                    <div>
                        <div className="advanced-filter-actions">
                            <Button color="secondary" label="Effacer" onClick={() => onAdvancedFiltersSubmit({})} />
                            <Button color="primary" label="Valider" onClick={() => onAdvancedFiltersSubmit(advancedFilters)} />
                        </div>
                    </div>
                </MenuBar.Item>
            </MenuBar>
            <MenuBar card>
                <MenuBar.Item
                    label="Mode de sélection"
                    icon={
                        currentAction === SynoptiqueAction.SELECT_LAYER
                            ? LotIcon.LAYER :
                            currentAction === SynoptiqueAction.SELECT_ONE
                                ? "mdi:eyedropper" :
                                currentAction === SynoptiqueAction.SELECT_ONE_BY_ONE
                                    ? "mdi:eyedropper-plus" :
                                    currentAction === SynoptiqueAction.SELECT_MULTIPLE
                                        ? "mdi:select" :
                                        currentAction === SynoptiqueAction.SELECT_MELANGE
                                            ? MelangeIcon.MELANGE : undefined
                    }
                >
                    <MenuBar.SubItem label="Couche" icon={LotIcon.LAYER} onClick={() => onActionChange(SynoptiqueAction.SELECT_LAYER)} />
                    <MenuBar.SubItem label="Echantillon" icon="mdi:eyedropper" onClick={() => onActionChange(SynoptiqueAction.SELECT_ONE)} />
                    <MenuBar.SubItem label="Echantillons un par un" icon="mdi:eyedropper-plus" onClick={() => onActionChange(SynoptiqueAction.SELECT_ONE_BY_ONE)} />
                    <MenuBar.SubItem label="Plusieurs échantillons" icon="mdi:select" onClick={() => onActionChange(SynoptiqueAction.SELECT_MULTIPLE)} />
                    <MenuBar.SubItem label="Mélange" icon={MelangeIcon.MELANGE} onClick={() => onActionChange(SynoptiqueAction.SELECT_MELANGE)} />
                </MenuBar.Item>
                <MenuBar.Item label="Zoom" icon="mdi:zoom-out-outline">
                    <MenuBar.SubItem label="Zoom avant" icon="mdi:zoom-in-outline" onClick={() => onActionChange(SynoptiqueAction.ZOOM_IN)} />
                    <MenuBar.SubItem label="Zoom arrière" icon="mdi:zoom-out-outline" onClick={() => onActionChange(SynoptiqueAction.ZOOM_OUT)} />
                </MenuBar.Item>
                <MenuBar.Item className="sm-hide" label="Imprimer" icon={ActionIcon.PRINT} onClick={() => onActionChange(SynoptiqueAction.PRINT)} />
            </MenuBar>
        </div >
    );
}

export default SynoptiqueMenu;