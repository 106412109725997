import { BasicEntity } from "./shared";
import { User } from "./user";

export type Business = BasicEntity & {
    name: string;
    address?: string;
    postCode: string;
    city?: string;
    country?: string;
    types: BusinessType[];
    producerType?: ProducerType[];
    providedMaterials?: ProvidedMaterial[];
    usersPopulated?: User[];
};

export enum ProvidedMaterial {
    ADDITIF = "additif",
    ENROBE = "enrobe",
    GRANULAT = "granulat",
    LIANT = "liant",
}

export const ProvidedMaterialLabel = {
    [ProvidedMaterial.ADDITIF]: "Additif",
    [ProvidedMaterial.ENROBE]: "Enrobé",
    [ProvidedMaterial.GRANULAT]: "Granulat",
    [ProvidedMaterial.LIANT]: "Liant",
};

export const ProvidedMaterials = [
    {
        key: ProvidedMaterial.ADDITIF,
        label: ProvidedMaterialLabel[ProvidedMaterial.ADDITIF],
    },
    {
        key: ProvidedMaterial.ENROBE,
        label: ProvidedMaterialLabel[ProvidedMaterial.ENROBE],
    },
    {
        key: ProvidedMaterial.GRANULAT,
        label: ProvidedMaterialLabel[ProvidedMaterial.GRANULAT],
    },
    {
        key: ProvidedMaterial.LIANT,
        label: ProvidedMaterialLabel[ProvidedMaterial.LIANT],
    },
];

export enum ProducerType {
    FIXE = "fixe",
    MOBILE = "mobile",
}

export const ProducerTypeLabel = {
    [ProducerType.FIXE]: "Centrale fixe",
    [ProducerType.MOBILE]: "Centrale mobile",
};

export const ProducerTypes = [
    { key: ProducerType.FIXE, label: ProducerTypeLabel[ProducerType.FIXE] },
    { key: ProducerType.MOBILE, label: ProducerTypeLabel[ProducerType.MOBILE] },
];

export enum BusinessType {
    PRODUCER = "producer",
    PROVIDER = "provider",
}

export const BusinessTypeLabel = {
    [BusinessType.PRODUCER]: "Producteur",
    [BusinessType.PROVIDER]: "Fournisseur",
};

export const BusinessTypes = [
    {
        key: BusinessType.PRODUCER,
        label: BusinessTypeLabel[BusinessType.PRODUCER],
        color: "#397d92",
    },
    {
        key: BusinessType.PROVIDER,
        label: BusinessTypeLabel[BusinessType.PROVIDER],
        color: "#faa97a",
    },
];

export const BusinessIcon = "mdi:building";
