import { Business } from "./business";
import { Coordinates } from "./location";
import { MaterialCharacteristicElement, MaterialType } from "./material";
import { Melange } from "./melange";
import { Phase, RoadPosition } from "./operation";
import { Controle, Sample } from "./sample";

export type Analysis = {
    _id: string;
    material?: string;
    type?: MaterialType;
    sample?: string;
    samplePopulated?: Sample;
    melange?: string;
    melangePopulated?: Melange;
    operation: string;
    date: Date;
    controle: Controle;
    business?: string;
    businessPopulated?: Business;
    data: AnalysisData;
    problematic: boolean;
    comment?: string;
    createdAt: number;
    updatedAt: number;
};

export type AnalysisDatum = {
    value: number;
    seuil?: number;
    problematic: boolean;
};

export type AnalysisData = {
    [field: string]: AnalysisDatum | AnalysisDatum[];
};

export type AnalysisSimplified = Analysis & {
    _id: string;
    date: Date;
    controle: Controle;
    sample?: string;
    melange?: string;
    business?: string;
    material?: string;
    data: AnalysisData;
    phase?: Phase;
    pr?: number;
    road?: string;
    roadPosition?: RoadPosition;
    position?: number;
    coordinates?: Coordinates;
    location?: string;
    name?: string;
};

export type AnalysisDataRow = AnalysisSimplified & {
    value: number;
    problematic?: boolean;
};

export class MinMaxAverage {
    min?: number;
    max?: number;
    average?: number;
}

export type ValuesMinMaxAverage = { values: number[] } & MinMaxAverage;

export type StatisticsPerFilter = Record<string, ValuesMinMaxAverage>;

export type AnalysisStatistics = {
    key: string;
    seuil?: number;
    average?: number;
    min?: number;
    max?: number;
    controle: StatisticsPerFilter;
    business: StatisticsPerFilter;
    material: StatisticsPerFilter;
    phase: StatisticsPerFilter;
    road: StatisticsPerFilter;
    roadPosition: StatisticsPerFilter;
    data: StatisticsChartData[];
    dataRows: AnalysisDataRow[];
    dataCount: number;
    problematicDataCount: number;
};

export type StatisticsChartData = {
    groupIndex: number | string;
    label?: string;
    controle: StatisticsPerFilter;
    business: StatisticsPerFilter;
    material: StatisticsPerFilter;
    phase: StatisticsPerFilter;
    road: StatisticsPerFilter;
    roadPosition: StatisticsPerFilter;
    average?: number;
};

export const AnalysisIcon = "ph:test-tube-fill";

export const getCharacValue = (
    analysis: Partial<Analysis>,
    characteristic: MaterialCharacteristicElement,
    seuil?: number
) => {
    if (characteristic.seuils) {
        const datum = Array.isArray(analysis?.data?.[characteristic.key])
            ? (analysis?.data?.[characteristic.key] as AnalysisDatum[])
            : [];
        return datum?.find((v) => v.seuil === seuil)?.value;
    } else if (characteristic.isBoolean) {
        const datum: AnalysisDatum | null = !Array.isArray(
            analysis?.data?.[characteristic.key]
        )
            ? (analysis?.data?.[characteristic.key] as AnalysisDatum)
            : null;
        return datum?.value;
    } else {
        const datum: AnalysisDatum | null = !Array.isArray(
            analysis?.data?.[characteristic.key]
        )
            ? (analysis?.data?.[characteristic.key] as AnalysisDatum)
            : null;
        return datum?.value;
    }
};

export const setCharacValue = (
    analysis: Partial<Analysis>,
    field: string,
    value: any,
    seuil?: number
): Partial<Analysis> => {
    const data = { ...analysis?.data };
    let dataField = data[field];
    if (!dataField) {
        if (seuil !== undefined) {
            dataField = [{ value, seuil, problematic: false }];
        } else {
            dataField = { value, problematic: false };
        }
    } else if (Array.isArray(dataField)) {
        const index = dataField.findIndex((s) => s.seuil === seuil);
        if (index >= 0) {
            dataField[index] = { seuil, value, problematic: false };
        } else {
            dataField.push({ seuil, value, problematic: false });
        }
    } else {
        dataField.value = value;
        dataField.problematic = false;
    }

    return { ...analysis, data: { ...data, [field]: dataField } };
};
