import { useState } from 'react';
import Map, { MapAction, Marker } from '.';
import { Coordinates } from '../../../models/location';
import { Modal } from '../../ui/Modal';
import './index.scss';

interface MapModalProps {
    title: string;
    action: MapAction;
    marker?: Coordinates;
    polygon?: Coordinates[];
    zone?: Coordinates[];
    center?: Coordinates;
    onSubmit: (data: { marker?: Coordinates, polygon?: Coordinates[] }) => void;
    onCancel?: () => void;
}

const MapModal = ({
    title,
    action = MapAction.MARKER,
    center,
    zone,
    marker: markerFromProps,
    polygon: polygonFromProps,
    onSubmit,
    onCancel
}: MapModalProps) => {
    const [marker, setMarker] = useState<Coordinates | null>(action === MapAction.MARKER && markerFromProps ? markerFromProps : null);
    const [polygon, setPolygon] = useState<Coordinates[] | null>(action === MapAction.POLYGON && polygonFromProps ? polygonFromProps : null);

    const handleActionComplete = (a: MapAction, data: { marker: Coordinates, polygon?: Coordinates[] }) => {
        if (a === MapAction.MARKER) {
            setMarker(data.marker);
        } else if (a === MapAction.POLYGON && polygon) {
            setPolygon(polygon);
        }
    }

    return (
        <Modal
            title={title}
            className="map-modal"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onCancel },
                { label: 'Confirmer', onClick: () => onSubmit({ marker: marker ?? undefined, polygon: polygon ?? undefined }) },
            ]}
        >
            <Map
                center={center}
                onActionComplete={handleActionComplete}
                polygon={polygonFromProps}
                base={zone}
                initialAction={action === MapAction.POLYGON && polygonFromProps ? undefined : action}
                availableActions={[action, MapAction.STYLE]}
            >
                {!!marker?.longitude && !!marker?.latitude && <Marker className="color-blue" longitude={marker.longitude} latitude={marker.latitude} />}
            </Map>
        </Modal>
    )
}

export default MapModal;