import { Icon } from '@iconify/react';
import { MouseEvent, ReactNode, useCallback } from 'react';
import './index.scss';

interface MenuItemProps {
    label?: string;
    children?: ReactNode;
    icon?: string;
    className?: string;
    onClick?: () => void;
}

export const MenuItem = ({ label, children, className, icon, onClick }: MenuItemProps) => (
    <div className={`menu-item ${className ?? ''}`} onClick={onClick}>
        {!!icon && <Icon icon={icon} className="icon-small color-steel" />}
        {!!label && <span>{label}</span>}
        {children}
    </div>
)

interface MenuProps {
    label?: string;
    icon?: string;
    iconPosition?: 'right' | 'left';
    children?: ReactNode;
    childrenPosition?: 'right' | 'left';
    onClick?: () => void;
    className?: string;
    active?: boolean;
}

const Menu = ({ label, icon, iconPosition, children, childrenPosition, onClick, className, active }: MenuProps) => {

    const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
        if (onClick) {
            e.stopPropagation();
            onClick();
        }
    }, [onClick]);

    return (
        <div className={`menu ${className ?? ''} ${active ? 'active' : ''}`} onClick={handleClick}>
            {!!icon && iconPosition !== 'right' && <Icon icon={icon} className="icon-small color-steel" />}
            {!!label && <span>{label}</span>}
            {!!icon && iconPosition === 'right' && <Icon icon={icon} className="icon-small color-steel" />}
            {!!children && (
                <div className={`menu-dropdown menu-dropdown-${childrenPosition ?? 'left'}`}>
                    {children}
                </div>
            )}
        </div>
    )
}

export default Menu;