import { useEffect, useState } from 'react';
import { Analysis } from '../../models/analysis';
import { Material, MaterialCharacteristicElementRequirement, getAnalysisCharac } from '../../models/material';
import { ListItem } from '../../models/shared';
import { getRequest } from '../../services/request.service';
import AnalysisCell from './AnalysisCell';
import AnalysisHeader, { AnalysisMinMax } from './AnalysisHeader';
import './index.scss';

interface SampleAnalysisProps {
    materialId?: string;
    analysis: Partial<Analysis>;
    options: { [k: string]: boolean };
    onChange: (a: Partial<Analysis>) => void;
}

const SampleAnalysis = ({
    materialId,
    analysis,
    options,
    onChange,
}: SampleAnalysisProps) => {
    const [characteristics, setCharacteristics] = useState<MaterialCharacteristicElementRequirement[]>([]);
    const [producers, setProducers] = useState<ListItem[]>([]);

    useEffect(() => {
        if (materialId) {
            getRequest<Material>(`/material/${materialId}`)
                .then((material) => {
                    setCharacteristics(getAnalysisCharac(material));

                    if (material.business && !analysis.business) {
                        onChange({ ...analysis, business: material.business });
                    }
                })
                .catch(() => setCharacteristics([]));
        }
    }, [materialId]);

    useEffect(() => {
        getRequest<ListItem[]>(`/business/list/producer`)
            .then(setProducers)
            .catch(() => null)
    }, []);

    if (!characteristics) {
        return (null);
    }

    return !!characteristics.length ? (
        <div className="table-container">
            <table>
                <AnalysisHeader characteristics={characteristics} options={{ ...options, producer: true, minMax: true }} />
                <tbody>
                    <AnalysisMinMax characteristics={characteristics} options={{ ...options, producer: true, minMax: true }} minOrMax="max" />
                    {typeof onChange === 'function'
                        ? (
                            <tr className="editing">
                                {options?.minMax && <td />}
                                {options?.date && <td />}
                                {options?.controle && <td />}
                                {options?.provider && <td />}
                                {options?.producer && <td />}
                                <td>
                                    <select className="input-long" value={analysis?.business ?? ''} onChange={(e) => onChange({ ...analysis, business: e.target.value })}>
                                        <option value=""></option>
                                        {producers.map(c => <option key={c.key} value={c.key}>{c.label}</option>)}
                                    </select>
                                </td>
                                {characteristics.map(c => <AnalysisCell key={c.key} isEditing characteristic={c} analysis={analysis} onChange={onChange} />)}
                            </tr>
                        ) : (
                            <tr className={analysis?.controle ?? ''}>
                                {options?.minMax && <td />}
                                {options?.date && <td />}
                                {options?.controle && <td />}
                                {options?.provider && <td />}
                                {options?.producer && <td />}
                                <td>{analysis.businessPopulated?.name}</td>
                                {characteristics.map(c => <AnalysisCell key={c.key} characteristic={c} analysis={analysis} />)}
                            </tr>
                        )
                    }
                    <AnalysisMinMax characteristics={characteristics} options={{ ...options, producer: true, minMax: true }} minOrMax="min" />
                </tbody>
            </table >
        </div >
    ) : (<div className="no-characteristic">Aucune caractéristique renseignée dans l'exigence</div>);
}

export default SampleAnalysis;