import { Fragment } from 'react';
import { MaterialCharacteristicElementRequirement } from '../../models/material';
import './index.scss';

interface AnalysisHeaderProps {
    characteristics: MaterialCharacteristicElementRequirement[];
    options?: any;
}

export const AnalysisMinMax = ({
    characteristics,
    minOrMax = 'max',
    options
}: AnalysisHeaderProps & { minOrMax?: 'min' | 'max' }) => (
    <tr className={minOrMax}>
        {options?.minMax && <td className="td-sample">{minOrMax === 'max' ? 'Max.' : 'Min.'} exigence</td>}
        {options?.date && <td />}
        {options?.status && <td />}
        {options?.controle && <td />}
        {options?.road && <td />}
        {options?.roadPosition && <td />}
        {options?.pr && <td />}
        {options?.provider && <td />}
        {options?.producer && <td />}
        {options?.thickness && <td />}
        {options?.collage && <td />}
        {options?.diameter && <td />}
        {characteristics.map(c => {
            if (!Array.isArray(c.requirement)) {
                return (<td className="th-full with-border" key={c.key}>{(c.requirement as any)?.[minOrMax] ?? ''}</td>);
            } else {
                return (
                    <Fragment key={c.key}>
                        {c.seuils?.map((s, index) => (
                            <td key={index} className={index === 0 ? 'th-seuil with-border' : 'th-seuil'}>
                                {Array.isArray(c.requirement) ? c.requirement.find(r => r.seuil === s.seuil)?.[minOrMax] ?? '' : ''}
                            </td>
                        ))}
                    </Fragment>
                );
            }
        })}
        {options?.comment && <th className="with-border" />}
        {options?.actions && <td />}
    </tr>
);

const AnalysisHeader = ({
    characteristics,
    options
}: AnalysisHeaderProps) => (
    <thead>
        <tr>
            {options?.minMax && <th className="th-full">Intitulé</th>}
            {options?.date && <th className="th-full">Date</th>}
            {options?.status && <th className="th-full">Statut</th>}
            {options?.controle && <th className="th-full">Controle</th>}
            {options?.road && <th className="th-full">Voie</th>}
            {options?.roadPosition && <th className="th-full">Position</th>}
            {options?.pr && <th className="th-full">PR</th>}
            {options?.provider && <th className="th-full">Fournisseur</th>}
            {options?.producer && <th className="th-full">Producteur</th>}
            {options?.thickness && <th className="th-full">Epaisseur</th>}
            {options?.collage && <th className="th-full">Collage</th>}
            {options?.diameter && <th className="th-full">Diamètre</th>}
            {characteristics.map(c => (
                <th key={c.key} colSpan={c.seuils?.length || 1} className="with-border">
                    {c.label}
                </th>
            ))}
            {options?.comment && <th className="th-full with-border">Commentaire</th>}
            {options?.actions && <th className="sticky-menu" />}
        </tr>
        <tr className="tr-seuils">
            {options?.minMax && <th />}
            {options?.date && <th />}
            {options?.status && <th />}
            {options?.controle && <th />}
            {options?.road && <td />}
            {options?.roadPosition && <td />}
            {options?.pr && <td />}
            {options?.provider && <th />}
            {options?.producer && <th />}
            {options?.thickness && <th />}
            {options?.collage && <th />}
            {options?.diameter && <th />}
            {characteristics.map(c => !c.seuils
                ? (<th className="th-full with-border" key={c.key}>{c.unit}</th>)
                : (c.seuils.map((s, index) => <th key={c.key + index} className={index === 0 ? 'th-seuil with-border' : 'th-seuil'}>{s.seuil}{c.seuilUnit ? <span> {c.seuilUnit}</span> : ''}</th>))
            )}
            {options?.comment && <th className="with-border" />}
            {options?.actions && <th className="sticky-menu" />}
        </tr>
    </thead>
);

export default AnalysisHeader;