import { Fragment, useCallback, useEffect, useState } from 'react';
import { HomeMapFilters } from '..';
import Buttons, { ButtonsMultiple } from '../../../components/inputs/Buttons';
import CheckboxList from '../../../components/inputs/CheckboxList';
import { Modal } from '../../../components/ui/Modal';
import { OperationSubTypes, Phases } from '../../../models/operation';
import { OuvrageTypes } from '../../../models/ouvrage';
import { ListItem } from '../../../models/shared';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import { WorkspaceType } from '../../../services/slices/workspace.slice';

export interface HomeFiltersProps {
    filters?: Partial<HomeMapFilters>;
    onSubmit: (f?: Partial<HomeMapFilters>) => void;
    onClose: () => void;
}

const HomeFilters = ({ filters: filtersFromProps, onSubmit, onClose }: HomeFiltersProps) => {
    const [filters, setFilters] = useState<Partial<HomeMapFilters> | undefined>(filtersFromProps);
    const { workspace } = useWorkspace();
    const [businesses, setBusinesses] = useState<ListItem[]>([]);
    const [standards, setStandards] = useState<ListItem[]>([]);


    const handleSubmit = useCallback(() => {
        onSubmit(filters ?? undefined);
        onClose();
    }, [filters, onSubmit, onClose]);

    useEffect(() => {
        getRequest<ListItem[]>('/business/list')
            .then(setBusinesses)
            .catch(() => setBusinesses([]));
        getRequest<ListItem[]>('/material/list/standards')
            .then(setStandards)
            .catch(() => setStandards([]));
    }, []);

    return (
        <Modal
            title="Filtres"
            id="home-filters-modal"
            size="medium"
            actions={[
                { label: 'Effacer', color: 'secondary', onClick: () => setFilters(undefined) },
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit }
            ]}
        >
            <div>
                <h4>Trier par</h4>
                <div className="filter-buttons">
                    <Buttons items={[
                        { key: 'date', label: 'Date' },
                        { key: 'name', label: 'Nom' },
                        { key: 'project', label: 'Projet' }
                    ]}
                        value={filters?.sortBy}
                        onChange={(sortBy) => setFilters({ ...filters, sortBy })}
                    />
                </div>
            </div>
            <div>
                <h4>Ordre de tri</h4>
                <div className="filter-buttons">
                    <Buttons items={[
                        { key: 1, label: 'Croissant', icon: 'mdi:sort-alphabetical-ascending' },
                        { key: -1, label: 'Décroissant', icon: 'mdi:sort-alphabetical-descending' }
                    ]}
                        value={filters?.sortDirection}
                        onChange={(sortDirection) => setFilters({ ...filters, sortDirection })}
                    />
                </div>
            </div>
            {workspace === WorkspaceType.CHAUSSES ? (
                <Fragment>
                    <div>
                        <h4>Type d'opération</h4>
                        <div className="filter-buttons">
                            <ButtonsMultiple items={OperationSubTypes}
                                value={filters?.types}
                                onChange={(types) => setFilters({ ...filters, types })}
                            />
                        </div>
                    </div>
                    <div>
                        <h4>Phase</h4>
                        <div className="filter-buttons">
                            <ButtonsMultiple items={Phases}
                                value={filters?.phases}
                                onChange={(phases) => setFilters({ ...filters, phases })}
                            />
                        </div>
                    </div>
                    <div>
                        <h4>Matériaux</h4>
                        <CheckboxList
                            id="materials"
                            value={filters?.standards}
                            items={standards}
                            onChange={(standards) => setFilters({ ...filters, standards })}
                        />
                    </div>
                    <div>
                        <h4>Entreprises</h4>
                        <CheckboxList
                            id="businesses"
                            value={filters?.businesses}
                            items={businesses}
                            onChange={(businesses) => setFilters({ ...filters, businesses })}
                        />
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <div>
                        <h4>Type d'ouvrage</h4>
                        <div className="filter-buttons">
                            <ButtonsMultiple items={OuvrageTypes}
                                value={filters?.types}
                                onChange={(types) => setFilters({ ...filters, types })}
                            />
                        </div>
                    </div>
                    <div>
                        <h4>Entreprises</h4>
                        <CheckboxList
                            id="businesses"
                            value={filters?.businesses}
                            items={businesses}
                            onChange={(businesses) => setFilters({ ...filters, businesses })}
                        />
                    </div>
                </Fragment>
            )}
        </Modal>
    )
}
export default HomeFilters;