import { Icon } from '@iconify/react';
import { ActionIcon } from '../../utils/icons';

interface IconLinkProps {
    type: 'add' | 'position' | 'copy' | 'trash' | 'edit' | 'view' | 'chart';
    label: string;
    onClick: () => void;
}

const IconLink = ({
    type,
    label,
    onClick
}: IconLinkProps) => (
    <div className="link" onClick={() => typeof onClick === 'function' ? onClick() : undefined}>
        {label && <span>{label}</span>}
        {type === 'add' && <Icon icon={ActionIcon.ADD} />}
        {type === 'position' && <Icon icon="mdi:crosshairs-gps" />}
        {type === 'copy' && <Icon icon={ActionIcon.COPY} />}
        {type === 'trash' && <Icon icon={ActionIcon.DELETE} />}
        {type === 'edit' && <Icon icon={ActionIcon.EDIT} />}
        {type === 'view' && <Icon icon={ActionIcon.VIEW} />}
        {type === 'chart' && <Icon icon="mdi:chart-bar-stacked" />}
    </div>
)

export default IconLink;