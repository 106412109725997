import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "../../models/user";
import config from "../config.service";
import { logout, setCurrentUser, TOKEN_KEY } from "./auth.slice";

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl ?? "http://localhost:3005",
        prepareHeaders: (headers) => {
            headers.set(
                "authorization",
                `Bearer ${localStorage.getItem(TOKEN_KEY)}`
            );
            return headers;
        },
    }),
    endpoints: (builder) => ({
        currentUser: builder.query<User, void>({
            query: () => ({
                url: "/user/me",
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setCurrentUser(data));
                } catch {
                    dispatch(logout());
                }
            },
        }),
    }),
});

export const { useCurrentUserQuery, useLazyCurrentUserQuery } = apiSlice;
