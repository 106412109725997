import { Icon } from '@iconify/react';
import { ReactNode, useCallback, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import MenuBar from '../MenuBar';
import './index.scss';

export interface ToolbarProps {
    children: ReactNode;
}

const Toolbar = ({ children }: ToolbarProps) => <div className="toolbar">{children}</div>;

export interface ToolbarBlockProps {
    label: string;
    children: ReactNode;
}

Toolbar.ToolbarBlock = ({ label, children }: ToolbarBlockProps) => (
    <div className="toolbar-block">
        <span>{label}</span>
        <div>{children}</div>
    </div>
);

export interface ToolbarButtonProps {
    label: string;
    icon: string;
    active?: boolean;
    onClick: () => void;
}

Toolbar.ToolbarButton = ({ label, icon, active, onClick }: ToolbarButtonProps) => (
    <div className={`toolbar-button ${active ? 'active' : ''}`} onClick={onClick}>
        <Icon icon={icon} />
        <span>{label}</span>
    </div>
);

export interface ToolbarDropdownProps {
    label: string;
    icon: string;
    active?: boolean;
    onClick: (action: string) => void;
    items: { key: string, label: string, icon: string }[];
}

const ToolbarDropdown = ({ label, icon, active, onClick, items }: ToolbarDropdownProps) => {
    const [isOpened, setOpened] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => setOpened(false), []));

    const handleClick = (action: string) => {
        onClick(action);
        setOpened(false);
    }

    return (
        <div className={`toolbar-button toolbar-dropdown ${active ? 'active' : ''}`} onClick={() => setOpened(true)} ref={ref}>
            <Icon icon={icon} />
            <span>{label}</span>
            <MenuBar card column className={isOpened ? 'visible' : undefined}>
                {items?.map(item => (
                    <MenuBar.Item key={item.key} onClick={() => handleClick(item.key)} icon={item.icon} label={item.label} />
                ))}
            </MenuBar>
        </div>
    );
}
Toolbar.ToolbarDropdown = ToolbarDropdown;

export default Toolbar;