import { Analysis } from "./analysis";
import { OperationLocation } from "./location";
import { CoucheType, Lot } from "./lot";
import { Material, TypeGranulat } from "./material";
import { Melange } from "./melange";
import { Phase } from "./operation";
import { RevisionEntity } from "./shared";

export type Sample = RevisionEntity & {
    name: string;
    diameter?: number;
    type: SampleType;
    location: OperationLocation;
    operation: string;
    phase: Phase;
    lot?: string;
    lotPopulated?: Lot;
    fullLot?: string;
    date: Date;
    controle: Controle;
    problematic: boolean;
    laboratory?: string;
    layers: Layer[];
    thickness: number;
    readOnly: boolean;
};

export const isSample = (o: any) => o.hasOwnProperty("layers");

export type Layer = {
    problematic: boolean;
    thickness: number;
    collage?: boolean;
    fissure?: Fissure;
    fracture?: Fissure;
    material?: string;
    materialPopulated?: Material;
    typeCouche?: CoucheType;
    typeGranulat?: TypeGranulat;
    supposedMaterial?: string;
    order: number;
    analysis?: string;
    analysisPopulated?: Analysis;
    melange?: string;
    melangePopulated?: Melange;
    isTheoretical?: boolean;
    comment?: string;
    active: boolean;
};

export enum SampleType {
    CAROTTE = "carotte",
    SONDAGE = "sondage",
    PRELEVEMENT = "prelevement",
}

export const SampleTypeLabel = {
    [SampleType.CAROTTE]: "Carotte",
    [SampleType.SONDAGE]: "Sondage",
    [SampleType.PRELEVEMENT]: "Prélèvement",
};

export const SampleIcon = {
    [SampleType.CAROTTE]: "mdi:square-outline",
    [SampleType.SONDAGE]: "mdi:circle-outline",
    [SampleType.PRELEVEMENT]: "mdi:triangle-outline",
};

export const SampleTypes = [
    {
        key: SampleType.CAROTTE,
        label: SampleTypeLabel[SampleType.CAROTTE],
        icon: SampleIcon[SampleType.CAROTTE],
    },
    {
        key: SampleType.SONDAGE,
        label: SampleTypeLabel[SampleType.SONDAGE],
        icon: SampleIcon[SampleType.SONDAGE],
    },
    {
        key: SampleType.PRELEVEMENT,
        label: SampleTypeLabel[SampleType.PRELEVEMENT],
        icon: SampleIcon[SampleType.PRELEVEMENT],
    },
];

export const SampleTypesEtude = [
    { key: SampleType.CAROTTE, label: SampleTypeLabel[SampleType.CAROTTE] },
    { key: SampleType.SONDAGE, label: SampleTypeLabel[SampleType.SONDAGE] },
];

export enum Fissure {
    TOTAL = "total",
    PARTIAL = "partial",
}

export const FissureLabel = {
    [Fissure.TOTAL]: "totale",
    [Fissure.PARTIAL]: "partielle",
};

export enum Controle {
    EXTERIEUR = "exterieur",
    EXTERNE = "externe",
    AUTRE = "autre",
}

export const ControleLabel = {
    [Controle.EXTERIEUR]: "Exterieur",
    [Controle.EXTERNE]: "Externe",
    [Controle.AUTRE]: "Autre",
};

export const ControleColor = {
    [Controle.EXTERIEUR]: "#397d92",
    [Controle.EXTERNE]: "#faa97a",
    [Controle.AUTRE]: "#84a31f",
};

export const Controles = [
    {
        key: Controle.EXTERIEUR,
        label: ControleLabel[Controle.EXTERIEUR],
        color: ControleColor[Controle.EXTERIEUR],
    },
    {
        key: Controle.EXTERNE,
        label: ControleLabel[Controle.EXTERNE],
        color: ControleColor[Controle.EXTERNE],
    },
    {
        key: Controle.AUTRE,
        label: ControleLabel[Controle.AUTRE],
        color: ControleColor[Controle.AUTRE],
    },
];

export const ControleChartColor = {
    [Controle.EXTERIEUR]: "#397d92",
    [Controle.EXTERNE]: "#faa97a",
    [Controle.AUTRE]: "#84a31f",
};

export const ControleChartColorTransparency = {
    [Controle.EXTERIEUR]: "rgba(57, 125, 146, 0.7)",
    [Controle.EXTERNE]: "rgba(250, 169, 122, 0.7)",
    [Controle.AUTRE]: "rgba(132, 163, 31, 0.7)",
};

export const SampleLabels = {
    carotte: {
        type: "Carotte",
        new: "Nouvelle carotte",
        create: "Créer une carotte",
        edit: "Editer une carotte",
        masse: "Créer plusieurs carottes",
    },
    sondage: {
        type: "Sondage",
        new: "Nouveau sondage",
        create: "Créer un sondage",
        edit: "Editer un sondage",
        masse: "Créer plusieurs sondages",
    },
    prelevement: {
        type: "Prélèvement",
        new: "Nouveau prélèvement",
        create: "Créer un prélèvement",
        edit: "Editer un prélèvement",
        masse: "Créer plusieurs prélèvements",
    },
    population: {
        type: "Population",
        new: "Nouvelle population",
        create: "Créer une population",
        edit: "Editer une population",
        masse: "Créer plusieurs population",
    },
    marker: {
        type: "Point d'intérêt",
        new: "Nouveau point d'intérêt",
        create: "Créer un point d'intérêt",
        edit: "Editer un point d'intérêt",
        masse: "Créer plusieurs points d'intérêt",
    },
    melange: {
        type: "Mélange",
        new: "Nouveau mélange",
        create: "Créer un mélange",
        select: "Sélectionner pour mélange",
        cancel: "Annuler le mélange",
        edit: "Editer un mélange",
        masse: "Créer plusieurs mélange",
    },
};
