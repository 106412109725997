import './index.scss';

interface ProgressBarProps {
    value?: number;
    maximum?: number;
    minimum?: number;
    cursor?: number;
}

const ProgressBar = ({
    value,
    maximum,
    minimum,
    cursor
}: ProgressBarProps) => !value || !maximum ? null : (
    <div className="progress-bar">
        {cursor !== undefined && <div className="progress-bar-cursor" style={{ left: `calc(${cursor * 100 / maximum}% - 4px)` }} />}
        <div className="progress-bar-value" style={{ width: `${value * 100 / maximum}%` }} />
        {minimum !== undefined && <div className="progress-bar-min" style={{ left: `calc(${minimum * 100 / maximum}% - 4px)` }} />}
        <div className="progress-bar-max" style={{ left: `calc(100% - 4px)` }} />
    </div>
);

export default ProgressBar;