import { ReactNode } from "react";
import { BasicEntity } from "../../../models/shared";
import Switch from "../../inputs/Switch";
import { Textarea } from "../../inputs/Textarea";
import './index.scss';

interface EntityCardProps<T extends BasicEntity> {
    entity: Partial<T>;
    attachInput: (field: string) => any;
    children?: ReactNode;
}

const EntityCard = <T extends BasicEntity>({ entity, attachInput, children }: EntityCardProps<T>) => {
    return (
        <div className="entity-card">
            <h4>Paramètres</h4>
            <Switch<boolean> items={[{ key: true, label: 'Actif' }, { key: false, label: 'Inactif' }]} {...attachInput('active')} />
            <div className="input-column">
                <label htmlFor="country">Commentaire</label>
                <Textarea {...attachInput('comment')} />
            </div>
            {children}
        </div>
    )
}

export default EntityCard;