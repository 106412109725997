import { useEffect, useMemo, useRef, useState } from "react";
import { Layer, Popup, Source } from "react-map-gl";
import Map, { MapAction, MapRefProps, Marker } from "../../../components/data/Map";
import { IdentityCardOperation, IdentityCardOuvrage } from "../../../components/entities/IdentityCard";
import MenuBar from "../../../components/ui/MenuBar";
import { Coordinates } from "../../../models/location";
import { Operation, OperationSubType, OperationSubTypes, OperationType, Phase, PhaseColor } from "../../../models/operation";
import { Ouvrage, OuvrageIcon, OuvrageType, OuvrageTypes } from "../../../models/ouvrage";
import useWorkspace from "../../../services/hooks/use-workspace";
import { WorkspaceType } from "../../../services/slices/workspace.slice";
import { ActionIcon } from "../../../utils/icons";

const SELECTED_COLOR = '#d94032';

interface HomeMapProps {
    operations: Operation[];
    selectedOperation?: Operation;
    ouvrages: Ouvrage[];
    selectedOuvrage?: Ouvrage;
    onSelect: (o?: Operation | Ouvrage) => void;
    onCreate?: (option: { operationType?: OperationType, ouvrageType?: OuvrageType }) => void;
    onFilter: () => void;
}

const HomeMap = ({ operations, selectedOperation, ouvrages, selectedOuvrage, onSelect, onCreate, onFilter }: HomeMapProps) => {
    const [popup, setPopup] = useState<(Coordinates & { operation?: Operation, ouvrage?: Ouvrage }) | null>(null);
    const { workspace } = useWorkspace();
    const mapRef = useRef<MapRefProps>(null);

    const layer = useMemo(() => {
        if (!operations.length) return null;

        const data = {
            "type": "FeatureCollection",
            "features": [] as any[]
        }

        for (const operation of operations) {
            if (operation.linesLots) {
                data.features.push({
                    type: "Feature",
                    properties: { phase: undefined },
                    geometry: {
                        type: "LineString",
                        coordinates: operation.line.map(p => [p.longitude, p.latitude])
                    }
                })

                data.features.push({
                    type: "Feature",
                    properties: { phase: operation.phase },
                    geometry: {
                        type: "MultiLineString",
                        coordinates: operation.linesLots.map(line => line.map(p => [p.longitude, p.latitude]))
                    }
                })
            } else {
                data.features.push({
                    type: "Feature",
                    properties: { phase: operation.phase },
                    geometry: {
                        type: "LineString",
                        coordinates: operation.line.map(p => [p.longitude, p.latitude])
                    }
                })
            }
        }

        return (
            <Source id="network" type="geojson" data={data}>
                <Layer
                    id="network-layer"
                    type="line"
                    source="network"
                    layout={{
                        "line-join": "round",
                        "line-cap": "round"
                    }}
                    paint={{
                        'line-color': [
                            'match', ['get', 'phase'],
                            Phase.EXPERTISE, PhaseColor[Phase.EXPERTISE],
                            Phase.TRAVAUX, PhaseColor[Phase.TRAVAUX],
                            Phase.PREPARATOIRE, PhaseColor[Phase.PREPARATOIRE],
                            Phase.ETUDE, PhaseColor[Phase.ETUDE],
                            '#c2c3c9'
                        ],
                        'line-width': 5,
                    }}
                />
            </Source>
        )
    }, [operations]);

    const layerSelected = useMemo(() => {
        if (!selectedOperation?.line.length) return null;

        const data = {
            "type": "FeatureCollection",
            "features": [] as any[]
        }
        if (selectedOperation.linesLots) {
            data.features.push({
                type: "Feature",
                properties: { color: 'grey' },
                geometry: {
                    type: "LineString",
                    coordinates: selectedOperation.line.map(p => [p.longitude, p.latitude])
                }
            })

            data.features.push({
                type: "Feature",
                geometry: {
                    type: "MultiLineString",
                    coordinates: selectedOperation.linesLots.map(line => line.map(p => [p.longitude, p.latitude]))
                }
            })
        } else {
            data.features.push({
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: selectedOperation.line.map(p => [p.longitude, p.latitude])
                }
            })
        }

        return (
            <Source id="network-selected" type="geojson" data={data}>
                <Layer
                    id="network-layer-selected"
                    type="line"
                    source="network-selected"
                    layout={{
                        "line-join": "round",
                        "line-cap": "round"
                    }}
                    paint={{
                        'line-color': [
                            'match', ['get', 'color'],
                            'grey', '#c2c3c9',
                            SELECTED_COLOR
                        ],
                        'line-width': 5,
                    }}
                />
            </Source>
        )
    }, [selectedOperation]);

    const operationsMarkers = useMemo(() => {
        if (!operations.length) return null;

        return operations.map(o => {
            if (o.line.length > 1) {
                return [
                    <Marker
                        key={`marker-${o._id}-start`}
                        longitude={o.line[0].longitude}
                        latitude={o.line[0].latitude}
                        color={selectedOperation?._id === o._id ? SELECTED_COLOR : PhaseColor[o.phase]}
                        onMouseEnter={() => setPopup({ latitude: o.line[0].latitude, longitude: o.line[0].longitude, operation: o })}
                        onMouseLeave={() => setPopup(null)}
                        onClick={() => onSelect(o)}
                        icon="mdi:flag-outline"
                    />,
                    <Marker
                        key={`marker-${o._id}-end`}
                        longitude={o.line[o.line.length - 1].longitude}
                        latitude={o.line[o.line.length - 1].latitude}
                        color={selectedOperation?._id === o._id ? SELECTED_COLOR : PhaseColor[o.phase]}
                        onMouseEnter={() => setPopup({ latitude: o.line[o.line.length - 1].latitude, longitude: o.line[o.line.length - 1].longitude, operation: o })}
                        onMouseLeave={() => setPopup(null)}
                        onClick={() => onSelect(o)}
                        icon="mdi:flag-checkered"
                    />
                ];
            }

            return !!o.zone?.marker ? (
                <Marker
                    key={`marker-${o._id}`}
                    longitude={o.zone.marker.longitude}
                    latitude={o.zone.marker.latitude}
                    color={selectedOperation?._id === o._id ? SELECTED_COLOR : PhaseColor[o.phase]}
                    onMouseEnter={() => setPopup({ latitude: o.zone.marker!.latitude, longitude: o.zone.marker!.longitude, operation: o })}
                    onMouseLeave={() => setPopup(null)}
                    onClick={() => onSelect(o)}
                    icon={OperationSubType[o.type].icon}
                />
            ) : null;
        }).flat();
    }, [operations, selectedOperation, onSelect]);

    const ouvragesMarkers = useMemo(() => {
        if (!ouvrages.length) return null;

        return ouvrages.filter(o => o.location.gps).map(o => (
            <Marker
                key={`marker-${o._id}`}
                longitude={o.location.gps!.longitude}
                latitude={o.location.gps!.latitude}
                color={selectedOuvrage?._id === o._id ? SELECTED_COLOR : 'red'}
                onMouseEnter={() => setPopup({ ...o.location.gps!, ouvrage: o })}
                onMouseLeave={() => setPopup(null)}
                onClick={() => onSelect(o)}
                icon={OuvrageIcon[o.type]}
            />
        ));
    }, [ouvrages, selectedOuvrage, onSelect]);

    useEffect(() => {
        if (selectedOperation && mapRef.current) {
            if (selectedOperation.line.length) {
                mapRef.current.fitToEntities(selectedOperation.line, { animate: true });
            } else if (selectedOperation.zone?.marker) {
                mapRef.current.goTo(selectedOperation.zone.marker.longitude, selectedOperation.zone.marker.latitude);
            }
        }
        if (selectedOuvrage && mapRef.current) {
            mapRef.current.goTo(selectedOuvrage.location.gps.longitude, selectedOuvrage.location.gps.latitude);
        }
    }, [selectedOperation, selectedOuvrage]);

    return (
        <Map availableActions={[MapAction.POSITION, MapAction.STYLE]} ref={mapRef}>
            {!!onCreate && (
                <MenuBar card className="md-hide map-menu">
                    <MenuBar.Item
                        label={workspace === WorkspaceType.CHAUSSES ? 'Nouvelle opération' : 'Nouvel ouvrage'}
                        icon={ActionIcon.ADD}
                    >
                        {workspace === WorkspaceType.CHAUSSES && OperationSubTypes.map(type => (
                            <MenuBar.SubItem
                                key={type.key}
                                label={type.label}
                                icon={type.icon}
                                onClick={() => onCreate({ operationType: type.key })}
                            />
                        ))}
                        {workspace === WorkspaceType.OUVRAGES && OuvrageTypes.map(type => (
                            <MenuBar.SubItem
                                key={type.key}
                                label={type.label}
                                icon={type.icon}
                                onClick={() => onCreate({ ouvrageType: type.key })}
                            />
                        ))}
                    </MenuBar.Item>
                    <MenuBar.Item
                        label="Filtrer"
                        icon={ActionIcon.FILTER}
                        onClick={onFilter}
                    />
                </MenuBar>
            )}
            {workspace === WorkspaceType.CHAUSSES && layer}
            {workspace === WorkspaceType.CHAUSSES && layerSelected}
            {workspace === WorkspaceType.CHAUSSES && operationsMarkers}
            {workspace === WorkspaceType.OUVRAGES && ouvragesMarkers}
            {!!popup && (
                <Popup
                    longitude={popup.longitude}
                    latitude={popup.latitude}
                    anchor="bottom"
                    offset={20}
                    className="map-popup"
                    onClose={() => setPopup(null)}
                    maxWidth="400px"
                >
                    {!!popup.operation && <IdentityCardOperation entity={popup.operation} />}
                    {!!popup.ouvrage && <IdentityCardOuvrage entity={popup.ouvrage} />}
                </Popup>
            )}
        </Map>
    )
}
export default HomeMap;