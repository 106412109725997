import { useNavigate } from 'react-router-dom';
import List, { FilterType } from '../../../components/data/List';
import Tag from '../../../components/ui/Tag';
import { Diagnostic, DiagnosticStatusList } from '../../../models/diagnostic';
import { Role } from '../../../models/user';
import useAuth from '../../../services/hooks/use-auth.hook';
import useWorkspace from '../../../services/hooks/use-workspace';
import { formatDate } from '../../../utils/format';

const Diagnostics = () => {
    const { ouvrage, workspacePermissions } = useWorkspace();
    const { user } = useAuth();
    const navigate = useNavigate();

    return (
        <List<Diagnostic>
            columns={[
                { key: 'date', label: 'Date', mapper: (element) => formatDate(element.date) },
                { key: 'status', label: 'Statut', mapper: (element) => <Tag value={element.status} items={DiagnosticStatusList} /> },
                { key: 'grade', label: 'Note' },
                { key: 'progress', label: 'Progression', mapper: (element) => `${Math.round((element.elements?.length ?? 0) * 100 / ouvrage.elements.length)}%` },
            ]}
            dataEndpoint={`/diagnostic/ouvrage/${ouvrage._id}`}
            crudEndpoint="/diagnostic"
            baseUrl={`/ouvrage/${ouvrage._id}/diagnostics`}
            actions={{
                comment: workspacePermissions.write && user.role !== Role.SUBCONTRACTOR,
                edit: workspacePermissions.write,
                activate: workspacePermissions.administrate,
                duplicate: workspacePermissions.write && user.role !== Role.SUBCONTRACTOR,
                delete: workspacePermissions.administrate
            }}
            initialPagination={{ sortBy: 'date', sortDirection: -1 }}
            onView={(d) => navigate(`/ouvrage/${ouvrage._id}/diagnostics/${d._id}`)}
            filters={[
                { field: 'active', label: 'Actif' },
                { field: 'status', label: 'Statut', type: FilterType.MULTIPLE_SELECT, items: DiagnosticStatusList },

            ]}
            warningBeforeDelete
            buttons={user.role !== Role.SUBCONTRACTOR ? [{ label: 'Créer un diagnostic', onClick: () => navigate(`/ouvrage/${ouvrage._id}/diagnostics/creer`) }] : []}
        />
    )
}

export default Diagnostics;