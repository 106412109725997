import { Icon } from '@iconify/react';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Operation } from '../../../models/operation';
import { Ouvrage } from '../../../models/ouvrage';
import { Role, User, UserWorkspaceRole, WorkspaceRole, WorkspaceRoles, WorkspaceRolesSubcontractors } from '../../../models/user';
import { addToast } from '../../../services/slices/ui.slice';
import { getFullName } from '../../../utils/format';
import { ActionIcon } from '../../../utils/icons';
import NativeSelect from '../../inputs/NativeSelect';
import Card from '../../ui/Card';
import Menu from '../../ui/Menu';
import { ModalRight } from '../../ui/Modal/ModalRight';
import UserSearchModal from '../UserSearchModal';
import './index.scss';

interface UsersManagementListProps {
    title: string;
    type: 'user' | 'subcontractor';
    users?: UserWorkspaceRole[];
    onChange: (u: UserWorkspaceRole[]) => void;
    limit?: number;
    noRole?: boolean;
}

export const UsersManagementList = ({ title, type, users, limit, onChange, noRole }: UsersManagementListProps) => {
    const [isUserModalVisible, setUserModalVisible] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleDelete = (user: string) => {
        const _users = [...(users ?? [])];
        const index = _users.findIndex(ur => ur.user === user);

        if (index === -1) return;

        _users.splice(index, 1);

        onChange(_users);
    };

    const handleAdd = (usersToAdd: User[]) => {
        const _users = [...(users ?? [])];

        for (const user of usersToAdd) {
            if (_users.some(u => u.user === user._id)) continue;

            if (limit !== undefined && (users?.length ?? 0) >= limit) {
                dispatch(addToast({ type: 'error', message: 'La limite du nombre d\'utilisateurs a été atteint' }));
                break;
            }

            _users.push({ user: user._id, userPopulated: user, role: user.role === Role.SUBCONTRACTOR ? WorkspaceRole.EXTERIEUR : WorkspaceRole.READ });
        }

        setUserModalVisible(false);
        onChange(_users);
    };

    const handleChange = (user: string, role: WorkspaceRole) => {
        const _users = [...(users ?? [])];
        const index = _users.findIndex(ur => ur.user === user);

        if (index === -1) return;

        _users[index].role = role;

        onChange(_users);
    };

    return (
        <Card
            title={title}
            actions={<Menu icon={ActionIcon.ADD} label="Ajouter" onClick={() => setUserModalVisible(true)} />}
        >
            <div className="user-management-list">
                {users?.map(u => (
                    <div key={u.user}>
                        <div>
                            <span>{getFullName(u.userPopulated)}</span>
                            {u.userPopulated?.businessPopulated && <span>{u.userPopulated?.businessPopulated.name}</span>}
                        </div>
                        {!noRole && <NativeSelect id={u.user} value={u.role} items={type === 'user' ? WorkspaceRoles : WorkspaceRolesSubcontractors} onChange={(role) => role ? handleChange(u.user, role) : null} />}
                        <Icon icon={ActionIcon.DELETE} onClick={() => handleDelete(u.user)} className="icon-button" />
                    </div>
                ))}
            </div>

            {!!isUserModalVisible && <UserSearchModal mode={type} onClose={() => setUserModalVisible(false)} onSubmit={handleAdd} />}
        </Card>
    )
}

export interface UsersManagementPanelProps {
    entity: Partial<Operation | Ouvrage>;
    onChange: (users: UserWorkspaceRole[]) => void;
    visible?: boolean;
    withSubcontractors?: boolean;
    onClose: () => void;
}

const UsersManagementPanel = ({
    entity,
    onChange,
    visible,
    withSubcontractors,
    onClose
}: UsersManagementPanelProps) => {
    const users = useMemo(() => entity.users?.filter(u => [WorkspaceRole.ADMIN, WorkspaceRole.READ, WorkspaceRole.VALIDATE, WorkspaceRole.WRITE].includes(u.role))
        ?? []
        , [entity]);

    const subcontractors = useMemo(() => entity.users?.filter(u => ![WorkspaceRole.ADMIN, WorkspaceRole.READ, WorkspaceRole.VALIDATE, WorkspaceRole.WRITE].includes(u.role))
        ?? []
        , [entity]);


    return (
        <ModalRight
            visible={!!visible}
            title="Utilisateurs"
            className="users-management-panel"
            actions={[{ label: 'Fermer', onClick: onClose }]}
        >
            <UsersManagementList title="Utilisateurs" type="user" onChange={(users) => onChange([...subcontractors, ...users])} limit={entity.licensePopulated?.usersPerOperationQuota} users={users} />
            {!!withSubcontractors && (
                <UsersManagementList title="Prestataires" type="subcontractor" onChange={(subcontractors) => onChange([...users, ...subcontractors])} users={subcontractors} />
            )}
        </ModalRight>
    )
}
export default UsersManagementPanel;