import { Fragment } from 'react';
import { useLocation } from 'react-router';
import { Permission, PermissionLabel } from '../../../models/user';
import { postRequest } from '../../../services/request.service';
import './index.scss';

interface AskAccessProps {
    permission: Permission
}
const AskAccess = ({
    permission
}: AskAccessProps) => {
    const location = useLocation();

    const handleAskAccess = async () => {
        postRequest(
            '/message/ask-access',
            { permission: PermissionLabel[permission] ?? location.pathname },
            { loader: true, successMessage: 'Demande d\'accès envoyée', errorMessage: 'Une erreur est survenue lors de la demande' }
        ).catch(() => null);
    }

    return (
        <div className="ask-access">
            {permission === Permission.PRINT ? (
                <Fragment>
                    <div className="ask-access-line1">L'impression de documents ne vous est pas accessible</div>
                    <div className="ask-access-line2"><span onClick={handleAskAccess}>Contactez l'administrateur</span> pour débloquer cette fonctionnalité</div>
                </Fragment>
            ) : (
                permission === Permission.FILTER ? (
                    <Fragment>
                        <div className="ask-access-line1">Filtrer les éléments requiert une permission supplémentaire</div>
                        <div className="ask-access-line2"><span onClick={handleAskAccess}>Envoyez une demande</span> à l'administrateur pour obtenir plus d'options</div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="ask-access-line1">Vous n'avez pas la permission de visualiser les données</div>
                        <div className="ask-access-line2">Pour faire évoluer votre accès, contactez l'administrateur en <span onClick={handleAskAccess}>cliquant ici</span></div>
                    </Fragment>
                )
            )}
        </div>
    )
}

export default AskAccess;