import { ResponseType } from "axios";
import AxiosInstance from "../services/AxiosInstance";
import { TOKEN_KEY } from "./slices/auth.slice";
import { addToast, startLoader, stopLoader } from "./slices/ui.slice";
import store from "./store";

interface RequestOptions {
    params: any;
    loader: boolean;
    successMessage: string;
    errorMessage: string;
    responseType: ResponseType;
    timeout: number;
    headers: Record<string, string>;
}

const request = async <T>(
    method: "get" | "post" | "put" | "patch" | "delete",
    url: string,
    body: any,
    options?: Partial<RequestOptions>
): Promise<T> => {
    if (options?.loader) {
        store.dispatch(startLoader());
    }

    try {
        let data: T;
        const _options = {
            params: options?.params,
            responseType: options?.responseType,
            timeout: options?.timeout,
            headers: {
                authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
                ...options?.headers,
            },
        };

        switch (method) {
            case "get":
                const getResponse = await AxiosInstance.get<T>(url, _options);
                data = getResponse.data;
                break;
            case "post":
                const postResponse = await AxiosInstance.post<T>(
                    url,
                    body,
                    _options
                );
                data = postResponse.data;
                break;
            case "put":
                const putResponse = await AxiosInstance.put<T>(
                    url,
                    body,
                    _options
                );
                data = putResponse.data;
                break;
            case "patch":
                const patchResponse = await AxiosInstance.patch<T>(
                    url,
                    body,
                    _options
                );
                data = patchResponse.data;
                break;
            default:
                const deleteResponse = await AxiosInstance.delete<T>(
                    url,
                    _options
                );
                data = deleteResponse.data;
                break;
        }

        if (options?.successMessage) {
            store.dispatch(
                addToast({
                    type: "success",
                    message: options.successMessage,
                })
            );
        }

        return data;
    } catch (e) {
        if (options?.errorMessage) {
            const status = (e as any)?.error.status;
            if (status === 429) {
                store.dispatch(
                    addToast({
                        type: "error",
                        message:
                            "Le nombre de requêtes autorisé a été atteint. Pour des raisons de sécurité, merci de patienter quelques minutes avant de réessayer.",
                        error: e as any,
                    })
                );
            } else if (status === 403 || status === 404) {
                store.dispatch(
                    addToast({
                        type: "error",
                        message:
                            "La donnée demandée n'a pas été trouvée ou vous ne disposez pas des authorisations nécessaire pour y accéder.",
                        error: e as any,
                    })
                );
            } else {
                store.dispatch(
                    addToast({
                        type: "error",
                        message: options.errorMessage,
                        error: e as any,
                    })
                );
            }
        }

        throw e;
    } finally {
        if (options?.loader) {
            store.dispatch(stopLoader());
        }
    }
};

export const getRequest = async <T>(
    url: string,
    options?: Partial<RequestOptions>
): Promise<T> => request<T>("get", url, {}, options);

export const postRequest = async <T>(
    url: string,
    body: any,
    options?: Partial<RequestOptions>
): Promise<T> => request<T>("post", url, body, options);

export const putRequest = async <T>(
    url: string,
    body: any,
    options?: Partial<RequestOptions>
): Promise<T> => request<T>("put", url, body, options);

export const patchRequest = async <T>(
    url: string,
    body: any,
    options?: Partial<RequestOptions>
): Promise<T> => request<T>("patch", url, body, options);

export const deleteRequest = async <T>(
    url: string,
    options?: Partial<RequestOptions>
): Promise<T> => request<T>("delete", url, {}, options);
