import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, redirect, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MessageType } from '../../models/message';
import { OperationIcon } from '../../models/operation';
import { Ouvrage } from '../../models/ouvrage';
import { Controle } from '../../models/sample';
import { Permission, Role, WorkspaceRole } from '../../models/user';
import useAuth from '../../services/hooks/use-auth.hook';
import useWorkspace from '../../services/hooks/use-workspace';
import { getRequest } from '../../services/request.service';
import { useLazyCurrentUserQuery } from '../../services/slices/api.slice';
import { addToast } from '../../services/slices/ui.slice';
import { clearWorkspace, setOuvrage, WorkspacePermissions } from '../../services/slices/workspace.slice';
import { ActionIcon } from '../../utils/icons';
import MessageModal from '../MessageModal';
import MenuBar from '../ui/MenuBar';

export const OuvrageMenuWrapper = () => {
    const location = useLocation();
    const { ouvrage, workspacePermissions } = useWorkspace();
    const { user } = useAuth();
    const [isNotifyModalVisible, setNotifyModalVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        dispatch(clearWorkspace());
        navigate('/');
    }, []);

    return (
        <Fragment>
            <MenuBar>
                <MenuBar.Item
                    icon="mdi:dots-vertical"
                    label="Menu"
                >
                    {!!workspacePermissions.notify && <MenuBar.SubItem label="Notifier les utilisateurs" icon={ActionIcon.SEND} onClick={() => setNotifyModalVisible(true)} />}
                    {!!workspacePermissions.administrate && <MenuBar.SubItem label="Editer l'ouvrage" icon="mdi:settings-outline" onClick={() => navigate(`/ouvrage/${ouvrage._id}/editer`)} />}
                    <MenuBar.SubItem label="Fermer" icon={ActionIcon.CLOSE} onClick={handleClose} />
                </MenuBar.Item>
                {workspacePermissions.read && (
                    <Fragment>
                        <MenuBar.Item
                            icon={OperationIcon.SYNOPTIQUE}
                            label="Visualisation"
                            onClick={() => navigate(`/ouvrage/${ouvrage._id}`)}
                            active={location.pathname.startsWith(`/ouvrage/${ouvrage._id}/visualisation`) || location.pathname === `/ouvrage/${ouvrage._id}`}
                        />
                    </Fragment>
                )}
                {workspacePermissions.write && (
                    <Fragment>
                        <MenuBar.Item
                            icon="mdi:shield-car"
                            label="Diagnostics"
                            onClick={() => navigate(`/ouvrage/${ouvrage._id}/diagnostics`)}
                            active={location.pathname.startsWith(`/ouvrage/${ouvrage._id}/diagnostics`)}
                        />
                    </Fragment>
                )}
                {workspacePermissions.read && (
                    <MenuBar.Item
                        icon="mdi:mailbox-open-outline"
                        label="Boîte aux lettres"
                        onClick={() => navigate(`/ouvrage/${ouvrage._id}/boite-aux-lettres`)}
                        active={location.pathname.startsWith(`/ouvrage/${ouvrage._id}/boite-aux-lettres`)}
                    />
                )}
            </MenuBar>
            <Outlet />
            {isNotifyModalVisible && <MessageModal type={MessageType.OUVRAGE_UPDATE} message={`${ouvrage.name} : ouvrage mis à jour`} onClose={() => setNotifyModalVisible(false)} onSubmit={() => setNotifyModalVisible(false)} />}
        </Fragment>
    )
}

const OuvrageWrapper = () => {
    const { ouvrageId } = useParams();
    const { isOuvrageLoaded, ouvrage } = useWorkspace();
    const { user, permissions } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [getUser] = useLazyCurrentUserQuery();

    const getOuvrage = useCallback(async (_id: string) => {
        getRequest<Ouvrage>(`/ouvrage/${_id}`, { loader: true })
            .then((ouvrage) => {
                let permissions: Partial<WorkspacePermissions> = {};

                if (user.role === Role.SUPER_ADMIN) {
                    permissions = {
                        administrate: true,
                        notify: true,
                        upload: true,
                        validate: true,
                        write: true,
                        read: true,
                        localizedResults: true,
                        materialResults: true,
                        synoptiqueData: true,
                        filter: true,
                        controles: [Controle.EXTERIEUR, Controle.EXTERNE, Controle.AUTRE]
                    };
                } else {
                    let ouvrageRole = ouvrage.users.find(ur => ur.user === user._id)?.role;

                    if (ouvrage.licensePopulated?.manager === user._id) {
                        ouvrageRole = WorkspaceRole.ADMIN;
                    }

                    permissions = {
                        administrate: ouvrageRole === WorkspaceRole.ADMIN,
                        notify: ouvrageRole === WorkspaceRole.ADMIN,
                        upload: ouvrageRole === WorkspaceRole.ADMIN,
                        validate: ouvrageRole === WorkspaceRole.ADMIN || ouvrageRole === WorkspaceRole.VALIDATE,
                        write: !!ouvrageRole && ouvrageRole !== WorkspaceRole.READ,
                        read: !!ouvrageRole && [WorkspaceRole.ADMIN, WorkspaceRole.VALIDATE, WorkspaceRole.READ, WorkspaceRole.WRITE].includes(ouvrageRole),
                        localizedResults: !!ouvrage.licensePopulated ? !!ouvrage.licensePopulated.permissions.includes(Permission.LOCALIZED_RESULTS) : permissions.localizedResults,
                        materialResults: !!ouvrage.licensePopulated ? !!ouvrage.licensePopulated.permissions.includes(Permission.MATERIAL_RESULTS) : permissions.materialResults,
                        synoptiqueData: !!ouvrage.licensePopulated ? !!ouvrage.licensePopulated.permissions.includes(Permission.SYNOPTIQUE_DATA) : permissions.synoptiqueData,
                        filter: !!ouvrage.licensePopulated ? !!ouvrage.licensePopulated.permissions.includes(Permission.FILTER) : permissions.filter,
                    };
                }

                dispatch(setOuvrage({ ouvrage, permissions }));
                setSearchParams({});
            })
            .catch((e) => {
                dispatch(clearWorkspace());
                if ((e as any)?.error?.status === 401) {
                    navigate('/erreur/401');
                } else {
                    dispatch(addToast({
                        type: 'error', message: 'Une erreur est survenue. Veuillez contacter l\'administrateur.', error: e as any
                    }));
                    navigate('/');
                }
            });
    }, [user, permissions]);

    useEffect(() => {
        if (ouvrageId && (!ouvrage?._id || ouvrage._id !== ouvrageId || !!searchParams.get('refresh_ouvrage'))) {
            getOuvrage(ouvrageId);

            // Refresh user to refresh ouvragecount on license
            if (!!searchParams.get('refresh_ouvrage') && user.managedLicense) {
                try {
                    getUser()
                } catch { }
            }
        } else if (!ouvrageId) {
            redirect('/');
        }
    }, [ouvrageId, searchParams]);

    if (!ouvrageId || !isOuvrageLoaded || (ouvrage._id !== ouvrageId) || !!searchParams.get('refresh_ouvrage')) return null;

    return <Outlet />
}

export default OuvrageWrapper;

