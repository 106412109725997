import { Icon } from '@iconify/react';
import { ReactNode, useCallback, useState } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ActionIcon } from '../../utils/icons';

const Expandable = ({
    children
}: { children: ReactNode }) => {
    const [isExpanded, setExpanded] = useState(false);
    const ref = useOutsideClick(useCallback(() => setExpanded(false), []));

    return (
        <div className="expandable">
            <div className={`expandable-content${isExpanded ? ' expanded' : ''}`} ref={ref}>
                {isExpanded
                    ? <Icon icon={ActionIcon.CLOSE} className="icon-button no-print" onClick={() => setExpanded(false)} />
                    : <Icon icon={ActionIcon.ADD} className="icon-button no-print" onClick={() => setExpanded(true)} />}
                {children}
            </div>
        </div>
    )
}

export default Expandable;