import NumberInput from '../../../components/inputs/NumberInput';
import Card from '../../../components/ui/Card';
import { MaterialSpecificationsFormProps } from '../EditComponent';

const EmulsionBitumeEdit = ({
    attachInput
}: MaterialSpecificationsFormProps) => (
    <Card title="Dosage et dilution">
        <div className="row">
            <div className="input-column">
                <label htmlFor="specifications.dosage">Dosage *</label>
                <NumberInput {...attachInput('specifications.dosage')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.dosageGravillons">Dosage en gravillons</label>
                <NumberInput {...attachInput('specifications.dosageGravillons')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.dilution">Dilution</label>
                <NumberInput {...attachInput('specifications.dilution')} />
            </div>
        </div>
    </Card>
)

export default EmulsionBitumeEdit;