import { ChangeEvent, useEffect, useState } from "react";

export interface SmallNumberInputProps {
    value?: string | number;
    onChange: (value?: number) => void;
}

const SmallNumberInput = ({ value, onChange }: SmallNumberInputProps) => {
    const [tempValue, setTempValue] = useState<string>();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === undefined || value === '') {
            onChange(undefined);
        } else if (!isNaN(Number(value))) {
            onChange(Number(value));
        } else {
            setTempValue(value);
        }
    };

    useEffect(() => {
        setTempValue(value !== undefined ? String(value) : undefined);
    }, [value]);

    return (
        <input
            type="number"
            onWheel={(e) => e.currentTarget.blur()}
            value={tempValue ?? ''}
            onChange={handleChange}
        />
    )
}
export default SmallNumberInput;