import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { AnalysisDataRow, AnalysisStatistics, MinMaxAverage } from '../../../models/analysis';
import { Phase, PhaseLabel, RoadPosition, RoadPositionLabel } from '../../../models/operation';
import { ControleColor, ControleLabel, Controles } from '../../../models/sample';
import { ListItem } from '../../../models/shared';
import { Permission } from '../../../models/user';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import { formatDate, formatNumberToFixedDecimal } from '../../../utils/format';
import { floatToPrString } from '../../../utils/pr';
import Switch from '../../inputs/Switch';
import Expandable from '../../ui/Expandable';
import FakeData from '../../ui/FakeData';
import FakeDataTable from '../../ui/FakeData/FakeDataTable';
import Printable from '../../ui/Printable';
import ProgressBar from './ProgressBar';
import ScaleInput from './ScaleInput';
import StatisticsChart from './StatisticsChart';

const ColumnLabel: { [key: string]: string } = {
    pr: 'PR',
    road: 'Voie',
    roadPosition: 'Position',
    controle: 'Contrôle',
    position: 'Couche',
    material: 'Exigence',
    phase: 'Phase',
    business: 'Entreprise',
    date: 'Date',
    name: 'Nom',
    operation: 'Opération',
    location: 'Localisation'
}

interface StatisticsProps {
    data: AnalysisStatistics;
    title: string;
    subtitle?: string;
    filename: string;
    columns: string[];
    isAuthorized?: boolean;
    permission: Permission;
    chartAxis?: string;
    chartByOptions?: string[];
    phases?: Phase[];
    isLibrary?: boolean;
    isProducer?: boolean;
}

const Statistics = ({
    data,
    title,
    subtitle,
    filename,
    columns,
    isAuthorized,
    chartAxis = 'pr',
    chartByOptions = [],
    phases = [],
    permission,
    isLibrary = false,
    isProducer = false
}: StatisticsProps) => {
    const { operation } = useWorkspace();
    const [chartType, setChartType] = useState('average');
    const [chartBy, setChartBy] = useState<string>(chartByOptions[0]);
    const [materials, setMaterials] = useState<ListItem[] | null>(null);
    const [businesses, setBusinesses] = useState<ListItem[] | null>(null);
    const [operations, setOperations] = useState<ListItem[] | null>(null);
    const [scale, setScale] = useState({
        min: data.min && data.min <= 0 ? Math.floor(data.min * 1.1) : 0,
        max: !data.max || data.max <= 0 ? 0 : Math.ceil(data.max * 1.1),
    });

    const getColumnValue = useCallback((row: AnalysisDataRow, column: string) => {
        switch (column) {
            case 'pr':
                return floatToPrString(row.pr);
            case 'road':
                return row.road ? operation.roadsObj[row.road]?.label ?? '--' : '';
            case 'roadPosition':
                return RoadPositionLabel[row.roadPosition as RoadPosition] ?? '--';
            case 'controle':
                return ControleLabel[row.controle];
            case 'position':
                return row.position !== undefined ? row.position : '--';
            case 'material':
                return row.material ? materials?.find(b => b.key === row.material)?.label ?? 'Exigence indisponible' : '--';
            case 'business':
                return row.business ? businesses?.find(b => b.key === row.business)?.label ?? `${isProducer ? 'Producteur' : 'Fournisseur'} indisponible` : '--';;
            case 'operation':
                return row.operation ? operations?.find(o => o.key === row.operation)?.label ?? 'Opération indisponible' : '--';
            case 'phase':
                return PhaseLabel[row.phase as Phase];
            case 'date':
                return row.date ? formatDate(row.date) : '--';
            default:
                return String(row[column as keyof AnalysisDataRow]) ?? '--';
        }
    }, [businesses, isProducer, materials, operations, operation]);

    const rows = useMemo(() => data.dataRows.map((row, index) => (
        <tr key={index} style={row.controle ? { borderLeft: `${ControleColor[row.controle]} 3px solid` } : undefined}>
            {columns.map(column => <td key={index + '_' + column}>{getColumnValue(row, column)}</td>)}
            <td className={row.problematic ? 'problematic' : ''}>
                <ProgressBar value={row.value} maximum={data.max} minimum={data.min} cursor={data.average} />
                {formatNumberToFixedDecimal(row.value)}
            </td>
        </tr>
    )), [data, columns, getColumnValue]);

    const headers = useMemo(() => columns.map(column => {
        const label = column !== 'business' ? ColumnLabel[column] ?? column : isProducer ? 'Producteur' : 'Fournisseur';
        return <td key={column}>{label}</td>;
    })
        , [columns, isProducer]);

    useEffect(() => {
        if ((columns.includes('material') || chartByOptions.includes('material')) && !materials) {
            getRequest<ListItem[]>(`/material/list/requirements${!isLibrary && operation ? '/' + operation._id : ''}`)
                .then(setMaterials)
                .catch(() => setMaterials([]));
        }
        if ((columns.includes('operation') || chartByOptions.includes('business')) && !businesses) {
            getRequest<ListItem[]>('/business/list')
                .then(setBusinesses)
                .catch(() => setBusinesses([]));
        }
        if (columns.includes('operation') && !operations) {
            getRequest<ListItem[]>('/operation/list')
                .then(setOperations)
                .catch(() => setOperations([]));
        }
    }, [chartByOptions, columns, operation]);

    useEffect(() => {
        setScale({
            min: data.min && data.min <= 0 ? Math.floor(data.min * 1.1) : 0,
            max: !data.max || data.max <= 0 ? 0 : Math.ceil(data.max * 1.1),
        });
    }, [data]);

    return (
        <Printable title={title} subtitle={subtitle} filename={filename}>
            {isAuthorized ? (
                <div className="data-table">
                    <h3>Représentation des valeurs</h3>
                    <div className="data-chart-controles no-print">
                        {isLibrary || chartAxis !== 'pr' || (operation && operation.synoptique === 1 && chartAxis === 'pr')
                            ? (
                                <Fragment>
                                    <ScaleInput scale={scale} onChange={setScale} />
                                    <Switch
                                        id="chart-type"
                                        className="switch-chart-type"
                                        items={[{ key: 'average', label: 'Moyenne' }, { key: 'scatter', label: 'Nuage' }]}
                                        value={chartType}
                                        onChange={(value) => value !== undefined ? setChartType(value) : null}
                                    />
                                </Fragment>
                            ) : (
                                <Switch
                                    id="chart-type"
                                    className="switch-chart-type"
                                    items={[{ key: 'average', label: 'Linéaire' }, { key: 'bubble', label: 'Cartésien' }]}
                                    value={chartType}
                                    onChange={(value) => value !== undefined ? setChartType(value) : null}
                                />
                            )
                        }
                        {chartByOptions.length > 1 && (
                            <Switch
                                className="switch-chart-by"
                                id="chart-by"
                                items={[
                                    { key: 'controle', label: 'Contrôle' },
                                    ...(chartByOptions.includes('material') && (phases.includes(Phase.TRAVAUX) || isLibrary) ? [{ key: 'material', label: 'Matériau' }] : []),
                                    ...(chartByOptions.includes('phase') && phases.length > 1 ? [{ key: 'phase', label: 'Phase' }] : []),
                                    ...(chartByOptions.includes('road') && operation.synoptique === 1 ? [{ key: 'road', label: 'Voie' }] : []),
                                    ...(chartByOptions.includes('roadPosition') && operation.synoptique === 1 ? [{ key: 'roadPosition', label: 'Emplacement' }] : []),
                                    ...(chartByOptions.includes('business') ? [{ key: 'business', label: isProducer ? 'Producteur' : 'Fournisseur' }] : []),
                                ]}
                                value={chartBy}
                                onChange={(value) => value !== undefined ? setChartBy(value) : null}
                            />
                        )}
                    </div>
                    <div className="data-chart">
                        <Expandable>
                            <StatisticsChart
                                data={data}
                                scale={scale}
                                axis={chartAxis}
                                type={chartType}
                                by={chartBy}
                                phases={phases}
                                materials={materials ?? []}
                                businesses={businesses ?? []}
                            />
                        </Expandable>
                    </div>
                    <h3>Statistiques</h3>
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Minimum</td>
                                <td>Moyenne</td>
                                <td>Maximum</td>
                            </tr>
                        </thead>
                        <tbody>
                            {chartBy === 'controle' && Controles.map(controle => (
                                <tr key={controle.key}>
                                    <td>{controle.label}</td>
                                    {['min', 'average', 'max'].map(field => (
                                        <td key={field}>
                                            <span className="data-value">{formatNumberToFixedDecimal(data.controle[controle.key]?.[field as keyof MinMaxAverage])}</span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            {chartBy === 'phase' && phases.map(phase => (
                                <tr key={phase}>
                                    <td>{PhaseLabel[phase]}</td>
                                    {['min', 'average', 'max'].map(field => (
                                        <td key={field}>
                                            <span className="data-value">{formatNumberToFixedDecimal(data.phase[phase]?.[field as keyof MinMaxAverage])}</span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            {chartBy === 'material' && Object.keys(data.material).map(key => (
                                <tr key={key}>
                                    <td>{materials?.find(m => m.key === key)?.label ?? ''}</td>
                                    {['min', 'average', 'max'].map(field => (
                                        <td key={field}>
                                            <span className="data-value">{formatNumberToFixedDecimal(data.material[key]?.[field as keyof MinMaxAverage])}</span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            {chartBy === 'business' && Object.keys(data.business).map(key => (
                                <tr key={key}>
                                    <td>{businesses?.find(b => b.key === key)?.label ?? ''}</td>
                                    {['min', 'average', 'max'].map(field => (
                                        <td key={field}>
                                            <span className="data-value">{formatNumberToFixedDecimal(data.business[key]?.[field as keyof MinMaxAverage])}</span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            <tr>
                                <td><strong>Global</strong></td>
                                {['min', 'average', 'max'].map(field => (
                                    <td key={field}>
                                        <span className="data-value">{formatNumberToFixedDecimal(data[field as keyof MinMaxAverage])}</span>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                    <h3>Données brutes</h3>
                    <table>
                        <thead>
                            <tr>
                                {headers}
                                <td className="data-cell">Valeur</td>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            ) : (
                <FakeData permission={permission}><FakeDataTable /></FakeData>
            )}
        </Printable>
    );
}

export default Statistics;