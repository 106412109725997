import { useCallback, useMemo } from "react";
import { FormBaseProps } from "../../../hooks/useForm";
import './index.scss';

interface SwitchProps<T> extends FormBaseProps<T> {
    items: { key: T, label: string }[];
    label?: string;
    className?: string;
}

const Switch = <T,>({
    id,
    items,
    value,
    className,
    disabled,
    onChange
}: SwitchProps<T>) => {
    const handleClick = useCallback((index: number) => {
        if (disabled) return;

        if (index < items.length && items[index].key !== value) {
            onChange(items[index].key, items[index].label);
        }
    }, [value, onChange, disabled, items]);

    const selectedIndex = useMemo(() => items.findIndex(i => i.key === value), [value, items]);

    return (
        <div className={`${className ? className : ''} switch-input ${disabled ? 'disabled' : ''}`} id={id}>
            {items.map((item, index) => (
                <div
                    key={`${id}_${item.key}`}
                    onClick={() => handleClick(index)}
                    className={item.key === value ? "item selected" : "item"}
                >
                    {item.label}
                </div>
            ))}
            <div style={{ left: `${items?.length ? selectedIndex * 100 / items?.length : 0}%`, width: `${items?.length ? 100 / items?.length : 0}%` }} className="item-hidden"><div /></div>
        </div>
    )
};

export const SwitchYesNo = (props: Omit<SwitchProps<boolean>, 'items'>) => (
    <Switch<boolean>
        items={[{ key: true, label: 'Oui' }, { key: false, label: 'Non' }]}
        {...props}
    />
);

export default Switch;