import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Popup, Source } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import SampleDetail from '../../../components/SampleDetail';
import { ListFilters } from '../../../components/data/List';
import Map, { MapAction, MapRefProps, Marker } from '../../../components/data/Map';
import IdentityCard from '../../../components/entities/IdentityCard';
import CheckboxList from '../../../components/inputs/CheckboxList';
import MenuBar from '../../../components/ui/MenuBar';
import { ModalRight } from '../../../components/ui/Modal/ModalRight';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import useDebounce from '../../../hooks/useDebounce';
import { Coordinates } from '../../../models/location';
import { Lot } from '../../../models/lot';
import { Marker as InterestMarker } from '../../../models/marker';
import { Operation, OperationIcon, Phase, Phases } from '../../../models/operation';
import { PopulationSynoptique } from '../../../models/population';
import { Sample, SampleIcon } from '../../../models/sample';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import { SynoptiqueElements } from '../../../synoptique/Synoptique.class';
import { filtersToQueryParam } from '../../../utils/format';
import { SynoptiqueFilters } from '../Synoptique';
import Filters from '../Synoptique/components/Filters';
import MarkerDetail from './MarkerDetail';
import './index.scss';

const INITIAL_FILTERS_TYPE_1: ListFilters = {
    populationDisplay: true,
    sampleDisplay: true,
    populationProblematic: true,
    sampleProblematic: true,
};

const INITIAL_FILTERS_TYPE_2: ListFilters = {
    populationDisplay: true,
    sampleDisplay: true,
    populationProblematic: true,
};

const FILTERS_LOCALSTORAGE_KEY = 'oso-map-filters';

const getFiltersInLocalStorage = () => JSON.parse(localStorage.getItem(FILTERS_LOCALSTORAGE_KEY) || "{}");

const setFiltersInLocalStorage = (operation: Operation, filters: any) => {
    const storedFilters = getFiltersInLocalStorage();
    storedFilters[operation._id] = filters;
    localStorage.setItem(FILTERS_LOCALSTORAGE_KEY, JSON.stringify(storedFilters));
}

const OperationMap = () => {
    const { operation } = useWorkspace();
    const [elements, setElements] = useState<SynoptiqueElements>({});
    const [isPanelVisible, setPanelVisible] = useState<boolean>(false);
    const [activeFilters, setActiveFilters] = useState<ListFilters>({});
    const [popup, setPopup] = useState<(Coordinates & { data: Sample | Lot | PopulationSynoptique | InterestMarker }) | null>(null);
    const [selectedSample, setSelectedSample] = useState<string | null>(null);
    const [selectedMarker, setSelectedMarker] = useState<string | null>(null);
    const mapRef = useRef<MapRefProps>(null);
    useDebounce(() => getElements(), 600, [activeFilters]);
    const navigate = useNavigate();

    const getElements = useCallback(async () => {
        setFiltersInLocalStorage(operation, activeFilters);
        getRequest<SynoptiqueElements>(`/operation/${operation?._id}/elements`, {
            params: { ...filtersToQueryParam({ ...activeFilters }) },
            errorMessage: 'Une erreur est survenue lors de la récupération des éléments',
            loader: true
        })
            .then(setElements)
            .catch(() => {
                setElements({});
            });
    }, [activeFilters]);

    const markers = useMemo(() => {
        const sampleMarkers = (elements.samples ?? [])
            .filter(s => s.location?.coordinates?.latitude && s.location?.coordinates?.longitude)
            .map(s => (
                <Marker
                    key={'sample' + s._id}
                    onClick={() => setSelectedSample(s._id)}
                    latitude={s.location.coordinates?.latitude!}
                    longitude={s.location.coordinates?.longitude!}
                    className={s.problematic ? 'color-error' : 'color-success'}
                    icon={SampleIcon[s.type]}
                    onMouseEnter={() => setPopup({
                        longitude: s.location.coordinates?.longitude!,
                        latitude: s.location.coordinates?.latitude!,
                        data: s
                    })}
                />
            ));

        const markerMarkers = (elements.markers ?? [])
            .filter(s => s.location?.coordinates?.latitude && s.location?.coordinates?.longitude)
            .map(s => (
                <Marker
                    key={'marker' + s._id}
                    onClick={() => setSelectedMarker(s._id)}
                    latitude={s.location.coordinates?.latitude!}
                    longitude={s.location.coordinates?.longitude!}
                    className={s.problematic ? 'color-error' : 'color-success'}
                    icon="mdi:camera"
                    onMouseEnter={() => setPopup({
                        longitude: s.location.coordinates?.longitude!,
                        latitude: s.location.coordinates?.latitude!,
                        data: s
                    })}
                />
            ));

        const populationMarkers = (elements.populations ?? [])
            .filter(s => s.location?.coordinates?.latitude && s.location?.coordinates?.longitude)
            .map((s, i) => (
                <Marker
                    key={'population' + i}
                    latitude={s.location.coordinates?.latitude!}
                    longitude={s.location.coordinates?.longitude!}
                    className={s.problematic ? 'color-error' : 'color-success'}
                    icon="mdi:test-tube-empty"
                    onMouseEnter={() => setPopup({
                        longitude: s.location.coordinates?.longitude!,
                        latitude: s.location.coordinates?.latitude!,
                        data: s
                    })}
                />
            ));
        return [...sampleMarkers, ...markerMarkers, ...populationMarkers];
    }, [elements]);

    const layers = useMemo(() => {
        const data = {
            "type": "FeatureCollection",
            "features": elements.lots?.map(lot => ({
                type: "Feature",
                properties: { lot },
                geometry: {
                    type: "Polygon",
                    coordinates: [lot.zone.polygon?.map(p => [p.longitude, p.latitude]) ?? []]
                }
            }))
        }

        return (
            <Source id="lots" type="geojson" data={data}>
                <Layer
                    id="lots-layer"
                    type="fill"
                    source="network"
                    paint={{
                        'fill-color': '#397d92',
                        'fill-outline-color': '#397d92',
                        'fill-opacity': 0.3
                    }}
                />
            </Source>
        )
    }, [elements]);

    const handleActionComplete = (a: MapAction, data: { marker: Coordinates }) => {
        if (a === MapAction.INTEREST_POINT) {
            navigate(`/operation/${operation._id}/${operation.phases[0]}/point-d-interet/creer?latitude=${data.marker.latitude}&longitude=${data.marker.longitude}`);
        }
    }

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.fitToEntities([...elements.lots ?? [], ...elements.samples ?? [], ...elements.markers ?? []]);
        }
    }, [elements]);

    useEffect(() => {
        // Get stored filters
        try {
            const storedFilters = getFiltersInLocalStorage();
            setActiveFilters(storedFilters[operation._id] ?? (operation.synoptique === 1 ? INITIAL_FILTERS_TYPE_1 : INITIAL_FILTERS_TYPE_2));
        } catch {
            setActiveFilters({});
        }
    }, [operation]);

    return (
        <Fragment>
            <ScrollableContent noPadding id="operation-map">
                <Map
                    availableActions={[MapAction.INTEREST_POINT, MapAction.POSITION, MapAction.STYLE]}
                    onActionComplete={handleActionComplete}
                    base={operation.zone.polygon}
                    ref={mapRef}
                >
                    <MenuBar card className="map-menu">
                        <MenuBar.Item label="Phases" icon={OperationIcon.PHASE}>
                            <CheckboxList id="map-phases" value={activeFilters.phase as Phase[] | undefined} items={Phases.filter(p => operation.phases.includes(p.key))} onChange={(phase) => setActiveFilters({ ...activeFilters, phase })} />
                        </MenuBar.Item>
                    </MenuBar>
                    {layers}
                    {markers}
                    {!!popup && (
                        <Popup
                            longitude={popup.longitude}
                            latitude={popup.latitude}
                            anchor="bottom"
                            offset={20}
                            className="map-popup"
                            onClose={() => setPopup(null)}
                            maxWidth="350px"
                        >
                            <IdentityCard entity={popup.data} />
                        </Popup>
                    )}
                </Map>
                <SampleDetail id={selectedSample ?? undefined} onClose={() => setSelectedSample(null)} />
                <MarkerDetail id={selectedMarker ?? undefined} onClose={() => setSelectedMarker(null)} />
                <ModalRight
                    visible={!!isPanelVisible}
                    className="synoptique-filters-panel"
                    actions={[
                        { label: 'Réinitialiser', color: 'secondary', onClick: () => setActiveFilters(operation.synoptique === 1 ? { ...INITIAL_FILTERS_TYPE_1 } : { ...INITIAL_FILTERS_TYPE_2 }) },
                        { label: 'Fermer', onClick: () => setPanelVisible(false) },
                    ]}
                >
                    <Filters
                        filters={activeFilters as SynoptiqueFilters}
                        withProblematic={operation.synoptique !== 1}
                        onChange={(filters) => setActiveFilters(filters as ListFilters)}
                    />
                </ModalRight >
            </ScrollableContent>
        </Fragment>
    )
}

/* 

icon={{
    url: s.problematic
        ? s.type === SampleType.CAROTTE ? carotteMarkerProblematic : (s.type === SampleType.PRELEVEMENT ? prelevementMarkerProblematic : sondageMarkerProblematic)
        : s.type === SampleType.CAROTTE ? carotteMarker : (s.type === SampleType.PRELEVEMENT ? prelevementMarker : sondageMarker),
    scaledSize: SCALED_SIZE,
    anchor: ANCHOR
}} */


export default OperationMap;