import { useCallback, useEffect, useState } from "react";
import NumberInput from "../../../../components/inputs/NumberInput";
import TextInput from "../../../../components/inputs/TextInput";
import PanelRight from "../../../../components/ui/PanelRight";
import useForm, { FormComparator } from "../../../../hooks/useForm";
import { OuvrageElement } from "../../../../models/ouvrage";

export interface OuvrageBarriereFormProps {
    barriere: OuvrageElement;
    onClose: () => void;
    onChange: (barriere: OuvrageElement) => void;
    onDelete: () => void;
}

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.MIN_LENGTH, compareTo: 3 }],
    'dimensions.length': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.height': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'coordinates.x': [{ comparator: FormComparator.REQUIRED }],
    'coordinates.y': [{ comparator: FormComparator.REQUIRED }],

}

const OuvrageBarriereForm = ({ onClose, barriere, onChange, onDelete }: OuvrageBarriereFormProps) => {
    const [initial, setInitial] = useState<OuvrageElement>(barriere);
    const { entity, attachInput, setEntity, validate, errors } = useForm<OuvrageElement>(barriere);

    const handleClose = useCallback(() => {
        onChange(initial);
        onClose();
    }, [initial]);

    const handleSubmit = useCallback(() => {
        if (validate(VALIDATION)) {
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        const entityValidated = validate(VALIDATION);
        if (entityValidated) onChange(entityValidated);
    }, [entity]);

    useEffect(() => {
        setInitial(barriere ?? {});
        setEntity(barriere ?? {});
    }, [barriere?._id]);

    return (
        <PanelRight
            title={initial.type === 'glissiere' ? 'Glissière' : 'Barrière'}
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: handleClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit }
            ]}
            onDelete={onDelete}
        >
            <div className="input-column">
                <label htmlFor="name">Nom *</label>
                <TextInput {...attachInput('name')} />
            </div>
            <div className="column">
                <h4>Dimensions</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="length">Longueur *</label>
                        <NumberInput {...attachInput('dimensions.length')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="height">Hauteur *</label>
                        <NumberInput {...attachInput('dimensions.height')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Position</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="x">Longitudinale *</label>
                        <NumberInput {...attachInput('coordinates.x')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="y">Transversale *</label>
                        <NumberInput {...attachInput('coordinates.y')} />
                    </div>
                </div>
            </div>
        </PanelRight>
    )
}
export default OuvrageBarriereForm;