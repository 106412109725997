import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import SampleAnalysisColumn from '../../../components/Analysis/SampleAnalysisColumn';
import ValidationCard from '../../../components/entities/ValidationCard';
import Card from '../../../components/ui/Card';
import { ModalRight } from '../../../components/ui/Modal/ModalRight';
import Tag from '../../../components/ui/Tag';
import { Component } from '../../../models/component';
import { Controles } from '../../../models/sample';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import { formatDate } from '../../../utils/format';
import './index.scss';

interface ComponentDetailProps {
    id?: string;
    component?: Component;
    onClose: (refresh?: boolean) => void;
}

const ComponentDetail = ({
    id,
    component: componentFromProps,
    onClose
}: ComponentDetailProps) => {
    const { workspacePermissions } = useWorkspace();
    const [component, setComponent] = useState<Component | null>(componentFromProps ?? null);

    useEffect(() => {
        if (id) {
            getRequest<Component>(`/component/${id}`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'analyse' })
                .then(setComponent)
                .catch(() => onClose());
        } else if (componentFromProps) {
            setComponent(componentFromProps);
        } else {
            setComponent(null);
            onClose();
        }
    }, [id, componentFromProps]);

    if (!component) {
        return (null);
    }

    return (
        <ModalRight
            visible={!!component}
            actions={[{ label: 'Fermer', color: 'secondary', onClick: onClose }]}
            title={component?.materialPopulated?.name}
        >
            <div id="component-detail">
                <div id="component-detail-header">
                    <Tag value={component.controle} items={Controles} />
                </div>
                <ValidationCard
                    entity={component}
                    canValidate={workspacePermissions.validate}
                    endpoint="/component"
                    onSubmit={() => onClose(true)}
                />
                <Card title="Données">
                    {!!component.analysisPopulated?.date && <span><strong>Date d'analyse : </strong>{formatDate(component.analysisPopulated?.date)}</span>}
                    {!!component.analysisPopulated?.businessPopulated && <span><strong>Fournisseur : </strong>{component.analysisPopulated?.businessPopulated.name}</span>}
                    {!!component.comment && <span><strong>Commentaire : </strong>{component.comment}</span>}
                    {!!component.problematic && <span><strong>Problématique : </strong><Icon icon="mdi:warning-outline" className="icon-small color-error" /></span>}
                    {component.analysisPopulated && component.materialPopulated && <SampleAnalysisColumn analysis={component.analysisPopulated} material={component.materialPopulated} />}
                </Card>
            </div>
        </ModalRight >
    );
}

export default ComponentDetail;