import Card from '../../../components/ui/Card';
import Select from '../../../components/inputs/Select';
import TextInput from '../../../components/inputs/TextInput';
import { DestinationGranulats, OrigineGranulats, TypeGranulats } from '../../../models/material';
import { MaterialSpecificationsFormProps } from '../EditComponent';

const GranulatEdit = ({
    attachInput
}: MaterialSpecificationsFormProps) => (
    <Card title="Description">
        <div className="row">
            <div className="input-column">
                <label htmlFor="specifications.fractionGranulat">Fraction granulométrique</label>
                <TextInput {...attachInput('specifications.fractionGranulat')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.typeGranulat">Type</label>
                <Select items={TypeGranulats} {...attachInput('specifications.typeGranulat')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.origineGranulat">Origine *</label>
                <Select items={OrigineGranulats} {...attachInput('specifications.origineGranulat')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.destinationGranulat">Destination</label>
                <Select items={DestinationGranulats} {...attachInput('specifications.destinationGranulat')} />
            </div>
        </div>
    </Card>
)

export default GranulatEdit;