import { Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import Expandable from '../Expandable';

const CHART_DATA = {
    "min": 0,
    "max": 48,
    "data": {
        "labels": [
            "Sens 2 - VL Roue droite 6+580",
            "Sens 2 - VL Roue droite 6+630",
            "Sens 2 - VL Roue droite 6+670",
            "Sens 2 - VL Axe 7+170",
            "Sens 2 - VL Axe 7+650",
            "Sens 2 - VL Axe 8+550",
            "Sens 2 - VL Axe 8+605",
            "Sens 2 - VL Roue droite 8+608",
            "Sens 2 - VL Axe 9+500",
            "Sens 2 - VL Axe 9+800",
            "Sens 2 - VL Axe 9+802",
            "Sens 2 - VL Roue droite 10+337"
        ],
        "datasets": [
            {
                "borderWidth": 1,
                "pointRadius": 2,
                "spanGaps": true,
                "label": "Couche 1",
                "data": [
                    3,
                    2,
                    2.5,
                    2.5,
                    2,
                    2,
                    2.5,
                    2.5,
                    2.5,
                    2,
                    2,
                    2.5
                ],
                "backgroundColor": "#010508",
                "borderColor": "#010508"
            },
            {
                "borderWidth": 1,
                "pointRadius": 2,
                "spanGaps": true,
                "label": "Couche 2",
                "data": [
                    2.5,
                    3.5,
                    3,
                    7.5,
                    9.5,
                    9.5,
                    10,
                    10,
                    9.5,
                    9.5,
                    9.5,
                    3
                ],
                "backgroundColor": "#397d92",
                "borderColor": "#397d92"
            },
            {
                "borderWidth": 1,
                "pointRadius": 2,
                "spanGaps": true,
                "label": "Couche 3",
                "data": [
                    4,
                    5,
                    5,
                    30,
                    2,
                    2,
                    1.5,
                    1.5,
                    2.5,
                    3,
                    4
                ],
                "backgroundColor": "#d94032",
                "borderColor": "#d94032"
            },
            {
                "borderWidth": 1,
                "pointRadius": 2,
                "spanGaps": true,
                "label": "Couche 4",
                "data": [
                    6,
                    5,
                    6,
                    13,
                    13,
                    12,
                    11,
                    12,
                    12,
                    5
                ],
                "backgroundColor": "#faa97a",
                "borderColor": "#faa97a"
            },
            {
                "borderWidth": 1,
                "pointRadius": 2,
                "spanGaps": true,
                "label": "Couche 5",
                "data": [
                    13,
                    14,
                    15.5,
                    14,
                    13,
                    13,
                    14,
                    14,
                    12
                ],
                "backgroundColor": "#f2c8a2",
                "borderColor": "#f2c8a2"
            },
            {
                "borderWidth": 1,
                "pointRadius": 2,
                "spanGaps": true,
                "label": "Couche 6",
                "data": [
                    16,
                    14.5,
                    13
                ],
                "backgroundColor": "#d3eaf0",
                "borderColor": "#d3eaf0"
            }
        ]
    }
}

const FakeThicknessChart = () => (
    <Fragment>
        <Expandable>
            <Bar
                data={CHART_DATA.data}
                options={{
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom'
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            reverse: true,
                            stacked: true,
                            max: CHART_DATA.max,
                            min: CHART_DATA.min,
                        }
                    }
                }}
            />
        </Expandable>
        <div className="data-table">
            <h3>Données brutes</h3>
            <table>
                <thead>
                    <tr>
                        <td>Echantillon</td>
                        {(CHART_DATA.data.datasets).map(dataset => (
                            <td key={dataset.label}>{dataset.label}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {(CHART_DATA.data.labels).map((label, index) => (
                        <tr key={index}>
                            <td>{label}</td>
                            {(CHART_DATA.data.datasets).map(dataset => (
                                <td key={dataset.label}>{dataset.data[index]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </Fragment>
);

export default FakeThicknessChart;