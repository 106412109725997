import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { getRequest } from '../../../services/request.service';

export interface HomeImageProps {
    entityId: string;
}

const HomeImage = ({ entityId }: HomeImageProps) => {
    const [image, setImage] = useState<string>();

    useEffect(() => {
        getRequest<string>(`/storage/entity/${entityId}/first`)
            .then(setImage)
            .catch(() => setImage(undefined))
    }, [entityId]);

    return (
        <div className="home-result-card-image">
            {!image ? (
                <div>
                    <Icon icon="mdi:image-remove-outline" className="icon-xl color-silver" />
                </div>
            ) : (
                <img src={`data:image/webp;charset=utf-8;base64,${image}`} alt="" />
            )}
        </div>
    )
}
export default HomeImage;