// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ouvrage-element-form {
  overflow: hidden;
}
#ouvrage-element-form .col {
  height: 100%;
}

#ouvrage-blueprint-edit {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  position: relative;
}
#ouvrage-blueprint-edit #ouvrage-blueprint-edit-menu {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/pages/Ouvrage/Edit/index.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,YAAA;AAER;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;AACR","sourcesContent":["#ouvrage-element-form {\n    overflow: hidden;\n    & .col {\n        height: 100%;\n    }\n}\n\n#ouvrage-blueprint-edit {\n    display: flex;\n    flex-direction: row;\n    flex-grow: 1;\n    height: 100%;\n    position: relative;\n\n    & #ouvrage-blueprint-edit-menu {\n        position: absolute;\n        top: 12px;\n        left: 12px;\n        z-index: 2;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
