export const toggleInArray = <T>(
    item: T,
    array: T[] | undefined,
    equals?: (e1: T, e2: T) => boolean
): T[] => {
    if (!array?.length) return [item];
    const _array = [...array];
    const index = _array.findIndex((e) =>
        equals ? equals(item, e) : item === e
    );
    if (index === -1) {
        _array.push(item);
    } else {
        _array.splice(index, 1);
    }
    return _array;
};

export const replaceInArray = <T>(
    item: T,
    array: T[] | undefined,
    equals?: (e1: T, e2: T) => boolean,
    addIfNotExists?: boolean
): T[] | undefined => {
    if (!array?.length && !addIfNotExists) return undefined;
    const _array = [...(array ?? [])];
    const index = _array.findIndex((e) =>
        equals ? equals(item, e) : item === e
    );
    if (index === -1) {
        if (!addIfNotExists) return array;
        _array.push(item);
    }
    _array[index] = item;
    return _array;
};

export const getNestedField = (entity: any, field: string): any => {
    if (!entity) return undefined;

    const fieldSplit = field.split(".");
    if (fieldSplit.length === 1) {
        return entity[field];
    } else {
        const firstItem = fieldSplit.shift();
        return getNestedField(entity[firstItem!], fieldSplit.join("."));
    }
};

export const changeFieldValue = (entity: any, field: string, value: any) => {
    const _entity = { ...entity };

    const fieldSplit = field.split(".");

    if (fieldSplit.length === 1) {
        _entity[field] = value;
    } else {
        const firstItem = fieldSplit.shift();
        _entity[firstItem!] = changeFieldValue(
            entity[firstItem!] ?? {},
            fieldSplit.join("."),
            value
        );
    }

    return _entity;
};

export const removeFieldValue = (entity: any, field: string) => {
    if (!entity) return;
    const _entity = { ...entity };

    const fieldSplit = field.split(".");
    if (fieldSplit.length === 1) {
        delete _entity[field];
    } else {
        const firstItem = fieldSplit.shift();
        _entity[field] = removeFieldValue(
            entity[firstItem!],
            fieldSplit.join(".")
        );
    }

    return _entity;
};

export const transformValue = (value: any): any => {
    if (value instanceof Date) {
        return value.toISOString();
    }

    return value;
};

export const isArray = <T>(e: T[] | T): e is T[] => Array.isArray(e);

export const isType = <T>(
    obj: any,
    callback: (obj: any) => boolean
): obj is T => callback(obj);
