import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import List, { FilterType } from '../../components/data/List';
import Header from '../../components/ui/Header';
import { RoleLabel, User } from '../../models/user';
import { formatTimestampToFullLocalString } from '../../utils/format';
import { ActionIcon } from '../../utils/icons';

const Users = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Utilisateurs' }]} withBorder />
            <List<User>
                columns={[
                    { key: 'firstName', label: 'Nom', mapper: (element) => `${element.firstName ? element.firstName + ' ' : ''}${element.lastName ?? ' '}` },
                    { key: 'email', label: 'Email' },
                    { key: 'role', label: 'Rôle', mapper: (element) => RoleLabel[element.role] ?? '' },
                    { key: 'business', label: 'Entreprise', mapper: (element) => element.businessPopulated?.name ?? '' },
                    { key: 'connectedAt', label: 'Dernière connexion', mapper: (element) => element.connectedAt ? formatTimestampToFullLocalString(element.connectedAt) : '' }
                ]}
                dataEndpoint="/user"
                crudEndpoint="/user"
                baseUrl="/utilisateurs"
                actions={{
                    edit: true,
                    activate: true,
                    delete: true,
                }}
                warningBeforeDelete
                initialPagination={{ sortBy: 'firstName', sortDirection: 1 }}
                buttons={[{ label: 'Créer un utilisateur', icon: ActionIcon.ADD, onClick: () => navigate('/utilisateurs/creer') }]}
                filters={[
                    { field: 'active', label: 'Actif' },
                    { field: 'business', label: 'Entreprise', type: FilterType.MULTIPLE_SELECT, endpoint: '/business/list' }
                ]}
            />
        </Fragment>
    )
}

export default Users;
