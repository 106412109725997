import { Icon } from '@iconify/react';
import { useCallback, useState } from 'react';
import { FormBaseProps } from '../../../hooks/useForm';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { ActionIcon } from '../../../utils/icons';
import { Textarea } from '../Textarea';
import './index.scss';

interface CommentPopupProps extends FormBaseProps<string> {
    withLabel?: boolean;
}

const CommentPopup = ({
    value,
    onChange,
    withLabel = false
}: CommentPopupProps) => {
    const [isTextareaVisible, setTextareaVisible] = useState(false);
    const ref = useOutsideClick(useCallback(() => setTextareaVisible(false), []));

    return (
        <div className="comment" ref={ref}>
            {withLabel && <span onClick={() => setTextareaVisible(!isTextareaVisible)}>Commentaire</span>}
            {value
                ? <Icon icon={ActionIcon.COMMENT_ON} className="icon-button" onClick={() => setTextareaVisible(!isTextareaVisible)} />
                : <Icon icon={ActionIcon.COMMENT_ON} className="icon-button" onClick={() => setTextareaVisible(!isTextareaVisible)} />
            }
            {isTextareaVisible && (
                <Textarea
                    id="comment"
                    value={value ?? ''}
                    onChange={onChange}
                />
            )}
        </div>
    );
}

export default CommentPopup;