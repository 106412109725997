import Card from "../../../components/ui/Card";
import Select from "../../../components/inputs/Select";
import { ClasseArases, ClassePlateformes, DestinationSols, TypeSols } from "../../../models/material";
import { MaterialSpecificationsFormProps } from "../EditComponent";

const SolEdit = ({
    attachInput
}: MaterialSpecificationsFormProps) => (
    <Card title="Spécifications">
        <div className="row">
            <div className="input-column">
                <label htmlFor="specifications.typeSol">Type de sol *</label>
                <Select items={TypeSols} {...attachInput('specifications.typeSol')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.destinationSol">Destination *</label>
                <Select items={DestinationSols} {...attachInput('specifications.destinationSol')} />
            </div>
            <div className="input-column">
                <label htmlFor="sol.destination">Classe plateforme</label>
                <Select items={ClassePlateformes} {...attachInput('specifications.classePlateforme')} />
            </div>
            <div className="input-column">
                <label htmlFor="sol.classeArase">Classe Arase</label>
                <Select items={ClasseArases} {...attachInput('specifications.classeArase')} />
            </div>
        </div>
    </Card>
)

export default SolEdit;