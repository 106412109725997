import { ReactNode } from "react";
import Button, { ButtonProps } from "../Button";
import IconLink from "../IconLink";
import './index.scss';

export interface PanelRightProps {
    children: ReactNode;
    title?: string;
    className?: string;
    id?: string;
    actions?: ButtonProps[];
    onDelete?: () => void;
}

const PanelRight = ({ title, className, id, actions, children, onDelete }: PanelRightProps) => {

    return (
        <div id={id} className={`panel-right ${className ?? ''}`}>
            {title && <div className="panel-right-title">{title}</div>}
            <div className="panel-right-content">
                {children}
                {!!onDelete && (
                    <div className="panel-right-content-delete">
                        <IconLink type="trash" label="Supprimer" onClick={onDelete} />
                    </div>
                )}
            </div>
            <div className="panel-right-footer">
                {actions?.map(a => <Button key={a.label} {...a} />)}
            </div>
        </div>
    )
}
export default PanelRight;