import { useCallback, useEffect, useState } from "react";
import NumberInput from "../../../../components/inputs/NumberInput";
import PanelRight from "../../../../components/ui/PanelRight";
import useForm, { FormComparator } from "../../../../hooks/useForm";
import { Buse } from "../../../../models/ouvrage";

export interface BuseFormProps {
    buse: Buse;
    onClose: () => void;
    onChange: (buse: Buse) => void;
}

const VALIDATION = {
    length: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    diameterV: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    diameterH: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    thicknessV: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    thicknessH: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    biseauStart: [{ comparator: FormComparator.POSITIVE_NUMBER }],
    biseauEnd: [{ comparator: FormComparator.POSITIVE_NUMBER }],
}

const BuseForm = ({ onClose, buse, onChange }: BuseFormProps) => {
    const [initial, setInitial] = useState<Buse>(buse);
    const { entity, attachInput, setEntity, validate } = useForm<Buse>(buse);

    const handleClose = useCallback(() => {
        onChange(initial);
        onClose();
    }, [initial]);

    const handleSubmit = useCallback(() => {
        if (validate(VALIDATION)) {
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        const entity = validate(VALIDATION);
        if (entity) onChange(entity);
    }, [entity]);

    return (
        <PanelRight
            title="Buse"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: handleClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit }
            ]}
        >
            <div className="input-column">
                <label htmlFor="length">Longueur *</label>
                <NumberInput {...attachInput('length')} />
            </div>
            <div className="column">
                <h4>Diamètres</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="diameterH">Horizontal *</label>
                        <NumberInput {...attachInput('diameterH')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="diameterV">Vertical *</label>
                        <NumberInput {...attachInput('diameterV')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Epaisseurs</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="thicknessH">Horizontale *</label>
                        <NumberInput {...attachInput('thicknessH')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="thicknessV">Verticale *</label>
                        <NumberInput {...attachInput('thicknessV')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Biseaux (degrés)</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="biseauStart">Début</label>
                        <NumberInput {...attachInput('biseauStart')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="biseauEnd">Fin</label>
                        <NumberInput {...attachInput('biseauEnd')} />
                    </div>
                </div>
            </div>
        </PanelRight>
    )
}
export default BuseForm;