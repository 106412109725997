import { useState, } from 'react';
import { Modal } from '.';
import TextInput from '../../inputs/TextInput';
import './index.scss';

interface ModalDeleteProps {
    onCancel: () => void;
    onSubmit: () => void;
}

const ModalDelete = ({
    onCancel,
    onSubmit
}: ModalDeleteProps) => {
    const [deleteInputValue, setDeleteInputValue] = useState('');

    const handleSubmit = (event?: any) => {
        event?.preventDefault();
        if (deleteInputValue && deleteInputValue.toLocaleLowerCase()) {
            onSubmit();
        }
    }
    return (
        <Modal
            title="Attention !"
            size="small"
            actions={[
                { color: 'secondary', label: 'Annuler', onClick: onCancel },
                { color: deleteInputValue?.toLocaleLowerCase() === 'supprimer' ? 'primary' : 'disabled', label: 'Confirmer', onClick: handleSubmit },
            ]}
        >
            <div>La suppression de cet élément est définitive et entraîne
                <span className="error"> la suppression en cascade de toutes les informations qui lui sont liées</span>
            </div>
            <div>Si vous souhaitez vraiment continuer, tapez SUPPRIMER</div>
            <form onSubmit={handleSubmit} autoComplete="off">
                <TextInput
                    id="delete-input"
                    placeholder="SUPPRIMER"
                    value={deleteInputValue}
                    onChange={(value) => setDeleteInputValue(value ?? '')}
                />
            </form>
        </Modal>
    )
}

export default ModalDelete;