
import { useCallback, useState } from 'react';
import { Material, MaterialListParams, MaterialType, MaterialTypes } from '../../models/material';
import { getRequest } from '../../services/request.service';
import List from '../data/List';
import Select from '../inputs/Select';
import { Modal } from '../ui/Modal';
import './index.scss';

interface ModalStandardProps {
    onSubmit: (m: Material) => void;
    onClose: () => void;
}

const ModalStandard = ({
    onSubmit,
    onClose,
}: ModalStandardProps) => {
    const [type, setType] = useState<MaterialType | null>(null);
    const [typeSelected, setTypeSelected] = useState(false);

    const getMaterial = useCallback(async (e: Material) => {
        getRequest<Material>(`/material/${e._id}`, { errorMessage: 'Une erreur est survenue lors de la récupération du matériau', loader: true })
            .then((data) => {
                onSubmit(data);
                onClose();
            })
            .catch(() => null);
    }, []);

    return (
        <Modal
            title="Sélection de norme"
            className={`modal-standard ${type && typeSelected ? 'modal-standard-list' : ''}`}
            actions={!type || !typeSelected ? [
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Suivant', onClick: () => setTypeSelected(true) },
            ] : [
                { label: 'Annuler', color: 'secondary', onClick: onClose },
            ]}
        >
            {!type || !typeSelected ? (
                <div className="input-column">
                    <label htmlFor="type">Sélectionner le type de matériau</label>
                    <Select
                        id="type"
                        value={type ?? ''}
                        items={MaterialTypes}
                        onChange={(value) => setType(value as MaterialType)}
                    />
                </div>
            ) : (
                <List
                    columns={MaterialListParams[type].columns}
                    dataEndpoint={`/material/standard/${type}`}
                    onView={getMaterial}
                />
            )}
        </Modal>
    )
}

export default ModalStandard;
