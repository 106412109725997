import { Icon } from '@iconify/react';
import { Fragment } from 'react';
import Tag from '../../../../components/ui/Tag';
import Tooltip from '../../../../components/ui/Tooltip';
import { Phases, RoadPositionLabel } from '../../../../models/operation';
import { Controles, Sample, SampleIcon, SampleTypeLabel } from '../../../../models/sample';
import useWorkspace from '../../../../services/hooks/use-workspace';
import { LayerColors } from '../../../../synoptique/Synoptique.class';
import { formatDate, formatNumberToFixedDecimal } from '../../../../utils/format';
import { floatToPrString } from '../../../../utils/pr';

interface SampleCardProps {
    sample: Sample;
    onSelectSample: (_id: string) => void;
    thickness: number;
    active: boolean;
}

const SampleCard = ({
    sample,
    onSelectSample,
    thickness,
    active
}: SampleCardProps) => {
    const { operation } = useWorkspace();

    return (
        <div className={`sample-card ${active ? 'active' : ''}`} onClick={() => onSelectSample(sample._id)}>
            <div className="sample-layers">
                {!!sample.layers?.length && sample.layers.map((layer, i) => (
                    <div key={i} style={!!layer.thickness ? { backgroundColor: LayerColors[i], height: Math.floor(layer.thickness * 100 / thickness) + '%' } : { backgroundColor: '#c2c3c9', height: '100%' }}>{!!layer.problematic && <Icon icon="fluent:warning-16-regular" className="icon-small color-error" />}</div>
                ))}
                {!!sample.thickness && <span className="sample-thickness">{formatNumberToFixedDecimal(sample.thickness)} cm</span>}
            </div>
            <div className="sample-details">
                <div className="sample-name"><Tooltip text={SampleTypeLabel[sample.type]}>{SampleIcon[sample.type]}</Tooltip>{sample.name}</div>
                <div className="sample-date">{formatDate(sample.date)}</div>
                <Tag value={sample.phase} items={Phases} />
                <Tag value={sample.controle} items={Controles} />
                {operation.synoptique === 1
                    ? (
                        <Fragment>
                            <div className="sample-detail-group">
                                <label>PR</label>
                                <div>{floatToPrString(sample.location.pr)}</div>
                            </div>
                            <div className="sample-detail-group">
                                <label>Voie</label>
                                <div>{sample.location.road ? operation.roadsObj?.[sample.location.road]?.name : ''}</div>
                            </div>
                            <div className="sample-detail-group">
                                <label>Empl.</label>
                                <div>{sample.location.roadPosition ? RoadPositionLabel[sample.location.roadPosition] : ''}</div>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="sample-detail-group">
                                <label>Repère kilom.</label>
                                <div>{sample.location.pr ? floatToPrString(sample.location.pr) : ''}</div>
                            </div>
                            <div className="sample-detail-group">
                                <label>Localisation</label>
                                <div>{sample.location.location ?? ''}</div>
                            </div>
                        </Fragment>
                    )}
                {sample.fullLot &&
                    <div className="sample-detail-group">
                        <label>Lot</label>
                        <div>{sample.fullLot}</div>
                    </div>
                }
            </div>
        </div>
    );
}

export default SampleCard;