import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/api.slice";
import authSlice from "./slices/auth.slice";
import uiSlice from "./slices/ui.slice";
import workspaceSlice from "./slices/workspace.slice";

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        ui: uiSlice,
        auth: authSlice,
        workspace: workspaceSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            apiSlice.middleware
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
