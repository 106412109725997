import { Icon } from "@iconify/react";
import { Fragment, useMemo } from "react";
import { Revision, RevisionEntity, RevisionStatusList, RevisionTypeLabel } from "../../../models/shared";
import useWorkspace from "../../../services/hooks/use-workspace";
import { formatDate, getFullName } from "../../../utils/format";
import { ActionIcon } from "../../../utils/icons";
import Select from "../../inputs/Select";
import ModalHelper from "../../ui/ModalHelper";
import Tooltip from "../../ui/Tooltip";
import './index.scss';

const STATUS_HELPER = `Le statut défini l'étape où se trouve la donnée dans le processus de validation.\n
Seuls les utilisateurs ayant les rôles d'administrateur ou validateur sur l'opération en cours peuvent modifier ce champ.
Pour les rédacteurs et prestataires, le statut par défaut est "brouillon" lors de la rédaction. Afin de démarrer le processus de validation, utiliser le bouton de notification de fin de saisie sur la page de suivi de l'opération.
`;

interface RevisionCardProps<T extends RevisionEntity> {
    entity: Partial<T>;
    attachInput: (field: string) => any;
}

const RevisionCard = <T extends RevisionEntity>({ entity, attachInput }: RevisionCardProps<T>) => {
    const { workspacePermissions } = useWorkspace();

    const revisions = useMemo(() => {
        const r: Revision[] = [...entity.revisions ?? []];
        r.reverse();
        return r;
    }, [entity]);

    return (
        <Fragment>
            <h4>Révisions</h4>
            <div className="input-column">
                <label>
                    Statut
                    <ModalHelper text={STATUS_HELPER} />
                </label>
                <Select items={RevisionStatusList} {...attachInput('status')} disabled={!workspacePermissions.validate} />
            </div>
            {!!revisions.length && (
                <div className="input-column">
                    <label>Historique</label>
                    <div className="revisions">
                        {revisions.map(revision => (
                            <div key={revision.date.getTime() + revision.type} className="revision">
                                <div>
                                    <span>{RevisionTypeLabel[revision.type]}</span>
                                    <span>{formatDate(revision.date, '/', 'full')} par {getFullName(revision.userPopulated)}</span>
                                </div>
                                {!!revision.comment && (
                                    <Tooltip position="right" text={revision.comment}>
                                        <Icon icon={ActionIcon.COMMENT_ON} className="icon-button" />
                                    </Tooltip>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default RevisionCard;