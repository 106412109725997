import { Icon } from '@iconify/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import CharacAddRemove, { SelectedMaterialCharacteristicElement } from '../../../components/CharacAddRemove';
import StatisticsPicker from '../../../components/data/Statistics/StatisticsPicker';
import NumberInput from '../../../components/inputs/NumberInput';
import PrInput from '../../../components/inputs/PrInput';
import Select, { MultipleSelect } from '../../../components/inputs/Select';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Header from '../../../components/ui/Header';
import NoResult from '../../../components/ui/NoResult';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { AnalysisStatistics } from '../../../models/analysis';
import { MaterialCharacteristic, UnknownMaterialCharacteristics } from '../../../models/material';
import { Phase, Phases, RoadPosition, RoadPositionsList } from '../../../models/operation';
import { Permission } from '../../../models/user';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import { ActionIcon } from '../../../utils/icons';

const VALIDATION = {
    way: [{ comparator: FormComparator.REQUIRED }],
    prStart: [{ comparator: FormComparator.PR }],
    prEnd: [{ comparator: FormComparator.PR }],
};

const TEXT_ROAD_OPERATION = "Cet outil permet d'afficher les valeurs moyennes par PR des analyses, illustrées par phase et par type de contrôle. Seul le sens est obligatoire, les données étant moyennées mélanger les PR de deux sens fausserait les résultats.\n\nLe champ position de couche permet d'indiquer la couche de carotte ou de travaux à étudier.";
const TEXT_ZONE_OPERATION = "Cet outil permet d'afficher les valeurs des analyses réparties par coordonnées et illustrées par phase et par type de contrôle.\n\nLe champ position de couche permet d'indiquer la couche de carotte ou de travaux à étudier.";

type LocationSearch = {
    way: number;
    road: string;
    roadPosition: RoadPosition;
    layer: number;
    prStart: number;
    prEnd: number;
    phase: Phase[];
    material: string[];
}

const ByLocation = () => {
    const { operation, workspacePermissions } = useWorkspace();
    const [availableCharacs, setAvailableCharacs] = useState<MaterialCharacteristic[]>([]);
    const [selectedCharacs, setSelectedCharacs] = useState<SelectedMaterialCharacteristicElement[]>([]);
    const [statistics, setStatistics] = useState<AnalysisStatistics[] | null>(null);
    const { entity, attachInput, validate, setEntity } = useForm<LocationSearch>({});
    const [areParametersOpened, setParametersOpened] = useState<boolean>(true);

    const clear = useCallback(() => {
        setEntity({});
        setStatistics(null);
        setSelectedCharacs([]);
        setAvailableCharacs([{ key: 'general', label: 'Général', elements: UnknownMaterialCharacteristics }]);
        setParametersOpened(true);
    }, []);

    const handleSearch = useCallback(async () => {
        const entity = validate(operation.synoptique === 1 ? VALIDATION : {});

        if (!entity) return;

        const params = {
            ...entity,
            phase: entity?.phase ?? operation.phases,
            prStart: entity?.prStart,
            prEnd: entity?.prEnd,
            operation: operation._id,
            characteristics: selectedCharacs.map(charac => ({
                key: charac.key,
                min: charac.searchParams.min,
                max: charac.searchParams.max,
                seuil: charac.searchParams.seuil,
                tolerance: charac.searchParams.tolerance
            }))
        }

        getRequest<AnalysisStatistics[]>('/analysis/localized-statistics', { params, loader: true, errorMessage: 'Une erreur est survenue lors de la recherche.' })
            .then((data) => {
                setStatistics(data);
                setParametersOpened(false);
            })
            .catch(() => setStatistics(null));
    }, [entity, operation, selectedCharacs, validate]);

    useEffect(() => {
        setAvailableCharacs([{ key: 'general', label: 'Général', elements: UnknownMaterialCharacteristics }]);
    }, []);

    return (
        <Fragment>
            <Header
                breadcrumbs={[{ label: operation.name, href: `/operation/${operation._id}` }, { label: 'Résultats localisés' }]}
            >
                <Button color="secondary" label="Réinitialiser" onClick={clear} />
                <Button color="primary" icon={ActionIcon.SEARCH} label="Rechercher" onClick={handleSearch} />
            </Header>
            <ScrollableContent
                side={(
                    <span className="info">
                        <Icon icon="mdi:information-box-outline" />
                        {operation.synoptique === 1 ? TEXT_ROAD_OPERATION : TEXT_ZONE_OPERATION}
                    </span>
                )}
            >
                <Card title="Paramètres" opened={areParametersOpened}>
                    {operation.synoptique === 1
                        ? (
                            <Fragment>
                                <div className="row">
                                    <div className="input-column">
                                        <label htmlFor="way">Sens</label>
                                        <Select<number> items={operation.ways} {...attachInput('way')} />
                                    </div>
                                    <div className="input-column">
                                        <label htmlFor="road">Voie</label>
                                        <Select items={entity?.way ? operation.roads.filter(r => r.way === entity.way) : operation.roads} {...attachInput('road')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-column">
                                        <label htmlFor="roadPosition">Emplacement</label>
                                        <Select items={RoadPositionsList} {...attachInput('roadPosition')} />
                                    </div>
                                    <div className="input-column">
                                        <label htmlFor="layer">Position de couche</label>
                                        <NumberInput {...attachInput('layer')} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-column">
                                        <label htmlFor="prStart">Du PR</label>
                                        <PrInput {...attachInput('prStart')} />
                                    </div>
                                    <div className="input-column">
                                        <label htmlFor="prEnd">Au PR</label>
                                        <PrInput {...attachInput('prEnd')} />
                                    </div>
                                </div>
                                {operation.phases.length > 1 && (
                                    <div className="input-column">
                                        <label htmlFor="phase">Phase</label>
                                        <MultipleSelect items={Phases.filter(p => operation.phases.includes(p.key))}  {...attachInput('phase')} />
                                    </div>
                                )}
                                {(operation.phases.includes(Phase.TRAVAUX) || operation.phases.includes(Phase.EXPERTISE)) && (
                                    <div className="input-column">
                                        <label htmlFor="material">Matériau</label>
                                        <MultipleSelect endpoint={`/material/list/product/${operation._id}`} {...attachInput('material')} />
                                    </div>
                                )}
                            </Fragment>
                        ) : (
                            <div className="row">
                                <div className="form-group col lg3 sm6">
                                    <label htmlFor="layer">Position de couche</label>
                                    <NumberInput {...attachInput('layer')} />
                                </div>
                                {operation.phases?.length > 1 && (
                                    <div className="form-group col lg3 sm6">
                                        <label htmlFor="phase">Phase</label>
                                        <MultipleSelect items={Phases.filter(p => operation.phases.includes(p.key))}  {...attachInput('phase')} />
                                    </div>
                                )}
                            </div>
                        )
                    }
                </Card>
                <Card title="Caractéristiques" opened={areParametersOpened}>
                    <CharacAddRemove
                        availableCharacs={availableCharacs}
                        setAvailableCharacs={setAvailableCharacs}
                        selectedCharacs={selectedCharacs}
                        setSelectedCharacs={setSelectedCharacs}
                    />
                </Card>
                {!!statistics?.length && (
                    <Card title="Résultats">
                        <StatisticsPicker
                            statistics={statistics}
                            subtitle={operation.name}
                            columns={
                                operation.synoptique === 1
                                    ? ['pr', 'road', 'roadPosition', 'position', 'material', 'controle', 'phase']
                                    : ['date', 'name', 'location', 'position', 'material', 'controle', 'phase']
                            }
                            chartAxis="pr"
                            chartByOptions={[
                                'controle',
                                'material',
                                'phase',
                                ...(!entity?.road ? ['road'] : []),
                                ...(entity?.road && !entity?.roadPosition ? ['roadPosition'] : []),
                            ]}
                            permission={Permission.LOCALIZED_RESULTS}
                            isAuthorized={workspacePermissions.localizedResults}
                            phases={operation.phases.length === 1 || !entity?.phase?.length ? Phases.filter(p => operation.phases.includes(p.key))?.map(p => p.key) : entity?.phase}
                        />
                    </Card>
                )}
                {statistics && !statistics.length && (
                    <NoResult
                        text="Aucun résultat pour ces paramètres"
                    />
                )}
            </ScrollableContent>
        </Fragment>
    )
}

export default ByLocation;