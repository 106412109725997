import { Analysis } from "./analysis";
import { Phase } from "./operation";
import { Controle, Sample } from "./sample";
import { RevisionEntity } from "./shared";
import { StorageFile } from "./storage";

export type Melange = RevisionEntity & {
    name: string;
    operation: string;
    type: MelangeType;
    way?: number;
    date: Date;
    controle: Controle;
    problematic: boolean;
    laboratory?: string;
    analysis?: string;
    analysisPopulated?: Analysis;
    samples: string[];
    samplesPopulated?: Sample[];
    samplesSelectedLayers?: { _id: string; order: number }[];
    files: StorageFile[];
    phase: Phase;
};

export const isMelange = (o: any) => o.hasOwnProperty("samples");

export enum MelangeType {
    ITSR = "itsr",
    MELANGE = "melange",
    LIANT = "liant",
    MELANGE_LIANT = "melange_liant",
}

export const MelangeTypeLabel = {
    [MelangeType.ITSR]: "ITSR",
    [MelangeType.MELANGE]: "Mélange",
    [MelangeType.LIANT]: "Liant",
    [MelangeType.MELANGE_LIANT]: "Mélange+liant",
};

export const MelangeTypes = [
    { key: MelangeType.ITSR, label: MelangeTypeLabel[MelangeType.ITSR] },
    { key: MelangeType.MELANGE, label: MelangeTypeLabel[MelangeType.MELANGE] },
    { key: MelangeType.LIANT, label: MelangeTypeLabel[MelangeType.LIANT] },
    {
        key: MelangeType.MELANGE_LIANT,
        label: MelangeTypeLabel[MelangeType.MELANGE_LIANT],
    },
];

export enum MelangeIcon {
    MELANGE = "mdi:merge",
}
