import { ReactNode } from 'react';
import Breadcrumbs, { Breadcrumb } from '../Breadcrumb';
import './index.scss';

interface HeaderProps {
    children?: ReactNode;
    breadcrumbs: Breadcrumb[];
    withBorder?: boolean;
}

const Header = ({
    children,
    breadcrumbs,
    withBorder
}: HeaderProps) => (
    <div id="header" className={withBorder ? 'with-border' : undefined}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {children}
    </div>
);

export default Header;