import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import SmallList from "../../components/data/SmallList";
import { IdentityCardLicense, IdentityCardOperation } from "../../components/entities/IdentityCard";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import Header from "../../components/ui/Header";
import ScrollableContent from "../../components/ui/ScrollableContent";
import { License, PermissionActive, QuotaCurrent } from "../../models/license";
import { Permissions } from "../../models/user";
import { getRequest } from "../../services/request.service";
import './index.scss';

const LicenseForUser = () => {
    const navigate = useNavigate();
    const [license, setLicense] = useState<License>();

    const permissions: PermissionActive[] = useMemo(() => Permissions.map(p => ({ _id: p.label, active: !!license?.permissions?.includes(p.key) })), [license]);
    const quotas: QuotaCurrent[] = useMemo(() => [
        { _id: 'Operations', quota: license?.operationsQuota ?? 0 },
        { _id: 'Utilisateurs par opération', quota: license?.usersPerOperationQuota ?? 0 },
    ], [license]);

    useEffect(() => {
        getRequest<License>('/license/me', { errorMessage: 'Une erreur est survenue lors de la récupération de la license', loader: true })
            .then(setLicense)
            .catch(() => navigate(-1))
    }, []);

    if (!license) return (null);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Ma licence' }]} withBorder>
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
            </Header>
            <ScrollableContent
                side={
                    <Card title="Permissions">
                        {!!license.permissions?.length && (
                            <div className="input-column">
                                <label htmlFor="permissions">Permissions</label>
                                <SmallList<PermissionActive>
                                    data={permissions}
                                    columns={[{ key: '_id', label: 'Permission' }, { key: 'active', label: '', mapper: (e) => e.active ? 'Oui' : 'Non' }]}
                                />
                            </div>
                        )}
                        <div className="input-column">
                            <label htmlFor="permissions">Quotas</label>
                            <SmallList<QuotaCurrent>
                                data={quotas}
                                columns={[{ key: '_id', label: 'Permission' }, { key: 'quota', label: 'Limite' }]}
                            />
                        </div>
                    </Card>
                }
            >
                <IdentityCardLicense entity={license} />
                {!!license.operationsPopulated?.length && (
                    <Card title="Opérations">
                        {license.operationsPopulated?.map(o => <IdentityCardOperation key={o._id} entity={o} onClick={() => navigate(`/operation/${o._id}`)} />)}
                    </Card>
                )}
            </ScrollableContent>
        </Fragment >
    )
}

export default LicenseForUser;