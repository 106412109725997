import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import promo from '../assets/images/login-bg.png';
import logo from '../assets/images/logoCompact@2x.png';
import useWorkspace from '../services/hooks/use-workspace';
import Login from './Public/Login';
import Recovery from './Public/Recovery';
import Renew from './Public/Renew';

const ROUTES: RouteObject[] = [
    { path: '/', element: <Login /> },
    { path: 'recuperation', element: <Recovery /> },
    { path: 'nouveau-mot-de-passe/:token', element: <Renew /> },
    { path: '/*', element: <Login /> },
]

const Public = () => {
    const { workspace } = useWorkspace();

    return (
        <div id="public">
            <div>
                <div id="public-content">
                    <div>
                        <img src={logo} alt="" />
                        <span>OSO Numérique</span>
                    </div>
                    <div>
                        <RouterProvider router={createBrowserRouter(ROUTES)} />
                    </div>
                </div>
                <div id="public-promo">
                    <img src={promo} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Public;
