import { Fragment, MouseEvent, TouchEvent, useMemo, useState } from 'react';
import { useVisualisation } from '..';
import { CoordinatesXYZ } from '../../../../models/location';
import { Ouvrage, OuvrageElement } from '../../../../models/ouvrage';
import { getEventCoordinates, markerToCoordinates, updateCoordinates } from '../functions';

interface ElementMarkerProps {
    element: Partial<OuvrageElement>;
    selected?: boolean;
    onClick?: () => void;
    onMove?: (coordinates: CoordinatesXYZ) => void;
    className?: string;
    hide?: boolean;
}

export const ElementMarker = ({ element, selected, onClick, onMove, className, hide }: ElementMarkerProps) => {
    const state = useVisualisation();
    const [isMoving, setMoving] = useState<boolean>(false);

    const coordinates = markerToCoordinates(element.coordinates!, state.view);

    const handleMouseDown = (e: TouchEvent | MouseEvent) => {
        if (onMove) {
            e.stopPropagation();
            setMoving(true);
        }
    }

    const handleMouseMove = (e: TouchEvent | MouseEvent) => {
        if (onMove && isMoving) {
            e.stopPropagation();
            onMove(updateCoordinates(state, getEventCoordinates(e), element.coordinates ?? {}));
        }
    }

    if (hide) return null;
    return (
        <g className={`element-marker ${selected ? 'selected' : ''} ${onMove ? 'movable' : ''} ${className ?? ''}`}>
            <text
                x={coordinates.x}
                y={coordinates.y - 13 / state.zoom}
                fontSize={11 / state.zoom}
                strokeWidth={3 / state.zoom}
                stroke="white"
            >
                {element.name}
            </text>
            <text
                x={coordinates.x}
                y={coordinates.y - 13 / state.zoom}
                fontSize={11 / state.zoom}
            >
                {element.name}
            </text>
            <circle
                cx={coordinates.x}
                cy={coordinates.y}
                r={8 / state.zoom}
                strokeWidth={2 / state.zoom}
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                onMouseMove={handleMouseMove}
                onTouchMove={handleMouseMove}
                onMouseUp={() => setMoving(false)}
                onTouchEnd={!isMoving ? onClick : () => setMoving(false)}
                onClick={onClick}
            />
        </g>
    )
}

export interface ElementMarkersProps {
    ouvrage: Partial<Ouvrage>;
    onSelect?: (element: OuvrageElement) => void;
    onMove?: (coordinates: CoordinatesXYZ) => void;
    selected?: string;
    hideSelected?: boolean;
}

const ElementMarkers = ({ ouvrage, onSelect, selected, onMove, hideSelected }: ElementMarkersProps) => {
    const elements = useMemo(() => ouvrage.elements?.filter(element => element.coordinates) ?? [], [ouvrage]);

    return (
        <Fragment>
            {elements.map(e => (
                <ElementMarker
                    key={e._id}
                    element={e}
                    onClick={onSelect ? () => onSelect(e) : undefined}
                    onMove={e._id === selected ? onMove : undefined}
                    selected={e._id === selected}
                    hide={hideSelected && e._id === selected}
                />
            ))}
        </Fragment>
    )
}

export default ElementMarkers;