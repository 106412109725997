import { useCallback, useEffect, useState } from 'react';
import { FormBaseProps } from '../../../hooks/useForm';
import { getRequest } from '../../../services/request.service';

export interface NativeSelectItem<T> { key: T; label?: string };

interface NativeSelectProps<T> extends FormBaseProps<T> {
    items?: NativeSelectItem<T>[];
    endpoint?: string;
    placeholder?: string;
    allowEmpty?: boolean;
}

const NativeSelect = <T,>({
    id,
    value,
    items,
    endpoint,
    placeholder,
    disabled,
    onChange,
    allowEmpty,
    errors
}: NativeSelectProps<T>) => {
    const [itemsOrData, setItemsOrData] = useState<NativeSelectItem<T>[]>([]);

    const handleChange = useCallback((v?: string) => {
        const item = itemsOrData.find(i => String(i.key) === v);
        if (item) {
            onChange(item.key, item.label);
        } else {
            onChange(undefined);
        }
    }, [itemsOrData, onChange]);

    useEffect(() => {
        if (endpoint) {
            getRequest<NativeSelectItem<T>[]>(endpoint).then(setItemsOrData).catch(() => setItemsOrData([]));
        } else {
            setItemsOrData(items ?? []);
        }
    }, [endpoint, items]);

    return (
        <div className="select">
            <select
                value={String(itemsOrData?.find(item => item.key === value)?.key) ?? undefined}
                onChange={(event) => handleChange(event.target.value)}
                disabled={disabled}
                className="border-input"
            >
                {!!allowEmpty && <option value={undefined}></option>}
                {itemsOrData?.map(item => (
                    <option value={String(item.key)} key={String(item.key)}>{item.label ?? String(item.key)}</option>
                ))}
            </select>
            {!!errors?.length && <div className="form-error">{errors.join('. ')}</div>}
        </div>
    )
}

export default NativeSelect;