import { Sample } from '../../models/sample';
import { LayerColors } from '../../synoptique/Synoptique.class';

const WIDTH = 48;
const HEIGHT = 96;

interface SampleIconLayerProps {
    height: number;
    depth: number;
    order: number;
    selected?: boolean;
}

const SampleIconLayer = ({ height, depth, order, selected }: SampleIconLayerProps) => {
    if (isNaN(height)) {
        return (null);
    }

    return (
        <path
            stroke={selected ? '#343434' : '#ffffff'}
            fill={LayerColors[order]}
            strokeWidth={selected ? 3 : 1}
            d={order === 0
                ? `
            M0,${depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)} 0 0,0 ${WIDTH},${depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)} 0 0,0 0,${depth + 10}
            L0,${height + depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)},0 0,0 ${WIDTH},${height + depth + 10}
            L${WIDTH},${depth + 10} 
        `
                : `
            M0,${depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)} 0 0,0 ${WIDTH},${depth + 10}
            L${WIDTH},${height + depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)},0 0,1 0,${height + depth + 10}
            L0,${depth + 10} 
        `
            }
        />
    );
}

interface SampleIconProps {
    sample: Sample;
    selectedLayers?: number[];
}

const SampleIcon = ({ sample, selectedLayers = [] }: SampleIconProps) => (
    <svg viewBox={`0 0 ${WIDTH} ${HEIGHT + 20}`} style={{ width: WIDTH, height: HEIGHT }}>
        {sample.layers.length > 1
            ? sample.layers.map((layer) => {
                const depth = sample.layers.filter((l) => l.order < layer.order).reduce((sum, l) => Number(l.thickness) + sum, 0);
                const layerHeight = (layer.thickness * HEIGHT / sample.thickness) ?? 0;
                const layerDepth = (depth * HEIGHT / sample.thickness) ?? 0;

                return <SampleIconLayer key={layer.order} order={layer.order} height={layerHeight} depth={layerDepth} selected={selectedLayers.includes(layer.order)} />
            }
            ) : (
                <SampleIconLayer order={0} height={HEIGHT} depth={0} />
            )
        }
    </svg>
);

export default SampleIcon;