import { Icon } from '@iconify/react';
import { useCallback, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { ActionIcon } from '../../../utils/icons';
import MenuBar, { MenuBarItemProps } from '../../ui/MenuBar';
import './index.scss';

interface OptionsMenuProps {
    onEdit?: () => void;
    onDelete?: () => void;
    onDuplicate?: () => void;
    actions?: MenuBarItemProps[];
}

const OptionsMenu = ({
    onDelete,
    onDuplicate,
    onEdit,
    actions
}: OptionsMenuProps) => {
    const [isOpened, setOpened] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => setOpened(false), []));

    const handleClick = useCallback((callback?: () => void) => {
        setOpened(false);
        callback && callback();
    }, [])

    return (
        <div className="options-menu" ref={ref}>
            <Icon icon="mdi:dots-vertical" className="icon-large icon-button" onClick={(event) => { event.stopPropagation(); setOpened(true); }} />
            <MenuBar card column className={isOpened ? 'visible' : undefined}>
                {actions?.map(a => (
                    <MenuBar.Item key={a.label} onClick={() => handleClick(a.onClick)} icon={a.icon} label={a.label} />
                ))}
                {!!onEdit && <MenuBar.Item onClick={() => handleClick(onEdit)} icon={ActionIcon.EDIT} label="Editer" />}
                {!!onDuplicate && <MenuBar.Item onClick={() => handleClick(onDuplicate)} icon={ActionIcon.COPY} label="Dupliquer" />}
                {!!onDelete && <MenuBar.Item onClick={() => handleClick(onDelete)} icon={ActionIcon.DELETE} label="Supprimer" />}
            </MenuBar>
        </div>
    );
}

export default OptionsMenu;