import { Fragment } from 'react';
import { Chart } from 'react-chartjs-2';
import { formatNumberToFixedDecimal, formatStringDate } from '../../../utils/format';
import Switch from '../../inputs/Switch';
import Expandable from '../Expandable';

const CHART_DATA = {
    "min": 0,
    "max": 100,
    "datasets": [
        {
            "borderWidth": 1,
            "pointRadius": 2,
            "spanGaps": true,
            "label": "Minimum",
            "data": [
                { "x": 0.063, "y": 3.7 },
                { "x": 2, "y": 12.3 },
                { "x": 6.3, "y": 16 },
                { "x": 10, "y": 86 }
            ],
            "backgroundColor": "#397d92",
            "borderColor": "#397d92"
        },
        {
            "borderWidth": 1,
            "pointRadius": 2,
            "spanGaps": true,
            "label": "Moyenne",
            "data": [
                { "x": 0.063, "y": 4.233333333333335 },
                { "x": 2, "y": 14.116666666666665 },
                { "x": 6.3, "y": 31.398333333333326 },
                { "x": 10, "y": 90.0050847457627 }
            ],
            "backgroundColor": "#010508",
            "borderColor": "#010508"
        },
        {
            "borderWidth": 1,
            "pointRadius": 2,
            "spanGaps": true,
            "label": "Maximum",
            "data": [
                { "x": 0.063, "y": 5.2 },
                { "x": 2, "y": 16 },
                { "x": 6.3, "y": 36 },
                { "x": 10, "y": 94 }
            ],
            "backgroundColor": "#d94032",
            "borderColor": "#d94032"
        }
    ],
    "ticks": [
        0.063,
        2,
        6.3,
        10
    ]
};

const STATISTICS = [
    {
        "seuil": 0.063,
        "average": 4.233333333333335,
        "min": 3.7,
        "max": 5.2
    },
    {
        "seuil": 2,
        "average": 14.116666666666665,
        "min": 12.3,
        "max": 16
    },
    {
        "seuil": 6.3,
        "average": 31.398333333333326,
        "min": 16,
        "max": 36
    },
    {
        "seuil": 10,
        "average": 90.0050847457627,
        "min": 86,
        "max": 94
    }
];

const ANALYSIS = [
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 3.7,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 12.8,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 28,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 87,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "teneurLiant": {
                "value": 4.37,
                "problematic": false
            },
            "granulometrie": [
                {
                    "value": 3.9,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 13.4,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 29,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 87,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4.1,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 13.7,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 36,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 94,
                    "seuil": 10,
                    "problematic": true
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4.1,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 34,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 91,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 5.2,
                    "seuil": 0.063,
                    "problematic": true
                },
                {
                    "value": 13,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 30,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 88,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.2,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 13,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 32,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 90,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.1,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 31,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 88,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.1,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 13,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 29,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 89,
                    "seuil": 10,
                    "problematic": false
                }
            ],
            "dosageCoucheAccrochage": [
                {
                    "value": 412,
                    "seuil": 350,
                    "problematic": true
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.3,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 35,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 92,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.3,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 34,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 91,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-23T23:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4.2,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 13.9,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 30.5,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 91.5,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 3.8,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 13.6,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 29.2,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 87.2,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 3.8,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 13.7,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 34.5,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 93.2,
                    "seuil": 10,
                    "problematic": true
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 3.9,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 13.7,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 31.7,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 88.5,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.4,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 33,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 90,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.1,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 31,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 88,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.2,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 31,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 89,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.2,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 32,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 89,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 31,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 89,
                    "seuil": 10,
                    "problematic": false
                }
            ],
            "dosageCoucheAccrochage": [
                {
                    "value": 408,
                    "seuil": 350,
                    "problematic": true
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.5,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 32,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 93,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-11-17T23:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4.4,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14.5,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 33.5,
                    "seuil": 6.3,
                    "problematic": true
                }
            ],
            "epaisseurCouche": {
                "value": 92,
                "problematic": true
            }
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4.4,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14.5,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 33.5,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 90,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4.5,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14.6,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 34.1,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 90.4,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4.2,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14.2,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 32.3,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 90,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4.2,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14.3,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 34.1,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 91,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.6,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 15,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 34,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 90,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.6,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 15,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 32,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 92,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.1,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 32,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 91,
                    "seuil": 10,
                    "problematic": false
                }
            ],
            "dosageCoucheAccrochage": [
                {
                    "value": 406,
                    "seuil": 350,
                    "problematic": true
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.2,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 32,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 91,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.2,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 15,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 32,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 92,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "externe",
        "data": {
            "granulometrie": [
                {
                    "value": 4.2,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 34,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 91,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-19T22:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 3.9,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 12.3,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 27.4,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 89.3,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-12T22:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 3.9,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 13,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 29.8,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 87.4,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-12T22:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 14.4,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 32.6,
                    "seuil": 6.3,
                    "problematic": false
                },
                {
                    "value": 90.8,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-12T22:00:00.000Z"
    },
    {
        "controle": "exterieur",
        "data": {
            "granulometrie": [
                {
                    "value": 4.5,
                    "seuil": 0.063,
                    "problematic": false
                },
                {
                    "value": 15.3,
                    "seuil": 2,
                    "problematic": false
                },
                {
                    "value": 33.1,
                    "seuil": 6.3,
                    "problematic": true
                },
                {
                    "value": 90.7,
                    "seuil": 10,
                    "problematic": false
                }
            ]
        },
        "date": "2021-10-12T22:00:00.000Z"
    },
];

const FakeGranulometrie = () => (
    <Fragment>
        <div className="global-statistics-content">
            <div className="global-statistics-chart">
                <Switch
                    className="no-print"
                    id="chart-by"
                    items={[{ key: 'global', label: 'Global' }, { key: 'controle', label: 'Par contrôle' }]}
                    value={'global'}
                    onChange={() => undefined}
                />
                <Expandable>
                    <Chart
                        type="line"
                        data={{
                            datasets: CHART_DATA.datasets ?? [],
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    display: true,
                                    position: 'bottom',
                                    labels: {
                                        padding: 20
                                    }
                                },
                            },
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    type: 'linear',
                                    ticks: {
                                        color: '#ffffff',
                                        display: false
                                    },
                                    grid: {
                                        display: false
                                    }
                                },
                                y: {
                                    max: CHART_DATA.max,
                                    min: CHART_DATA.min,
                                }

                            }
                        }}
                    />
                </Expandable>
            </div>
            <div className="data-table">
                <h3>Statistiques</h3>
                <table>
                    <thead>
                        <tr>
                            <td>Tamis</td>
                            <td>Minimum</td>
                            <td>Moyenne</td>
                            <td>Maximum</td>
                        </tr>
                    </thead>
                    <tbody>
                        {STATISTICS.map(s => (
                            <tr key={s.seuil}>
                                <td><strong>{s.seuil}</strong></td>
                                <td>{formatNumberToFixedDecimal(s.min)}</td>
                                <td>{formatNumberToFixedDecimal(s.average)}</td>
                                <td>{formatNumberToFixedDecimal(s.max)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="data-table">
            <h3>Données brutes</h3>
            <table>
                <thead>
                    <tr>
                        <td>Date</td>
                        {STATISTICS.map(s => <td key={s.seuil}>{s.seuil}</td>)}
                    </tr>
                </thead>
                <tbody>
                    {ANALYSIS.map((a, index) => (
                        <tr key={index} className={a.controle}>
                            <td>{formatStringDate(a.date)}</td>
                            {STATISTICS.map(s => (
                                <td key={s.seuil} className={a.data.granulometrie?.find(g => g.seuil === s.seuil)?.problematic ? 'problematic' : ''}>
                                    {formatNumberToFixedDecimal(a.data.granulometrie?.find(g => g.seuil === s.seuil)?.value)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </Fragment>
);

export default FakeGranulometrie;