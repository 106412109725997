import { Fragment } from "react/jsx-runtime";
import SmallList, { SmallListAction } from "../../../components/data/SmallList";
import Select from "../../../components/inputs/Select";
import { SwitchYesNo } from "../../../components/inputs/Switch";
import { Textarea } from "../../../components/inputs/Textarea";
import Card from "../../../components/ui/Card";
import { Modal } from "../../../components/ui/Modal";
import useForm, { FormComparator } from "../../../hooks/useForm";
import { DIAGNOSTIC_GRADES, DiagnosticElement } from "../../../models/diagnostic";
import { OuvrageElement } from "../../../models/ouvrage";

export interface DiagnosticElementFormProps {
    element: OuvrageElement;
    diagnosticElement?: DiagnosticElement;
    onSubmit: (element: DiagnosticElement) => void;
    onClose: () => void;
    moa?: boolean;
}

const VALIDATION = {
    diagnostic: [{ comparator: FormComparator.REQUIRED }],
    comment: [{ comparator: FormComparator.REQUIRED }],
}

const VALIDATION_GRADE = {
    grade: [{ comparator: FormComparator.REQUIRED }],
    security: [{ comparator: FormComparator.REQUIRED }],
}

export const DiagnosticElementForm = ({ element, diagnosticElement, onSubmit, onClose, moa }: DiagnosticElementFormProps) => {
    const { entity, attachInput, validate } = useForm<DiagnosticElement>(diagnosticElement ?? { elementId: element._id });

    const handleSubmit = () => {
        const validatedEntity = validate(entity.diagnostic ? { ...VALIDATION, ...VALIDATION_GRADE } : VALIDATION);
        if (validatedEntity) {
            onSubmit(validatedEntity);
            onClose();
        }
    }

    return (
        <Modal
            size={!moa ? 'small' : 'medium'}
            title={element.name}
            id="diagnostic-subcontractor-element-modal"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Confirmer', onClick: handleSubmit },
            ]}
        >
            <div className="row">
                <div className={`col ${moa ? 'lg6' : 'lg12'}`}>
                    <div className="row">
                        <div className="input-column lg12">
                            <label htmlFor="diagnostic">L'élément a-t-il pu être diagnostiqué ?</label>
                            <SwitchYesNo disabled={moa} {...attachInput('diagnostic')} />
                        </div>
                    </div>
                    {entity.diagnostic === true && (
                        <Fragment>
                            <div className="row">
                                <div className="input-column lg6">
                                    <label htmlFor="grade">Classe *</label>
                                    <Select disabled={moa} items={DIAGNOSTIC_GRADES}  {...attachInput('grade')} />
                                </div>
                                <div className="input-column lg6">
                                    <label htmlFor="security">S *</label>
                                    <SwitchYesNo disabled={moa} {...attachInput('security')} />
                                </div>
                            </div>
                            <div className="input-column">
                                <label htmlFor="comment">Commentaire</label>
                                <Textarea rows={12} disabled={moa} {...attachInput('comment')} />
                            </div>
                        </Fragment>
                    )}
                    {entity.diagnostic === false && (
                        <div className="input-column">
                            <label htmlFor="comment">Commentaire</label>
                            <Textarea rows={12} disabled={moa} placeholder="Expliquer les raisons" {...attachInput('comment')} />
                        </div>
                    )}
                </div>

                {!!moa && (
                    <Card title="Validation MOA" className="lg6">
                        <div className="row">
                            <div className="input-column lg6">
                                <label htmlFor="moaGrade">Classe</label>
                                <Select items={DIAGNOSTIC_GRADES}  {...attachInput('moaGrade')} />
                            </div>
                            <div className="input-column lg6">
                                <label htmlFor="moaSecurity">S *</label>
                                <SwitchYesNo  {...attachInput('moaSecurity')} />
                            </div>
                        </div>
                        <div className="input-column">
                            <label htmlFor="moaComment">Commentaire</label>
                            <Textarea rows={12}  {...attachInput('moaComment')} />
                        </div>
                    </Card>
                )}
            </div>
        </Modal>
    );
}

export interface DiagnosticElementsProps {
    elements?: OuvrageElement[];
    diagnosticElements?: DiagnosticElement[];
    title: string;
    onEdit: (element: OuvrageElement) => void;
    moa?: boolean;
}

export const DiagnosticElements = ({ elements, diagnosticElements, title, onEdit, moa }: DiagnosticElementsProps) => {
    const elementsForList = elements?.map(e => ({
        ...e,
        diagnostic: diagnosticElements?.find(de => de.elementId === e._id)
    }));

    if (!elements?.length) return null;

    return (
        <Card title={title}>
            <SmallList
                data={elementsForList}
                columns={[
                    { key: 'name', label: 'Nom' },
                    { key: 'type', label: 'Type' },
                    { key: 'grade', label: 'Classe', mapper: (element) => element.diagnostic?.grade ? `${element.diagnostic?.grade}${element.diagnostic.security === true ? 'S' : ''}` : '' },
                    { key: 'comment', label: 'Commentaire' },
                    ...(moa ? [
                        { key: 'moaGrade', label: 'Classe MOA', mapper: (element: any) => element.diagnostic?.moaGrade ? `${element.diagnostic?.moaGrade}${element.diagnostic.moaSecurity === true ? 'S' : ''}` : '' },
                        { key: 'moaComment', label: 'Commentaire MOA' },
                    ] : [])
                ]}
                actions={[SmallListAction.EDIT]}
                onEdit={onEdit}
            />
        </Card>
    );
}
