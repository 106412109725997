import { useSelector } from "react-redux";
import { AuthState } from "../slices/auth.slice";
import { RootState } from "../store";

const useAuth = (): AuthState => {
    const auth = useSelector((state: RootState) => state.auth);

    return auth;
};

export default useAuth;
