import { Sample } from '../../models/sample';
import { IdentityCardSample } from '../entities/IdentityCard';
import { Modal } from '../ui/Modal';
import Button from '../ui/Button';
import './index.scss';

interface ModalDuplicatesProps {
    onSubmit: () => void;
    onClose: () => void;
    duplicates: Sample[];
}

const ModalDuplicates = ({
    onSubmit,
    onClose,
    duplicates
}: ModalDuplicatesProps) => (
    <Modal title="Attention" className="modal-duplicates">
        <div className="modal-duplicates-content">
            <p>Des échantillons similaires sont déjà présents sur le lot. Etes-vous sûr qu'il ne s'agit pas d'un doublon ?</p>
            {(duplicates ?? []).map(sample => <IdentityCardSample key={sample._id} entity={sample} />)}
        </div>
        <div className="footer">
            <Button color="secondary" label="Annuler" onClick={() => onClose()} />
            <Button color="primary" label="Confirmer" onClick={() => onSubmit()} />
        </div>
    </Modal>
);

export default ModalDuplicates;
