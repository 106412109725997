import { User } from "./user";

export interface ListItem {
    key: string;
    label: string;
}

export enum RevisionType {
    CREATION = "creation",
    UPDATE = "update",
    VALIDATION = "validation",
    REJECTION = "rejection",
    ACTIVATION = "activation",
    DEACTIVATION = "deactivation",
}

export const RevisionTypeLabel = {
    [RevisionType.CREATION]: "Création",
    [RevisionType.UPDATE]: "Mise à jour",
    [RevisionType.VALIDATION]: "Validation",
    [RevisionType.REJECTION]: "Rejet",
    [RevisionType.ACTIVATION]: "Activation",
    [RevisionType.DEACTIVATION]: "Désactivation",
};

export enum RevisionStatus {
    DRAFT = "draft",
    PENDING = "pending",
    REJECTED = "rejected",
    VALIDATED = "validated",
}

export const RevisionStatusLabel = {
    [RevisionStatus.DRAFT]: "Brouillon",
    [RevisionStatus.PENDING]: "Attente validation",
    [RevisionStatus.REJECTED]: "Rejeté",
    [RevisionStatus.VALIDATED]: "Validé",
};

export const RevisionStatusColor = {
    [RevisionStatus.DRAFT]: "#494c5d",
    [RevisionStatus.PENDING]: "#faa97a",
    [RevisionStatus.REJECTED]: "#a30e09",
    [RevisionStatus.VALIDATED]: "#2ad344",
};

export const RevisionStatusList = [
    {
        key: RevisionStatus.DRAFT,
        label: RevisionStatusLabel[RevisionStatus.DRAFT],
        color: RevisionStatusColor[RevisionStatus.DRAFT],
    },
    {
        key: RevisionStatus.PENDING,
        label: RevisionStatusLabel[RevisionStatus.PENDING],
        color: RevisionStatusColor[RevisionStatus.PENDING],
    },
    {
        key: RevisionStatus.REJECTED,
        label: RevisionStatusLabel[RevisionStatus.REJECTED],
        color: RevisionStatusColor[RevisionStatus.REJECTED],
    },
    {
        key: RevisionStatus.VALIDATED,
        label: RevisionStatusLabel[RevisionStatus.VALIDATED],
        color: RevisionStatusColor[RevisionStatus.VALIDATED],
    },
];

export type Revision = {
    __v: number;
    type: RevisionType;
    date: Date;
    comment?: string;
    user?: string;
    userPopulated?: User;
};

export type BasicEntity = {
    _id: string;
    __v?: number;
    comment?: string;
    active: boolean;
    createdAt: number;
    createdBy?: string;
    createdByPopulated?: User;
    updatedAt: number;
    updatedBy?: string;
    updatedByPopulated?: User;
};

export type RevisionEntity = BasicEntity & {
    status: RevisionStatus;
    revisions: Revision[];
};
