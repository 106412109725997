import { useState } from "react";
import { Textarea } from "../inputs/Textarea";
import { Modal } from "../ui/Modal";
import './index.scss';

interface CommentModalProps {
    title: string;
    allowEmpty?: boolean;
    placeholder?: string;
    onSubmit: (c: string | undefined) => void;
    onClose: () => void;
}

const CommentModal = ({ title, allowEmpty, placeholder, onSubmit, onClose }: CommentModalProps) => {
    const [comment, setComment] = useState<string | undefined>();

    return (
        <Modal
            title={title}
            className="comment-modal"
            size="small"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', disabled: !allowEmpty && !comment, onClick: () => onSubmit(comment) }
            ]}
        >
            <Textarea
                id="comment"
                placeholder={placeholder}
                value={comment}
                onChange={setComment}
                rows={10}
            />
        </Modal>
    )
}

export default CommentModal;