import { Fragment, useMemo } from 'react';
import { Blueprint, Buse } from '../../../../models/ouvrage';
import { buseForVisualization } from '../functions';
import { VisualizationElement, VisualizationSelection } from '../types';
import Dimensions from './Dimensions';

export interface BuseProps {
    blueprint: Blueprint;
    scale: number;
    view: string;
    onSelect: (element: VisualizationSelection) => void;
    selected?: VisualizationSelection;
}

const BuseSvg = ({ blueprint, scale, view, onSelect, selected }: BuseProps) => {
    const data = useMemo((): VisualizationElement<Buse> | undefined => buseForVisualization(blueprint, view, scale), [blueprint, scale, view]);

    if (!data || !blueprint.buse) return null;

    return (
        <Fragment>
            <g id="buse" onClick={() => onSelect({ buse: true })} className={`visualization-element ${!!selected?.buse ? 'selected' : ''}`}>
                {view === 'bottom' && <path d={data.topPath} />}
                <path d={data.innerPath} />
                <path d={data.path} />
                {view === 'top' && <path d={data.topPath} />}
            </g>
            {!!selected?.buse && <Dimensions dimensions={data.dimensions} bbox={data.bbox} />}
        </Fragment>
    )
}

export default BuseSvg;