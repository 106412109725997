import { Icon } from '@iconify/react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MelangeDetail from '../../../components/MelangeDetail';
import SampleDetail, { SampleHistoryDetail } from '../../../components/SampleDetail';
import { ModalRight } from '../../../components/ui/Modal/ModalRight';
import Printable from '../../../components/ui/Printable';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import useDebounce from '../../../hooks/useDebounce';
import { CoucheType } from '../../../models/lot';
import { MelangeType } from '../../../models/melange';
import { Operation, Phase, RoadPosition } from '../../../models/operation';
import { PopulationType } from '../../../models/population';
import { SampleType } from '../../../models/sample';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import LinearSynoptique from '../../../synoptique/LinearSynoptique.class';
import SynoptiqueClass, { ActionDoneResult, Direction, SynoptiqueAction, SynoptiqueElements, SynoptiqueHover, SynoptiqueSelection } from '../../../synoptique/Synoptique.class';
import ZoneSynoptique from '../../../synoptique/ZoneSynoptique.class';
import MarkerDetail from '../Map/MarkerDetail';
import Filters from './components/Filters';
import Legend from './components/Legend';
import LotView from './components/LotView';
import SampleView from './components/SampleView';
import SynoptiqueHoverElements from './components/SynoptiqueHoverElements';
import SynoptiqueMenu from './components/SynoptiqueMenu';
import './index.scss';


export interface SynoptiqueFilterMinMaxLayer {
    key?: string;
    min?: number;
    max?: number;
    layer?: number;
}

export interface SynoptiqueAdvancedFilters {
    sampleValue?: SynoptiqueFilterMinMaxLayer;
    sampleThickness?: SynoptiqueFilterMinMaxLayer;
    populationValue?: SynoptiqueFilterMinMaxLayer;
}

export interface SynoptiqueFilters {
    phase?: Phase[] | undefined;
    fullLot?: string[] | undefined;
    material?: string[] | undefined;
    coucheType?: CoucheType[] | undefined;
    sampleDiameter?: number | undefined;
    road?: string[] | undefined;
    roadPosition?: RoadPosition[] | undefined;
    lotDisplay?: boolean | undefined;
    sampleType?: SampleType[] | undefined;
    sampleAnalysis?: boolean | undefined;
    sampleProblematic?: boolean | undefined;
    sampleDisplay?: boolean | undefined;
    history?: boolean | undefined;
    populationType?: PopulationType[] | undefined;
    populationProblematic?: boolean | undefined;
    populationDisplay?: boolean | undefined;
    markerDisplay?: boolean | undefined;
    melangeType?: MelangeType[] | undefined;
    melangeDisplay?: boolean | undefined;
    melangeProblematic?: boolean | undefined;
}

const INITIAL_FILTERS = {
    populationProblematic: true,
};

const FILTERS_LOCALSTORAGE_KEY = 'synoptique-filters';

const getFiltersInLocalStorage = () => JSON.parse(localStorage.getItem(FILTERS_LOCALSTORAGE_KEY) || "{}");

const setFiltersInLocalStorage = (operation: Operation, filters: any) => {
    const storedFilters = getFiltersInLocalStorage();
    storedFilters[operation._id] = filters;
    localStorage.setItem(FILTERS_LOCALSTORAGE_KEY, JSON.stringify(storedFilters));
}

const Synoptique = () => {
    const { operation } = useWorkspace();
    const canvasDomRef = useRef<HTMLCanvasElement>(null);
    const canvasScrollRef = useRef<HTMLDivElement>(null);
    const synoptiqueRef = useRef<SynoptiqueClass | null>(null);
    const [currentAction, setCurrentAction] = useState<SynoptiqueAction | null>(SynoptiqueAction.SELECT_ONE);
    const [selectedSampleId, setSelectedSampleId] = useState<string>();
    const [selectedHistorySampleId, setSelectedHistorySampleId] = useState<string>();
    const [selectedMarkerId, setSelectedMarkerId] = useState<string>();
    const [selectedMelangeId, setSelectedMelangeId] = useState<string>();
    const [selectedElements, setSelectedElements] = useState<SynoptiqueSelection>();
    const [selectedElement, setSelectedElement] = useState<string | null>(null);
    const [selectionDiv, setSelectionDiv] = useState<{ top: number; left: number; width: number; height: number; } | null>(null);
    const [hoverElements, setHoverElements] = useState<SynoptiqueHover>([]);
    const [activeFilters, setActiveFilters] = useState<SynoptiqueFilters>({ ...INITIAL_FILTERS });
    const [advancedFilters, setAdvancedFilters] = useState<SynoptiqueAdvancedFilters>({});
    const [isPanelVisible, setPanelVisible] = useState(false);
    const [isSynoptiqueInitialized, setSynoptiqueInitialized] = useState(false);
    useDebounce(() => getElements(), 600, [activeFilters, advancedFilters]);
    const navigate = useNavigate();

    const onActionChange = useCallback((action: SynoptiqueAction) => {
        const _action = action === currentAction ? null : action;
        if (action !== SynoptiqueAction.ZOOM_IN && action !== SynoptiqueAction.ZOOM_OUT) {
            setCurrentAction(_action);
        }
        synoptiqueRef?.current?.setAction(_action);
    }, [currentAction]);

    const handleSelectSample = useCallback((_id: string) => {
        setSelectedElement(_id);
        setSelectedSampleId(_id);
        synoptiqueRef?.current?.highlightElement(_id);
    }, []);

    const onActionDone = useCallback((action: SynoptiqueAction, result: ActionDoneResult) => {
        switch (action) {
            case SynoptiqueAction.SELECT_ONE:
                setSelectedSampleId(result.selection?.samples?.[0]?._id);
                setSelectedHistorySampleId(result.selection?.samplesCompiled?.[0]?._id);
                setSelectedMarkerId(result.selection?.markers?.[0]?._id);
                setSelectedElements(undefined);
                break;
            case SynoptiqueAction.SELECT_MULTIPLE_MOVE:
                const bbox = canvasDomRef.current?.getBoundingClientRect();
                const parentBbox = canvasDomRef.current?.parentElement?.getBoundingClientRect();
                const parentScroll = canvasDomRef.current?.parentElement?.scrollTop ?? 0;

                if (!bbox || !result.selectionDiv || !parentBbox) return;

                setSelectionDiv({
                    ...result.selectionDiv,
                    top: result.selectionDiv.top + bbox.top - parentBbox.top + parentScroll,
                });
                break;
            case SynoptiqueAction.HOVER_ELEMENTS:
                setHoverElements(result.hover ?? []);
                break;
            case SynoptiqueAction.INIT_ELEMENTS:
                if (result && operation.synoptique === 1 && canvasScrollRef?.current && synoptiqueRef?.current && !isSynoptiqueInitialized) {
                    const firstElementScroll = (result.firstPosition?.x ?? 0) + 100 - (canvasScrollRef.current.offsetWidth / 2);
                    if ((canvasScrollRef.current.scrollLeft ?? 0) <= firstElementScroll) {
                        canvasScrollRef.current.scrollLeft = firstElementScroll;
                    }
                }
                break;
            case SynoptiqueAction.SELECT_EXIT:
                if (result?.exit) {
                    const operation = result.exit.operation;

                    if (operation) {
                        navigate(`/operation/${operation}/synoptique`);
                    }
                }
                break;
            case SynoptiqueAction.SELECT_MELANGE:
                setSelectedElements(result.selection);
                setSelectedMelangeId(result.selection?.melangeId);
                break;
            default:
                setSelectedElements(result.selection);
        }
        setSelectedElement(null);
    }, [operation.synoptique, isSynoptiqueInitialized]);

    const getElements = useCallback(async () => {
        setFiltersInLocalStorage(operation, activeFilters);

        getRequest<SynoptiqueElements>(`/operation/${operation?._id}/elements`, {
            params: { ...activeFilters, ...(advancedFilters ?? {}) },
            errorMessage: 'Une erreur est survenue lors de la récupération des éléments',
            loader: true
        })
            .then((e) => synoptiqueRef.current?.setElements(e))
            .catch(() => null);
    }, [activeFilters, operation, advancedFilters]);

    const startSynoptique = async () => {
        if (canvasDomRef?.current) {
            synoptiqueRef.current = operation.synoptique === 1
                ? new LinearSynoptique(
                    canvasDomRef.current,
                    operation,
                    onActionDone
                )
                : new ZoneSynoptique(
                    canvasDomRef.current,
                    operation,
                    onActionDone
                );

            // Get stored filters
            const storedFilters = getFiltersInLocalStorage();

            setActiveFilters(storedFilters[operation._id] ?? { ...INITIAL_FILTERS, phase: operation.phases?.length ? [operation.phases[operation.phases.length - 1]] : [] });
            setSynoptiqueInitialized(true);
        }
    }

    const handleGoToPr = (pr: number) => {
        if (canvasScrollRef?.current && synoptiqueRef?.current) {
            canvasScrollRef.current.scrollLeft = (synoptiqueRef.current.getScrollFromPr(pr) ?? 0) - (canvasScrollRef.current.offsetWidth / 2);
        }
    }

    const handleCloseBottomPanel = useCallback(() => {
        setSelectedElements(undefined);
        synoptiqueRef?.current?.clearSelections();
        synoptiqueRef?.current?.render();
        setSelectionDiv(null);
    }, []);

    useEffect(() => {
        if (canvasDomRef?.current && !isSynoptiqueInitialized) {
            startSynoptique();
        }
    }, [canvasDomRef.current, isSynoptiqueInitialized]);

    return (
        <Fragment>
            <ScrollableContent noPadding noScroll id="synoptique-page">
                <SynoptiqueMenu
                    currentAction={currentAction ?? undefined}
                    onActionChange={onActionChange}
                    onChange={setActiveFilters}
                    onGoToPr={handleGoToPr}
                    filters={activeFilters}
                    advancedFilters={advancedFilters}
                    onAdvancedFiltersSubmit={setAdvancedFilters}
                    toggleFilters={() => setPanelVisible(true)}
                />
                <div id="synoptique">
                    <Printable
                        title="Synoptique"
                        subtitle={operation.name}
                        filename="Synoptique"
                        disposition="landscape"
                        flow="both"
                        hideBanner
                        togglePrint={currentAction === SynoptiqueAction.PRINT}
                        onPrintEnd={() => setCurrentAction(null)}
                    >
                        <div
                            id="canvas-container"
                            className={currentAction === SynoptiqueAction.PRINT ? 'printing' : ''}
                            style={currentAction === SynoptiqueAction.PRINT
                                ? {
                                    width: canvasScrollRef.current?.getBoundingClientRect().height + 'px',
                                }
                                : undefined
                            }
                        >
                            <div
                                id="canvas-scroll-container"
                                className={operation.synoptique !== 1 ? ' type-2' : ''}
                                ref={canvasScrollRef}
                            >
                                {operation.synoptique === 1 && !!operation.way2 && (
                                    <div id="way2">
                                        <div><Icon icon="mdi:arrow-left" className="icon-small color-steel" /><div><span>Sens 2</span><span>{operation.way2.name}</span></div></div>
                                        <div><div><span>Sens 2</span><span>{operation.way1.name}</span></div><Icon icon="mdi:arrow-right" className="icon-small color-steel" /></div>
                                    </div>
                                )}
                                {currentAction === SynoptiqueAction.SELECT_MULTIPLE && selectionDiv && (
                                    <div
                                        id="canvas-selection-div"
                                        style={{
                                            width: selectionDiv.width,
                                            height: selectionDiv.height,
                                            left: selectionDiv.left,
                                            top: selectionDiv.top
                                        }}
                                    />
                                )}
                                <canvas
                                    id="canvas"
                                    className={`canvas-${currentAction ?? ''}`}
                                    ref={canvasDomRef}
                                />
                                {operation.synoptique === 1 && (
                                    <div id="way1">
                                        <div>{!!operation.way2 && <Fragment><Icon icon="mdi:arrow-left" className="icon-small color-steel" /><div><span>Sens 1</span><span>{operation.way2.name}</span></div></Fragment>}</div>
                                        <div><div><span>Sens 1</span><span>{operation.way1.name}</span></div><Icon icon="mdi:arrow-right" className="icon-small color-steel" /></div>
                                    </div>
                                )}
                                <Legend />
                                <SynoptiqueHoverElements elements={hoverElements} />
                            </div>
                        </div >
                    </Printable>
                    {!!selectedElements?.samples?.length && (
                        <SampleView
                            onSelectElement={handleSelectSample}
                            samples={selectedElements.samples}
                            selectedElement={selectedElement ?? undefined}
                            onClose={handleCloseBottomPanel}
                            currentDirection={Direction.BOTTOM}
                        />
                    )}
                    {!!selectedElements?.lots?.length && (
                        <LotView
                            layers={selectedElements.lots}
                            onSelectLayer={(l) => setSelectedElement(l?._id ?? null)}
                            selectedLayer={selectedElement ?? undefined}
                            onClose={handleCloseBottomPanel}
                            currentDirection={Direction.BOTTOM}
                        />
                    )}
                </div>
                <SampleDetail id={selectedSampleId ?? undefined} onClose={() => setSelectedSampleId(undefined)} />
                <SampleHistoryDetail id={selectedHistorySampleId ?? undefined} onClose={() => setSelectedHistorySampleId(undefined)} />
                <MarkerDetail id={selectedMarkerId ?? undefined} onClose={() => setSelectedMarkerId(undefined)} />
                <MelangeDetail id={selectedMelangeId ?? undefined} onClose={() => setSelectedMelangeId(undefined)} />
                <ModalRight
                    visible={!!isPanelVisible}
                    className="synoptique-filters-panel"
                    actions={[
                        { label: 'Réinitialiser', color: 'secondary', onClick: () => setActiveFilters({ ...INITIAL_FILTERS }) },
                        { label: 'Fermer', onClick: () => setPanelVisible(false) },
                    ]}
                >
                    <Filters
                        filters={activeFilters}
                        withProblematic
                        isSynoptique
                        onChange={setActiveFilters}
                    />
                </ModalRight >
            </ScrollableContent>
        </Fragment>
    )
}

export default Synoptique;