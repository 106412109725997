import { Icon } from '@iconify/react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, Polygon } from 'geojson';
import { MapEvent, Map as MapForRef, MapMouseEvent } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Layer, Map as MapBox, MapRef, MarkerDragEvent, Marker as MarkerMapbox, Source } from 'react-map-gl';
import { Address, Coordinates, LocatedEntity } from '../../../models/location';
import config from '../../../services/config.service';
import { getBoundingBox, getPolygonCentroid } from '../../../utils/location';
import MenuBar from '../../ui/MenuBar';
import DrawControl from './DrawControl';
import Geocoder from './Geocoder';
import './index.scss';

export enum MapAction {
    POSITION = 'position',
    MARKER = 'marker',
    INTEREST_POINT = 'interest',
    POLYGON = 'polygon',
    STYLE = 'style'
};

export interface MapRefProps {
    fitToEntities: <T extends LocatedEntity>(e: T[], options?: { keepZoom?: boolean, animate?: boolean }) => void;
    getMap: () => MapForRef | undefined,
    goTo: (longitude: number, latitude: number, zoom?: number) => void;
}

const CENTER_DEFAULT = { longitude: 2.7, latitude: 46.9, zoom: 5 };

interface MapProps {
    center?: Coordinates;
    initialAction?: MapAction;
    availableActions?: MapAction[];
    onGeocoder?: (gps: Coordinates, address: Address) => void;
    polygon?: Coordinates[];
    base?: Coordinates[];
    onActionComplete?: (a: MapAction, data: { marker: Coordinates, polygon?: Coordinates[] }) => void;
    children?: ReactNode;
}

const Map = forwardRef<MapRefProps, MapProps>(({
    center,
    initialAction,
    availableActions,
    onGeocoder,
    polygon,
    base,
    onActionComplete,
    children
}: MapProps, ref) => {
    const [userPosition, setUserPosition] = useState<Coordinates | null>(null);
    const [mapStyle, setMapStyle] = useState<string>("mapbox://styles/mapbox/streets-v12");
    const [currentAction, setCurrentAction] = useState<MapAction | null>(initialAction ?? null);
    const mapRef = useRef<MapRef | null>(null);
    const drawRef = useRef<MapboxDraw | null>(null);

    const baseLayer = useMemo(() => {
        if (!base || base.length < 2) return;
        const data = {
            "type": "FeatureCollection",
            "features": [{
                type: "Feature",
                properties: {},
                geometry: {
                    type: "Polygon",
                    coordinates: [base?.map(p => [p.longitude, p.latitude]) ?? []]
                }
            }]
        }

        return (
            <Source id="base-polygon" type="geojson" data={data}>
                <Layer
                    id="base-layer"
                    type="fill"
                    source="operation-base"
                    paint={{
                        'fill-color': '#0c2c40',
                        'fill-outline-color': '#0c2c40',
                        'fill-opacity': 0.2
                    }}
                />
                <Layer
                    id="base-outline-layer"
                    type="line"
                    source="operation-base"
                    paint={{
                        'line-color': '#0c2c40',
                        'line-width': 2,
                    }}
                />
            </Source>
        )
    }, [base]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLoad = useCallback((e: MapEvent) => {
        if (base || polygon) {
            e.target.fitBounds(getBoundingBox(base ?? polygon ?? []), { padding: 20 })
        }
    }, []);

    const handlePolygonDone = useCallback((p: Polygon) => {
        try {
            const polygon = p.coordinates[0].map(p => ({ longitude: p[0], latitude: p[1] }));
            const marker = getPolygonCentroid(polygon);
            setCurrentAction(null);
            if (onActionComplete) {
                onActionComplete(MapAction.POLYGON, { marker, polygon });
            }
        } catch { }
    }, []);

    const handleDrawCreate = useCallback((e: { features: Feature[] }) => {
        if (!e.features?.length || !e.features[0].id || !drawRef.current) return;

        handlePolygonDone(e.features[0].geometry as Polygon);
    }, [handlePolygonDone]);

    const handleDrawUpdate = useCallback((e: { features: Feature[] }) => {
        if (!e.features?.length || !e.features[0].id || !drawRef.current) return;

        handlePolygonDone(e.features[0].geometry as Polygon);
    }, [handlePolygonDone]);

    const handleMarkerDone = useCallback((e: MapMouseEvent) => {
        if (!onActionComplete || !currentAction || (currentAction !== MapAction.MARKER && currentAction !== MapAction.INTEREST_POINT)) return;

        onActionComplete(currentAction, { marker: { longitude: e.lngLat.lng, latitude: e.lngLat.lat } });
    }, [currentAction, onActionComplete]);

    const fitToEntities = useCallback(<T extends LocatedEntity>(entities: T[], options?: { keepZoom?: boolean, animate?: boolean }) => {
        try {
            mapRef.current?.fitBounds(getBoundingBox(entities), options?.keepZoom ? { zoom: mapRef.current.getZoom(), padding: 150, animate: !!options.animate } : { maxZoom: 10, padding: 150, animate: !!options?.animate });
        } catch { }
    }, []);

    useImperativeHandle(ref, () => ({
        fitToEntities,
        getMap: () => mapRef.current?.getMap(),
        goTo: (longitude: number, latitude: number, zoom?: number) => mapRef.current?.flyTo({ center: [longitude, latitude], zoom: zoom ?? 8, animate: true }),
    })
        , []);

    const handleCenterMyPosition = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    if (mapRef.current) {
                        mapRef.current.setCenter({ lon: position.coords.longitude, lat: position.coords.latitude });
                        mapRef.current.setZoom(10)
                    }
                    setUserPosition(position.coords);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (mapRef.current && center) {
            mapRef.current.setCenter({ lon: center.longitude, lat: center.latitude });

            if (center.zoom) {
                mapRef.current.setZoom(center.zoom)
            }
        }
    }, [center]);

    return (
        <div className="map-container">
            <MapBox
                mapboxAccessToken={config.mapToken}
                initialViewState={center ?? { ...CENTER_DEFAULT }}
                mapStyle={mapStyle}
                projection={{ name: 'mercator' }}
                onLoad={handleLoad}
                ref={mapRef}
                cursor={currentAction === MapAction.POLYGON ? 'crosshair' : undefined}
                onClick={currentAction === MapAction.MARKER || currentAction === MapAction.INTEREST_POINT ? handleMarkerDone : undefined}
            >
                {baseLayer}
                {!!onGeocoder && <Geocoder onSelectResponse={onGeocoder} />}
                {!!availableActions?.includes(MapAction.POLYGON) && (
                    <DrawControl
                        position="top-left"
                        polygon={polygon}
                        displayControlsDefault={false}
                        controls={{
                            polygon: true,
                            point: true
                        }}
                        defaultMode="draw_polygon"
                        onCreate={handleDrawCreate}
                        onUpdate={handleDrawUpdate}
                        onDelete={handleDrawUpdate}
                    />
                )}
                {!!availableActions?.length && (
                    <MenuBar card column className="map-menu-right">
                        {availableActions?.includes(MapAction.POLYGON) && (
                            <MenuBar.Item
                                label="Tracer une zone"
                                icon="mdi:shape-polygon-plus"
                                onClick={() => setCurrentAction(currentAction === MapAction.POLYGON ? null : MapAction.POLYGON)}
                                active={currentAction === MapAction.POLYGON}
                            />
                        )}
                        {availableActions?.includes(MapAction.INTEREST_POINT) && (
                            <MenuBar.Item
                                label="Ajouter un point d'intérêt"
                                icon="mdi:camera-marker-outline"
                                onClick={() => setCurrentAction(currentAction === MapAction.INTEREST_POINT ? null : MapAction.INTEREST_POINT)}
                                active={currentAction === MapAction.INTEREST_POINT}
                            />
                        )}
                        {availableActions?.includes(MapAction.MARKER) && (
                            <MenuBar.Item
                                label="Ajouter un point"
                                icon="mdi:location-add-outline"
                                onClick={() => setCurrentAction(currentAction === MapAction.MARKER ? null : MapAction.MARKER)}
                                active={currentAction === MapAction.MARKER}
                            />
                        )}
                        {availableActions?.includes(MapAction.POSITION) && (
                            <MenuBar.Item
                                label="Centrer sur ma position"
                                icon="mdi:crosshairs-account"
                                onClick={handleCenterMyPosition}
                            />
                        )}
                        {availableActions?.includes(MapAction.STYLE) && (
                            <MenuBar.Item
                                label="Style"
                                icon="mdi:globe"
                                disposition="left"
                            >
                                <MenuBar.SubItem label="Défaut" onClick={() => setMapStyle('mapbox://styles/mapbox/streets-v12')} />
                                <MenuBar.SubItem label="Satelitte" onClick={() => setMapStyle('mapbox://styles/mapbox/satellite-v9')} />
                                <MenuBar.SubItem label="Réseau" onClick={() => setMapStyle('mapbox://styles/mapbox/satellite-streets-v12')} />
                                <MenuBar.SubItem label="Navigation" onClick={() => setMapStyle('mapbox://styles/mapbox/navigation-day-v1')} />
                                <MenuBar.SubItem label="Clair" onClick={() => setMapStyle('mapbox://styles/mapbox/light-v11')} />
                                <MenuBar.SubItem label="Sombre" onClick={() => setMapStyle('mapbox://styles/mapbox/dark-v11')} />
                            </MenuBar.Item>
                        )}
                    </MenuBar>
                )}
                {!!userPosition && (
                    <Marker
                        icon="mdi:target-user"
                        longitude={userPosition.longitude}
                        latitude={userPosition.latitude}
                        className="color-blue"
                    />
                )}
                {children}
            </MapBox>
        </div>
    );
});

interface MarkerProps {
    longitude: number;
    latitude: number;
    className?: string;
    color?: string;
    icon?: string;
    onMouseLeave?: () => void;
    onMouseEnter?: () => void;
    onDragEnd?: (e: MarkerDragEvent) => void;
    onClick?: () => void;
}

export const Marker = ({ longitude, latitude, className, icon, color, onClick, onMouseEnter, onMouseLeave, onDragEnd }: MarkerProps) => (
    <MarkerMapbox
        longitude={longitude}
        latitude={latitude}
        draggable={!!onDragEnd}
        anchor="bottom"
        onDragEnd={onDragEnd}
    >
        <div className={`map-marker ${!!onDragEnd ? 'map-marker-draggable' : ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
            <Icon icon="fontisto:map-marker" className={`map-marker-base color-white ${className ?? ''}`} color={color} />
            <Icon icon={icon ?? 'mdi:checkbox-blank-circle'} className="map-marker-icon color-white icon-small" />
        </div>
    </MarkerMapbox>
);

export default Map;