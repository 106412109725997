import { ReactNode } from 'react';
import { Permission } from '../../../models/user';
import AskAccess from '../AskAccess';
import './index.scss';

interface FakeDataProps {
    permission: Permission;
    children: ReactNode;
}

const FakeData = ({ permission, children }: FakeDataProps) => {
    return (
        <div className="fake-data">
            <AskAccess permission={permission} />
            {children}
        </div>
    );
}

export default FakeData;