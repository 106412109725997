import Card from "../../../components/ui/Card";
import Select from "../../../components/inputs/Select";
import { OrigineAdditifs } from "../../../models/material";
import { MaterialSpecificationsFormProps } from "../EditComponent";


const AdditifEdit = ({
    attachInput
}: MaterialSpecificationsFormProps) => (
    <Card title="Spécifications">
        <div className="row">
            <div className="input-column">
                <label htmlFor="specifications.origineAdditif">Origine *</label>
                <Select
                    items={OrigineAdditifs}
                    {...attachInput('specifications.origineAdditif')}
                />
            </div>
        </div>
    </Card>
)

export default AdditifEdit;