import { Fragment, useCallback, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import EntityCard from '../../../components/entities/EntityCard';
import RevisionCard from '../../../components/entities/RevisionCard';
import Checkbox from '../../../components/inputs/Checkbox';
import DatePickerInput from '../../../components/inputs/DatePicker';
import PositionPicker from '../../../components/inputs/PositionPicker';
import PrInput from '../../../components/inputs/PrInput';
import Select from '../../../components/inputs/Select';
import TextInput from '../../../components/inputs/TextInput';
import { Textarea } from '../../../components/inputs/Textarea';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import DropZone from '../../../components/ui/Dropzone';
import Header from '../../../components/ui/Header';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { TabSectionSubTitles, TabSectionTitles } from '../../../components/ui/TabSection';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { Marker, PATHOLOGIES } from '../../../models/marker';
import { Phase, Phases, RoadPositionsList } from '../../../models/operation';
import { SampleLabels } from '../../../models/sample';
import { RevisionStatus } from '../../../models/shared';
import { StorageFile } from '../../../models/storage';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest, postRequest, putRequest } from '../../../services/request.service';
import { ActionIcon } from '../../../utils/icons';

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    description: [{ comparator: FormComparator.REQUIRED }],
    date: [{ comparator: FormComparator.REQUIRED }],
};
const VALIDATION_TYPE_1 = {
    'location.pr': [{ comparator: FormComparator.PR }],
    'location.way': [{ comparator: FormComparator.REQUIRED }],
    'location.road': [{ comparator: FormComparator.REQUIRED }],
};
const VALIDATION_TYPE_2 = {
    'location.coordinates.longitude': [{ comparator: FormComparator.REQUIRED }],
    'location.coordinates.latitude': [{ comparator: FormComparator.REQUIRED }],
    "location.location": [{ comparator: FormComparator.REQUIRED }],
};

const MarkerForm = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const { markerId, phase } = useParams();
    const { operation, workspacePermissions } = useWorkspace();
    const { entity, attachInput, setEntity, validate, onChange, onChangeMultiple, errors, hasChanged, setChanged } = useForm<Marker>({});
    const tabsRef = useRef<{ [k: string]: HTMLDivElement }>({});

    const goToRef = useCallback((id: string) => {
        if (tabsRef?.current?.[id]) {
            tabsRef.current[id].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    const handleWayChange = useCallback((way: number) => {
        onChangeMultiple([
            { field: 'location.way', value: way },
            { field: 'location.road', value: undefined },
        ])
    }, [onChangeMultiple]);

    const handleFiles = useCallback((files: StorageFile[]) => {
        onChangeMultiple([
            { field: 'files', value: files.map(f => f._id) },
            { field: 'filesPopulated', value: files },
        ]);
    }, [onChangeMultiple]);

    const handleSubmit = useCallback(async (close?: boolean) => {
        const entity = validate({
            ...VALIDATION,
            ...(operation.synoptique === 1
                ? VALIDATION_TYPE_1
                : VALIDATION_TYPE_2)
        });

        if (!entity) return;

        entity.operation = operation._id;

        const create = !entity._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<Marker>('/marker', entity, {
            successMessage: create ? 'Point d\'intérêt créé avec succès' : 'Point d\'intérêt modifié avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch(() => null);
    }, [validate, operation.synoptique === 1, setEntity]);

    useEffect(() => {
        if (markerId) {
            getRequest<Marker>(`/marker/${markerId}`, { errorMessage: 'Une erreur est survenue lors de la récupération du marker.', loader: true })
                .then(setEntity)
                .catch(() => navigate(-1));
        } else {
            const queryParams = new URLSearchParams(search);
            const longitude = queryParams.get('longitude');
            const latitude = queryParams.get('latitude');
            setEntity({
                status: workspacePermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
                active: true,
                phase: phase as Phase,
                location: {
                    coordinates: longitude && latitude ? {
                        longitude: Number(longitude),
                        latitude: Number(latitude),
                    } : undefined
                }
            });
        }
    }, [operation]);

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation?.name, href: `/operation/${operation?._id}` },
                    { label: 'Carte', href: `/operation/${operation?._id}/carte` },
                    { label: entity._id ? SampleLabels.marker.edit : SampleLabels.marker.create }
                ]}
            >
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={ActionIcon.SAVE}
                            onClick={handleSubmit}
                        />
                        <Button

                            label="Enregistrer et fermer"
                            icon={ActionIcon.SAVE_CLOSE}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <ScrollableContent
                side={
                    <EntityCard entity={entity} attachInput={attachInput}>
                        <RevisionCard<Marker> entity={entity} attachInput={attachInput} />
                    </EntityCard>
                }
            >
                <Card title="Informations générales">
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="name">Intitulé *</label>
                            <TextInput {...attachInput('name')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="date">Date *</label>
                            <DatePickerInput max={new Date()} withIcon {...attachInput('date')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="phase">Phase</label>
                            <Select items={Phases} {...attachInput('phase')} />
                        </div>
                    </div>
                    <div className="input-column">
                        <label htmlFor="description">Description *</label>
                        <Textarea {...attachInput('description')} />
                    </div>
                </Card>
                <Card title="Images">
                    <DropZone direction="column" files={entity.filesPopulated ?? []} onChange={handleFiles} />
                </Card>
                <Card title="Localisation">
                    {operation.synoptique === 1 ? (
                        <Fragment>
                            <div className="row">
                                <div className="input-column">
                                    <label htmlFor="way">Sens *</label>
                                    <Select<number>
                                        id="way"
                                        value={entity.location?.way}
                                        items={operation.ways}
                                        onChange={(way) => way ? handleWayChange(way) : null}
                                        errors={errors.way}
                                    />
                                </div>
                                <div className="input-column">
                                    <label htmlFor="road">Voie *</label>
                                    <Select
                                        items={entity.location?.way
                                            ? operation.roads.filter(r => r.way === entity.location!.way)
                                            : []
                                        }
                                        disabled={!entity.location?.way}
                                        {...attachInput('location.road')}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-column">
                                    <label htmlFor="roadPosition">Emplacement *</label>
                                    <Select items={RoadPositionsList} {...attachInput('location.roadPosition')} />
                                </div>
                                <div className="input-column">
                                    <label htmlFor="pr">PR *</label>
                                    <PrInput {...attachInput('location.pr')} />
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="input-column">
                            <label htmlFor="pr">Repère kilométrique</label>
                            <PrInput  {...attachInput('location.pr')} />
                        </div>
                    )}
                    <PositionPicker
                        type="location"
                        location={entity?.location?.location}
                        marker={entity?.location?.coordinates}
                        initialPosition={entity?.location?.coordinates}
                        onChange={onChange}
                        errors={errors}
                    />
                </Card>
                <Card title="Pathologies">
                    {PATHOLOGIES.map(p => (
                        <Fragment key={p.key}>
                            <TabSectionTitles
                                ref={el => el ? tabsRef.current[p.key] = el : null}
                                tabs={PATHOLOGIES.map(c => ({ key: c.key, label: c.label }))}
                                current={p.key}
                                onClick={goToRef}
                            />
                            <TabSectionSubTitles
                                tabs={p.items}
                                onClick={goToRef}
                            />
                            <div className="charac-container">
                                {p.items.map(item => (
                                    <div className="charac-ref-block" key={item.key} ref={el => el ? tabsRef.current[item.key] = el : null}>
                                        <div className="charac-block">
                                            <div className="charac-title">{item.label}</div>
                                            <div className="charac-content">
                                                <div className="charac-header">
                                                    <span>Valeur {!!item.unit && <small> ({item.unit})</small>}</span>
                                                    <span>{item.withQuantity ? 'Quantité' : ''}</span>
                                                    <span>Problématique</span>
                                                </div>
                                                <div className="charac-row">
                                                    <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={entity.pathologies?.[item.key]?.value ?? ''} onChange={(event) => onChange(`pathologies.${item.key}.value`, Number(event.target.value))} /></div>
                                                    <div>{item.withQuantity && <input type="number" onWheel={(e) => e.currentTarget.blur()} value={entity.pathologies?.[item.key]?.quantity ?? ''} onChange={(event) => onChange(`pathologies.${item.key}.quantity`, Number(event.target.value))} />}</div>
                                                    <div className="charac-checkbox">
                                                        <Checkbox   {...attachInput(`pathologies.${item.key}.problematic`)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Fragment>
                    ))}
                </Card>
            </ScrollableContent>
        </Fragment >
    )
}

export default MarkerForm;