import { Icon } from "@iconify/react";
import { ReactNode, forwardRef, useEffect, useState } from "react";
import Menu from "../Menu";
import './index.scss';

interface CardProps {
    title?: string;
    actions?: ReactNode;
    children?: ReactNode;
    type?: 'grey' | 'info' | 'action';
    opened?: boolean;
    className?: string;
    id?: string;
}

const Card = forwardRef<HTMLDivElement, CardProps>(({ title, children, type, actions, opened, className, id }, ref) => {
    const [isOpened, setOpened] = useState<boolean>(opened === undefined ? true : opened);

    useEffect(() => {
        if (opened !== undefined) setOpened(opened);
    }, [opened]);

    return (
        <div id={id} className={`card card-${type ?? 'card'} ${!isOpened ? 'closed' : ''} ${className ?? ''}`} ref={ref}>
            {type === 'info' && <Icon icon="mdi:information-box-outline" />}
            {!!title && (
                <div className="card-title">
                    <h4>{title}</h4>
                    <div className="card-actions">
                        {actions}
                        {opened !== undefined && isOpened && <Menu icon="mdi:chevron-up" onClick={() => setOpened(false)} label="Masquer" />}
                        {opened !== undefined && !isOpened && <Menu icon="mdi:chevron-down" onClick={() => setOpened(true)} label="Afficher" />}
                    </div>
                </div>
            )}
            {children}
        </div>
    );
});

export default Card;