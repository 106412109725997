import { Icon } from '@iconify/react';
import { saveAs } from 'file-saver';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import FileModal from '../../components/FileModal';
import MessageModal from '../../components/MessageModal';
import Switch from '../../components/inputs/Switch';
import Toggle from '../../components/inputs/Toggle';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Menu from '../../components/ui/Menu';
import ModalDelete from '../../components/ui/Modal/ModalDelete';
import NoResult from '../../components/ui/NoResult';
import ScrollableContent from '../../components/ui/ScrollableContent';
import useForm from '../../hooks/useForm';
import { Message, MessageType } from '../../models/message';
import { StorageFile } from '../../models/storage';
import useAuth from '../../services/hooks/use-auth.hook';
import useWorkspace from '../../services/hooks/use-workspace';
import { deleteRequest, getRequest, postRequest } from '../../services/request.service';
import { addToast } from '../../services/slices/ui.slice';
import { WorkspaceType } from '../../services/slices/workspace.slice';
import { formatTimestamp, getFullName } from '../../utils/format';
import { ActionIcon } from '../../utils/icons';
import './index.scss';

interface MessageInterface {
    message: string;
    email: boolean;
    private: false;
    type: MessageType;
}

const DEFAULT_MESSAGE: MessageInterface = {
    message: '',
    email: false,
    private: false,
    type: MessageType.MESSAGE
}

const MailBox = () => {
    const { operation, ouvrage, workspace, workspacePermissions } = useWorkspace();
    const dispatch = useDispatch();
    const { user } = useAuth();
    const [files, setFiles] = useState<StorageFile[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [messageType, setMessagesType] = useState<string | undefined>('public');
    const [isOptionsPanelVisible, setOptionsPanelVisible] = useState<boolean>(false);
    const [isFileModalVisible, setFileModalVisible] = useState<boolean>(false);
    const [fileForDelete, setFileForDelete] = useState<StorageFile | null>(null);
    const [fileToNotify, setFileForNotify] = useState<StorageFile | null>(null);
    const { attachInput, entity, onChange, setEntity } = useForm<MessageInterface>({ ...DEFAULT_MESSAGE });
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const messageRef = useRef<HTMLDivElement>(null);

    const getFiles = async () => getRequest<StorageFile[]>(`/${workspace === WorkspaceType.CHAUSSES ? 'operation/' + operation._id : 'ouvrage/' + ouvrage._id}/files`, { loader: true, errorMessage: 'Une erreur est survenue lors de la récupération des documents.' })
        .then(setFiles)
        .catch(() => null);

    const getMessages = async () => getRequest<Message[]>(`/${workspace === WorkspaceType.CHAUSSES ? 'operation/' + operation._id : 'ouvrage/' + ouvrage._id}/messages/${messageType !== 'public' ? 'private' : ''}`, { loader: true, errorMessage: 'Une erreur est survenue lors de la récupération des messages.' })
        .then(setMessages)
        .catch(() => null);

    const handleSendMessage = () => postRequest<Message>(
        `/${workspace === WorkspaceType.CHAUSSES ? 'operation/' + operation._id : 'ouvrage/' + ouvrage._id}/message`,
        { ...entity, operation: operation._id },
        { loader: true, errorMessage: 'Une erreur est survenue lors de l\'envoi du message', successMessage: 'Message envoyé avec succès' })
        .then(() => {
            getMessages();
            setEntity({ ...DEFAULT_MESSAGE });
        })
        .catch(() => null);

    const handleDelete = async (file: StorageFile) => deleteRequest(`/${workspace === WorkspaceType.CHAUSSES ? 'operation/' + operation._id : 'ouvrage/' + ouvrage._id}/file/${file._id}`, { loader: true, errorMessage: 'Une erreur est survenue lors de la suppression.', successMessage: 'Document supprimé avec succès' })
        .then(() => {
            getFiles();
            setFileForDelete(null);
        })
        .catch(() => null);

    const handleDownload = async (file: StorageFile) => getRequest<string>(`/storage/${file._id}/download`, { responseType: 'blob', timeout: 30000, loader: true })
        .then((data) => {
            saveAs(data, file.originalFileName);
            getFiles();
        })
        .catch((e) => {
            if (e?.error?.status === 404) {
                dispatch(addToast({ type: 'error', message: 'Le fichier n\'a pas été trouvé.', error: e as any }));
            } else {
                dispatch(addToast({ type: 'error', message: 'Une erreur est survenue lors du téléchargement.', error: e as any }));
            }
        });

    const handleSubmit = () => {
        setFileModalVisible(false);
        setFileForNotify(null);
        getFiles();
        getMessages();
    };

    const getNotifiedDownloadCount = (file: StorageFile) => {
        if (!file.downloadedBy?.length || !file.notifyTo?.length) {
            return 0;
        } else {
            return file.downloadedBy.filter(db => file.notifyTo.some(v => v === db.user)).length;
        }
    };

    useEffect(() => {
        setMessages([]);
        getMessages();
    }, [messageType]);

    useEffect(() => {
        if (messageRef.current) {
            const height = messageRef.current.getBoundingClientRect()?.height;
            const scrollHeight = messageRef.current.scrollHeight;
            messageRef.current.scrollTop = Math.ceil(scrollHeight - height);
        }
    }, [messages]);

    useEffect(() => {
        getFiles();
    }, [workspace]);

    return (
        <ScrollableContent
            id="mailbox"
            side={
                <div id="mailbox-files">
                    <div><h4>Documents</h4><Menu icon={ActionIcon.ADD} label="Ajouter" onClick={() => setFileModalVisible(true)} /></div>
                    {!files?.length && <NoResult text="Aucun document partagé" />}
                    {!!files?.length && files.map(file => (
                        <div key={file._id} className="mailbox-files-file">
                            <span className="mailbox-files-file-name">{file.name}</span>
                            {!!file.description && <span className="mailbox-files-file-description">{file.description}</span>}
                            <span className="mailbox-files-file-uploader">{getFullName(file.createdByPopulated)}, {formatTimestamp(file.createdAt, undefined, 'full')}</span>
                            <div>
                                <Menu icon="mdi:download-outline" label="Télécharger" onClick={() => handleDownload(file)} />
                                <div className="mailbox-files-file-actions">
                                    {(workspacePermissions.administrate || user._id === file.createdBy) && (
                                        <Fragment>
                                            <Menu icon={ActionIcon.DELETE} onClick={() => setFileForDelete(file)} />
                                            <Menu icon={ActionIcon.SEND} onClick={() => setFileForNotify(file)} />
                                        </Fragment>
                                    )}
                                    <Menu icon="mdi:information-box-outline" childrenPosition="right">
                                        <div className="mailbox-files-file-download">
                                            <span>Téléchargements ({getNotifiedDownloadCount(file) ?? 0}/{file.notifyTo?.length ?? 0}) :</span>
                                            {(file.downloadedBy ?? []).map(download => (
                                                <div key={download.timestamp}>
                                                    <span><strong>{getFullName(download.userPopulated)}</strong> ({file.notifyToPopulated?.some(u => u._id === download.user) ? 'Notifié' : 'Non notifié'})</span>
                                                    <span>Le {formatTimestamp(download.timestamp, undefined, 'full')}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        >
            <Card
                id="mailbox-messages"
                title="Messages"
                actions={<Switch id="messagesType" value={messageType} items={[{ key: 'public', label: 'Publics' }, { key: 'private', label: 'Privés' }]} onChange={setMessagesType} />}
            >
                <span className="info sm-hide">
                    <Icon icon="mdi:information-box-outline" />La mise à jour des message n'est pas automatique. Merci de bien vouloir rafraichir la page régulièrement
                </span>
                <div id="mailbox-messages-container" ref={messageRef}>
                    {messages.map(m => (
                        <div key={m._id} className={`mailbox-messages-message ${m.createdBy === user._id ? 'mailbox-messages-message-by-user' : ''}`}>
                            <div className="mailbox-messages-message-sender">
                                {getFullName(m.createdByPopulated)} <span>{formatTimestamp(m.createdAt, undefined, 'full')}</span>
                            </div>
                            {m.filePopulated && (<div className="mailbox-messages-message-file">Fichier concerné : <strong>{m.filePopulated.name}</strong></div>)}
                            <div className="mailbox-messages-message-content">
                                {m.message}
                            </div>
                        </div>
                    ))}
                </div>
                {messageType === 'public' && (
                    <form id="mailbox-messages-form">
                        <div id="mailbox-messages-form-input">
                            <textarea
                                id="message"
                                rows={3}
                                onChange={(e) => onChange('message', e.target.value)}
                                value={entity.message}
                                placeholder="Message..."
                                ref={textareaRef}
                            />
                        </div>
                        <div id="mailbox-messages-form-actions">
                            <Button label="Envoyer" icon={ActionIcon.SEND} disabled={!entity.message} onClick={handleSendMessage} />
                            <Button label="Options" icon={ActionIcon.FILTER} color="secondary" onClick={() => setOptionsPanelVisible(!isOptionsPanelVisible)} />
                        </div>
                        <div id="mailbox-messages-form-options" className={isOptionsPanelVisible ? 'visible' : ''}>
                            <Toggle label="Notifier par email :" {...attachInput('email')} />
                        </div>
                    </form>
                )}
            </Card>
            {isFileModalVisible && <FileModal onSubmit={handleSubmit} onClose={() => setFileModalVisible(false)} />}
            {fileToNotify && <MessageModal type={MessageType.FILE} associatedFile={fileToNotify} onSubmit={handleSubmit} onClose={() => setFileForNotify(null)} />}
            {fileForDelete && <ModalDelete onSubmit={() => handleDelete(fileForDelete)} onCancel={() => setFileForDelete(null)} />}
        </ScrollableContent>
    );
}

export default MailBox;