import { Icon } from '@iconify/react';
import Card from '../../../../components/ui/Card';
import { MarkerIcon } from '../../../../models/marker';
import { PopulationIcon } from '../../../../models/population';
import { SampleIcon, SampleLabels, SampleType } from '../../../../models/sample';

const Legend = () => (
    <Card className="sm-hide" id="synoptique-legend">
        <div className="legend-item"><Icon icon="mdi:cards-diamond-outline" className="icon-xs color-black" /><span>Historique</span></div>
        <div className="legend-item"><Icon icon={SampleIcon[SampleType.CAROTTE]} className="icon-xs color-black" /><span>{SampleLabels.carotte.type}</span></div>
        <div className="legend-item"><Icon icon={SampleIcon[SampleType.SONDAGE]} className="icon-xs color-black" /><span>{SampleLabels.sondage.type}</span></div>
        <div className="legend-item"><Icon icon={SampleIcon[SampleType.PRELEVEMENT]} className="icon-xs color-black" /><span>{SampleLabels.prelevement.type}</span></div>
        <div className="legend-item"><Icon icon={PopulationIcon} className="icon-xs color-black" /><span>{SampleLabels.population.type}</span></div>
        <div className="legend-item"><Icon icon={MarkerIcon} className="icon-xs color-black" /><span>{SampleLabels.marker.type}</span></div>
    </Card>
);

export default Legend;