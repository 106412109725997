import { Fragment, useState } from 'react';
import { useVisualisation } from '..';
import { CoordinatesXYZ } from '../../../../models/location';
import { OuvrageMarker } from '../../../../models/ouvrage-marker';
import { markerToCoordinates, updateCoordinates } from '../functions';

export interface VisualisationMarkerProps {
    marker: Partial<OuvrageMarker>;
    onMove?: (coordinates: CoordinatesXYZ) => void;
    onClick?: () => void;
    selected?: boolean;
    hide?: boolean;
}

const VisualisationMarker = ({ marker, onMove, selected, hide, onClick }: VisualisationMarkerProps) => {
    const state = useVisualisation();
    const [isMoving, setMoving] = useState<boolean>(false);

    const coordinates = marker.coordinates ? markerToCoordinates(marker.coordinates, state.view) : undefined;

    if (!coordinates || hide) return null;

    return (
        <Fragment>
            <circle
                className={`visualization-marker ${onClick ? 'clickable' : ''} ${selected ? 'selected' : ''} ${onMove ? 'movable' : ''}`}
                cx={coordinates.x}
                cy={coordinates.y}
                r={5 / state.zoom}
                strokeWidth={2 / state.zoom}
                onMouseDown={onMove ? (e) => { e.stopPropagation(); setMoving(true); } : undefined}
                onMouseMove={onMove && isMoving ? (e) => { e.stopPropagation(); onMove(updateCoordinates(state, { x: e.clientX, y: e.clientY }, marker.coordinates ?? {})); } : undefined}
                onMouseUp={() => setMoving(false)}
                onClick={onClick}
            />
        </Fragment>
    )
}

export default VisualisationMarker;