export const isPrGreater = (pr1: string, pr2: string): boolean => {
    try {
        return prToFloat(pr1) >= prToFloat(pr2);
    } catch (e) {
        return false;
    }
};

export const arePrEquals = (pr1: string, pr2: string): boolean => {
    try {
        return pr1 === pr2 || prToFloat(pr1) === prToFloat(pr2);
    } catch (e) {
        return false;
    }
};

export const prToFloat = (pr: string): number => {
    try {
        const prSplit = `${pr}`.replace(".", "+").split("+");
        let prString = prSplit[0];
        if (prSplit?.length === 2) {
            prString += `.${prSplit[1].padStart(3, "0")}`;
        }

        return Number.parseFloat(prString);
    } catch (e) {
        return -1;
    }
};

export const floatToPrString = (pr?: number): string => {
    if (pr === undefined) return "";
    try {
        return pr.toFixed(3).replace(".", "+");
    } catch (e) {
        return "";
    }
};

export const cleanPrString = (pr: string): string => {
    try {
        const prSplit = pr.replace(".", "+").replace(",", "+").split("+");
        let prString = prSplit[0];
        if (prSplit?.length === 2) {
            prString += `+${prSplit[1].padStart(3, "0")}`;
        } else {
            prString += `+000`;
        }
        return prString;
    } catch (e) {
        return "PR INVALIDE";
    }
};

export const prRegex = /^[0-9]+(\+[0-9]{1,3})?$/;
