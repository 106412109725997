import { Fragment, useCallback } from 'react';
import { Analysis, AnalysisDatum } from '../../models/analysis';
import './index.scss';
import { MaterialCharacteristicElement } from '../../models/material';

interface CellEditProps {
    characteristic: MaterialCharacteristicElement;
    analysis: Partial<Analysis>;
    onChange: (a: Partial<Analysis>) => void;
}

const CellEdit = ({
    characteristic,
    analysis,
    onChange
}: CellEditProps) => {
    const handleChangeData = useCallback((field: string, value: any, seuil?: number) => {
        const data = { ...analysis?.data };
        let dataField = data[field];
        if (!dataField) {
            if (seuil !== undefined) {
                dataField = [{ value, seuil, problematic: false }];
            } else {
                dataField = { value, problematic: false };
            }
        } else if (Array.isArray(dataField)) {
            const index = dataField.findIndex(s => s.seuil === seuil);
            if (index >= 0) {
                dataField[index] = { seuil, value, problematic: false };
            } else {
                dataField.push({ seuil, value, problematic: false });
            }
        } else {
            dataField.value = value;
            dataField.problematic = false;
        }

        onChange({ ...analysis, data: { ...data, [field]: dataField } });
    }, [onChange, analysis]);

    if (characteristic.seuils) {
        const datum = Array.isArray(analysis?.data?.[characteristic.key]) ? analysis?.data?.[characteristic.key] as AnalysisDatum[] : [];
        return (
            <Fragment>
                {characteristic.seuils.map((s, seuilIndex) => (
                    <td
                        key={seuilIndex}
                        className={`${seuilIndex === 0 ? 'with-border' : ''}${datum?.find(v => v.seuil === s.seuil)?.problematic ? ' problematic' : ''}`}
                    >
                        <input type="number" onWheel={(e) => e.currentTarget.blur()} value={datum?.find(v => v.seuil === s.seuil)?.value ?? ''} onChange={(event) => handleChangeData(characteristic.key, event.target.value !== '' ? Number(event.target.value) : undefined, s.seuil)} />
                    </td>
                ))}
            </Fragment>
        )
    } else if (characteristic.isBoolean) {
        const datum: AnalysisDatum | null = !Array.isArray(analysis?.data?.[characteristic.key]) ? analysis?.data?.[characteristic.key] as AnalysisDatum : null;
        return (
            <td className={`with-border ${datum?.problematic ? 'problematic' : ''}`}>
                <select className="input-long" value={datum?.value} onChange={(event) => handleChangeData(characteristic.key, Number(event.target.value))}>
                    <option selected value={undefined}></option>
                    <option value={0}>Non</option>
                    <option value={1}>Oui</option>
                </select>
            </td>
        )
    } else {
        const datum: AnalysisDatum | null = !Array.isArray(analysis?.data?.[characteristic.key]) ? analysis?.data?.[characteristic.key] as AnalysisDatum : null;
        return (
            <td className={`with-border ${datum?.problematic ? 'problematic' : ''}`}>
                <input type="number" onWheel={(e) => e.currentTarget.blur()} value={datum?.value ?? ''} onChange={(event) => handleChangeData(characteristic.key, event.target.value !== '' ? Number(event.target.value) : undefined)} />
            </td>
        )
    }
}

interface CellProps {
    characteristic: MaterialCharacteristicElement;
    analysis: Partial<Analysis>;
    onClick?: (c: MaterialCharacteristicElement, s?: number) => void;
}
const Cell = ({
    characteristic,
    analysis,
    onClick
}: CellProps) => {
    if (characteristic.seuils) {
        const datum = Array.isArray(analysis?.data?.[characteristic.key]) ? analysis?.data?.[characteristic.key] as AnalysisDatum[] : [];
        return (
            <Fragment>
                {characteristic.seuils.map((s, seuilIndex) => (
                    <td
                        key={seuilIndex}
                        className={`${seuilIndex === 0 ? 'with-border' : ''}${datum?.find(v => v.seuil === s.seuil)?.problematic ? ' problematic' : ''}`}
                        onClick={() => typeof onClick === 'function' ? onClick(characteristic, s.seuil) : undefined}
                    >
                        {datum?.find(v => v.seuil === s.seuil)?.value ?? ''}
                    </td>
                ))}
            </Fragment>
        )
    } else if (characteristic.isBoolean) {
        const datum: AnalysisDatum | null = !Array.isArray(analysis?.data?.[characteristic.key]) ? analysis?.data?.[characteristic.key] as AnalysisDatum : null;
        return (
            <td className={`with-border ${datum?.problematic ? 'problematic' : ''}`} onClick={() => typeof onClick === 'function' ? onClick(characteristic) : undefined}>
                {datum?.value ? 'Oui' : 'Non'}
            </td>
        )
    } else {
        const datum: AnalysisDatum | null = !Array.isArray(analysis?.data?.[characteristic.key]) ? analysis?.data?.[characteristic.key] as AnalysisDatum : null;
        return (
            <td className={`with-border ${datum?.problematic ? 'problematic' : ''}`} onClick={() => typeof onClick === 'function' ? onClick(characteristic) : undefined}>
                {datum?.value ?? ''}
            </td>
        )
    }
}

interface AnalysisCellProps {
    characteristic: MaterialCharacteristicElement;
    analysis: Partial<Analysis>;
    isEditing?: boolean;
    onChange?: (a: Partial<Analysis>) => void;
    onClick?: () => void;
}

const AnalysisCell = ({
    characteristic,
    analysis,
    isEditing = false,
    onChange,
    onClick
}: AnalysisCellProps) => isEditing && onChange
        ? <CellEdit characteristic={characteristic} analysis={analysis} onChange={onChange} />
        : <Cell characteristic={characteristic} analysis={analysis} onClick={onClick} />;

export default AnalysisCell;