import { Icon } from '@iconify/react';
import { MouseEvent, ReactNode, TouchEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Role } from '../../../models/user';
import useAuth from '../../../services/hooks/use-auth.hook';
import './index.scss';

export interface MenuBarProps {
    children: ReactNode;
    id?: string;
    className?: string;
    card?: boolean;
    column?: boolean;
}

const MenuBar = ({ children, id, className, card, column }: MenuBarProps) => {
    return (
        <div id={id} className={`menu-bar ${card ? 'menu-bar-card' : ''} ${column ? 'menu-bar-column' : ''} ${className ?? ''}`}>
            {children}
        </div>
    )
}

export interface MenuBarItemProps {
    icon?: string | ReactNode;
    label?: string;
    onClick?: () => void;
    active?: boolean;
    children?: ReactNode;
    disposition?: 'bottom-right' | 'bottom-left' | 'right' | 'left';
    id?: string;
    className?: string;
    type?: 'normal' | 'button';
    error?: boolean;
    stopPropagation?: boolean;
}

const MenuBarItem = ({ id, className, icon, label, onClick, active, children, disposition, type, error, stopPropagation }: MenuBarItemProps) => {
    const [childrenVisible, setChildrenVisible] = useState<boolean>(false);

    const handleClick = useCallback((e: MouseEvent | TouchEvent) => {
        e.stopPropagation();
        if (!stopPropagation && childrenVisible) setChildrenVisible(false);
        if (onClick) onClick();
    }, [onClick, stopPropagation, childrenVisible]);

    return (
        <div
            id={id}
            className={`menu-bar-item ${active ? 'active' : ''} ${error ? 'menu-bar-item-error' : ''} menu-bar-item-${type ?? 'normal'} ${disposition ?? 'bottom-left'} ${className ?? ''}`}
            onClick={handleClick}
            onMouseEnter={() => setChildrenVisible(true)}
            onMouseLeave={() => setChildrenVisible(false)}
        >
            {typeof icon === 'string' && <Icon icon={icon} className={`icon-small ${type === 'button' ? 'color-white' : 'color-steel'}`} />}
            {typeof icon !== 'string' && icon}
            {!!label && <span>{label}</span>}
            {error && <div className="menu-bar-item-pill" />}
            {!!children && (
                <div className={`menu-bar-item-children ${childrenVisible ? 'visible' : ''}`}>
                    {children}
                </div>
            )}
        </div>
    )
}

MenuBar.Item = MenuBarItem;
MenuBar.SubItem = ({ icon, label, onClick, active }: MenuBarItemProps) => {
    return (
        <div className={`menu-bar-sub-item ${active ? 'active' : ''}`} onClick={onClick} onTouchStart={onClick}>
            {typeof icon === 'string' && <Icon icon={icon} className="icon-small color-steel" />}
            {typeof icon !== 'string' && icon}
            <span>{label}</span>
        </div>
    )

}

export type MenuBarNavigationPage = { key: string, label: string, role?: Role, error?: boolean };

interface MenuBarNavigationProps {
    pages: MenuBarNavigationPage[];
    setPage?: (p: string) => void;
    className?: string;
}

export const MenuBarNavigation = ({ pages, setPage, className }: MenuBarNavigationProps) => {
    const { page } = useParams();
    const { user } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchHistory, setSearchHistory] = useState<any>({});

    const handleClick = (newPage: string) => {
        if (newPage !== page && page) {
            // Keep search history between pages
            const _searchHistory = Object.assign({}, searchHistory);
            _searchHistory[page] = location.search;
            setSearchHistory(_searchHistory);

            const path = location.pathname.split('/');
            path.pop();
            if (setPage) setPage(newPage);
            navigate(`${path.join('/')}/${newPage}${searchHistory[newPage] ?? ''}`, { replace: true });
        }
    }

    useEffect(() => {
        if (!page) {
            if (setPage) setPage(pages[0].key);
            navigate(`${location.pathname}/${pages[0].key}`, { replace: true });
        } else if (!pages?.some(p => p.key === page)) {
            navigate('/erreur/404');
        } else {
            if (setPage) setPage(page);
        }
    }, [page]);

    return (
        <MenuBar className={`menu-bar-navigation ${className ?? ''}`}>
            {pages.filter(p => !p.role || p.role === user.role).map(p => (
                <MenuBar.Item key={p.key} active={page === p.key} error={p.error} onClick={() => handleClick(p.key)} label={p.label} />
            ))}
        </MenuBar>
    )
}

export default MenuBar;