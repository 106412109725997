import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, Outlet, RouterProvider, useNavigate } from 'react-router-dom';
import Notifications from '../components/Notifications';
import OperationWrapper, { OperationMenuWrapper } from '../components/OperationWrapper';
import OuvrageWrapper, { OuvrageMenuWrapper } from '../components/OuvrageWrapper';
import Sidebar, { SidebarSubcontractor } from '../components/Sidebar';
import { Role } from '../models/user';
import useAuth from '../services/hooks/use-auth.hook';
import useWorkspace from '../services/hooks/use-workspace';
import { useCurrentUserQuery } from '../services/slices/api.slice';
import { GlobalPermissions, logout } from '../services/slices/auth.slice';
import { WorkspacePermissions, WorkspaceType } from '../services/slices/workspace.slice';
import Businesses from './Business';
import BusinessEdit from './Business/BusinessEdit';
import Catalogue from './Catalogue';
import MaterialEdit from './Catalogue/Edit';
import Error from './Error';
import Home from './Home';
import FindCharacteristic from './Library/FindCharacteristic';
import FindMaterial from './Library/FindMaterial';
import Licenses from './License';
import LicenseForUser from './License/License';
import LicenseEdit from './License/LicenseEdit';
import MailBox from './Mailbox';
import ComponentEdit from './Operation/Components/ComponentEdit';
import Dashboard from './Operation/Dashboard';
import LotEdit from './Operation/Lot/LotEdit';
import LotView from './Operation/Lot/LotView';
import OperationMap from './Operation/Map';
import MarkerForm from './Operation/Map/MarkerForm';
import OperationEdit from './Operation/OperationEdit';
import PopulationEdit from './Operation/Population/PopulationEdit';
import Requirement from './Operation/Requirement';
import ByLocation from './Operation/Results/ByLocation';
import ByMaterial from './Operation/Results/ByMaterial';
import ByMaterialResults from './Operation/Results/ByMaterialResults';
import Melanges from './Operation/Results/Melanges';
import WorkSummary from './Operation/Results/WorkSummary';
import SampleEdit from './Operation/Sample/SampleEdit';
import SampleEditMultiple from './Operation/Sample/SampleEditMultiple';
import StudyFollowUp from './Operation/StudyFollowUp';
import MelangeEdit from './Operation/StudyFollowUp/MelangeEdit';
import Synoptique from './Operation/Synoptique';
import WorkFollowUp from './Operation/WorkFollowUp';
import Diagnostics from './Ouvrage/Diagnostics';
import DiagnosticEdit from './Ouvrage/Diagnostics/DiagnosticEdit';
import DiagnosticSubcontractor from './Ouvrage/Diagnostics/DiagnosticSubcontractor';
import OuvrageEdit from './Ouvrage/Edit';
import OuvrageView from './Ouvrage/View';
import Users from './Users';
import Profile from './Users/Profile';
import UserEdit from './Users/UserEdit';

interface ProtectedRouteProps {
    type?: WorkspaceType;
    canAccess?: (permissions: Partial<GlobalPermissions>, workspacePermissions: Partial<WorkspacePermissions>) => boolean | undefined;
    children: JSX.Element;
}

const ProtectedRoute = ({ children, type, canAccess }: ProtectedRouteProps) => {
    const { permissions } = useAuth();
    const { workspacePermissions, workspace } = useWorkspace();
    const navigate = useNavigate();

    const error = useMemo(() => canAccess && !canAccess(permissions, workspacePermissions)
        ? '401'
        : type && type !== workspace
            ? '404'
            : undefined,
        [type, canAccess, permissions, workspace, workspacePermissions])

    useEffect(() => {
        if (error === '404' || error === '401') {
            navigate('/' + error, { replace: true })
        }
    }, [error]);

    return !error ? children : null;
}

const PrivateWrapper = () => {
    const { user } = useAuth();
    const { workspace } = useWorkspace();

    if (!user._id) return null;

    return (
        <div id="app" className={`${workspace === WorkspaceType.OUVRAGES ? 'oa' : ''}`}>
            {user.role !== Role.SUBCONTRACTOR && <Sidebar />}
            <div id="content">
                {user.role === Role.SUBCONTRACTOR && <SidebarSubcontractor />}
                <Notifications />
                <Outlet />
            </div>
        </div>
    );
}

const Private = () => {
    const { error } = useCurrentUserQuery(undefined, { pollingInterval: 1000000 });
    const { user } = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            dispatch(logout());
        }
    }, [error]);

    return <RouterProvider router={createBrowserRouter([
        {
            path: "/",
            element: <PrivateWrapper />,
            children: [
                { path: '*', element: <Error /> },
                { path: 'erreur/:code?', element: <Error /> },
                { path: 'accueil', element: <Home /> },
                { path: 'mon-profil', element: <Profile /> },
                { path: 'ma-licence', element: <LicenseForUser /> },

                // Operations
                // Can administrate operations (admin and license owners)
                { path: 'operation/creer/:type/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(p) => p.administrate}><OperationEdit /></ProtectedRoute> },
                {
                    path: 'operation/:operationId',
                    element: <OperationWrapper />,
                    children: [
                        {
                            path: '',
                            element: <OperationMenuWrapper />,
                            children: [
                                { path: '', element: <ProtectedRoute type={WorkspaceType.CHAUSSES}>{user.role !== Role.SUBCONTRACTOR ? <Synoptique /> : <Dashboard />}</ProtectedRoute> },
                                { path: 'tableau-de-bord', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><Dashboard /></ProtectedRoute> },
                                // Can read operation
                                { path: 'carte', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.read}><OperationMap /></ProtectedRoute> },
                                { path: 'boite-aux-lettres', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.read}><MailBox /></ProtectedRoute> },
                                // Can access operation results
                                { path: 'resultats/localisation-et-phase', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.localizedResults}><ByLocation /></ProtectedRoute> },
                                { path: 'resultats/melanges', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.localizedResults}><Melanges /></ProtectedRoute> },
                                { path: 'resultats/materiaux/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.materialResults}><ByMaterial /></ProtectedRoute> },
                                { path: 'resultats/materiau/:materialId', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.materialResults}><ByMaterialResults /></ProtectedRoute> },
                                { path: 'resultats/bilan-travaux', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.read}><WorkSummary /></ProtectedRoute> },
                                // Can write in operation (write and subcontractors)
                                { path: 'travaux/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><WorkFollowUp /></ProtectedRoute> },
                                { path: 'etude/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><StudyFollowUp /></ProtectedRoute> },
                                { path: 'preparatoire/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><StudyFollowUp /></ProtectedRoute> },
                                { path: 'expertise/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><StudyFollowUp /></ProtectedRoute> },
                                { path: 'travaux/lot/:lotId/detail/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><LotView /></ProtectedRoute> },
                                // Can administrate operation
                                { path: 'exigences/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.administrate}><Requirement /></ProtectedRoute> },
                                { path: 'editer/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.administrate}><OperationEdit /></ProtectedRoute> },
                            ]
                        },
                        // Operation forms outside operation menu wrapper
                        { path: 'travaux/lot/creer/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><LotEdit /></ProtectedRoute> },
                        { path: 'travaux/lot/:lotId/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><LotEdit /></ProtectedRoute> },
                        { path: 'travaux/population/creer', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><PopulationEdit /></ProtectedRoute> },
                        { path: 'travaux/population/:populationId/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><PopulationEdit /></ProtectedRoute> },
                        { path: 'travaux/lot/:lotId/population/creer', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><PopulationEdit /></ProtectedRoute> },
                        { path: 'travaux/lot/:lotId/population/:populationId/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><PopulationEdit /></ProtectedRoute> },
                        { path: 'travaux/lot/:lotId/:sampleType/creer-en-masse', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><SampleEditMultiple /></ProtectedRoute> },
                        { path: 'travaux/lot/:lotId/:sampleType/creer', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><SampleEdit /></ProtectedRoute> },
                        { path: 'travaux/lot/:lotId/:sampleType/:sampleId/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><SampleEdit /></ProtectedRoute> },
                        { path: 'travaux/suivi-de-constituants/:materialId', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><ComponentEdit /></ProtectedRoute> },
                        { path: ':phase/point-d-interet/creer/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><MarkerForm /></ProtectedRoute> },
                        { path: ':phase/point-d-interet/:markerId/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><MarkerForm /></ProtectedRoute> },
                        { path: ':phase/melange/creer', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><MelangeEdit /></ProtectedRoute> },
                        { path: ':phase/melange/:melangeId/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><MelangeEdit /></ProtectedRoute> },
                        { path: ':phase/:sampleType/creer', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><SampleEdit /></ProtectedRoute> },
                        { path: ':phase/:sampleType/:sampleId/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.write}><SampleEdit /></ProtectedRoute> },
                        { path: 'exigences/:materialType/creer/:standardId', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.administrate}><MaterialEdit /></ProtectedRoute> },
                        { path: 'exigences/:materialType/:materialId/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(_, wp) => wp.administrate}><MaterialEdit /></ProtectedRoute> },
                    ]
                },
                // Can access library
                { path: 'bibliotheque/recherche-de-caracteristique', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(p) => p.library}><FindCharacteristic /></ProtectedRoute> },
                { path: 'bibliotheque/recherche-de-materiau', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(p) => p.library}><FindMaterial /></ProtectedRoute> },
                // Can administrate
                { path: 'catalogue/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(p) => p.administrate}><Catalogue /></ProtectedRoute> },
                { path: 'catalogue/:materialType/creer', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(p) => p.administrate}><MaterialEdit /></ProtectedRoute> },
                { path: 'catalogue/:materialType/:standardId/:page?', element: <ProtectedRoute type={WorkspaceType.CHAUSSES} canAccess={(p) => p.administrate}><MaterialEdit /></ProtectedRoute> },

                // Can administrate operations (admin and license owners)
                { path: 'ouvrage/creer/:type/:page?', element: <ProtectedRoute type={WorkspaceType.OUVRAGES} canAccess={(p) => p.administrate}><OuvrageEdit /></ProtectedRoute> },
                { path: 'ouvrage/:ouvrageId/editer/:page?', element: <ProtectedRoute type={WorkspaceType.OUVRAGES} canAccess={(_, wp) => wp.administrate}><OuvrageEdit /></ProtectedRoute> },
                {
                    path: 'ouvrage/:ouvrageId',
                    element: <OuvrageWrapper />,
                    children: user.role !== Role.SUBCONTRACTOR ? [
                        {
                            path: '',
                            element: <OuvrageMenuWrapper />,
                            children: [
                                { path: '', element: <ProtectedRoute type={WorkspaceType.OUVRAGES} canAccess={(_, wp) => wp.read}><OuvrageView /></ProtectedRoute> },
                                { path: 'diagnostics', element: <ProtectedRoute type={WorkspaceType.OUVRAGES} canAccess={(_, wp) => wp.write}><Diagnostics /></ProtectedRoute> },
                                { path: 'diagnostics/:diagnosticId/:page?', element: <ProtectedRoute type={WorkspaceType.OUVRAGES} canAccess={(_, wp) => wp.write}><DiagnosticEdit /></ProtectedRoute> },
                                { path: 'boite-aux-lettres', element: <ProtectedRoute type={WorkspaceType.OUVRAGES} canAccess={(_, wp) => wp.read}><MailBox /></ProtectedRoute> },
                            ]
                        }
                    ] : [{
                        path: '',
                        element: <DiagnosticSubcontractor />
                    }]
                },

                { path: 'annuaire', element: <ProtectedRoute canAccess={(p) => p.administrate}><Businesses /></ProtectedRoute> },
                { path: 'annuaire/creer', element: <ProtectedRoute canAccess={(p) => p.administrate}><BusinessEdit /></ProtectedRoute> },
                { path: 'annuaire/:id/:page?', element: <ProtectedRoute canAccess={(p) => p.administrate}><BusinessEdit /></ProtectedRoute> },
                // Can super administrate
                { path: 'utilisateurs', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><Users /></ProtectedRoute> },
                { path: 'utilisateurs/creer', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><UserEdit /></ProtectedRoute> },
                { path: 'utilisateurs/:id/:page?', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><UserEdit /></ProtectedRoute> },
                { path: 'licences', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><Licenses /></ProtectedRoute> },
                { path: 'licences/creer', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><LicenseEdit /></ProtectedRoute> },
                { path: 'licences/:id/:page?', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><LicenseEdit /></ProtectedRoute> },
                { path: '/', element: <Home /> },
            ],
        },
    ])} />;
}

export default Private;
