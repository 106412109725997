import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoadPositionLabel } from '../../models/operation';
import { Population, PopulationTypeLabel } from '../../models/population';
import { ControleLabel, Controles } from '../../models/sample';
import useWorkspace from '../../services/hooks/use-workspace';
import { getRequest } from '../../services/request.service';
import { formatNumberToFixedDecimal } from '../../utils/format';
import { ActionIcon } from '../../utils/icons';
import { floatToPrString } from '../../utils/pr';
import ValidationCard from '../entities/ValidationCard';
import Card from '../ui/Card';
import Dropdown from '../ui/Dropdown';
import Menu from '../ui/Menu';
import { ModalRight } from '../ui/Modal/ModalRight';
import './index.scss';

interface PopulationDetailProps {
    id?: string;
    population?: Population;
    onClose: (refresh?: boolean) => void;
}

const PopulationDetail = ({
    id,
    population: populationFromProps,
    onClose
}: PopulationDetailProps) => {
    const { operation, workspacePermissions } = useWorkspace();
    const [population, setPopulation] = useState<Population | null>(populationFromProps ?? null);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getRequest<Population>(`/population/${id}`, { errorMessage: 'Une erreur est survenue lors de la récupération de la population' })
                .then(setPopulation)
                .catch(() => onClose());
        } else if (populationFromProps) {
            setPopulation(populationFromProps);
        } else {
            setPopulation(null);
            onClose();
        }
    }, [id, populationFromProps]);

    if (!population) {
        return (null);
    }

    return (
        <ModalRight
            visible={!!population}
            actions={[{ label: 'Fermer', color: 'secondary', onClick: onClose }]}
            title={PopulationTypeLabel[population.type]}
            icon={workspacePermissions.write ? <Menu label="Editer" icon={ActionIcon.EDIT} onClick={() => navigate(`/operation/${operation?._id}/travaux/population/${population._id}/editer`)} /> : undefined}
        >
            <div id="population-detail">
                {!!population.comment && <span><strong>Commentaire : </strong>{population.comment}</span>}
                <ValidationCard
                    entity={population}
                    canValidate={workspacePermissions.validate}
                    endpoint="/population"
                    onSubmit={() => onClose(true)}
                />
                <Card title="Conformité">
                    <table className="simple-table">
                        <thead>
                            <tr>
                                <th />
                                <th>à 95%</th>
                                <th>à 100%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Controles.map(controle => (
                                <tr key={controle.key}>
                                    <td>{ControleLabel[controle.key]}</td>
                                    <td>{population.controle?.[controle.key]?.compliance95 === false
                                        ? 'Non conforme'
                                        : population.controle?.[controle.key]?.compliance95 === true ? 'Conforme' : ''}
                                    </td>
                                    <td>{population.controle?.[controle.key]?.compliance100 === false
                                        ? 'Non conforme'
                                        : population.controle?.[controle.key]?.compliance100 === true ? 'Conforme' : ''}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card>
                <Card title="Statistiques">
                    <table className="simple-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Minimum</th>
                                <th>Maximum</th>
                                <th>Moyenne</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Controles.map(controle => (
                                <tr key={controle.key} className="col">
                                    <td>{ControleLabel[controle.key]}</td>
                                    <td>{formatNumberToFixedDecimal(population.controle?.[controle.key]?.statistics?.min)}</td>
                                    <td>{formatNumberToFixedDecimal(population.controle?.[controle.key]?.statistics?.max)}</td>
                                    <td>{formatNumberToFixedDecimal(population.controle?.[controle.key]?.statistics?.average)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td><strong>Global</strong></td>
                                <td>{formatNumberToFixedDecimal(population.statistics?.min)}</td>
                                <td>{formatNumberToFixedDecimal(population.statistics?.max)}</td>
                                <td>{formatNumberToFixedDecimal(population.statistics?.average)}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
                <Card title="Statistiques">
                    <table className="simple-table">
                        <thead>
                            <tr>
                                <th>Min.</th>
                                <th>Moy.</th>
                                <th>Max.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{population.statistics?.min}</td>
                                <td>{formatNumberToFixedDecimal(population.statistics?.average)}</td>
                                <td>{population.statistics?.max}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
                <Card title="Données brutes">
                    {Controles.filter(c => !!population.controle[c.key]).map(c => {
                        const controle = population.controle[c.key]!;
                        return (
                            <Dropdown title={c.label} key={c.key}>
                                <table className="simple-table">
                                    <thead>
                                        {operation.synoptique === 1 ? (
                                            <tr>
                                                <td>PR</td>
                                                <td>Voie</td>
                                                <td>Emplacement</td>
                                                <td>Valeur</td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td>PR</td>
                                                <td>Longitude</td>
                                                <td>Latitude</td>
                                                <td>Valeur</td>
                                            </tr>
                                        )}
                                    </thead>
                                    <tbody>
                                        {controle.data?.map(d => operation.synoptique === 1 ? (
                                            <tr key={String(d.value) + String(d.location.pr ?? '')}>
                                                <td>{d.location.pr !== undefined ? floatToPrString(d.location.pr) : ''}</td>
                                                <td>{d.location.road ? operation.roadsObj[d.location.road].name : ''}</td>
                                                <td>{d.location.roadPosition ? RoadPositionLabel[d.location.roadPosition] : ''}</td>
                                                <td className={d.problematic ? 'problematic' : ''}>{d.value}</td>
                                            </tr>
                                        ) : (
                                            <tr key={String(d.value) + String(d.location.pr ?? '')}>
                                                <td>{d.location.pr !== undefined ? floatToPrString(d.location.pr) : ''}</td>
                                                <td>{d.location.coordinates?.longitude}</td>
                                                <td>{d.location.coordinates?.latitude}</td>
                                                <td className={d.problematic ? 'problematic' : ''}>{d.value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Dropdown>
                        );
                    })}
                </Card>
            </div>
        </ModalRight >
    );
}

export default PopulationDetail;