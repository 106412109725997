import { BasicEntity } from "./shared";
import { UserWorkspaceRole } from "./user";

export interface Diagnostic extends BasicEntity {
    status: DiagnosticStatus;
    ouvrage: string;
    date: Date;
    grade?: string;
    elements?: DiagnosticElement[];
    subcontractorComment?: string;
    users: UserWorkspaceRole[];
}

export interface DiagnosticElement {
    elementId: string;
    comment?: string;
    grade?: string;
    security?: boolean;
    diagnostic: boolean;
    moaGrade?: string;
    moaSecurity?: boolean;
    moaComment?: string;
}

export const getGrade = (element: DiagnosticElement) =>
    element?.grade
        ? `${element?.grade}${element.security === true ? "S" : ""}`
        : undefined;
export const getMoaGrade = (element: DiagnosticElement) =>
    element?.moaGrade
        ? `${element?.moaGrade}${element.moaSecurity === true ? "S" : ""}`
        : undefined;
export const getFinalGrade = (element: DiagnosticElement) =>
    getMoaGrade(element) ?? getGrade(element);

export const getOuvrageGrade = (diagnostic: Partial<Diagnostic>) =>
    diagnostic.elements
        ?.map((e) => getFinalGrade(e))
        .filter((g) => !!g)
        .sort((g1, g2) => (g1! < g2! ? 1 : -1))[0];

export enum DiagnosticStatus {
    PLANNED = "planned",
    IN_PROGRESS = "in_progress",
    DONE = "done",
    MOA_DONE = "moa_done",
}

export const DIAGNOSTIC_GRADES = [
    { key: "1", label: "1" },
    { key: "2", label: "2" },
    { key: "2E", label: "2E" },
    { key: "3", label: "3" },
    { key: "3U", label: "3U" },
];

export const DiagnosticStatusLabel = {
    [DiagnosticStatus.PLANNED]: "Plannifié",
    [DiagnosticStatus.IN_PROGRESS]: "En cours",
    [DiagnosticStatus.DONE]: "Terminé",
    [DiagnosticStatus.MOA_DONE]: "Terminé MOA",
};

export const DiagnosticStatusColor = {
    [DiagnosticStatus.PLANNED]: "#494c5d",
    [DiagnosticStatus.IN_PROGRESS]: "#faa97a",
    [DiagnosticStatus.DONE]: "#a30e09",
    [DiagnosticStatus.MOA_DONE]: "#2ad344",
};

export const DiagnosticStatusList = [
    {
        key: DiagnosticStatus.PLANNED,
        label: DiagnosticStatusLabel[DiagnosticStatus.PLANNED],
        color: DiagnosticStatusColor[DiagnosticStatus.PLANNED],
    },
    {
        key: DiagnosticStatus.IN_PROGRESS,
        label: DiagnosticStatusLabel[DiagnosticStatus.IN_PROGRESS],
        color: DiagnosticStatusColor[DiagnosticStatus.IN_PROGRESS],
    },
    {
        key: DiagnosticStatus.DONE,
        label: DiagnosticStatusLabel[DiagnosticStatus.DONE],
        color: DiagnosticStatusColor[DiagnosticStatus.DONE],
    },
    {
        key: DiagnosticStatus.MOA_DONE,
        label: DiagnosticStatusLabel[DiagnosticStatus.MOA_DONE],
        color: DiagnosticStatusColor[DiagnosticStatus.MOA_DONE],
    },
];

export const DiagnosticIcon = "mdi:shield-car";
