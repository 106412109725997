import { useEffect, useRef, useState } from "react";

const useDebounce = (
    callback: () => void,
    duration: number,
    states: any[],
    callOnInit?: boolean
) => {
    const [isInit, setInit] = useState<boolean>(false);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        if (isInit) {
            debounceTimeout.current = setTimeout(callback, duration);
        } else {
            setInit(true);
            if (callOnInit) callback();
        }

        return () => {
            debounceTimeout.current && clearTimeout(debounceTimeout.current);
        };
    }, states);
};

export default useDebounce;
