import { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Password from '../../components/inputs/Password';
import SelectAutocomplete from '../../components/inputs/SelectAutocomplete';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Header from '../../components/ui/Header';
import ScrollableContent from '../../components/ui/ScrollableContent';
import useForm, { FormComparator } from '../../hooks/useForm';
import { UserProfile } from '../../models/user';
import useAuth from '../../services/hooks/use-auth.hook';
import { putRequest } from '../../services/request.service';
import { useLazyCurrentUserQuery } from '../../services/slices/api.slice';
import { addToast } from '../../services/slices/ui.slice';

const VALIDATION = {
    firstName: [{ comparator: FormComparator.REQUIRED }],
    lastName: [{ comparator: FormComparator.REQUIRED }],
    email: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.EMAIL }],
};

const Profile = () => {
    const dispatch = useDispatch();
    const { user } = useAuth();
    const { attachInput, validate } = useForm<UserProfile>(user);
    const [getMe] = useLazyCurrentUserQuery();
    const navigate = useNavigate();

    const handleSubmit = useCallback(() => {
        const entity = validate(VALIDATION);

        if (!entity) return;

        if (entity.newPassword && entity.newPassword !== entity.newPasswordRepeat) {
            dispatch(addToast({ type: 'error', message: 'Les mots de passe ne correspondent pas' }));
        } else if (
            entity.newPassword
            && (
                !entity.newPassword.match(/[A-Z]/)
                || !entity.newPassword.match(/[a-z]/)
                || !entity.newPassword.match(/[0-9]/)
                || !entity.newPassword.match(/.{8,32}/)
            )
        ) {
            dispatch(addToast({ type: 'error', message: 'Le mot de passe n\'est pas au bon format' }));
        } else {
            putRequest('/user/me', entity, { successMessage: 'Mise à jour du profil effectuée avec succès', loader: true })
                .then(() => getMe())
                .then(() => navigate(-1))
                .catch((e) => {
                    if ((e as any)?.error?.status === 409) {
                        dispatch(addToast({ type: 'error', message: 'Cet email est déjà utilisée par un utilisateur' }));
                    } else if ((e as any)?.error?.status === 401) {
                        dispatch(addToast({ type: 'error', message: 'Le mot de passe est incorrect' }));
                    } else {
                        dispatch(addToast({ type: 'error', message: 'Une erreur est survenue lors de l\'enregistrement.', error: e as any }));
                    }
                })
        }
    }, [validate, getMe]);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Mon compte' }]} >
                <Button
                    label="Enregistrer"
                    onClick={handleSubmit}
                />
            </Header>

            <ScrollableContent>
                <Card title="Identification">

                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="firstName">Prénom *</label>
                            <TextInput {...attachInput('firstName')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="firstName">Nom *</label>
                            <TextInput {...attachInput('lastName')} />
                        </div>
                    </div>
                    <div className="input-column">
                        <label htmlFor="email">Email *</label>
                        <TextInput {...attachInput('email')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="business">Entreprise *</label>
                        <SelectAutocomplete endpoint="/business/list" {...attachInput('business')} />
                    </div>
                </Card>
                <Card title="Mot de passe">
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="password">Mot de passe actuel *</label>
                            <Password {...attachInput('password')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="newPassword">Nouveau mot de passe *</label>
                            <Password withProgressBar withWarnings {...attachInput('newPassword')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="newPasswordRepeat">Répéter le mot de passe *</label>
                            <Password {...attachInput('newPasswordRepeat')} />
                        </div>
                    </div>
                </Card>
            </ScrollableContent>
        </Fragment >
    )
}

export default Profile;
