import { ReactNode, useCallback, useEffect, useState } from 'react';
import { FormBaseProps, FormRule, validateOne } from '../../../hooks/useForm';
import './index.scss';

interface TextInputProps extends FormBaseProps<string> {
    maxLength?: number;
    placeholder?: string;
    onBlur?: (v: string | number) => void;
    onFocus?: () => void;
    onBlurValidationError?: FormRule[];
    onBlurValidationWarning?: FormRule[];
    autoComplete?: boolean;
    icon?: ReactNode;
    onIconClick?: () => void;
    type?: 'text' | 'password';
}

const TextInput = ({
    id,
    value,
    type,
    maxLength,
    placeholder,
    disabled,
    onBlur,
    onFocus,
    onChange,
    onBlurValidationError,
    onBlurValidationWarning,
    icon,
    onIconClick,
    errors,
    autoComplete,
    warnings
}: TextInputProps) => {
    const [errorsToDisplay, setErrorsToDisplay] = useState<string[]>([]);
    const [warningsToDisplay, setWarningsToDisplay] = useState<string[]>([]);

    const handleBlur = useCallback((v: string | number) => {
        if (onBlurValidationError?.length) setErrorsToDisplay(validateOne(v, onBlurValidationError));
        if (onBlurValidationWarning?.length) setWarningsToDisplay(validateOne(v, onBlurValidationWarning));
        if (onBlur) onBlur(v);
    }, [onBlurValidationError, onBlurValidationWarning, onBlur]);

    useEffect(() => {
        setErrorsToDisplay(errors ?? []);
    }, [errors]);

    useEffect(() => {
        setWarningsToDisplay(warnings ?? []);
    }, [warnings]);

    return (
        <div className="form-input-text">
            <input
                autoComplete={autoComplete ? "on" : "off"}
                id={id}
                type={type ?? 'text'}
                readOnly={!!disabled}
                onBlur={(e) => handleBlur(e.target.value)}
                onChange={typeof onChange === 'function' ? (e) => onChange(e.target.value) : undefined}
                onFocus={onFocus}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
                maxLength={maxLength ? maxLength : undefined}
                className={`border-input ${!!errorsToDisplay?.length ? 'input-error' : (!!warnings?.length ? 'input-warning' : '')}`}
            />
            {!!icon && <div className={`form-icon ${onIconClick ? 'form-icon-cursor' : ''}`} onClick={onIconClick}>{icon}</div>}
            <div className="form-error">{
                !!errorsToDisplay?.length
                    ? errorsToDisplay[0] + '. '
                    : <span className="form-warning">{!!warningsToDisplay?.length ? warningsToDisplay.join('. ') + '. ' : ''}</span>
            }
            </div>
        </div>
    );
}

export default TextInput;