import { useParams, useRouteError } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Error401 from '../assets/images/401.png';
import Error404 from '../assets/images/404.png';
import Button from '../components/ui/Button';

const style404 = {
    backgroundImage: `url(${Error404})`,
    backgroundPosition: 'bottom 0 right 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%'
}

const style401 = {
    backgroundImage: `url(${Error401})`,
    backgroundPosition: 'bottom 0 right 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%'
}


const Error = () => {
    const navigate = useNavigate();
    let error = useRouteError();
    const { code } = useParams();

    if (code === '401' || code === '403') {
        return (
            <div className="content" id="error" style={style401}>
                <span id="error-code">401</span>
                <span id="error-title">Non authorisé</span>
                <span id="error-subtitle">Vous n'avez pas les droits nécessaires pour accéder à cette page</span>
                <span id="error-text">Nous vous invitons à poursuivre votre navigation en retournant à l'accueil ou à la page précédente</span>
                <div>
                    <Button color="secondary" label="Page précédente" onClick={() => navigate(-1)} />
                    <Button label="Retour accueil" onClick={() => navigate('/')} />
                </div>
            </div>
        );
    } else if (code === '404') {
        return (
            <div className="content" id="error" style={style404}>
                <span id="error-code">404</span>
                <span id="error-title">Page introuvable</span>
                <span id="error-subtitle">La page que vous recherchez n'a pas été trouvée</span>
                <span id="error-text">Nous vous invitons à poursuivre votre navigation en retournant à l'accueil ou à la page précédente</span>
                <div>
                    <Button color="secondary" label="Page précédente" onClick={() => navigate(-1)} />
                    <Button label="Retour accueil" onClick={() => navigate('/')} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="content" id="error">
                <span id="error-title">Erreur</span>
                <span id="error-subtitle">Une erreur est survenue lors du chargement de la page</span>
                <span id="error-text">Nous vous invitons à poursuivre votre navigation en retournant à l'accueil ou à la page précédente. Si le problème persiste, contactez l'administrateur.</span>
                <div>
                    <Button color="secondary" label="Page précédente" onClick={() => navigate(-1)} />
                    <Button label="Retour accueil" onClick={() => navigate('/')} />
                </div>
            </div>
        );
    }
}

export default Error;