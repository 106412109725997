import { Icon } from '@iconify/react';
import { useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { FormBaseProps } from '../../../hooks/useForm';
import { ActionIcon } from '../../../utils/icons';
import TextInput from '../TextInput';
import './index.scss';

interface SearchInputProps extends FormBaseProps<string> {
    maxLength?: number,
    minLength?: number,
    placeholder?: string,
}

const SearchInput = ({
    id,
    value: valueFromProps,
    maxLength,
    minLength,
    placeholder,
    disabled,
    onChange,
}: SearchInputProps) => {
    const [value, setValue] = useState<string | undefined>(valueFromProps);
    useDebounce(() => {
        if (!value) {
            onChange(undefined);
        } else if (value.length >= (minLength ?? 3)) {
            onChange(value);
        }
    }, 500, [value]);

    return (
        <div className="search-input">
            <Icon icon={ActionIcon.SEARCH} />
            <TextInput
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={setValue}
                maxLength={maxLength}
                disabled={disabled}
            />
            {!!value && <Icon icon={ActionIcon.CLOSE} className="icon-button" onClick={() => setValue(undefined)} />}
        </div>
    );
}

export default SearchInput;