import { useCallback, useEffect, useState } from "react";
import NumberInput from "../../../../components/inputs/NumberInput";
import PanelRight from "../../../../components/ui/PanelRight";
import useForm, { FormComparator } from "../../../../hooks/useForm";
import { OuvrageElement } from "../../../../models/ouvrage";

export interface OuvrageTablierFormProps {
    tablier: OuvrageElement;
    onClose: () => void;
    onChange: (tablier: OuvrageElement) => void;
}

const VALIDATION = {
    'dimensions.length': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.width': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.height': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
}

const OuvrageTablierForm = ({ onClose, tablier, onChange }: OuvrageTablierFormProps) => {
    const [initial, setInitial] = useState<OuvrageElement>(tablier);
    const { entity, attachInput, setEntity, validate } = useForm<OuvrageElement>(tablier);

    const handleClose = useCallback(() => {
        onChange(initial);
        onClose();
    }, [initial]);

    const handleSubmit = useCallback(() => {
        if (validate(VALIDATION)) {
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        const entityValidated = validate(VALIDATION);
        if (entityValidated) onChange(entityValidated);
    }, [entity]);

    return (
        <PanelRight
            title="Tablier"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: handleClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit }
            ]}
        >
            <div className="input-column">
                <label htmlFor="length">Longueur *</label>
                <NumberInput {...attachInput('dimensions.length')} />
            </div>
            <div className="input-column">
                <label htmlFor="width">Largeur *</label>
                <NumberInput {...attachInput('dimensions.width')} />
            </div>
            <div className="input-column">
                <label htmlFor="height">Epaisseur *</label>
                <NumberInput {...attachInput('dimensions.height')} />
            </div>
        </PanelRight>
    )
}
export default OuvrageTablierForm;