import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ValidationCard from '../../../components/entities/ValidationCard';
import FileTile from '../../../components/FileTile';
import Card from '../../../components/ui/Card';
import Dropdown from '../../../components/ui/Dropdown';
import Menu from '../../../components/ui/Menu';
import { ModalRight } from '../../../components/ui/Modal/ModalRight';
import Tag from '../../../components/ui/Tag';
import { AnalysisIcon } from '../../../models/analysis';
import { Marker, PATHOLOGIES } from '../../../models/marker';
import { Phases, RoadPositionLabel } from '../../../models/operation';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import { formatDate } from '../../../utils/format';
import { ActionIcon } from '../../../utils/icons';
import { floatToPrString } from '../../../utils/pr';

interface MarkerDetailProps {
    id?: string;
    marker?: Marker;
    onClose: (refresh?: boolean) => void;
}

const MarkerDetail = ({
    id,
    marker: markerFromProps,
    onClose,
}: MarkerDetailProps) => {
    const { operation, workspacePermissions } = useWorkspace();
    const [marker, setMarker] = useState<Marker | null>(markerFromProps ?? null);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getRequest<Marker>(`/marker/${id}/images`, { errorMessage: 'Une erreur est survenue lors de la récupération du marker' })
                .then(setMarker)
                .catch(() => onClose());
        } else if (markerFromProps) {
            setMarker(markerFromProps);
        } else {
            setMarker(null);
            onClose();
        }
    }, [id, markerFromProps]);

    if (!marker) {
        return (null);
    }

    return (
        <ModalRight
            visible={!!marker}
            title={marker?.name}
            actions={[{ label: 'Fermer', color: 'secondary', onClick: onClose }]}
            icon={workspacePermissions.write ? <Menu label="Editer" icon={ActionIcon.EDIT} onClick={() => navigate(`/operation/${operation?._id}/${marker?.phase}/point-d-interet/${marker?._id}`)} /> : undefined}
        >
            <div id="marker-detail">
                <Card>
                    <div id="marker-detail-header">
                        <div>
                            <span>{formatDate(marker.date)}</span>
                            <Tag value={marker.phase} items={Phases} />
                        </div>
                        <div id="road">{marker.location?.road ? operation.roadsObj?.[marker.location?.road]?.label : ''}</div>
                        {operation.synoptique === 1 && <div id="position">{floatToPrString(marker.location?.pr) ?? ''}, {marker.location?.roadPosition ? RoadPositionLabel[marker.location?.roadPosition] : ''}</div>}
                    </div>
                    {!!marker?.description && <span><strong>Description : </strong>{marker.description}</span>}
                </Card>
                <ValidationCard
                    entity={marker}
                    canValidate={workspacePermissions.validate}
                    endpoint="/marker"
                    onSubmit={() => onClose(true)}
                />
                {!!marker.filesPopulated?.length && (
                    <Card title="Fichiers">
                        <div id="marker-files">
                            {marker.filesPopulated?.map(f => <FileTile key={f._id} file={f} />)}
                        </div>
                    </Card>
                )}
                <Card title="Pathologies">
                    {PATHOLOGIES.map(p => (
                        <Dropdown
                            title={
                                <div className="pathology-title">
                                    {p.items.some(item => marker.pathologies?.[item.key]?.problematic) && <Icon icon="mdi:warning-outline" className="icon-small color-error" />}
                                    {p.items.some(item => marker.pathologies?.[item.key]) && <Icon icon={AnalysisIcon} />}
                                    {p.label}
                                </div>
                            }
                            key={p.key}
                        >
                            {p.items.filter(item => marker.pathologies?.[item.key]).map(item => (
                                <div key={item.key} className={`pathology-item ${marker.pathologies[item.key].problematic ? 'problematic' : ''}`}>
                                    {marker.pathologies[item.key].problematic && <Icon icon="mdi:warning-outline" className="icon-small color-error" />}
                                    {item.label} : {marker.pathologies[item.key].value}{item.unit}
                                </div>
                            ))}
                        </Dropdown>
                    ))}
                </Card>
            </div>
        </ModalRight>
    )
}

export default MarkerDetail;