import { Icon } from "@iconify/react";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../../services/request.service";
import { deleteToast } from "../../../services/slices/ui.slice";
import { RootState } from "../../../services/store";
import { Textarea } from "../../inputs/Textarea";
import Collapse from "../Collapse";
import { Modal } from "../Modal";
import './index.scss';

export type ToastError = {
    _id?: string;
    error: any;
}

export type ToastData = {
    timestamp: number;
    type: 'error' | 'warning' | 'success';
    message: string;
    error?: ToastError;
}

interface ToastProps {
    toast: ToastData | null;
    onClose: () => void;
}

const Toast = ({
    toast,
    onClose
}: ToastProps) => {
    const timeout = useRef<NodeJS.Timeout | null>(null)
    const [currentToast, setCurrentToast] = useState<ToastData | null>(null);
    const [errorToNotify, setErrorToNotify] = useState<ToastError | null>(null);
    const [notifyMessage, setNotifyMessage] = useState<string | null>(null);

    const handleNotify = useCallback(async (e: ToastError, message?: string) => {
        postRequest('/message/notify-admin', { error: e.error, loggingId: e._id, message }, { loader: true, errorMessage: 'Une erreur est survenue pendant l\'envoi de la notification.', successMessage: 'Notification envoyée aux administrateurs' })
            .then(() => {
                setNotifyMessage(null);
                setErrorToNotify(null);
            })
            .catch(() => null);
    }, []);

    const clear = () => timeout.current ? clearTimeout(timeout.current) : undefined;
    const start = (limit = 5000) => {
        if (timeout?.current) {
            clear();
        }
        timeout.current = setTimeout(onClose, limit)
    }

    const handleClose = (e: React.MouseEvent<any>, toastError?: ToastData) => {
        e.stopPropagation();
        clear();

        if (toastError) {
            setErrorToNotify({
                _id: toastError.error?._id,
                error: JSON.stringify({ errorMessage: toastError.message, ...toastError.error }, null, 2)
            });
        }
        onClose();
    }

    useEffect(() => {
        if (toast) {
            setCurrentToast(toast);
            clear();
            start();
        }
        return clear;
    }, [toast]);

    return (
        <Fragment>
            <div className={`toast ${toast ? `toast-${toast.type} active` : ''}`} onMouseOver={clear} onMouseLeave={() => start(1000)} onClick={(e) => handleClose(e, currentToast?.type === 'error' ? currentToast : undefined)}>
                {currentToast?.type === 'success' ? <Icon icon="mdi:success-circle" /> : <Icon icon="mdi:error" />}
                <div>
                    <span>{currentToast?.message}</span>
                    {currentToast?.type === 'error' && <span>Cliquer ici pour notifier l'administrateur</span>}
                </div>
                <Icon icon="mdi:close" className="icon-small icon-button" onClick={(e) => handleClose(e)} />
            </div>
            {errorToNotify && (
                <Modal
                    title="Notifier l'incident"
                    className="modal-notify"
                    actions={[
                        { color: 'secondary', label: 'Annuler', onClick: () => setErrorToNotify(null) },
                        { color: 'primary', label: 'Confirmer', onClick: () => handleNotify(errorToNotify, notifyMessage ?? undefined) }
                    ]}
                >
                    <p className="info">
                        Envoyer un message à l'administrateur pour le notifier d'une erreur bloquante. Détailler au maximum le scénario à reproduire
                    </p>
                    {errorToNotify.error && (
                        <Collapse header="Détail de l'erreur">
                            <div className="print-error"><pre>{errorToNotify.error}</pre></div>
                        </Collapse>
                    )}
                    <Textarea
                        id="notifyMessage"
                        value={notifyMessage ?? undefined}
                        rows={5}
                        placeholder="Message optionnel à ajouter à l'email"
                        onChange={(value) => setNotifyMessage(value ?? '')}
                    />
                </Modal>
            )}
        </Fragment>
    );
}

export const ToastQueue = () => {
    const { toasts } = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();

    if (!toasts.length) return null;

    return (
        <div id="toast-queue">
            {toasts.map(t => <Toast key={t.timestamp} toast={t} onClose={() => dispatch(deleteToast(t.timestamp))} />)}
        </div>
    )
}

export default Toast;