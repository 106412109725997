import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import Index from './pages';
import store from './services/store';
import './styles/index.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <Index />
    </Provider>
);
