import { Fragment, useCallback } from 'react';
import { SynoptiqueFilters } from '..';
import CheckboxList from '../../../../components/inputs/CheckboxList';
import Switch from '../../../../components/inputs/Switch';
import Toggle from '../../../../components/inputs/Toggle';
import AskAccess from '../../../../components/ui/AskAccess';
import Dropdown from '../../../../components/ui/Dropdown';
import { CoucheType, CoucheTypes } from '../../../../models/lot';
import { MelangeType, MelangeTypes } from '../../../../models/melange';
import { Phase, Phases, RoadPosition, RoadPositionsList } from '../../../../models/operation';
import { PopulationType, PopulationTypes } from '../../../../models/population';
import { SampleType, SampleTypesEtude } from '../../../../models/sample';
import { Permission } from '../../../../models/user';
import useWorkspace from '../../../../services/hooks/use-workspace';
import { getRequest } from '../../../../services/request.service';

interface FiltersProps {
    filters?: SynoptiqueFilters;
    withProblematic?: boolean;
    isSynoptique?: boolean;
    onChange: (f: SynoptiqueFilters) => void;
}

const Filters = ({
    filters,
    withProblematic = false,
    isSynoptique = false,
    onChange
}: FiltersProps) => {
    const { operation, workspacePermissions } = useWorkspace();

    const handleRealTimeSwitch = useCallback(async (value?: boolean) => {
        if (value) {
            getRequest(`/operation/${operation?._id}/compile-elements`, { errorMessage: 'Une erreur est survenue lors de la récupération des éléments', loader: true })
                .catch(() => null);
        }

        onChange({ ...filters, realtime: value })
    }, [operation, filters]);

    return (
        <Fragment>
            {!workspacePermissions.filter && <AskAccess permission={Permission.FILTER} />}
            <div id="synoptique-filters" className={!workspacePermissions.filter ? 'blur' : ''}>
                <div className="filters-title"><h2>Général</h2></div>
                {isSynoptique && (
                    <div className={`input-container ${filters?.realtime !== undefined ? 'active' : ''}`}>
                        <label>Données compilées</label>
                        <Switch<boolean>
                            id="realtime"
                            items={[{ key: true, label: 'Oui' }, { key: false, label: 'Non' }]}
                            value={filters?.realtime ?? false}
                            onChange={handleRealTimeSwitch}
                        />
                    </div>
                )}
                <Dropdown
                    title="Phase"
                    className={filters?.phase?.length ? 'active' : ''}
                >
                    <CheckboxList
                        id="phase"
                        value={filters?.phase}
                        items={Phases.filter(p => operation.phases.includes(p.key))}
                        onChange={(phase) => onChange({ ...filters, phase: phase as Phase[] | undefined })}
                    />
                </Dropdown>
                {operation.synoptique === 1 && (
                    <Fragment>
                        <div className="filters-title"><h2>Localisation</h2></div>
                        <Dropdown
                            title="Voie"
                            className={filters?.road?.length ? 'active' : ''}
                        >
                            <CheckboxList
                                id="road"
                                value={filters?.road}
                                items={operation.roads}
                                onChange={(road) => onChange({ ...filters, road })}
                            />
                        </Dropdown>
                        <Dropdown
                            title="Position"
                            className={filters?.roadPosition?.length ? 'active' : ''}
                        >
                            <CheckboxList
                                id="roadPosition"
                                value={filters?.roadPosition}
                                items={RoadPositionsList}
                                onChange={(roadPosition) => onChange({ ...filters, roadPosition: roadPosition as RoadPosition[] | undefined })}
                            />
                        </Dropdown>
                    </Fragment>
                )}
                {operation.phases.includes(Phase.TRAVAUX) && (
                    <Fragment>
                        <div className="filters-title">
                            <h2>Lots</h2>
                            <Toggle
                                id="lotDisplay"
                                value={filters?.lotDisplay !== false}
                                onChange={(lotDisplay) => onChange({ ...filters, lotDisplay })}
                            />
                        </div>
                        <Dropdown
                            title="Lot"
                            className={filters?.fullLot?.length ? 'active' : ''}
                        >
                            <CheckboxList
                                id="fullLot"
                                value={filters?.fullLot}
                                endpoint={`/lot/distinct/${operation._id}`}
                                onChange={(fullLot) => onChange({ ...filters, fullLot })}
                            />
                        </Dropdown>
                        <Dropdown
                            title="Matériau"
                            className={filters?.material?.length ? 'active' : ''}
                        >
                            <CheckboxList
                                id="material"
                                value={filters?.material}
                                endpoint={`/material/list/product/${operation._id}`}
                                onChange={(material) => onChange({ ...filters, material })}
                            />
                        </Dropdown>
                        <Dropdown
                            title="Type de couche"
                            className={filters?.coucheType?.length ? 'active' : ''}
                        >
                            <CheckboxList
                                id="coucheType"
                                value={filters?.coucheType}
                                items={CoucheTypes}
                                onChange={(coucheType) => onChange({ ...filters, coucheType: coucheType as CoucheType[] | undefined })}
                            />
                        </Dropdown>
                    </Fragment>
                )}
                <div className="filters-title">
                    <h2>Echantillons</h2>
                    <Toggle
                        id="sampleDisplay"
                        value={filters?.sampleDisplay !== false}
                        onChange={(sampleDisplay) => onChange({ ...filters, sampleDisplay })}
                    />
                </div>
                <Dropdown
                    title="Type"
                    className={filters?.sampleType?.length ? 'active' : ''}
                >
                    <CheckboxList
                        id="sampleType"
                        value={filters?.sampleType}
                        items={SampleTypesEtude}
                        onChange={(sampleType) => onChange({ ...filters, sampleType: sampleType as SampleType[] | undefined })}
                    />
                </Dropdown>
                <div className={`input-container ${filters?.sampleDiameter !== undefined ? 'active' : ''}`}>
                    <label>Diamètre de carottage (mm)</label>
                    <input type="number" value={filters?.sampleDiameter} onChange={(e) => onChange({ ...filters, sampleDiameter: !isNaN(Number(e.target.value)) ? Number(e.target.value) : undefined })} />
                </div>
                <div className={`input-container ${filters?.sampleAnalysis !== undefined ? 'active' : ''}`}>
                    <label>Avec analyse</label>
                    <Switch
                        id="sampleAnalysis"
                        items={[{ key: true, label: 'Oui' }, { key: false, label: 'Non' }, { key: undefined, label: 'Tout' }]}
                        value={filters?.sampleAnalysis}
                        onChange={(sampleAnalysis) => onChange({ ...filters, sampleAnalysis })}
                    />
                </div>
                {withProblematic && (
                    <div className={`input-container ${filters?.sampleProblematic !== undefined ? 'active' : ''}`}>
                        <label>Problématique</label>
                        <Switch
                            id="sampleProblematic"
                            items={[{ key: true, label: 'Oui' }, { key: false, label: 'Non' }, { key: undefined, label: 'Tout' }]}
                            value={filters?.sampleProblematic}
                            onChange={(sampleProblematic) => onChange({ ...filters, sampleProblematic })}
                        />
                    </div>
                )}
                {withProblematic && (
                    <div className={`input-container ${filters?.previousSampleDisplay !== undefined ? 'active' : ''}`}>
                        <label>Anciens échantillons</label>
                        <Switch
                            id="previousSampleDisplay"
                            items={[{ key: true, label: 'Oui' }, { key: false, label: 'Non' }]}
                            value={filters?.previousSampleDisplay === true}
                            onChange={(previousSampleDisplay) => onChange({ ...filters, previousSampleDisplay })}
                        />
                    </div>
                )}
                {(operation.phases.includes(Phase.ETUDE) || operation.phases.includes(Phase.PREPARATOIRE)) && (
                    <Fragment>
                        <div className="filters-title">
                            <h2>Mélanges</h2>
                            <Toggle
                                id="melangeDisplay"
                                value={filters?.melangeDisplay !== false}
                                onChange={(melangeDisplay) => onChange({ ...filters, melangeDisplay })}
                            />
                        </div>
                        <Dropdown
                            title="Type"
                            className={filters?.melangeType?.length ? 'active' : ''}
                        >
                            <CheckboxList
                                id="melangeType"
                                value={filters?.melangeType}
                                items={MelangeTypes}
                                onChange={(melangeType) => onChange({ ...filters, melangeType: melangeType as MelangeType[] | undefined })}
                            />
                        </Dropdown>
                        {withProblematic && (
                            <div className={`input-container ${filters?.melangeProblematic !== undefined ? 'active' : ''}`}>
                                <label>Problématique</label>
                                <Switch
                                    id="melangeProblematic"
                                    items={[{ key: true, label: 'Oui' }, { key: false, label: 'Non' }, { key: undefined, label: 'Tout' }]}
                                    value={filters?.melangeProblematic}
                                    onChange={(melangeProblematic) => onChange({ ...filters, melangeProblematic })}
                                />
                            </div>
                        )}
                    </Fragment>
                )}
                {operation.phases.includes(Phase.TRAVAUX) && (
                    <Fragment>
                        <div className="filters-title">
                            <h2>Populations</h2>
                            <Toggle
                                id="populationDisplay"
                                value={filters?.populationDisplay !== false}
                                onChange={(populationDisplay) => onChange({ ...filters, populationDisplay })}
                            />
                        </div>
                        <Dropdown
                            title="Type"
                            className={filters?.populationType?.length ? 'active' : ''}
                        >
                            <CheckboxList
                                id="populationType"
                                value={filters?.populationType}
                                items={PopulationTypes}
                                onChange={(populationType) => onChange({ ...filters, populationType: populationType as PopulationType[] | undefined })}
                            />
                        </Dropdown>
                        {withProblematic && (
                            <div className={`input-container ${filters?.populationProblematic !== undefined ? 'active' : ''}`}>
                                <label>Problématique</label>
                                <Switch
                                    id="populationProblematic"
                                    items={[{ key: true, label: 'Oui' }, { key: false, label: 'Non' }, { key: undefined, label: 'Tout' }]}
                                    value={filters?.populationProblematic}
                                    onChange={(populationProblematic) => onChange({ ...filters, populationProblematic })}
                                />
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}

export default Filters;