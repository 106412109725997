import { ReactNode } from 'react';
import './index.scss';
import Button, { ButtonProps } from '../Button';

interface ModalProps {
    children: ReactNode;
    title?: string;
    className?: string;
    size?: 'small' | 'medium' | 'large';
    actions?: ButtonProps[];
    id?: string;
}

export const Modal = ({
    children,
    title,
    className,
    size,
    actions,
    id
}: ModalProps) => (
    <div className="modal-backdrop">
        <div className={`modal ${className ?? ''} modal-${size ?? 'medium'}`}>
            {title && <div className="modal-title">{title}</div>}
            <div className="modal-content" id={id}>
                {children}
            </div>
            <div className="modal-footer">
                {actions?.map(a => <Button key={a.label} {...a} />)}
            </div>
        </div>
    </div>
);
