import { FormBaseProps } from "../../../hooks/useForm";
import './index.scss';

interface CheckboxProps extends FormBaseProps<boolean> {
    label?: string;
    className?: string;
    disabled?: boolean;
}

const Checkbox = ({
    id,
    value,
    label,
    className,
    onChange,
    disabled
}: CheckboxProps) => (
    <div
        className={`checkbox ${value ? 'checked' : ''} ${className ?? ''}`}
        onClick={typeof onChange === 'function' && !disabled ? (e) => { e.stopPropagation(); onChange(!value, label); } : undefined}
    >
        <input
            type="checkbox"
            checked={value ?? false}
            onChange={typeof onChange === 'function' && !disabled ? (e) => { e.stopPropagation(); onChange(!value, label); } : () => null}
            id={id}
            name={id}
        />
        <div className="mark" />
        {!!label && <span>{label}</span>}
    </div>
);

export default Checkbox;