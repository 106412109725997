import { Fragment, useCallback, useState } from 'react';
import ModalStandard from '../../components/ModalStandard';
import StatisticsPicker from '../../components/data/Statistics/StatisticsPicker';
import { IdentityCardMaterial } from '../../components/entities/IdentityCard';
import DatePickerInput from '../../components/inputs/DatePicker';
import { MultipleSelect } from '../../components/inputs/Select';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Header from '../../components/ui/Header';
import Menu from '../../components/ui/Menu';
import NoResult from '../../components/ui/NoResult';
import ScrollableContent from '../../components/ui/ScrollableContent';
import useForm from '../../hooks/useForm';
import { AnalysisStatistics } from '../../models/analysis';
import { Material, MaterialsWithProducer } from '../../models/material';
import { Permission } from '../../models/user';
import useAuth from '../../services/hooks/use-auth.hook';
import { getRequest } from '../../services/request.service';
import { ActionIcon } from '../../utils/icons';
import './index.scss';

const INFO = "La recherche de caractéristique permet d'afficher les résultats d'analyse pour un matériau donné et une fourchette temporelle optionnelle, et ce sur l'ensemble des opérations suivies par OSO Numérique.\n\nLes résultats sont présentés par caractéristique, puis par entreprise (fournisseur ou producteur) et type de contrôle (extérieur, externe ou autre).";

const FindCharacteristic = () => {
    const { permissions } = useAuth();
    const [standard, setStandard] = useState<Material | null>(null);
    const [modalStandardVisible, setModalStandardVisible] = useState<boolean>(false);
    const [statistics, setStatistics] = useState<AnalysisStatistics[] | null>(null);
    const { entity, attachInput, setEntity } = useForm<{ businesses: string[], from: Date, to: Date }>({});

    const clear = () => {
        setEntity({});
        setStandard(null);
        setStatistics(null);
    }

    const handleSearch = useCallback(async () => {
        if (!standard) return;

        const params = { type: standard.type, material: standard._id, ...(entity ?? {}) };
        getRequest<AnalysisStatistics[]>('/analysis/search', { params, loader: true, errorMessage: 'Une erreur est survenue lors de la recherche.' })
            .then(setStatistics)
            .catch(() => setStatistics(null));
    }, [standard]);


    return (
        <Fragment>
            <Header withBorder breadcrumbs={[{ label: 'Bibliothèque', href: '/bibliotheque' }, { label: 'Recherche de caractéristique' }]}>
                <Button color="secondary" label="Réinitialiser" onClick={clear} />
                <Button color="primary" icon={ActionIcon.SEARCH} label="Rechercher" onClick={handleSearch} />
            </Header>
            <ScrollableContent
                side={<span className="info">{INFO}</span>}
            >
                <Card title="Matériau" actions={<Menu icon={ActionIcon.SEARCH} label="Sélectionner" onClick={() => setModalStandardVisible(true)} />}>
                    {!!standard && <IdentityCardMaterial entity={standard} />}
                </Card>
                <Card title="Paramètres">
                    <div className="row">
                        {standard?.type && MaterialsWithProducer.includes(standard?.type) ? (
                            <div className="input-column">
                                <label htmlFor="businesses">Producteur</label>
                                <MultipleSelect endpoint="/business/list/producer" {...attachInput('businesses')} />
                            </div>
                        ) : (
                            <div className="input-column">
                                <label htmlFor="businesses">Fournisseur</label>
                                <MultipleSelect endpoint="/business/list/provider" {...attachInput('businesses')} />
                            </div>
                        )}
                        <div className="input-column">
                            <label htmlFor="from">Depuis</label>
                            <DatePickerInput withIcon {...attachInput('from')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="to">Jusqu'à</label>
                            <DatePickerInput withIcon {...attachInput('to')} />
                        </div>
                    </div>
                </Card>
                {!!statistics?.length && (
                    <Card title="Résultats">
                        <StatisticsPicker
                            statistics={statistics}
                            materialType={standard?.type}
                            subtitle="Résultats de bibliothèque"
                            columns={['date', 'operation', 'material', 'business', 'controle']}
                            chartAxis="date"
                            chartByOptions={['controle', 'business']}
                            isAuthorized={permissions.library}
                            permission={Permission.LIBRARY}
                            isLibrary={true}
                        />
                    </Card>
                )}
                {statistics && !statistics.length && (
                    <NoResult
                        text="Aucun résultat pour ces paramètres"
                    />
                )}
                {modalStandardVisible && <ModalStandard onSubmit={setStandard} onClose={() => setModalStandardVisible(false)} />}
            </ScrollableContent>
        </Fragment>
    )
}

export default FindCharacteristic;