import { Fragment } from 'react';
import { useVisualisation } from '..';
import { CoordinatesXYZ } from '../../../../models/location';
import { markerToCoordinates } from '../functions';

export interface VisualisationLinksProps {
    from: CoordinatesXYZ;
    to: { _id: string, coordinates: CoordinatesXYZ }[];
}

const VisualisationLinks = ({ from, to }: VisualisationLinksProps) => {
    const state = useVisualisation();
    const origin = markerToCoordinates(from, state.view);

    return (
        <Fragment>
            {to.map(point => {
                const end = markerToCoordinates(point.coordinates, state.view);
                return (
                    <line
                        key={point._id}
                        className="visualization-link"
                        x1={origin.x}
                        y1={origin.y}
                        x2={end.x}
                        y2={end.y}
                        strokeWidth={2 / state.zoom}
                    />
                )
            })}
        </Fragment>
    )
}

export default VisualisationLinks;