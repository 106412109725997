import { Fragment } from 'react/jsx-runtime';
import DocumentList from '../../DocumentList';
import ImageList from '../../ImageList';
import Card from '../../ui/Card';

export interface DocumentsPageProps {
    entityId?: string;
    url?: string;
    onChange?: (type: string, files: string[]) => void;
}

const DocumentsPage = ({ entityId, url, onChange }: DocumentsPageProps) => {
    return (
        <Fragment>
            <Card title="Images">
                <ImageList
                    entityId={entityId}
                    url={entityId ? `${url}/images` : undefined}
                    editable
                    onChange={onChange ? (images) => onChange('images', images) : undefined}
                />
            </Card>
            <Card title="Documents">
                <DocumentList
                    entityId={entityId}
                    url={entityId ? url : undefined}
                    editable
                    onChange={onChange ? (documents) => onChange('documents', documents) : undefined}
                />
            </Card>
        </Fragment>
    )
}
export default DocumentsPage;