import { Fragment, useState } from 'react';
import Button from '../../../components/ui/Button';
import { Data, Population } from '../../../models/population';
import { Controle } from '../../../models/sample';
import useWorkspace from '../../../services/hooks/use-workspace';
import { ActionIcon } from '../../../utils/icons';
import RoadDataSheet from './RoadDataSheet';
import ZoneDataSheet from './ZoneDataSheet';
import './index.scss';

interface DataFormProps {
    population: Partial<Population>;
    controle: Controle;
    onChange: (data: Data[]) => void;
}

const DataForm = ({
    population,
    controle,
    onChange,
}: DataFormProps) => {
    const { operation } = useWorkspace();
    const [isDataModalVisible, setDataModalVisible] = useState<boolean>(false);

    return (
        <Fragment>
            <Button label="Editer les données" icon={ActionIcon.EDIT} onClick={() => setDataModalVisible(true)} />
            {isDataModalVisible && operation.synoptique === 1 && (
                <RoadDataSheet
                    population={population}
                    controle={controle}
                    onSubmit={onChange}
                    onClose={() => setDataModalVisible(false)}
                />
            )}
            {isDataModalVisible && operation.synoptique !== 1 && (
                <ZoneDataSheet
                    population={population}
                    controle={controle}
                    onSubmit={onChange}
                    onClose={() => setDataModalVisible(false)}
                />
            )}
        </Fragment >
    )
}

export default DataForm;