import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Header from '../../../components/ui/Header';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { Lot } from '../../../models/lot';
import { SampleLabels, SampleType } from '../../../models/sample';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import './index.scss';
import SampleMasseAnalysis from './SampleMasseAnalysis';

const EditMultiple = () => {
    const { operation } = useWorkspace();
    const { lotId, sampleType } = useParams();
    const navigate = useNavigate();
    const [lot, setLot] = useState<Lot | null>(null)

    useEffect(() => {
        getRequest<Lot>(`/lot/${lotId}`, { errorMessage: 'Une erreur est survenue lors de la récupération du lot.', loader: true })
            .then(setLot)
            .catch(() => navigate(-1));
    }, []);

    if (!lot) {
        return (null);
    }

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation?.name, href: `/operation/${operation?._id}` },
                    { label: 'Travaux', href: `/operation/${operation?._id}/travaux` },
                    { label: lot?._id ? 'Lot ' + lot?.fullLot : 'Lot', href: `/operation/${operation?._id}/travaux/lot/${lot?._id}/detail` },
                    { label: SampleLabels[sampleType as SampleType].masse }
                ]}
                withBorder
            >
                <Button label="Fermer" onClick={() => navigate(-1)} />
            </Header>
            <ScrollableContent noPadding id="sample-masse-analysis">
                <SampleMasseAnalysis
                    roads={operation.roads.filter(r => lot?.roads.includes(r.key))}
                    lot={lot}
                    type={sampleType as SampleType}
                />
            </ScrollableContent>
        </Fragment>
    );
}

export default EditMultiple;