import {
    LocatedEntity,
    OperationLocation,
    OperationZone,
    PrCoordinates,
} from "../models/location";
import { Coordinates } from "./../models/location";

export const getBoundingBox = <T extends LocatedEntity>(
    entities: T[]
): [[number, number], [number, number]] => {
    const lngs = entities
        .map((e) =>
            Object.hasOwn(e, "longitude")
                ? (e as { longitude: number }).longitude
                : Object.hasOwn(e, "line")
                ? (e as { line: PrCoordinates[] }).line.map((c) => c.longitude)
                : Object.hasOwn(e, "location")
                ? (e as { location: OperationLocation }).location.coordinates
                      ?.longitude
                : (e as { zone: OperationZone }).zone.polygon?.map(
                      (p) => p.longitude
                  )
        )
        .flat()
        .filter((l) => !!l) as number[];
    const lats = entities
        .map((e) =>
            Object.hasOwn(e, "latitude")
                ? (e as { latitude: number }).latitude
                : Object.hasOwn(e, "line")
                ? (e as { line: PrCoordinates[] }).line.map((c) => c.latitude)
                : Object.hasOwn(e, "location")
                ? (e as { location: OperationLocation }).location.coordinates
                      ?.latitude
                : (e as { zone: OperationZone }).zone.polygon?.map(
                      (p) => p.latitude
                  )
        )
        .flat()
        .filter((l) => !!l) as number[];

    return [
        [Math.min(...lngs), Math.min(...lats)],
        [Math.max(...lngs), Math.max(...lats)],
    ];
};

export const getPolygonCentroid = (polygon: Coordinates[]) => {
    const lngs = polygon.map((p) => p.longitude);
    const lats = polygon.map((p) => p.latitude);

    return {
        longitude: (Math.max(...lngs) + Math.min(...lngs)) / 2,
        latitude: (Math.max(...lats) + Math.min(...lats)) / 2,
    };
};
