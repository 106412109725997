import { useCallback } from 'react';
import Map, { MapAction } from '../../../components/data/Map';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { FormHookReturn } from '../../../hooks/useForm';
import { Coordinates } from '../../../models/location';
import { OperationDto } from '../../../models/operation';
import './index.scss';

interface ZoneProps extends Pick<FormHookReturn<OperationDto>, 'entity' | 'onChange' | 'attachInput'> { }

const Zone = ({
    entity,
    onChange,
}: ZoneProps) => {
    const handlePolygonChange = useCallback((marker: Coordinates, polygon?: Coordinates[]) => {
        onChange('zone', { ...entity.zone, marker, polygon });
    }, [entity, onChange]);

    return (
        <ScrollableContent noPadding id="operation-zone">
            <Map
                onActionComplete={(_, data) => handlePolygonChange(data.marker, data.polygon)}
                availableActions={[MapAction.POLYGON, MapAction.STYLE, MapAction.POSITION]}
                initialAction={MapAction.POLYGON}
                polygon={entity.zone?.polygon}
            />
        </ScrollableContent>
    )
}

export default Zone;