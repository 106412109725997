import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IdentityCardMaterial } from '../../../components/entities/IdentityCard';
import Select from '../../../components/inputs/Select';
import Card from '../../../components/ui/Card';
import Header from '../../../components/ui/Header';
import Printable from '../../../components/ui/Printable';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { Summary } from '../../../models/operation';
import { PopulationTypes } from '../../../models/population';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import { formatNumberToFixedDecimal } from '../../../utils/format';

const WorkSummary = () => {
    const { operation } = useWorkspace();
    const [summary, setSummary] = useState<{ [k: string]: Summary } | null>(null);
    const [granulometries, setGranulometries] = useState<number[] | null>(null);
    const [selectedMaterialId, setSelectedMaterialId] = useState<string | null>(null);
    const [selectedMaterialSummary, setSelectedMaterialSummary] = useState<Summary | null>(null);
    const navigate = useNavigate();


    useEffect(() => {
        if (selectedMaterialId && summary) {
            const materialSummary = summary[selectedMaterialId];
            setSelectedMaterialSummary(materialSummary);
            setGranulometries(Object.keys(materialSummary.statistics?.granulometrie ?? {}).map(g => Number(g)).sort((k1, k2) => k1 > k2 ? 1 : -1));
        } else {
            setGranulometries(null);
        }
    }, [selectedMaterialId]);

    useEffect(() => {
        getRequest<{ [k: string]: Summary }>(`/operation/${operation._id}/summary`, { loader: true, errorMessage: 'Une erreur est survenue lors de la récupération des données' })
            .then((data) => {
                setSummary(data);
                if (Object.keys(data)[0]) {
                    setSelectedMaterialId(Object.keys(data)[0])
                }
            })
            .catch(() => navigate(-1));
    }, [operation]);

    if (!summary) {
        return (null);
    }

    return (
        <Fragment>
            <Header withBorder breadcrumbs={[{ label: operation.name, href: `/operation/${operation._id}` }, { label: 'Bilan travaux' }]} />
            <ScrollableContent>
                <Card title="Paramètres">
                    <div className="input-column">
                        <label htmlFor="selectedMaterial">Sélectionner un matériau</label>
                        <Select
                            id="selectedMaterial"
                            value={selectedMaterialId ?? undefined}
                            items={Object.keys(summary).map(key => ({ key, label: summary[key].material?.name ?? '' }))}
                            onChange={(value) => setSelectedMaterialId(value ?? null)}
                        />
                    </div>
                </Card>
                {!!selectedMaterialSummary && (
                    <Card title="Matériau">
                        <IdentityCardMaterial entity={selectedMaterialSummary.material} />
                    </Card>
                )}
                <Card title="Résultats">
                    <div id="work-summary">
                        {selectedMaterialSummary && (
                            <Printable title={`Bilan de travaux - ${selectedMaterialSummary.material.name}`} subtitle={operation.name} filename="bilan-travaux" disposition="landscape">
                                <div className="data-table">
                                    <h3>{selectedMaterialSummary.material.name}</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td rowSpan={3}>Lot</td>
                                                <td rowSpan={3}>Sens</td>
                                                <td rowSpan={3}>Voies</td>
                                                <td rowSpan={3} style={{ borderRight: '#e1e1e4 2px solid' }}>Epaisseur</td>
                                                <td colSpan={(!!granulometries?.length ? granulometries.length : 1) * 3}>Granulométrie</td>
                                                <td rowSpan={2} colSpan={3} style={{ borderLeft: '#e1e1e4 2px solid', borderRight: '#e1e1e4 2px solid' }}>Teneur en liant</td>
                                                {PopulationTypes.map(p => <td key={p.key} style={{ borderRight: '#e1e1e4 2px solid' }} rowSpan={2} colSpan={3}>{p.label}</td>)}
                                            </tr>
                                            <tr>
                                                {granulometries?.length ? granulometries.map(g => <td style={{ borderRight: '#e1e1e4 1px solid' }} colSpan={3} key={g}>{g}</td>) : (<td colSpan={3} />)}
                                            </tr>
                                            <tr>
                                                {granulometries?.length ? granulometries.map(g => (
                                                    <Fragment key={g}>
                                                        <td>Min.</td>
                                                        <td>Moy.</td>
                                                        <td style={{ borderRight: '#e1e1e4 1px solid' }}>Max.</td>
                                                    </Fragment>
                                                )) : (
                                                    <Fragment>
                                                        <td>Min.</td>
                                                        <td>Moy.</td>
                                                        <td style={{ borderRight: '#e1e1e4 1px solid' }}>Max.</td>
                                                    </Fragment>)}
                                                <td style={{ borderLeft: '#e1e1e4 2px solid' }}>Min.</td>
                                                <td>Moy.</td>
                                                <td style={{ borderRight: '#e1e1e4 2px solid' }}>Max.</td>
                                                {PopulationTypes.map(p => (
                                                    <Fragment key={p.key}>
                                                        <td>Min.</td>
                                                        <td>Moy.</td>
                                                        <td style={{ borderRight: '#e1e1e4 2px solid' }}>Max.</td>
                                                    </Fragment>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedMaterialSummary.lots?.map(lotSummary => (
                                                <tr key={lotSummary.lot._id}>
                                                    <td>{lotSummary.lot.fullLot}</td>
                                                    <td>{lotSummary.lot.way}</td>
                                                    <td>{lotSummary.lot.roads?.map(r => operation.roadsObj[r]?.name ?? '').join(',') ?? ''}</td>
                                                    <td style={{ borderRight: '#e1e1e4 2px solid' }}>{lotSummary.lot.thickness}</td>
                                                    {granulometries?.length ? granulometries?.map(g => (
                                                        <Fragment key={g}>
                                                            <td>{formatNumberToFixedDecimal(lotSummary.statistics?.granulometrie?.[g]?.min) ?? ''}</td>
                                                            <td>{formatNumberToFixedDecimal(lotSummary.statistics?.granulometrie?.[g]?.average) ?? ''}</td>
                                                            <td style={{ borderRight: '#e1e1e4 1px solid' }}>{formatNumberToFixedDecimal(lotSummary.statistics?.granulometrie?.[g]?.max) ?? ''}</td>
                                                        </Fragment>
                                                    )) : (<Fragment>
                                                        <td>--</td>
                                                        <td>--</td>
                                                        <td style={{ borderRight: '#e1e1e4 1px solid' }}>--</td>
                                                    </Fragment>)}
                                                    <td style={{ borderLeft: '#e1e1e4 2px solid' }}>{formatNumberToFixedDecimal(lotSummary.statistics?.teneurLiantValInd?.min) ?? ''}</td>
                                                    <td>{formatNumberToFixedDecimal(lotSummary.statistics?.teneurLiantValInd?.average) ?? ''}</td>
                                                    <td style={{ borderRight: '#e1e1e4 2px solid' }}>{formatNumberToFixedDecimal(lotSummary.statistics?.teneurLiantValInd?.max) ?? ''}</td>
                                                    {PopulationTypes.map(p => (
                                                        <Fragment key={p.key}>
                                                            <td>{formatNumberToFixedDecimal(lotSummary.statistics?.[p.key]?.min) ?? ''}</td>
                                                            <td>{formatNumberToFixedDecimal(lotSummary.statistics?.[p.key]?.average) ?? ''}</td>
                                                            <td style={{ borderRight: '#e1e1e4 2px solid' }}>{formatNumberToFixedDecimal(lotSummary.statistics?.[p.key]?.max) ?? ''}</td>
                                                        </Fragment>
                                                    ))}
                                                </tr>
                                            ))}
                                            <tr style={{ borderTop: '#e1e1e4 2px solid' }}>
                                                <td colSpan={4} style={{ borderRight: '#e1e1e4 2px solid' }}>Opération</td>
                                                {granulometries?.map(g => (
                                                    <Fragment key={g}>
                                                        <td>{formatNumberToFixedDecimal(selectedMaterialSummary.statistics?.granulometrie?.[g]?.min) ?? ''}</td>
                                                        <td>{formatNumberToFixedDecimal(selectedMaterialSummary.statistics?.granulometrie?.[g]?.average) ?? ''}</td>
                                                        <td style={{ borderRight: '#e1e1e4 1px solid' }}>{formatNumberToFixedDecimal(selectedMaterialSummary.statistics?.granulometrie?.[g]?.max) ?? ''}</td>
                                                    </Fragment>
                                                ))}
                                                <td style={{ borderLeft: '#e1e1e4 2px solid' }}>{formatNumberToFixedDecimal(selectedMaterialSummary.statistics?.teneurLiantValInd?.min) ?? ''}</td>
                                                <td>{formatNumberToFixedDecimal(selectedMaterialSummary.statistics?.teneurLiantValInd?.average) ?? ''}</td>
                                                <td style={{ borderRight: '#e1e1e4 2px solid' }}>{formatNumberToFixedDecimal(selectedMaterialSummary.statistics?.teneurLiantValInd?.max) ?? ''}</td>
                                                {PopulationTypes.map(p => (
                                                    <Fragment key={p.key}>
                                                        <td>{formatNumberToFixedDecimal(selectedMaterialSummary.statistics?.[p.key]?.min) ?? ''}</td>
                                                        <td>{formatNumberToFixedDecimal(selectedMaterialSummary.statistics?.[p.key]?.average) ?? ''}</td>
                                                        <td style={{ borderRight: '#e1e1e4 2px solid' }}>{formatNumberToFixedDecimal(selectedMaterialSummary.statistics?.[p.key]?.max) ?? ''}</td>
                                                    </Fragment>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Printable>
                        )}
                    </div>
                </Card>
            </ScrollableContent>
        </Fragment>
    )
}

export default WorkSummary;