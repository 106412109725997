import { Icon } from '@iconify/react';
import saveAs from 'file-saver';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { StorageFile } from '../../models/storage';
import { deleteRequest, getRequest, postRequest } from '../../services/request.service';
import { addToast } from '../../services/slices/ui.slice';
import ModalDelete from '../ui/Modal/ModalDelete';
import './index.scss';


export interface DocumentListTileProps {
    file: StorageFile;
    onDelete?: () => void;
}

const DocumentListTile = ({ file, onDelete }: DocumentListTileProps) => {
    const dispatch = useDispatch();

    const icon = useMemo(() => {
        if (file.mimeType.startsWith('image')) return "mdi:file-image";
        if ([
            'text/csv',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.oasis.opendocument.spreadsheet'
        ].includes(file.mimeType)) {
            return "mdi:file-excel";
        }
        if ([
            'application/zip',
            'application/x-zip-compressed'
        ].includes(file.mimeType)) {
            return "mdi:folder-zip";
        }
        if ([
            'text/plain',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword'
        ].includes(file.mimeType)) {
            return "mdi:file-text";
        }
        if ([
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.oasis.opendocument.presentation'
        ].includes(file.mimeType)) {
            return "mdi:file-powerpoint";
        }
        if (['application/pdf'].includes(file.mimeType)) {
            return "mdi:file-pdf-box";
        }
        return "mdi:file";
    }, [file]);

    const handleDownload = async () => getRequest<string>(`/storage/${file._id}/download`, { responseType: 'blob', timeout: 30000, loader: true })
        .then((data) => {
            saveAs(data, file.originalFileName);
        })
        .catch((e) => {
            if (e?.error?.status === 404) {
                dispatch(addToast({ type: 'error', message: 'Le fichier n\'a pas été trouvé.', error: e as any }));
            } else {
                dispatch(addToast({ type: 'error', message: 'Une erreur est survenue lors du téléchargement.', error: e as any }));
            }
        });

    return (
        <div className="document-list-tile">
            <Icon icon={icon} className="icon-xxl" />
            <span>{file.originalFileName}</span>
            <div className="document-list-action">
                <Icon icon="mdi:file-download" className="icon-button icon-large" onClick={() => handleDownload()} />
                {!!onDelete && <Icon icon="mdi:trash-can-outline" className="icon-button icon-large" onClick={onDelete} />}
            </div>
        </div>
    )
}

export interface DocumentListProps {
    url?: string;
    entityId?: string;
    editable?: boolean;
    onChange?: (files: string[]) => void;
    column?: boolean;
}

const DocumentList = ({ editable, entityId, url, column, onChange }: DocumentListProps) => {
    const [documents, setDocuments] = useState<StorageFile[]>([]);
    const [documentToDelete, setDocumentToDelete] = useState<StorageFile>();
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (!acceptedFiles?.length) return;
            let data = new FormData();
            for (const file of acceptedFiles) {
                data.append('files[]', file);
            }
            if (entityId) {
                data.append('entityId', entityId);
            }
            data.append('image', 'false');

            postRequest<StorageFile[]>('/storage', data, { headers: { 'content-type': 'multipart/form-data' }, loader: true, errorMessage: 'Une erreur est survenue pendant le téléversement' })
                .then((files) => {
                    const _documents = [...files, ...documents];
                    setDocuments(_documents);
                    onChange?.(_documents.map(d => d._id));
                })
                .catch(() => null);
        },
        maxFiles: 20,
    });

    const handleDelete = async (file: StorageFile) => {
        if (!editable || !onChange) return;
        deleteRequest<StorageFile>(`/storage/${file._id}`, { loader: true, errorMessage: 'Une erreur est survenue pendant la suppression' })
            .then(() => {
                const _documents = documents.filter(i => i._id !== file._id);
                setDocuments(_documents);
                onChange?.(_documents.map(i => i._id));
            })
            .catch(() => null)
            .finally(() => setDocumentToDelete(undefined));
    };

    useEffect(() => {
        if (url && entityId) {
            getRequest<StorageFile[]>(url).then(setDocuments).catch(() => null);
        }
    }, [url, entityId]);

    return (
        <div className={`document-list ${column ? 'document-list-column' : ''}`}>
            {(!!editable || !!onChange) && (
                <div {...getRootProps({ className: 'document-list-add' })}>
                    <input {...getInputProps()} />
                    <Icon icon="mdi:file-document-add" className="icon-xxl" />
                    <div>
                        <div>Glissez votre document ici</div>
                        <span>ou</span>
                        <span className="link">recherchez un fichier</span>
                    </div>
                </div>
            )}
            {documents?.map(document => (
                <DocumentListTile key={document._id} file={document} onDelete={() => setDocumentToDelete(document)} />
            ))}
            {!!documentToDelete && (
                <ModalDelete onCancel={() => setDocumentToDelete(undefined)} onSubmit={() => handleDelete(documentToDelete)} />
            )}
        </div>
    )
}

export default DocumentList;