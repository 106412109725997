import { Operation } from "./operation";
import { Ouvrage } from "./ouvrage";
import { BasicEntity } from "./shared";
import { StorageFile } from "./storage";

export type Notification = BasicEntity & {
    type: NotificationType;
    operation?: string;
    operationPopulated?: Operation;
    ouvrage?: string;
    ouvragePopulated?: Ouvrage;
    file?: string;
    filePopulated?: StorageFile;
    users: string[];
    data?: any;
};

export enum NotificationType {
    FILE = "file",
    MESSAGE = "message",
    PERMISSION = "permission",
    INCIDENT = "incident",
    OPERATION_DATA = "operation_data",
    OPERATION_UPDATE = "operation_update",
    OPERATION_ADD = "operation_add",
    OUVRAGE_DIAGNOSTIC = "ouvrage_diagnostic",
}
