import { Fragment, useMemo } from 'react';
import { Blueprint } from '../../../../models/ouvrage';
import { barriereForVisualization, cuboidForVisualization, pileForVisualization } from '../functions';
import { VisualizationSelection } from '../types';
import Dimensions from './Dimensions';

export interface PontProps {
    blueprint: Blueprint;
    scale: number;
    view: string;
    onSelect: (element: VisualizationSelection) => void;
    selected?: VisualizationSelection;
}

const Pont = ({ blueprint, scale, view, onSelect, selected }: PontProps) => {

    const data = useMemo(() => {
        if (!blueprint.tablier) return;

        return {
            tablier: cuboidForVisualization({ ...blueprint.tablier, x: 0, y: 0, z: 0 }, view, scale, blueprint.tablier),
            barrieres: [...blueprint.barrieres ?? []].sort((p1, p2) => view === "front" ? (p1.y < p2.y ? 1 : -1) : p1.y < p2.y ? -1 : 1)
                .map(b => barriereForVisualization(b, view, scale, blueprint.tablier!)),
            piles: [...blueprint.piles ?? []].sort((p1, p2) => view === "front" ? (p1.y < p2.y ? 1 : -1) : p1.y < p2.y ? -1 : 1)
                .map(p => pileForVisualization({ ...p, z: blueprint.tablier?.height }, view, scale, blueprint.tablier!))
        }
    }, [blueprint, scale, view]);

    const dimensions = useMemo(() => {
        if (!data?.tablier) return;
        if (selected?.tablier) {
            return <Dimensions dimensions={data.tablier?.dimensions} bbox={data.tablier.bbox} />
        } else if (selected?.pile) {
            const selectedPile = data.piles?.find(p => p.initial._id === selected.pile?._id);
            if (selectedPile) {
                return <Dimensions dimensions={selectedPile.dimensions} bbox={selectedPile.bbox} />
            }
        } else if (selected?.barriere) {
            const selectedBarriere = data.barrieres?.find(p => p.initial._id === selected.barriere?._id);
            if (selectedBarriere) {
                return <Dimensions dimensions={selectedBarriere.dimensions} bbox={selectedBarriere.bbox} />
            }
        }
    }, [data, selected]);

    if (!data) return null;

    return (
        <Fragment>
            {view !== 'top' && data?.barrieres?.map(barriere => (
                <path key={barriere.initial._id} className={`visualization-element barriere ${barriere.initial.type ?? ''} ${selected?.barriere?._id === barriere.initial._id ? 'selected' : ''}`} d={barriere.path} fillRule="nonzero" onClick={selected?.barriere?._id !== barriere.initial._id ? () => onSelect({ barriere: barriere.initial }) : undefined} />
            ))}
            {view !== 'top' && <path id="tablier" onClick={() => onSelect({ tablier: true })} className={`visualization-element ${!!selected?.tablier ? 'selected' : ''}`} d={data.tablier.path} />}

            {data?.piles?.map(pile => (
                <g key={pile.initial._id} className={`visualization-element pile ${selected?.pile?._id === pile.initial._id ? 'selected' : ''}`} onClick={selected?.pile?._id !== pile.initial._id ? () => onSelect({ pile: pile.initial }) : undefined} >
                    {view === 'bottom' && <path d={pile.topPath} />}
                    <path d={pile.path} />
                    {view === 'top' && <path d={pile.topPath} />}
                </g>
            ))}
            {view === 'top' && <path id="tablier" onClick={() => onSelect({ tablier: true })} className={`visualization-element ${!!selected?.tablier ? 'selected' : undefined}`} d={data.tablier.path} />}
            {view === 'top' && data?.barrieres?.map(barriere => (
                <path key={barriere.initial._id} className={`visualization-element barriere ${barriere.initial.type ?? ''} ${selected?.barriere?._id === barriere.initial._id ? 'selected' : ''}`} d={barriere.path} fillRule="nonzero" onClick={selected?.barriere?._id !== barriere.initial._id ? () => onSelect({ barriere: barriere.initial }) : undefined} />
            ))}
            {dimensions}
        </Fragment>
    )
}

export default Pont;