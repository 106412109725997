import { Analysis } from '../../models/analysis';
import { MaterialCharacteristicElementRequirement, UnknownMaterialCharacteristics } from '../../models/material';
import AnalysisCell from './AnalysisCell';
import AnalysisHeader from './AnalysisHeader';
import './index.scss';

interface SampleUnknownAnalysisProps {
    analysis: Partial<Analysis>;
    onChange: (a: Partial<Analysis>) => void;
}

const SampleUnknownAnalysis = ({
    analysis,
    onChange,
}: SampleUnknownAnalysisProps) => (
    <div className="table-container">
        <table>
            <AnalysisHeader characteristics={UnknownMaterialCharacteristics as MaterialCharacteristicElementRequirement[]} />
            <tbody>
                {onChange ? (
                    <tr>
                        {UnknownMaterialCharacteristics.map(c => <AnalysisCell key={c.key} isEditing characteristic={c} analysis={analysis} onChange={onChange} />)}
                    </tr>
                ) : (
                    <tr className={analysis?.controle ?? ''}>
                        {UnknownMaterialCharacteristics.map(c => <AnalysisCell key={c.key} characteristic={c} analysis={analysis} />)}
                    </tr>
                )}
            </tbody>
        </table >
    </div >
);

export default SampleUnknownAnalysis;