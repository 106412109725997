import { useCallback, useEffect, useState } from 'react';
import { FormBaseProps, FormRule, validateOne } from '../../hooks/useForm';
import { floatToPrString, prToFloat } from '../../utils/pr';
import TextInput from './TextInput';

interface PrInputProps extends FormBaseProps<number> {
    onBlurValidationError?: FormRule[];
    onBlurValidationWarning?: FormRule[];
}

const PrInput = ({
    id,
    value,
    disabled,
    onChange,
    onBlurValidationError,
    onBlurValidationWarning,
    errors
}: PrInputProps) => {
    const [errorsToDisplay, setErrorsToDisplay] = useState<string[]>([]);
    const [warnings, setWarnings] = useState<string[]>([]);
    const [stringValue, setStringValue] = useState<string>(floatToPrString(value));

    const handleBlur = useCallback((pr: string) => {
        const prFloat = prToFloat(pr);
        if (onBlurValidationError?.length) setErrorsToDisplay(validateOne(prFloat, onBlurValidationError));
        if (onBlurValidationWarning?.length) setWarnings(validateOne(prFloat, onBlurValidationWarning));
    }, [onBlurValidationError, onBlurValidationWarning]);

    const handleChange = useCallback((pr?: string) => {
        onChange(pr !== undefined && pr !== '' ? prToFloat(pr) : undefined);
        setStringValue(pr ?? '');
    }, [onChange]);

    useEffect(() => {
        if (!stringValue) {
            setStringValue(floatToPrString(value));
        }
    }, [value]);

    useEffect(() => {
        setErrorsToDisplay(errors ?? []);
    }, [errors]);

    return (
        <TextInput
            id={id}
            placeholder="Format 'km+hm'"
            value={stringValue}
            onChange={handleChange}
            onBlur={(v) => handleBlur(String(v))}
            disabled={disabled}
            errors={errorsToDisplay}
            warnings={warnings}
        />
    );
}

export default PrInput;