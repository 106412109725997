import { useCallback, useState } from "react";
import { changeFieldValue, getNestedField } from "../utils/objects";

export enum FormComparator {
    REQUIRED,
    NOT_EMPTY,
    SIZE,
    MAX_LENGTH,
    MIN_LENGTH,
    INTEGER,
    POSITIVE_INTEGER,
    NUMBER,
    POSITIVE_NUMBER,
    PR,
    PR_INSIDE,
    PR_EQUAL,
    PR_GREATER,
    PR_LESSER,
    EMAIL,
    REGEX,
}
export type FormRule = {
    comparator: FormComparator;
    compareTo?: any;
    message?: string;
};
export type EntityFormRules = {
    [key: string]: FormRule[];
};
export type FormErrors = {
    [key: string]: string[];
};

export const executeValidation = (
    value: any,
    { comparator, compareTo, message }: FormRule
): string | null => {
    switch (comparator) {
        case FormComparator.REQUIRED:
            return !!value || value === 0 || value === false
                ? null
                : "Ce champ est requis";
        case FormComparator.NOT_EMPTY:
            return Array.isArray(value) && !!value?.length
                ? null
                : message ?? "Choisir au moins une valeur";
        case FormComparator.SIZE:
            return Array.isArray(value) && value.length >= compareTo
                ? null
                : `Choisir au moins ${compareTo} valeur(s)`;
        case FormComparator.MAX_LENGTH:
            return (value?.length ?? 0) <= compareTo
                ? null
                : "Ce champ est limité à " + compareTo + " caractères";
        case FormComparator.MIN_LENGTH:
            return (value?.length ?? 0) >= compareTo
                ? null
                : "Ce champ attend au moins " + compareTo + " caractères";
        case FormComparator.INTEGER:
            return value === undefined ||
                (!isNaN(value) && Number.isInteger(value))
                ? null
                : "Ce champ attend un nombre entier";
        case FormComparator.POSITIVE_INTEGER:
            return value === undefined ||
                (!isNaN(value) && Number.isInteger(Number(value)) && value > 0)
                ? null
                : "Ce champ attend un nombre entier positif";
        case FormComparator.NUMBER:
            return value === undefined || !isNaN(value)
                ? null
                : "Ce champ est numérique";
        case FormComparator.POSITIVE_NUMBER:
            return value === undefined || (!isNaN(value) && value > 0)
                ? null
                : "Ce champ attend un nombre positif";
        case FormComparator.PR:
            return value === undefined || (!isNaN(value) && value >= 0)
                ? null
                : "Le format du PR est invalide";
        case FormComparator.PR_INSIDE:
            if (
                !value ||
                !compareTo?.min ||
                !compareTo?.max ||
                compareTo.min === "Infinity" ||
                compareTo.max === "Infinity"
            ) {
                return null;
            }

            let realMin = compareTo.min;
            let realMax = compareTo.max;

            if (compareTo.min > compareTo.max) {
                realMin = compareTo.max;
                realMax = compareTo.min;
            }

            return value >= realMin && realMax >= value
                ? null
                : message ?? "Le PR est hors limite";
        case FormComparator.PR_EQUAL:
            return !value || !compareTo || value === compareTo
                ? null
                : message ?? "Le PR n'est pas égual à " + compareTo;
        case FormComparator.PR_GREATER:
            return !value || !compareTo || value >= compareTo
                ? null
                : message ?? "Le PR n'est supérieur à " + compareTo;
        case FormComparator.PR_LESSER:
            return !value || !compareTo || compareTo >= value
                ? null
                : message ?? "Le PR n'est inférieur à " + compareTo;
        case FormComparator.EMAIL:
            const re =
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !value || re.test(value)
                ? null
                : "Le format de l'email est invalide";
        case FormComparator.REGEX:
            return !value || compareTo.test(String(value).toLowerCase())
                ? null
                : message ?? "La valeur ne correspond pas au format attendu";
        default:
            return null;
    }
};

export const validateAll = (
    entity: any,
    validations?: EntityFormRules
): [FormErrors, boolean] => {
    const errors: FormErrors = {};
    let hasErrors = false;
    for (const key in validations) {
        errors[key] = validateOne(
            getNestedField(entity, key),
            validations[key]
        );
        hasErrors = hasErrors || !!errors[key]?.length;
    }
    return [errors, hasErrors];
};

export const validateOne = (value: any, validation?: FormRule[]): string[] => {
    if (!validation) return [];

    const errors: string[] = [];
    for (const rule of validation ?? []) {
        const error = executeValidation(value, rule);

        if (error) errors.push(error);
    }

    return errors;
};

export interface FormBaseProps<U> {
    id: string;
    value?: U;
    onChange: (value: U | undefined, label?: string) => void;
    errors?: string[];
    warnings?: string[];
    disabled?: boolean;
}

export interface FormHookReturn<T> {
    entity: Partial<T>;
    hasChanged: boolean;
    setChanged: (c: boolean) => void;
    onChange: (field: string, value: any) => void;
    onChangeMultiple: (changes: { field: string; value: any }[]) => void;
    setEntity: (entity: Partial<T>) => void;
    validate: (validation?: EntityFormRules) => T | null;
    attachInput: (field: string) => any;
    errors: FormErrors;
    areFieldsOnError: (keys: string[]) => boolean;
}

export const useForm = <T extends { [k: string]: any }>(
    initialEntity: any | undefined
): FormHookReturn<T> => {
    const [entity, setEntity] = useState<Partial<T>>({ ...initialEntity });
    const [errors, setErrors] = useState<FormErrors>({});
    const [hasChanged, setChanged] = useState<boolean>(false);

    const onChange = useCallback((field: string, value: any): void => {
        setEntity((entity) => {
            const _entity = changeFieldValue(entity, field, value);

            setEntity(_entity);
            setChanged(true);
            setErrors((errors) => ({ ...errors, [field]: [] }));

            return _entity;
        });
    }, []);

    const onChangeMultiple = useCallback(
        (changes: { field: string; value: any }[]): void => {
            setEntity((entity) => {
                if (!changes.length) return entity;

                let _entity = { ...entity };

                for (const change of changes) {
                    _entity = changeFieldValue(
                        _entity,
                        change.field,
                        change.value
                    );
                }

                setEntity(_entity);
                setChanged(true);
                setErrors((errors) => {
                    let _errors = { ...errors };

                    for (const change of changes) {
                        _errors[change.field] = [];
                    }
                    return _errors;
                });

                return _entity;
            });
        },
        []
    );

    const validate = useCallback(
        (validation?: EntityFormRules): T | null => {
            const [_errors, hasError] = validateAll(entity, validation);
            setErrors(_errors);

            return hasError ? null : (entity as T);
        },
        [entity]
    );

    const attachInput = useCallback(
        (id: string) => ({
            id,
            onChange: (value: any) => onChange(id, value),
            value: getNestedField(entity, id),
            errors: errors[id],
        }),
        [errors, entity, onChange]
    );

    const areFieldsOnError = useCallback(
        (keys: string[]) =>
            Object.keys(errors).some(
                (k) => keys.includes(k) && errors[k]?.length
            ),
        [errors]
    );

    return {
        entity,
        hasChanged,
        setChanged,
        onChange,
        onChangeMultiple,
        validate,
        setEntity,
        attachInput,
        errors,
        areFieldsOnError,
    };
};

export default useForm;
