import { useEffect, useState } from 'react';
import Statistics from '.';
import { AnalysisStatistics } from '../../../models/analysis';
import { MATERIAL_FORM_PARAMETERS, MaterialCharacteristic, MaterialType, MaterialsWithProducer, UnknownMaterialCharacteristics } from '../../../models/material';
import { Phase } from '../../../models/operation';
import { Permission } from '../../../models/user';
import CharacSelector, { CharacSelectorSelected } from './CharacSelector';
import './index.scss';

interface StatisticsPickerProps {
    statistics: AnalysisStatistics[];
    columns: string[];
    title?: string;
    subtitle?: string;
    filename?: string;
    materialType?: MaterialType;
    chartAxis?: string;
    isAuthorized?: boolean;
    permission: Permission;
    chartByOptions?: string[];
    phases?: Phase[]
    isLibrary?: boolean;
}

const StatisticsPicker = ({
    statistics,
    columns,
    title,
    subtitle,
    filename,
    materialType,
    chartAxis = "pr",
    isAuthorized,
    permission,
    chartByOptions = [],
    phases = [],
    isLibrary = false,
}: StatisticsPickerProps) => {
    const [characteristics, setCharacteristics] = useState<MaterialCharacteristic[]>([]);
    const [selectedStatistics, setSelectedStatistics] = useState<AnalysisStatistics | null>(null);
    const [selectedKey, setSelectedKey] = useState<CharacSelectorSelected | null>(null);

    useEffect(() => {
        setSelectedKey(null);
        setSelectedStatistics(null);

        if (statistics) {
        }
    }, [statistics]);

    useEffect(() => {
        setSelectedKey(null);
        setSelectedStatistics(null);

        if (statistics) {
            if (materialType) {
                const _characteristics = [];
                // Loop through charac groups
                for (const group of (MATERIAL_FORM_PARAMETERS[materialType]?.characteristics ?? [])) {
                    const elements = [];
                    // Loop through charac elements
                    for (const element of group.elements) {
                        // Get elements with data
                        const elementStatistics = statistics.filter(s => s.key === element.key);
                        if (elementStatistics.length) {
                            // In case of seuil we need one entry per seuil
                            if (element.seuil) {
                                elements.push({ ...element, seuils: elementStatistics.map(s => ({ seuil: s.seuil! })) });
                            } else {
                                elements.push(element);
                            }
                        }
                    }
                    if (elements.length) {
                        _characteristics.push({ ...group, elements });
                    }
                }
                setCharacteristics(_characteristics);
            } else {
                const elements = [];
                // Loop through charac elements
                for (const element of UnknownMaterialCharacteristics) {
                    // Get elements with data
                    const elementStatistics = statistics.filter(s => s.key === element.key);
                    if (elementStatistics.length) {
                        // In case of seuil we need one entry per seuil
                        if (element.seuil) {
                            elements.push({ ...element, seuils: elementStatistics.map(s => ({ seuil: s.seuil! })) });
                        } else {
                            elements.push(element);
                        }
                    }
                }
                setCharacteristics([{ key: 'general', label: 'Général', elements }]);
            }
        }
    }, [materialType, statistics]);

    useEffect(() => {
        if (selectedKey?.key) {
            setSelectedStatistics(statistics.find(s => s.key === selectedKey.key && (selectedKey.seuil === null || s.seuil === selectedKey.seuil)) ?? null);
        }
    }, [selectedKey]);

    if (!statistics) {
        return (null);
    }

    return (
        <div className="analysis-results-container">
            <CharacSelector
                characteristics={characteristics}
                selected={selectedKey}
                onClick={setSelectedKey}
            />
            <div className="analysis-results">
                {selectedKey?.key && selectedStatistics && (
                    <Statistics
                        data={selectedStatistics}
                        columns={columns}
                        title={title ?? selectedKey.label}
                        subtitle={subtitle}
                        filename={filename ?? selectedKey.label}
                        chartByOptions={chartByOptions}
                        chartAxis={chartAxis}
                        isAuthorized={isAuthorized}
                        permission={permission}
                        phases={phases}
                        isLibrary={isLibrary}
                        isProducer={materialType && MaterialsWithProducer.includes(materialType)}
                    />
                )}
            </div>
        </div>
    );
}

export default StatisticsPicker;