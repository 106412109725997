import { Icon } from "@iconify/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommentModal from "../../components/CommentModal";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import Header from "../../components/ui/Header";
import Menu from "../../components/ui/Menu";
import ScrollableContent from "../../components/ui/ScrollableContent";
import { Phase, PhaseLabel } from "../../models/operation";
import { SampleType, SampleTypeLabel } from "../../models/sample";
import { RevisionStatusList } from "../../models/shared";
import useWorkspace from "../../services/hooks/use-workspace";
import { getRequest, postRequest } from "../../services/request.service";
import { ActionIcon } from "../../utils/icons";
import './index.scss';

const INFO_WRITING = "Le tableau de bord résume l'état des données rédigées par l'utilisateur en cours pour chaque étape de l'opération.\n\nEn fin de saisie, utiliser le bouton de notification pour faire avancer les données dans le processus de validation et notifier les validateurs des nouvelles données à valider.\n\nAttention : seules les données créées ou modifiées par l'utilisateur en cours sont comptabilisées, et seules celles-ci seront prises en compte lors de la notification";

const INFO_VALIDATION = "Le tableau de bord résume l'état des données de l'opération. Utiliser les liens associés à chaque ligne pour voir le détail";

type OperationDashboardCount = { [status: string]: number };

interface OperationPhaseDashboard {
    lot?: OperationDashboardCount;
    prelevement?: OperationDashboardCount;
    carotte?: OperationDashboardCount;
    population?: OperationDashboardCount;
    sondage?: OperationDashboardCount;
    melange?: OperationDashboardCount;
    marker?: OperationDashboardCount;
    component?: OperationDashboardCount;
}

interface OperationDashboard {
    etude?: OperationPhaseDashboard;
    preparatoire?: OperationPhaseDashboard;
    travaux?: OperationPhaseDashboard;
    expertise?: OperationPhaseDashboard;
}

interface DashboardProps {

}

const Dashboard = ({ }: DashboardProps) => {
    const { operation, workspacePermissions } = useWorkspace();
    const [isNotifyModalVisible, setNotifyModalVisible] = useState<boolean>(false);
    const [dashboard, setDashboard] = useState<OperationDashboard | null>(null);
    const navigate = useNavigate();

    const getDashboard = useCallback(() => {
        getRequest<any>(`/operation/${operation._id}/dashboard`, { loader: true, errorMessage: 'Une erreur est survenue pendant la récupération des informations' })
            .then(setDashboard)
            .catch(() => null);
    }, [operation]);

    const handleNotify = useCallback((comment: string | undefined) => {
        postRequest(`/operation/${operation._id}/notify-new-data`, { comment }, { loader: true, errorMessage: 'Une erreur est survenue pendant l\'envoi de la notification', successMessage: 'Notification envoyée' })
            .then(() => {
                getDashboard();
                setNotifyModalVisible(false);
            })
            .catch(() => null);
    }, [getDashboard]);

    useEffect(() => {
        getDashboard();
    }, [operation]);

    return (
        <Fragment>
            <Header breadcrumbs={[
                { label: operation?.name, href: `/operation/${operation?._id}` },
                { label: 'Tableau de bord' },
            ]} withBorder />
            <ScrollableContent
                side={
                    <Fragment>
                        {workspacePermissions.write && !workspacePermissions.validate && (
                            <Button
                                color="primary"
                                label="Notifier la saisie"
                                icon={ActionIcon.SEND}
                                onClick={() => setNotifyModalVisible(true)}
                            />
                        )}
                        <span className="info"><Icon icon="mdi:information-box-outline" />{workspacePermissions.validate ? INFO_VALIDATION : INFO_WRITING}</span>
                    </Fragment>
                }
            >
                {operation.phases.map(p => (
                    <Card key={p} title={PhaseLabel[p]} actions={<Menu icon={ActionIcon.GOTO} label="Accéder à la saisie" onClick={() => navigate(`/operation/${operation._id}/${p}`)} />}>
                        <table className="simple-table dashboard-table">
                            <thead>
                                <tr>
                                    <th />
                                    {RevisionStatusList.map(r => (<th key={r.key}>{r.label}</th>))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{SampleTypeLabel[SampleType.CAROTTE]}</td>
                                    {RevisionStatusList.map(r => (
                                        <th key={r.key} style={{ color: r.color }}>
                                            {dashboard?.[p]?.carotte?.[r.key]
                                                ? <span>{dashboard?.[p]?.carotte?.[r.key]}<Link to={`/operation/${operation._id}/${p}/carotte?status=${r.key}`}><Icon icon="mdi:open-in-new" className="icon-button" /></Link></span>
                                                : '--'
                                            }
                                        </th>
                                    ))}
                                </tr>
                                {p !== Phase.TRAVAUX ? (
                                    <tr>
                                        <td>{SampleTypeLabel[SampleType.SONDAGE]}</td>
                                        {RevisionStatusList.map(r => (
                                            <th key={r.key} style={{ color: r.color }}>
                                                {dashboard?.[p]?.sondage?.[r.key]
                                                    ? <span>{dashboard?.[p]?.sondage?.[r.key]}<Link to={`/operation/${operation._id}/${p}/sondage?status=${r.key}`}><Icon icon="mdi:open-in-new" className="icon-button" /></Link></span>
                                                    : '--'
                                                }
                                            </th>
                                        ))}
                                    </tr>
                                ) : (
                                    <Fragment>
                                        <tr>
                                            <td>{SampleTypeLabel[SampleType.PRELEVEMENT]}</td>
                                            {RevisionStatusList.map(r => (
                                                <th key={r.key} style={{ color: r.color }}>
                                                    {dashboard?.[p]?.prelevement?.[r.key]
                                                        ? <span>{dashboard?.[p]?.prelevement?.[r.key]}<Link to={`/operation/${operation._id}/${p}/prelevement?status=${r.key}`}><Icon icon="mdi:open-in-new" className="icon-button" /></Link></span>
                                                        : '--'
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Populations</td>
                                            {RevisionStatusList.map(r => (
                                                <th key={r.key} style={{ color: r.color }}>
                                                    {dashboard?.[p]?.population?.[r.key]
                                                        ? <span>{dashboard?.[p]?.population?.[r.key]}<Link to={`/operation/${operation._id}/${p}/population?status=${r.key}`}><Icon icon="mdi:open-in-new" className="icon-button" /></Link></span>
                                                        : '--'
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Lots</td>
                                            {RevisionStatusList.map(r => (
                                                <th key={r.key} style={{ color: r.color }}>
                                                    {dashboard?.[p]?.lot?.[r.key]
                                                        ? <span>{dashboard?.[p]?.lot?.[r.key]}<Link to={`/operation/${operation._id}/${p}/lot?status=${r.key}`}><Icon icon="mdi:open-in-new" className="icon-button" /></Link></span>
                                                        : '--'
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Suivi de constituants</td>
                                            {RevisionStatusList.map(r => (
                                                <th key={r.key} style={{ color: r.color }}>
                                                    {dashboard?.[p]?.component?.[r.key]
                                                        ? <span>{dashboard?.[p]?.component?.[r.key]}<Link to={`/operation/${operation._id}/${p}/suivi-de-constituants?status=${r.key}`}><Icon icon="mdi:open-in-new" className="icon-button" /></Link></span>
                                                        : '--'
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                    </Fragment>
                                )}
                                <tr>
                                    <td>Points d'intérêt</td>
                                    {RevisionStatusList.map(r => (
                                        <th key={r.key} style={{ color: r.color }}>
                                            {dashboard?.[p]?.marker?.[r.key]
                                                ? <span>{dashboard?.[p]?.marker?.[r.key]}<Link to={`/operation/${operation._id}/${p}/point-d-interet?status=${r.key}`}><Icon icon="mdi:open-in-new" className="icon-button" /></Link></span>
                                                : '--'
                                            }
                                        </th>))}
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                ))}
                {isNotifyModalVisible && (
                    <CommentModal
                        title="Commentaire"
                        placeholder="Détailler le contenu de la saisie, les points à vérifier, etc..."
                        allowEmpty
                        onClose={() => setNotifyModalVisible(false)}
                        onSubmit={handleNotify}
                    />

                )}
            </ScrollableContent>
        </Fragment>
    )
}

export default Dashboard;