import { useCallback, useEffect } from 'react';
import TextInput from '../../../../components/inputs/TextInput';
import { Textarea } from '../../../../components/inputs/Textarea';
import PanelRight from '../../../../components/ui/PanelRight';
import useForm, { FormComparator } from '../../../../hooks/useForm';
import { OuvrageElement } from '../../../../models/ouvrage';

interface OuvrageElementFormProps {
    element: Partial<OuvrageElement>;
    onSubmit: (element: OuvrageElement) => void;
    onClose: () => void;
    onDelete: () => void;
}

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
}

export const OuvrageElementForm = ({ element, onClose, onSubmit, onDelete }: OuvrageElementFormProps) => {
    const { attachInput, validate, onChange } = useForm<OuvrageElement>({ ...element });

    const handleSubmit = useCallback(() => {
        const element = validate(VALIDATION);
        if (element) {
            onSubmit(element);
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        if (element.coordinates) {
            onChange('coordinates', element.coordinates);
        }
    }, [element])

    return (
        <PanelRight
            title={element._id ? element.name : 'Créer un élément'}
            id="ouvrage-element-form"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Confirmer', onClick: handleSubmit },
            ]}
            onDelete={onDelete}
        >
            <div className="input-column">
                <label htmlFor="name">Nom *</label>
                <TextInput placeholder="Nom de l'élément" {...attachInput('name')} />
            </div>
            <div className="input-column">
                <label htmlFor="type">Type</label>
                <TextInput placeholder="Type" {...attachInput('type')} />
            </div>
            <div className="input-column">
                <label htmlFor="description">Description</label>
                <Textarea {...attachInput('description')} />
            </div>
            <div className="input-column">
                <label htmlFor="location">Localisation</label>
                <TextInput placeholder="Aide à la localisation" {...attachInput('location')} />
            </div>
        </PanelRight >
    )
}
export default OuvrageElementForm;