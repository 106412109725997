import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastData } from "../../components/ui/Toast";
import { Notification } from "../../models/notification";

export interface UiState {
    loader: number;
    toasts: ToastData[];
    notifications: Notification[];
    isNotificationsPanelVisible: boolean;
    helper?: string;
}

const initialState: UiState = {
    loader: 0,
    toasts: [],
    notifications: [],
    isNotificationsPanelVisible: false,
};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        startLoader: (state, _: PayloadAction<void>) => {
            state.loader++;
        },
        stopLoader: (state, _: PayloadAction<void>) => {
            state.loader = state.loader === 0 ? 0 : state.loader - 1;
        },
        addToast: (
            state,
            action: PayloadAction<Omit<ToastData, "timestamp">>
        ) => {
            state.toasts.push({ ...action.payload, timestamp: Date.now() });
        },
        deleteToast: (state, action: PayloadAction<number>) => {
            state.toasts = state.toasts.filter(
                (t) => t.timestamp !== action.payload
            );
        },
        setHelper: (state, action: PayloadAction<string | undefined>) => {
            state.helper = action.payload;
        },
        toggleNotificationsPanel: (state, _: PayloadAction<void>) => {
            state.isNotificationsPanelVisible =
                !state.isNotificationsPanelVisible;
        },
    },
});

export const {
    startLoader,
    stopLoader,
    addToast,
    deleteToast,
    setHelper,
    toggleNotificationsPanel,
} = uiSlice.actions;
export default uiSlice.reducer;
