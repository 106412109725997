// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ouvrage-marker .panel-right-content {
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Ouvrage/OuvrageMarkerForm/index.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR","sourcesContent":["#ouvrage-marker {\n    & .panel-right-content {\n        overflow-y: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
