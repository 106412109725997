
import { Icon } from "@iconify/react";
import { StorageFile } from "../../models/storage";
import Expandable from "../ui/Expandable";
import './index.scss';

interface FileTileProps {
    file: StorageFile;
}

const FileTile = ({ file }: FileTileProps) => {

    return (
        <div className="file-tile">
            {file.base64 ? (
                <Expandable><img src={`data:${file.mimeType};charset=utf-8;base64,${file.base64}`} alt="" /></Expandable>
            ) : <Icon icon="mdi-file" className="icon-large" />}
        </div>
    );
}

export default FileTile;