import { useMemo } from "react";
import useForm, { FormComparator } from "../../hooks/useForm";
import { StorageFile } from "../../models/storage";
import useAuth from "../../services/hooks/use-auth.hook";
import useWorkspace from "../../services/hooks/use-workspace";
import { postRequest } from "../../services/request.service";
import { WorkspaceType } from "../../services/slices/workspace.slice";
import { getFullName } from "../../utils/format";
import { ActionIcon } from "../../utils/icons";
import CheckboxList from "../inputs/CheckboxList";
import { Textarea } from "../inputs/Textarea";
import TextInput from "../inputs/TextInput";
import Toggle from "../inputs/Toggle";
import Card from "../ui/Card";
import DropZone from "../ui/Dropzone";
import { Modal } from "../ui/Modal";

interface FileInterface {
    message: string;
    email: boolean;
    private: boolean;
    sentTo: string[];
    file?: string;
    filePopulated?: StorageFile;
    name?: string;
    description?: string;
}

const DEFAULT_MESSAGE: Partial<FileInterface> = {
    email: true,
    private: false,
    sentTo: [],
}

const VALIDATION = {
    message: [{ comparator: FormComparator.REQUIRED }],
    file: [{ comparator: FormComparator.REQUIRED }],
    name: [{ comparator: FormComparator.REQUIRED }],
};

interface FileModalProps {
    onClose: () => void;
    onSubmit: () => void;
}

const FileModal = ({
    onClose,
    onSubmit
}: FileModalProps) => {
    const { operation, ouvrage, workspace } = useWorkspace();
    const { user } = useAuth();
    const { entity, attachInput, validate, onChangeMultiple } = useForm<FileInterface>({ ...DEFAULT_MESSAGE });

    const handleFileChange = (files: StorageFile[]) => {
        if (files.length) {
            onChangeMultiple([
                { field: 'file', value: files[0]._id },
                { field: 'filePopulated', value: files[0] },
            ]);
        } else {
            onChangeMultiple([
                { field: 'file', value: undefined },
                { field: 'filePopulated', value: undefined },
            ]);
        }
    };

    const handleSubmit = async () => {
        const entity = validate(VALIDATION);
        if (!entity) return;

        postRequest(`/${workspace === WorkspaceType.CHAUSSES ? 'operation/' + operation._id : 'ouvrage/' + ouvrage._id}/file`, entity, {
            successMessage: 'Document envoyé avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'envoi',
            loader: true
        })
            .then(onSubmit)
            .catch(() => null);
    };

    const usersList = useMemo(() => (operation.users?.map(u => ({ key: u.user, label: getFullName(u.userPopulated) })) ?? []).filter(u => u.key !== user._id), [operation]);

    return (
        <Modal
            title="Ajouter un document"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Envoyer', onClick: handleSubmit, icon: ActionIcon.SEND },
            ]}
            id="message-modal"
        >
            <div className="row">
                <div className="col lg8">
                    <div className="input-column">
                        <label htmlFor="name">Nom *</label>
                        <TextInput placeholder="Nom du fichier à afficher" {...attachInput('name')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="description">Description</label>
                        <TextInput {...attachInput('description')} />
                    </div>
                    <DropZone
                        direction="column"
                        files={entity.filePopulated ? [entity.filePopulated] : []}
                        onChange={handleFileChange}
                        maxFiles={1}
                    />
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <Textarea rows={6} placeholder="Message à joindre..." {...attachInput('message')} />
                    </div>
                </div>
                <Card className="col lg4">
                    <Toggle label="Notifier par email" {...attachInput('email')} />
                    <Toggle label="Message privé" {...attachInput('private')} />
                    {entity.private && (
                        <div className="form-group">
                            <label htmlFor="sentTo">Utilisateurs à notifier</label>
                            <CheckboxList items={usersList} {...attachInput('sentTo')} />
                        </div>
                    )}
                </Card>
            </div>
        </Modal>
    )
}

export default FileModal;