import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { setHelper } from "../../../services/slices/ui.slice";
import { RootState } from "../../../services/store";
import { Modal } from "../Modal";
import './index.scss';


interface ModalHelperProps {
    text: string;
}

const ModalHelper = ({ text }: ModalHelperProps) => {
    const dispatch = useDispatch();

    return (
        <div className="modal-helper-icon">
            <Icon icon="mdi:information-box-outline" className="color-light-grey" onClick={() => setHelper(text)} />
        </div>
    );
}

export const ModalHelperModal = () => {
    const { helper } = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();

    if (!helper) return null;

    return (
        <Modal title="Informations" actions={[{ label: "Fermer", color: "secondary", onClick: () => dispatch(setHelper(undefined)) }]} size="small">
            <div id="modal-helper-text">{helper}</div>
        </Modal>
    );
}

export default ModalHelper;