import { Icon } from "@iconify/react";
import { ReactNode, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import logo from "../../../assets/images/logo.png";
import useAuth from "../../../services/hooks/use-auth.hook";
import { ActionIcon } from "../../../utils/icons";
import './index.scss';

interface PrintableProps {
    title?: string;
    subtitle?: string;
    disposition?: 'portrait' | 'landscape';
    flow?: 'vertical' | 'horizontal' | 'both';
    data?: { label: string, value: string }[];
    hideBanner?: boolean;
    togglePrint?: boolean;
    onPrintEnd?: () => void;
    filename: string;
    children: ReactNode;
}

const Printable = ({
    title,
    subtitle,
    disposition = "portrait",
    flow = "vertical",
    data,
    hideBanner = false,
    togglePrint = false,
    onPrintEnd,
    filename,
    children,
}: PrintableProps) => {
    const { permissions } = useAuth();
    const ref = useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
        bodyClass: 'printable',
        copyStyles: true,
        pageStyle: '@page { size: auto; margin: 12mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
        content: () => ref.current,
        documentTitle: title,
        onAfterPrint: onPrintEnd ? onPrintEnd : undefined,
    });

    useEffect(() => {
        if (togglePrint) {
            handlePrint();
        }
    }, [togglePrint]);

    return (
        <div className="printable" ref={ref}>
            {!hideBanner && permissions.print && (
                <div
                    className="printable-banner sm-hide no-print"
                    onClick={handlePrint}
                >
                    <Icon icon={ActionIcon.PRINT} />
                    <span>Imprimer</span>
                </div>
            )}
            <div className="printable-table">
                <div className="printable-thead">
                    <div className="printable-tr">
                        <div className="printable-th">
                            <div>
                                <div className="printable-header-left">
                                    <img src={logo} alt="logo" />
                                    <h1>
                                        L'intelligence numérique
                                        <br />
                                        au service de l'expertise
                                    </h1>
                                </div>
                                <div className="printable-header-right">
                                    {title ? <div id="printable-title">{title}</div> : null}
                                    {subtitle ? <div id="printable-subtitle">{subtitle}</div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="printable-tbody">
                    <div className="printable-tr printable-data">
                        <div className="printable-td">
                            <div>
                                {data?.map((d, index) => (
                                    <span key={index}>
                                        <strong>{d.label} : </strong>
                                        {d.value}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="printable-tr printable-content">
                        <div className="printable-td">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Printable;