import { Coordinates } from "../models/location";

export const average = (array: number[]) =>
    Array.isArray(array) && array.length
        ? array.reduce((sum, a) => Number(a) + sum, 0) / array.length
        : NaN;

export const roundDecimals = (num: number, decimals: number = 2) =>
    !isNaN(num) && num !== null
        ? Math.round((num + Number.EPSILON) * Math.pow(10, decimals)) /
          Math.pow(10, decimals)
        : "";

// Converts numeric degrees to radians
const toRad = (value: number) => (value * Math.PI) / 180;
const distanceBetweenPoints = (point1: Coordinates, point2: Coordinates) => {
    var R = 6371; // km
    var dLat = toRad(point2.latitude - point1.latitude);
    var dLon = toRad(point2.longitude - point1.longitude);
    var lat1 = toRad(point1.latitude);
    var lat2 = toRad(point2.latitude);

    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
            Math.sin(dLon / 2) *
            Math.cos(lat1) *
            Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
};

export const getPrFromSegment = (
    point: Coordinates,
    segmentStart: Coordinates & { pr: number },
    segmentEnd: Coordinates
) => {
    const distanceSegment = distanceBetweenPoints(segmentStart, segmentEnd);
    const distancePoint = distanceBetweenPoints(segmentStart, point);

    return distanceSegment && distancePoint
        ? segmentStart.pr + distancePoint / distanceSegment
        : segmentStart.pr;
};
