import React, { ReactNode } from 'react';
import './index.scss';

interface TooltipProps {
    children: ReactNode,
    text: string,
    position?: 'center' | 'left' | 'right'
}

const Tooltip = ({ children, text, position = 'center' }: TooltipProps) => (
    <div className={`tooltip-container ${position}`} onClick={(e) => e.stopPropagation()}>
        {children}
        {!!text && (
            <span className="tooltip">
                {text}
            </span>
        )}
    </div>
);

export default Tooltip;