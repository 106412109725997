import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '../../../components/data/List';
import Header from '../../../components/ui/Header';
import { MenuBarNavigation } from '../../../components/ui/MenuBar';
import { Material, MaterialListParams, MaterialType, MaterialTypes } from '../../../models/material';
import useWorkspace from '../../../services/hooks/use-workspace';

const ByMaterial = () => {
    const { operation } = useWorkspace();
    const [exigenceType, setExigenceType] = useState<MaterialType>(MaterialType.GRANULAT);
    const navigate = useNavigate();

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: operation.name, href: `/operation/${operation._id}` }, { label: 'Résultats matériaux' }]} />
            <MenuBarNavigation pages={MaterialTypes} setPage={(page) => setExigenceType(page as MaterialType)} />
            {!!exigenceType && MaterialListParams[exigenceType] && (
                <List<Material>
                    columns={MaterialListParams[exigenceType].columns}
                    dataEndpoint={`/material/operation/${operation._id}/${exigenceType}`}
                    onView={(m) => navigate(`/operation/${operation._id}/resultats/materiau/${m._id}`)}
                    warningBeforeDelete
                />
            )}
        </Fragment>
    )
}

export default ByMaterial;
