import { Icon } from '@iconify/react';
import { ReactNode } from 'react';
import Button, { ButtonProps } from '../Button';
import { TagProps, TagsList } from '../Tag';

interface ModalBottomProps {
    id?: string;
    visible?: boolean;
    children: ReactNode;
    title?: string;
    tags?: TagProps[];
    className?: string;
    onClose?: () => void;
    actions?: ButtonProps[];
}

export const ModalBottom = ({
    visible,
    children,
    title,
    tags,
    id,
    className,
    actions,
    onClose
}: ModalBottomProps) => (
    <div className={`modal-bottom-backdrop ${visible ? 'visible' : ''}`}>
        <div className={`modal-bottom ${className ?? ''}`}>
            {!!onClose && <Icon icon="mdi:close" className="icon-button" onClick={onClose} />}
            {title && (
                <div className="modal-bottom-title">
                    <span>{title}</span>
                    {!!tags?.length && <TagsList tags={tags} />}
                </div>
            )}
            <div id={id} className="modal-bottom-content">
                {children}
            </div>
            {!!actions?.length && (
                <div className="modal-bottom-footer">
                    {actions?.map(a => <Button key={a.label} {...a} />)}
                </div>
            )}
        </div>
    </div>
);
