import { Icon } from '@iconify/react';
import { useCallback } from 'react';
import { toggleInArray } from '../../../utils/objects';
import './index.scss';

interface ButtonsItems<T> {
    key: T;
    icon?: string;
    label: string;
}

export interface ButtonsMultipleProps<T> {
    items: ButtonsItems<T>[];
    value?: T[];
    onChange: (v?: T[]) => void;
}

export const ButtonsMultiple = <T,>({ items, value, onChange }: ButtonsMultipleProps<T>) => {

    return (
        <div className="buttons">
            {items.map(i => (
                <div key={String(i.key)} className={value?.includes(i.key) ? 'active' : ''} onClick={() => onChange(toggleInArray(i.key, value))}>
                    {!!i.icon && <Icon icon={i.icon} className="icon-small" />}
                    <span>{i.label}</span>
                </div>
            ))}
        </div>
    )
}

export interface ButtonsProps<T> {
    items: ButtonsItems<T>[];
    value?: T;
    onChange: (v?: T) => void;
}

const Buttons = <T,>({ items, value, onChange }: ButtonsProps<T>) => {
    const handleChange = useCallback((v?: T[]) => {
        onChange(v?.pop());
    }, [onChange]);

    return <ButtonsMultiple items={items} value={value ? [value] : undefined} onChange={handleChange} />;
}

export default Buttons;