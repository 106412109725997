import { createContext, MouseEvent, TouchEvent, useContext, useEffect, useMemo, useReducer, useRef } from "react";
import Card from "../../../components/ui/Card";
import MenuBar from "../../../components/ui/MenuBar";
import { MarkerIcon } from "../../../models/marker";
import { OuvrageType } from "../../../models/ouvrage";
import Buse from "./components/Buse";
import Cube from "./components/Cube";
import Mur from "./components/Mur";
import Pont from "./components/Pont";
import { getEventCoordinates, updateCoordinates, visualizationReducer } from "./functions";
import './index.scss';
import { VisualizationProps, VisualizationReducerState, VisualizationSelection } from "./types";

const INITIAL_REDUCER_STATE: VisualizationReducerState = {
    bbox: { width: 100, height: 100, x: 0, y: 0 },
    viewBox: {
        x: 0,
        y: 0,
        width: 100,
        height: 100
    },
    view: 'front',
    type: OuvrageType.PONT,
    contentBbox: { width: 100, length: 100, height: 100, x: 0, y: 0, z: 0 },
    translation: { x: 0, y: 0 },
    translationInProgress: { x: 0, y: 0 },
    scale: 1,
    zoom: 1,
}


export const VisualizationContext = createContext<VisualizationReducerState>({
    ...INITIAL_REDUCER_STATE
});


const Visualization = ({
    ouvrage,
    blueprintSelection,
    editable,
    children,
    onBlueprintSelection,
    onMarker,
    onMarkerInit,
}: VisualizationProps) => {
    const ref = useRef<SVGSVGElement>(null);
    const [state, dispatch] = useReducer(visualizationReducer, { ...INITIAL_REDUCER_STATE, type: ouvrage.type ?? OuvrageType.MUR });

    const handleSelect = (s: VisualizationSelection) => {
        if (blueprintSelection || !onBlueprintSelection || onMarker || !editable) return;
        onBlueprintSelection(s);
    }

    const viewBoxString = useMemo(() => `${Math.round((state.viewBox.x + (state.translationInProgress?.x ?? 0)) / state.zoom)} ${Math.round((state.viewBox.y + (state.translationInProgress?.y ?? 0)) / state.zoom)} ${Math.round(state.viewBox.width / state.zoom)} ${Math.round(state.viewBox.height / state.zoom)}`
        , [state]);

    const handleAddMarker = (e: MouseEvent | TouchEvent) => {
        if (!onMarker) return;
        onMarker(updateCoordinates(state, getEventCoordinates(e), {}));
    }

    const handleMouseMove = (e: MouseEvent | TouchEvent) => {
        if (!state.mouseDown) return;
        dispatch({ type: 'translate', payload: { coordinates: getEventCoordinates(e) } })
    }

    const handleMouseDown = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        dispatch({ type: 'mouseDown', payload: { coordinates: getEventCoordinates(e) } });
    }

    const handleMouseUp = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        if (!state.translationInProgress?.x && !state.translationInProgress?.y) {
            if (onMarker) {
                handleAddMarker(e)
            }
        }

        dispatch({ type: 'mouseUp' });
    }

    useEffect(() => {
        dispatch({ type: 'setContentBbox', payload: { content: ouvrage.blueprint } });
    }, [ouvrage.blueprint]);

    useEffect(() => {
        if (!ref.current) return;

        const observer = new ResizeObserver(() => {
            if (ref.current) {
                dispatch({ type: 'setBbox', payload: { bbox: ref.current.getBoundingClientRect() } });
            }
        });

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    if (!ouvrage.blueprint) return null;

    return (
        <VisualizationContext.Provider value={state}>
            <div id="visualization">
                {!!onMarker && (
                    <Card id="visualization-tooltip">
                        <span className="info">Cliquez à l'endroit où se situe l'élément. Changez de vue si nécessaire. Vous pourrez le déplacer ultérieurement.</span>
                    </Card>
                )}
                {!!onMarkerInit && (
                    <MenuBar card id="visualization-marker-menu">
                        <MenuBar.Item
                            label="Ajouter un point d'intérêt"
                            onClick={onMarkerInit}
                            icon={MarkerIcon}
                        />
                    </MenuBar>
                )}
                <div id="visualization-views" className="no-print">
                    <MenuBar card column>
                        <MenuBar.Item label="Avant" onClick={() => dispatch({ type: 'setView', payload: { view: 'front' } })} active={state.view === 'front'} icon={<Cube side="front" />} />
                        <MenuBar.Item label="Gauche" onClick={() => dispatch({ type: 'setView', payload: { view: 'left' } })} active={state.view === 'left'} icon={<Cube side="left" />} />
                        <MenuBar.Item label="Dessus" onClick={() => dispatch({ type: 'setView', payload: { view: 'top' } })} active={state.view === 'top'} icon={<Cube side="top" />} />
                    </MenuBar>
                </div>
                <svg
                    id="visualizator"
                    className={`visualizator-${editable && !blueprintSelection ? 'edit' : onMarker ? 'marker' : ''} ${blueprintSelection ? 'visualizator-draw' : ''}`}
                    viewBox={viewBoxString}
                    fillRule={(state.view === 'left' || state.view === 'right') && ouvrage.type === OuvrageType.BUSE ? 'evenodd' : undefined}
                    ref={ref}
                    onWheel={(e) => dispatch({ type: 'addZoom', payload: { zoom: e.deltaY, coordinates: { x: e.clientX - e.currentTarget.getBoundingClientRect().left, y: e.clientY - e.currentTarget.getBoundingClientRect().top } } })}
                    onMouseMove={handleMouseMove}
                    onTouchMove={handleMouseMove}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                >
                    {ouvrage.type === OuvrageType.PONT && (
                        <Pont
                            view={state.view}
                            scale={state.scale}
                            blueprint={ouvrage.blueprint}
                            selected={blueprintSelection}
                            onSelect={handleSelect}
                        />
                    )}
                    {ouvrage.type === OuvrageType.MUR && (
                        <Mur
                            view={state.view}
                            scale={state.scale}
                            blueprint={ouvrage.blueprint}
                            selected={blueprintSelection}
                            onSelect={handleSelect}
                        />
                    )}
                    {ouvrage.type === OuvrageType.BUSE && (
                        <Buse
                            view={state.view}
                            scale={state.scale}
                            blueprint={ouvrage.blueprint}
                            selected={blueprintSelection}
                            onSelect={handleSelect}
                        />
                    )}
                    {children}
                </svg>
            </div>
        </VisualizationContext.Provider>
    )
}

export const useVisualisation = () => {
    return useContext<VisualizationReducerState>(VisualizationContext);
};

export default Visualization;