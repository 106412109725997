import { Chart } from 'react-chartjs-2';
import { MinMaxAverage } from '../../../models/analysis';
import { Phase, PhaseLabel, RoadPosition, RoadPositionLabel } from '../../../models/operation';
import { Controle, ControleLabel, Controles } from '../../../models/sample';
import { formatNumberToFixedDecimal } from '../../../utils/format';
import { floatToPrString } from '../../../utils/pr';
import ProgressBar from '../../data/Statistics/ProgressBar';
import Switch from '../../inputs/Switch';
import Expandable from '../Expandable';

const CHART_DATA = {
    min: 0,
    max: 6,
    datasets: [
        {
            borderWidth: 1,
            pointRadius: 2,
            spanGaps: true,
            label: "Exterieur",
            data: [
                { x: 81.35, y: 4.38 },
                { x: 81.4, y: 4.77 },
                { x: 81.45, y: 5.16 },
                { x: 81.5, y: 5 },
                { x: 81.7, y: 4.88 },
                { x: 81.8 },
                { x: 82.05, y: 4.98 },
                { x: 82.1, y: 4.87 },
                { x: 82.5, y: 4.89 },
                { x: 82.8, y: 4.67 },
                { x: 82.85, y: 4.86 },
                { x: 82.9, y: 4.625 },
                { x: 83.15 },
                { x: 83.2, y: 4.91 },
                { x: 83.25, y: 4.85 },
                { x: 83.7, y: 5.07 },
                { x: 83.75 },
                { x: 83.9 },
                { x: 83.95 },
                { x: 84.1, y: 4.82 },
                { x: 84.2, y: 4.835 },
                { x: 84.5 },
                { x: 84.55 },
                { x: 84.75, y: 4.79 },
                { x: 84.8, y: 4.82 },
                { x: 85 },
                { x: 85.05, y: 4.81 },
                { x: 85.1, y: 4.82 },
                { x: 85.6 },
                { x: 85.65 },
                { x: 85.7 },
                { x: 86.2 },
                { x: 86.3, y: 4.795 },
                { x: 86.35, y: 4.77 },
                { x: 86.4 },
                { x: 86.45 },
                { x: 86.9 },
                { x: 87.2 },
                { x: 87.3 },
                { x: 87.35 },
                { x: 87.4 },
                { x: 87.55 },
                { x: 87.6, y: 4.713333333333334 },
                { x: 87.65, y: 4.835 },
                { x: 87.7, y: 4.47 },
                { x: 87.8 },
                { x: 87.85 },
                { x: 88.55 },
                { x: 88.75, y: 5.025 },
                { x: 89.1 },
                { x: 89.15 },
                { x: 89.8, y: 4.83 },
                { x: 89.85, y: 4.74 },
                { x: 89.9, y: 4.41 },
                { x: 90 },
                { x: 90.25, y: 4.85 },
                { x: 90.3, y: 4.97 },
                { x: 90.5 },
                { x: 90.6 },
                {
                    x: 91
                }
            ],
            backgroundColor: "#397d92",
            borderColor: "#397d92"
        },
        {
            borderWidth: 1,
            pointRadius: 2,
            spanGaps: true,
            label: "Externe",
            data: [
                { x: 81.35, y: 4.51 },
                { x: 81.4, y: 4.66 },
                { x: 81.45, y: 4.82 },
                { x: 81.5 },
                { x: 81.7, y: 4.94 },
                { x: 81.8, y: 4.44 },
                { x: 82.05 },
                { x: 82.1 },
                { x: 82.5 },
                { x: 82.8, y: 4.51 },
                { x: 82.85 },
                { x: 82.9, y: 4.5 },
                { x: 83.15, y: 4.42 },
                { x: 83.2 },
                { x: 83.25, y: 4.45 },
                { x: 83.7 },
                { x: 83.75, y: 5.02 },
                { x: 83.9, y: 5.05 },
                { x: 83.95, y: 5.02 },
                { x: 84.1 },
                { x: 84.2 },
                { x: 84.5, y: 4.57 },
                { x: 84.55, y: 4.57 },
                { x: 84.75 },
                { x: 84.8 },
                { x: 85, y: 5.07 },
                { x: 85.05 },
                { x: 85.1 },
                { x: 85.6, y: 4.52 },
                { x: 85.65, y: 4.42 },
                { x: 85.7, y: 4.85 },
                { x: 86.2, y: 4.95 },
                { x: 86.3 },
                { x: 86.35 },
                { x: 86.4, y: 4.54 },
                { x: 86.45, y: 4.46 },
                { x: 86.9, y: 4.93 },
                { x: 87.2, y: 4.8 },
                { x: 87.3, y: 4.66 },
                { x: 87.35, y: 4.58 },
                { x: 87.4, y: 4.83 },
                { x: 87.55, y: 4.61 },
                { x: 87.6, y: 4.5 },
                { x: 87.65 },
                { x: 87.7 },
                { x: 87.8, y: 4.81 },
                { x: 87.85, y: 4.9 },
                { x: 88.55, y: 4.46 },
                { x: 88.75 },
                { x: 89.1, y: 4.8 },
                { x: 89.15, y: 4.96 },
                { x: 89.8 },
                { x: 89.85 },
                { x: 89.9 },
                { x: 90, y: 4.37 },
                { x: 90.25 },
                { x: 90.3 },
                { x: 90.5, y: 4.595000000000001 },
                { x: 90.6, y: 4.47 },
                { x: 91, y: 4.81 }
            ],
            backgroundColor: "#faa97a",
            borderColor: "#faa97a"
        },
        {
            borderWidth: 1,
            pointRadius: 2,
            spanGaps: true,
            label: "Autre",
            data: [
                { x: 81.35 },
                { x: 81.4 },
                { x: 81.45 },
                { x: 81.5 },
                { x: 81.7 },
                { x: 81.8 },
                { x: 82.05 },
                { x: 82.1 },
                { x: 82.5 },
                { x: 82.8 },
                { x: 82.85 },
                { x: 82.9 },
                { x: 83.15 },
                { x: 83.2 },
                { x: 83.25 },
                { x: 83.7 },
                { x: 83.75 },
                { x: 83.9 },
                { x: 83.95 },
                { x: 84.1 },
                { x: 84.2 },
                { x: 84.5 },
                { x: 84.55 },
                { x: 84.75 },
                { x: 84.8 },
                { x: 85 },
                { x: 85.05 },
                { x: 85.1 },
                { x: 85.6 },
                { x: 85.65 },
                { x: 85.7 },
                { x: 86.2 },
                { x: 86.3 },
                { x: 86.35 },
                { x: 86.4 },
                { x: 86.45 },
                { x: 86.9 },
                { x: 87.2 },
                { x: 87.3 },
                { x: 87.35 },
                { x: 87.4 },
                { x: 87.55 },
                { x: 87.6 },
                { x: 87.65 },
                { x: 87.7 },
                { x: 87.8 },
                { x: 87.85 },
                { x: 88.55 },
                { x: 88.75 },
                { x: 89.1 },
                { x: 89.15 },
                { x: 89.8 },
                { x: 89.85 },
                { x: 89.9 },
                { x: 90 },
                { x: 90.25 },
                { x: 90.3 },
                { x: 90.5 },
                { x: 90.6 },
                { x: 91 }
            ],
            backgroundColor: "#84a31f",
            borderColor: "#84a31f"
        }
    ]
};

const STATISTICS = {
    controle: {
        exterieur: {
            average: 4.807692307692307,
            min: 4.36,
            max: 5.18
        },
        externe: {
            average: 4.682820512820513,
            min: 4.37,
            max: 5.07
        },
        autre: {
            average: 4.682820512820513,
            min: 4.37,
            max: 5.07
        }
    },
    average: 4.745256410256411,
    min: 4.36,
    max: 5.18
};

const ANALYSIS = [
    {
        problematic: true,
        data: {
            value: 4.38,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 81.35,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.51,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 81.35,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.55,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 81.4,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.77,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 81.4,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.77,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 81.4,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.82,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 81.45,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 5.16,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 81.45,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 5,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 81.5,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.88,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 81.7,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.94,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 81.7,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.44,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 81.8,
        road: "614d8462fd7b5c0085105373",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.98,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 82.05,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.87,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 82.1,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.89,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 82.5,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.67,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 82.8,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.51,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 82.8,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.86,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 82.85,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.42,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 82.9,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.5,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 82.9,
        road: "614d8462fd7b5c0085105373",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.83,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 82.9,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.42,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 83.15,
        road: "614d8462fd7b5c0085105376",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.91,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 83.2,
        road: "614d8462fd7b5c0085105373",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.45,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 83.25,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.85,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 83.25,
        road: "614d8462fd7b5c0085105373",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 5.07,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 83.7,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 5.02,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 83.75,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 5.05,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 83.9,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 5.02,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 83.95,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.82,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 84.1,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.8,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 84.2,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.87,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 84.2,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.57,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 84.5,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.57,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 84.55,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.79,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 84.75,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.82,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 84.8,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 5.07,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 85,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.81,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 85.05,
        road: "614d8462fd7b5c0085105373",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.82,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 85.1,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.52,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 85.6,
        road: "614d8462fd7b5c0085105373",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.42,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 85.65,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.85,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 85.7,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.95,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 86.2,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.81,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 86.3,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.78,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 86.3,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.77,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 86.35,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.54,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 86.4,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.46,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 86.45,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.93,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 86.9,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.8,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 87.2,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.66,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 87.3,
        road: "614d8462fd7b5c0085105373",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.58,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 87.35,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.83,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 87.4,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.61,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 87.55,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.36,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 87.6,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.5,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 87.6,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.89,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 87.6,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.89,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 87.6,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.85,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 87.65,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.82,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 87.65,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.47,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 87.7,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.81,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 87.8,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.9,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 87.85,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.46,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 88.55,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 5.18,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 88.75,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.87,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 88.75,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.8,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 89.1,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.96,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 89.15,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.83,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 89.8,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.56,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 89.85,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.92,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 89.85,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.41,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 89.9,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.37,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 90,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.85,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 90.25,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.97,
            problematic: false
        },
        controle: "exterieur",
        phase: "travaux",
        pr: 90.3,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.37,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 90.5,
        road: "614d8462fd7b5c0085105373",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.82,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 90.5,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: true,
        data: {
            value: 4.47,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 90.6,
        road: "614d8462fd7b5c0085105374",
        roadPosition: "axe",
        position: 0,
    },
    {
        problematic: false,
        data: {
            value: 4.81,
            problematic: false
        },
        controle: "externe",
        phase: "travaux",
        pr: 91,
        road: "614d8462fd7b5c0085105375",
        roadPosition: "axe",
        position: 0,
    }
];

const FakeDataTable = () => (
    <div className="data-table">
        <h3>Représentation des valeurs</h3>
        <div className="data-chart">
            <div className="data-chart-controles no-print">
                <Switch
                    id="chart-type"
                    className="switch-chart-type"
                    items={[{ key: 'average', label: 'Moyenne' }, { key: 'scatter', label: 'Nuage' }]}
                    value={'average'}
                    onChange={() => undefined}
                />
                <Switch
                    className="switch-chart-by"
                    id="chart-by"
                    items={[{ key: 'phase', label: 'Phase' }, { key: 'controle', label: 'Contrôle' }]}
                    value={'controle'}
                    onChange={() => undefined}
                />
            </div>
            <Expandable>
                <Chart
                    type="line"
                    data={{
                        datasets: CHART_DATA.datasets,
                    }}
                    options={{
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom'
                            },
                        },
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                type: 'linear'
                            },
                            y: {
                                max: CHART_DATA.max,
                                min: CHART_DATA.min,
                            }
                        }
                    }}
                />
            </Expandable>
        </div>
        <h3>Statistiques</h3>
        <table>
            <thead>
                <tr>
                    <td></td>
                    <td>Minimum</td>
                    <td>Moyenne</td>
                    <td>Maximum</td>
                </tr>
            </thead>
            <tbody>
                {Controles.map(controle => (
                    <tr key={controle.key}>
                        <td>{controle.label}</td>
                        {['min', 'average', 'max'].map(field => (
                            <td key={field}>
                                <span className="data-value">{formatNumberToFixedDecimal(STATISTICS.controle[controle.key]?.[field as keyof MinMaxAverage])}</span>
                            </td>
                        ))}
                    </tr>
                ))}
                <tr>
                    <td><strong>Global</strong></td>
                    {['min', 'average', 'max'].map(field => (
                        <td key={field}>
                            <span className="data-value">{formatNumberToFixedDecimal(STATISTICS[field as keyof MinMaxAverage])}</span>
                        </td>
                    ))}
                </tr>
            </tbody>
        </table>
        <h3>Données brutes</h3>
        <table>
            <thead>
                <tr>
                    <td>PR</td>
                    <td>Voie</td>
                    <td>Position</td>
                    <td>Couche</td>
                    <td>Contrôle</td>
                    <td>Phase</td>
                    <td className="data-cell">Valeur</td>
                </tr>
            </thead>
            <tbody>
                {ANALYSIS.map((a, index) => (
                    <tr key={index} className={a.controle}>
                        <td>{a.pr ? floatToPrString(a.pr) : ''}</td>
                        <td>{`Voie ${a.road}`}</td>
                        <td>{RoadPositionLabel[a.roadPosition as RoadPosition] ?? ''}</td>
                        <td>{a.position !== undefined ? a.position + 1 : ''}</td>
                        <td>{ControleLabel[a.controle as Controle]}</td>
                        <td>{PhaseLabel[a.phase as Phase]}</td>
                        <td className={a.data?.problematic ? 'problematic' : ''}>
                            <ProgressBar value={a.data?.value} maximum={STATISTICS?.max} minimum={STATISTICS?.min} cursor={STATISTICS?.average} />
                            {formatNumberToFixedDecimal(a.data?.value)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default FakeDataTable;