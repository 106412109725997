import { useEffect, useMemo } from 'react';
import ImageList from '../../../components/ImageList';
import DatePicker from '../../../components/inputs/DatePicker';
import Select from '../../../components/inputs/Select';
import { Textarea } from '../../../components/inputs/Textarea';
import TextInput from '../../../components/inputs/TextInput';
import PanelRight from '../../../components/ui/PanelRight';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { OuvrageMarker } from '../../../models/ouvrage-marker';
import useWorkspace from '../../../services/hooks/use-workspace';
import { postRequest, putRequest } from '../../../services/request.service';
import './index.scss';

export interface OuvrageMarkerFormProps {
    marker: Partial<OuvrageMarker>;
    onClose: () => void;
    onSubmit: () => void;
}

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    description: [{ comparator: FormComparator.REQUIRED }],
    date: [{ comparator: FormComparator.REQUIRED }],
};

const OuvrageMarkerForm = ({ marker, onClose, onSubmit }: OuvrageMarkerFormProps) => {
    const { ouvrage } = useWorkspace();
    const { attachInput, entity, onChange, validate } = useForm<OuvrageMarker>(marker);

    const elements = useMemo(() => ouvrage.elements?.map(e => ({ key: e._id, label: e.name })), [ouvrage]);

    const handleSubmit = async () => {
        const entity = validate(VALIDATION);
        if (!entity) return;

        const create = !entity._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<OuvrageMarker>('/ouvrage-marker', entity, {
            successMessage: create ? 'Point d\'intérêt créé avec succès' : 'Point d\'intérêt modifié avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then(() => {
                onSubmit();
                onClose();
            })
            .catch(() => null);
    };

    const handleDelete = () => null;

    useEffect(() => {
        if (marker.coordinates) {
            onChange('coordinates', marker.coordinates);
        }
    }, [marker]);

    return (
        <PanelRight
            title={!entity._id ? 'Créer un point d\'intérêt' : 'Modifier un point d\'intérêt'}
            id="ouvrage-marker"
            actions={[
                { label: 'Fermer', color: 'secondary', onClick: onClose },
                { label: 'Confirmer', onClick: handleSubmit },
            ]}
            onDelete={handleDelete}
        >
            <div className="input-column">
                <label htmlFor="name">Nom *</label>
                <TextInput placeholder="Nom du marker" {...attachInput('name')} />
            </div>
            <div className="input-column">
                <label htmlFor="description">Description</label>
                <Textarea {...attachInput('description')} />
            </div>
            <div className="input-column">
                <label htmlFor="date">Date *</label>
                <DatePicker max={new Date()} withIcon {...attachInput('date')} />
            </div>
            <div className="input-column">
                <label htmlFor="description">Elément</label>
                <Select items={elements} {...attachInput('element')} />
            </div>
            <ImageList
                column
                entityId={entity._id}
                url={entity._id ? `/ouvrage-marker/${entity._id}/images` : undefined}
                editable
                onChange={(storageFiles) => onChange('storageFiles', storageFiles)}
            />
        </PanelRight>
    )
}
export default OuvrageMarkerForm;