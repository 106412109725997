import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UsersManagementPanel from '../../components/entities/UsersManagementPanel';
import Button from '../../components/ui/Button';
import Header from '../../components/ui/Header';
import { MenuBarNavigation } from '../../components/ui/MenuBar';
import UsersPills from '../../components/ui/UsersPills';
import useForm, { FormComparator } from '../../hooks/useForm';
import { OperationDto, OperationSubType, OperationSynoptiqueType, OperationType } from '../../models/operation';
import useAuth from '../../services/hooks/use-auth.hook';
import { getRequest, postRequest, putRequest } from '../../services/request.service';
import { ActionIcon } from '../../utils/icons';
import General from './Edit/General';
import Way from './Edit/Way';
import Zone from './Edit/Zone';
import './index.scss';

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    date: [{ comparator: FormComparator.REQUIRED }]
};
const VALIDATION_WAY_1 = {
    'way1.name': [{ comparator: FormComparator.REQUIRED }],
    'way1.prStart': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
    'way1.prEnd': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
};
const VALIDATION_WAY_2 = {
    'way2.name': [{ comparator: FormComparator.REQUIRED }],
    'way2.prStart': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
    'way2.prEnd': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
}
const VALIDATION_TYPE_2 = {};

const Edit = () => {
    const { operationId, type } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { entity, attachInput, validate, setEntity, setChanged, onChange, hasChanged, errors, areFieldsOnError } = useForm<OperationDto>({});
    const [page, setPage] = useState<string | null>(null);
    const [isUserPanelVisible, setUserPanelVisible] = useState<boolean>(false);

    const pages = useMemo(() => [
        { key: 'general', label: 'Général', error: areFieldsOnError(['name', 'date']) },
        ...(entity.synoptique === 2
            ? [
                { key: 'zone', label: 'Zone' }
            ] : [
                { key: 'sens1', label: 'Sens 1', error: areFieldsOnError(['way1.name', 'way1.prStart', 'way1.prEnd']) },
                { key: 'sens2', label: 'Sens 2', error: areFieldsOnError(['way2.name', 'way2.prStart', 'way2.prEnd']) }
            ])
    ], [entity, areFieldsOnError]);

    const handleSubmit = useCallback(async (type: number, withWay2: boolean, close?: boolean) => {
        const entity = validate({
            ...VALIDATION,
            ...(type === 1 ? {
                ...VALIDATION_WAY_1,
                ...(withWay2 ? VALIDATION_WAY_2 : {})
            } : VALIDATION_TYPE_2)
        })
        if (!entity) return;

        const create = !entity?._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<OperationDto>('/operation', entity, {
            successMessage: create ? 'Opération créée avec succès' : 'Opération mise à jour avec succès',
            errorMessage: "Une erreur est survenue lors de l'enregistrement",
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(`/operation/${data._id}?refresh_operation=1`)
                } else {
                    setChanged(false);
                    setEntity(data);
                    navigate(`/operation/${data._id}/editer/${page}?refresh_operation=1`);
                }
            })
            .catch(() => null);
    }, [validate, setEntity]);

    useEffect(() => {
        if (type) {
            setEntity({
                type: type as OperationType,
                synoptique: OperationSubType[type as OperationType]?.type === OperationSynoptiqueType.LINEAR ? 1 : 2,
                license: user.managedLicense?._id, licensePopulated: user.managedLicense,
                users: [],
                active: true
            });
        } else if (operationId) {
            getRequest<OperationDto>(`/operation/edit/${operationId}`, {
                errorMessage: 'Une erreur est survenue lors de la récupération de l\'opération.',
                loader: true
            })
                .then(setEntity)
                .catch(() => navigate('/'))
        } else {
            navigate('/');
        }
    }, []);

    if (!entity.synoptique || entity._id !== operationId) {
        return (null);
    }

    return (
        <Fragment>
            <Header
                breadcrumbs={[{ label: 'Opérations', href: '/' }, { label: entity?.name ? 'Editer l\'opération' : 'Nouvelle opération' }]}
            >
                <UsersPills users={entity.users} onClick={() => setUserPanelVisible(true)} />
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={ActionIcon.SAVE}
                            onClick={() => handleSubmit(entity.synoptique ?? 1, !!entity.way2)}
                        />
                        <Button

                            label="Enregistrer et fermer"
                            icon={ActionIcon.SAVE_CLOSE}
                            onClick={() => handleSubmit(entity.synoptique ?? 1, !!entity.way2, true)}
                        />
                    </Fragment>
                )}
            </Header>
            <MenuBarNavigation pages={pages} setPage={setPage} />
            {page === 'general' && <General entity={entity} onChange={onChange} attachInput={attachInput} errors={errors} />}
            {page === 'sens1' && <Way entity={entity} onChange={onChange} attachInput={attachInput} way={1} />}
            {page === 'sens2' && <Way entity={entity} onChange={onChange} attachInput={attachInput} way={2} />}
            {page === 'zone' && <Zone entity={entity} onChange={onChange} attachInput={attachInput} />}
            <UsersManagementPanel
                visible={isUserPanelVisible}
                onClose={() => setUserPanelVisible(false)}
                entity={entity}
                onChange={(users) => onChange('users', users)}
                withSubcontractors
            />
        </Fragment >
    )
}

export default Edit;