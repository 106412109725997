import { Fragment } from 'react/jsx-runtime';
import { FormHookReturn } from '../../../hooks/useForm';
import { Location } from '../../../models/location';
import Map, { Marker } from '../../data/Map';
import NumberInput from '../NumberInput';
import PrInput from '../PrInput';
import SelectAutocomplete from '../SelectAutocomplete';
import Switch from '../Switch';
import TextInput from '../TextInput';
import './index.scss';

export interface LocationPickerProps extends Pick<FormHookReturn<{ location?: Location }>, 'attachInput' | 'onChange' | 'onChangeMultiple' | 'entity'> {

}

const LocationPicker = ({ attachInput, onChange, onChangeMultiple, entity }: LocationPickerProps) => {

    return (
        <div className="location-picker row">
            <div className="col lg5">
                <h4>Adresse</h4>
                <div className="row">
                    <div className="input-column lg2">
                        <label htmlFor="streetNumber">Numéro</label>
                        <TextInput {...attachInput('location.address.streetNumber')} />
                    </div>
                    <div className="input-column lg10">
                        <label htmlFor="route">Rue</label>
                        <TextInput {...attachInput('location.address.route')} />
                    </div>
                </div>
                <div className="row">
                    <div className="input-column lg5">
                        <label htmlFor="city">Ville</label>
                        <TextInput {...attachInput('location.address.city')} />
                    </div>
                    <div className="input-column lg3">
                        <label htmlFor="postalCode">Code postal</label>
                        <TextInput {...attachInput('location.address.postalCode')} />
                    </div>
                    <div className="input-column lg4">
                        <label htmlFor="country">Pays</label>
                        <TextInput {...attachInput('location.address.country')} />
                    </div>
                </div>
                <h4>GPS</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="borneType">Longitude</label>
                        <NumberInput {...attachInput('location.gps.longitude')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="borneType">Latitude</label>
                        <NumberInput {...attachInput('location.gps.latitude')} />
                    </div>
                </div>
                <h4>Réseau</h4>
                <div className="input-column">
                    <label htmlFor="borneType">Type</label>
                    <Switch
                        className="form-switch"
                        items={[{ key: 'autoroute', label: 'Autoroute' }, { key: 'route', label: 'Route' }, { key: undefined, label: 'Autre' }]}
                        {...attachInput('location.network.type')}
                    />
                </div>
                {entity.location?.network?.type && (
                    <Fragment>
                        <div className="input-column">
                            <label htmlFor="referential">Référentiel *</label>
                            <SelectAutocomplete
                                endpoint={`/borne/list/${entity.location?.network?.type}`}
                                {...attachInput('location.network.referential')}
                            />
                        </div>
                        <table className="col lg12">
                            <thead>
                                <tr>
                                    <th />
                                    <th><label>PR *</label></th>
                                    <th><label>PR de fin</label></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><label>Sens 1</label></td>
                                    <td><PrInput {...attachInput('location.network.way1.pr')} /></td>
                                    <td><PrInput {...attachInput('location.network.way1.prEnd')} /></td>
                                </tr>
                                <tr>
                                    <td><label>Sens 2</label></td>
                                    <td><PrInput {...attachInput('location.network.way2.pr')} /></td>
                                    <td><PrInput {...attachInput('location.network.way2.prEnd')} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </Fragment>
                )}
            </div>
            <Map onGeocoder={(gps, address) => onChangeMultiple([{ field: 'location.address', value: address }, { field: 'location.gps', value: gps }])}>
                {!!entity.location?.gps?.longitude && !!entity.location?.gps?.latitude && (
                    <Marker
                        className="color-blue"
                        longitude={entity.location?.gps?.longitude}
                        latitude={entity.location?.gps?.latitude}
                        onDragEnd={({ lngLat }) => onChange('location.gps', { longitude: lngLat.lng, latitude: lngLat.lat })}
                    />
                )}
            </Map>
        </div>
    )
}
export default LocationPicker;