import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { AnalysisStatistics } from '../../../models/analysis';
import { Phase } from '../../../models/operation';
import { Controles } from '../../../models/sample';
import { ListItem } from '../../../models/shared';
import { LayerColors } from '../../../synoptique/Synoptique.class';
import { formatTimestamp } from '../../../utils/format';
import { StatisticsChartProps } from './StatisticsChart';

const COMMON_DATASET_OPTIONS = {
    borderWidth: 1,
    pointRadius: 2,
    spanGaps: true,
}

const getDatasets = (statistics: AnalysisStatistics, axis: string, chartType: string, chartBy: string, phases: Phase[], materials: ListItem[], businesses: ListItem[]) => {
    const datasets: any[] = [{
        ...COMMON_DATASET_OPTIONS,
        type: 'line',
        label: 'Moyenne',
        data: statistics.data?.map(d => d.average ?? undefined) ?? [],
        backgroundColor: LayerColors[0],
        borderColor: LayerColors[0]
    }];
    if (chartType === 'average') {
        if (chartBy === 'business') {
            datasets.push(...Object.keys(statistics.business).map((business, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'line',
                label: businesses.find(b => b.key === business)?.label ?? business,
                data: statistics.data?.map(d => d.business[business]?.average ?? undefined) ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        } else if (chartBy === 'controle') {
            datasets.push(...Controles.map((c, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'line',
                label: c.label,
                data: statistics.data?.map(d => d.controle[c.key]?.average ?? undefined) ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        } else if (chartBy === 'material') {
            datasets.push(...Object.keys(statistics.material).map((material, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'line',
                label: materials?.find(b => b.key === material)?.label ?? material,
                data: statistics.data?.map(d => d.material[material]?.average ?? undefined) ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        }
    } else {
        if (chartBy === 'business') {
            datasets.push(...Object.keys(statistics.business).map((business, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'scatter',
                label: businesses.find(b => b.key === business)?.label ?? business,
                data: statistics.data?.map(d => d.business[business]?.values.map(v => ({ x: axis === 'date' ? formatTimestamp(Number(d.groupIndex)) : d.label ?? d.groupIndex, y: v })) ?? []).flat() ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        } else if (chartBy === 'controle') {
            datasets.push(...Controles.map((c, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'scatter',
                label: c.label,
                data: statistics.data?.map(d => d.controle[c.key]?.values.map(v => ({ x: axis === 'date' ? formatTimestamp(Number(d.groupIndex)) : d.label ?? d.groupIndex, y: v })) ?? []).flat() ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        } else if (chartBy === 'material') {
            datasets.push(...Object.keys(statistics.material).map((material, index) => ({
                ...COMMON_DATASET_OPTIONS,
                type: 'scatter',
                label: materials?.find(b => b.key === material)?.label ?? material,
                data: statistics.data?.map(d => d.material[material]?.values.map(v => ({ x: axis === 'date' ? formatTimestamp(Number(d.groupIndex)) : d.label ?? d.groupIndex, y: v })) ?? []).flat() ?? [],
                backgroundColor: LayerColors[index + 1],
                borderColor: LayerColors[index + 1]
            })));
        }
    }
    return datasets;
}


const StatisticsNonLinearChart = ({
    data,
    type,
    by,
    axis,
    scale,
    phases = [],
    materials = [],
    businesses = []
}: StatisticsChartProps) => {
    const [datasets, setDatasets] = useState<any[] | null>(null);
    const [labels, setLabels] = useState<string[]>([]);

    useEffect(() => {
        setDatasets(getDatasets(data, axis, type, by, phases, materials, businesses));

        setLabels(axis === 'date'
            ? data.data.map(d => formatTimestamp(Number(d.groupIndex))!)
            : data.data.map(d => d.label ?? String(d.groupIndex)));
    }, [data, type, by, axis]);

    if (!datasets) {
        return null;
    }

    return (
        <Chart
            type="line"
            data={{
                labels: labels ?? [],
                datasets: datasets ?? [],
            }}
            options={{
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom'
                    },
                },
                maintainAspectRatio: false,
                scales: {
                    y: {
                        min: scale.min,
                        max: scale.max,
                    },
                    x: {
                        title: {
                            display: true,
                            text: axis === 'date' ? 'Date' : 'Référence',
                            align: 'start',
                            font: {
                                weight: 'bold',
                                size: 14,
                                style: 'italic',
                            }
                        }
                    }
                }
            }}
        />
    )
}

export default StatisticsNonLinearChart;