import { Icon } from '@iconify/react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Analysis } from '../../models/analysis';
import { Lot } from '../../models/lot';
import { Material, TypeGranulat, TypeGranulatLabel } from '../../models/material';
import { Melange, MelangeIcon } from '../../models/melange';
import { Operation, Phases, RoadPositionLabel } from '../../models/operation';
import { Controles, Fissure, FissureLabel, Sample, SampleCompiled, SampleLayer, SampleType } from '../../models/sample';
import useWorkspace from '../../services/hooks/use-workspace';
import { getRequest } from '../../services/request.service';
import { LayerColors } from '../../synoptique/Synoptique.class';
import { formatDate, formatNumberToFixedDecimal } from '../../utils/format';
import { ActionIcon } from '../../utils/icons';
import { floatToPrString } from '../../utils/pr';
import SampleAnalysisColumn from '../Analysis/SampleAnalysisColumn';
import { IdentityCardOperation } from '../entities/IdentityCard';
import ValidationCard from '../entities/ValidationCard';
import Card from '../ui/Card';
import Dropdown from '../ui/Dropdown';
import Menu from '../ui/Menu';
import { ModalRight } from '../ui/Modal/ModalRight';
import Tag from '../ui/Tag';
import './index.scss';

interface SampleLayerDropdownProps {
    order: number;
    problematic?: boolean;
    analysisPopulated?: Analysis;
    melangePopulated?: Melange;
    materialPopulated?: Material;
    supposedMaterial?: string;
    thickness?: number;
    type: SampleType;
    collage?: boolean;
    typeGranulat?: TypeGranulat;
    fissure?: Fissure;
    fracture?: Fissure;
    comment?: string;
    compiled?: boolean;
    createdFrom?: {
        lotPopulated: Lot;
        operationPopulated: Operation;
    }
}

const SampleLayerDropdown = ({
    order,
    problematic,
    analysisPopulated,
    melangePopulated,
    materialPopulated,
    supposedMaterial,
    thickness,
    type,
    collage,
    typeGranulat,
    fissure,
    fracture,
    comment,
    compiled,
    createdFrom,
}: SampleLayerDropdownProps) => {

    return (
        <Dropdown
            key={order}
            title={
                <div className="sample-detail-layer-title">
                    {problematic && <Icon icon="mdi:warning-outline" className="color-error" />}
                    {!!analysisPopulated?.data && <Icon icon="ph:test-tube-fill" />}
                    {!!melangePopulated?.analysisPopulated?.data && <Icon icon={MelangeIcon} className={melangePopulated?.problematic ? 'color-error' : ''} />}
                    <span className={createdFrom ? 'theoretical' : ''}>{materialPopulated?.name ?? supposedMaterial ?? '(Couche sans nom)'}</span>
                </div>
            }
            subTitle={!!thickness ? <span className={createdFrom ? 'theoretical' : ''}>{formatNumberToFixedDecimal(thickness)} cm</span> : ''}
            className={`layer${order}`}
        >
            <div className="sample-detail-layer">
                {!createdFrom && type === SampleType.CAROTTE && !collage && <span className="sample-detail-layer-collage-error"><Icon icon="mdi:warning-outline" className="icon-small color-error" /> Collage manquant</span>}
                {!createdFrom && type === SampleType.CAROTTE && collage && <span>Collage : oui</span>}
                {typeGranulat && <span>Type granulat : {TypeGranulatLabel[typeGranulat]}</span>}
                {!createdFrom && !!fissure && <span className="sample-detail-layer-collage-error"><Icon icon="mdi:warning-outline" className="icon-small color-error" />Fissure {FissureLabel[fissure] ?? ''}</span>}
                {!createdFrom && !!fracture && <span className="sample-detail-layer-collage-error"><Icon icon="mdi:warning-outline" className="icon-small color-error" />Fracture {FissureLabel[fracture] ?? ''}</span>}
                {analysisPopulated?.data && (
                    <Fragment>
                        <h4>Analyse</h4>
                        <SampleAnalysisColumn analysis={analysisPopulated} material={materialPopulated} />
                    </Fragment>
                )}
                {melangePopulated?.analysisPopulated?.data && (
                    <Fragment>
                        <h4>{melangePopulated?.problematic && <Icon icon="mdi:warning-outline" className="icon-small color-error" />}Mélange : {melangePopulated.name}</h4>
                        <SampleAnalysisColumn analysis={melangePopulated.analysisPopulated} material={materialPopulated} />
                    </Fragment>
                )}
                {!!createdFrom && (
                    <Fragment>
                        <div>Compilé depuis {createdFrom.operationPopulated.name}, lot {createdFrom.lotPopulated.fullLot}</div>
                    </Fragment>
                )}
                {comment && <div className="sample-detail-layer-comment"><h4>Commentaire</h4><span>{comment}</span></div>}
            </div>
        </Dropdown>
    )
}

const WIDTH = 48;
const HEIGHT = 96;

const SampleIconLayer = ({ height, depth, order, color }: { height: number; depth: number; order: number; color: string }) => {
    if (isNaN(height)) {
        return (null);
    }

    return (
        <path
            stroke="#ffffff"
            fill={color}
            strokeWidth="1"
            d={order === 0
                ? `
            M0,${depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)} 0 0,0 ${WIDTH},${depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)} 0 0,0 0,${depth + 10}
            L0,${height + depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)},0 0,0 ${WIDTH},${height + depth + 10}
            L${WIDTH},${depth + 10} 
        `
                : `
            M0,${depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)} 0 0,0 ${WIDTH},${depth + 10}
            L${WIDTH},${height + depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)},0 0,1 0,${height + depth + 10}
            L0,${depth + 10} 
        `
            }
        />
    );
}

interface SampleIconProps {
    layers: SampleLayer[];
    thickness: number;
}

const SampleIcon = ({ layers, thickness }: SampleIconProps) => (
    <svg viewBox={`0 0 ${WIDTH} ${HEIGHT + 20}`} style={{ width: WIDTH, height: HEIGHT }}>
        {layers.length > 1
            ? layers.map((layer) => {
                const depth = layers.filter((l) => l.order < layer.order).reduce((sum, l) => Number(l.thickness) + sum, 0);
                const layerHeight = layer.thickness * HEIGHT / thickness;
                const layerDepth = depth * HEIGHT / thickness;

                return <SampleIconLayer key={layer.order} order={layer.order} color={LayerColors[layer.order]} height={layerHeight} depth={layerDepth} />
            }
            ) : (
                <SampleIconLayer order={0} height={HEIGHT} color={LayerColors[0]} depth={0} />
            )
        }
    </svg>
);

const SampleCompiledIcon = ({ sampleCompiled }: { sampleCompiled: SampleCompiled }) => sampleCompiled.lots.length ? (
    <svg viewBox={`0 0 ${WIDTH} ${HEIGHT + 20}`} style={{ width: WIDTH, height: HEIGHT }}>
        {sampleCompiled.layers.length > 1
            ? sampleCompiled.layers.map((layer) => {
                const depth = sampleCompiled.layers.filter((l) => l.order < layer.order).reduce((sum, l) => Number(l.thickness) + sum, 0);
                const layerHeight = layer.thickness * HEIGHT / sampleCompiled.thickness;
                const layerDepth = depth * HEIGHT / sampleCompiled.thickness;

                return <SampleIconLayer key={layer.order} order={layer.order} color={!layer.originalLayer ? '#634b63' : LayerColors[layer.originalLayer.order]} height={layerHeight} depth={layerDepth} />
            }
            ) : (
                <SampleIconLayer order={0} height={HEIGHT} depth={0} color={LayerColors[0]} />
            )
        }
    </svg>
) : <SampleIcon layers={sampleCompiled.originalLayers} thickness={sampleCompiled.thickness} />;

interface SampleDetailProps {
    id?: string;
    sample?: Sample;
    onClose: (refresh?: boolean) => void;
}

const SampleDetail = ({
    id,
    sample: sampleFromProps,
    onClose
}: SampleDetailProps) => {
    const { operation, workspacePermissions } = useWorkspace();
    const [sample, setSample] = useState<Sample | null>(sampleFromProps ?? null);
    const [sampleCompiled, setSampleCompiled] = useState<SampleCompiled | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getRequest<Sample>(`/sample/${id}/analysis`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'échantillon' })
                .then(setSample)
                .catch(() => onClose());
        } else if (sampleFromProps) {
            setSample(sampleFromProps);
        } else {
            setSample(null);
            onClose();
        }
    }, [id, sampleFromProps]);

    useEffect(() => {
        setSampleCompiled(null);

        if (sample?.sampleCompiled) {
            getRequest<SampleCompiled>(`/sample/compiled/${sample.sampleCompiled}`)
                .then(setSampleCompiled)
                .catch(() => setSampleCompiled(null));
        }
    }, [sample?.sampleCompiled]);

    if (!sample) {
        return (null);
    }

    return (
        <ModalRight
            visible={!!sample}
            actions={[{ label: 'Fermer', color: 'secondary', onClick: onClose }]}
            title={sample?.name}
            icon={workspacePermissions.write && !sample.readOnly ? <Menu label="Editer" icon={ActionIcon.EDIT} onClick={() => navigate(`/operation/${operation?._id}/${sample.phase}/${sample.type}/${sample._id}/editer`)} /> : undefined}
        >
            <div id="sample-detail">
                <Card>
                    <div id="sample-detail-header">
                        <SampleIcon layers={sample.layers} thickness={sample.thickness} />
                        <div id="sample-detail-title">
                            <div>
                                <span>{formatDate(sample.date)}</span>
                                <Tag value={sample.controle} items={Controles} />
                                <Tag value={sample.phase} items={Phases} />
                            </div>
                            <div id="road">{sample.location?.road ? operation.roadsObj?.[sample.location?.road]?.label : ''}</div>
                            {operation.synoptique === 1 && <div id="position">{floatToPrString(sample.location?.pr) ?? ''}, {sample.location?.roadPosition ? RoadPositionLabel[sample.location?.roadPosition] : ''}</div>}
                        </div>
                    </div>
                    {!!sample.thickness && <span><strong>Epaisseur : </strong>{formatNumberToFixedDecimal(sample.thickness)} cm</span>}
                    {!!sample.diameter && <span><strong>Diamètre carottage : </strong>{formatNumberToFixedDecimal(sample.diameter, 0)} mm</span>}
                    {!!sample.comment && <span><strong>Commentaire : </strong>{sample.comment}</span>}
                    {!!sample.location?.location && <span><strong>Localisation : </strong>{sample.location?.location}</span>}
                </Card>
                <ValidationCard
                    entity={sample}
                    canValidate={workspacePermissions.validate}
                    endpoint="/sample"
                    onSubmit={() => onClose(true)}
                />
                {sampleCompiled && sampleCompiled.lots.length ? (
                    <div id="sample-detail-compiled">
                        <h4>Structure au sol</h4>
                        <Card className="sample-detail-layers">
                            {sampleCompiled.layers.map(layer => (
                                <SampleLayerDropdown
                                    key={layer.order}
                                    order={layer.originalLayer?.order ?? -1}
                                    problematic={layer.originalLayer?.problematic}
                                    analysisPopulated={layer.originalLayer?.analysisPopulated}
                                    melangePopulated={layer.originalLayer?.melangePopulated}
                                    materialPopulated={layer.materialPopulated}
                                    supposedMaterial={layer.originalLayer?.supposedMaterial}
                                    thickness={layer.thickness}
                                    type={sample.type}
                                    collage={layer.originalLayer?.collage}
                                    typeGranulat={layer.originalLayer?.typeGranulat}
                                    fissure={layer.originalLayer?.fissure}
                                    fracture={layer.originalLayer?.fracture}
                                    compiled={true}
                                    createdFrom={layer.createdFrom}
                                />
                            ))}
                        </Card>
                    </div>
                ) : (
                    <Card className="sample-detail-layers">
                        {sample.layers.map(layer => (
                            <SampleLayerDropdown
                                key={layer.order}
                                order={layer.order}
                                problematic={layer.problematic}
                                analysisPopulated={layer.analysisPopulated}
                                melangePopulated={layer.melangePopulated}
                                materialPopulated={layer.materialPopulated}
                                supposedMaterial={layer.supposedMaterial}
                                thickness={layer.thickness}
                                type={sample.type}
                                collage={layer.collage}
                                typeGranulat={layer.typeGranulat}
                                fissure={layer.fissure}
                                fracture={layer.fracture}
                                comment={layer.comment}
                                compiled={false}
                            />
                        ))}
                    </Card>
                )}
                {!!sampleCompiled && !!sampleCompiled.lots.length && (
                    <div id="sample-detail-compiled">
                        <h4>Echantillon d'étude</h4>
                        <Card className="sample-detail-layers">
                            {sample.layers.map(layer => (
                                <SampleLayerDropdown
                                    key={layer.order}
                                    order={layer.order}
                                    problematic={layer.problematic}
                                    analysisPopulated={layer.analysisPopulated}
                                    melangePopulated={layer.melangePopulated}
                                    materialPopulated={layer.materialPopulated}
                                    supposedMaterial={layer.supposedMaterial}
                                    thickness={layer.thickness}
                                    type={sample.type}
                                    collage={layer.collage}
                                    typeGranulat={layer.typeGranulat}
                                    fissure={layer.fissure}
                                    fracture={layer.fracture}
                                    comment={layer.comment}
                                    compiled={false}
                                />
                            ))}
                        </Card>
                    </div>
                )}
            </div>
        </ModalRight >
    );
}

interface SampleHistoryDetailProps {
    id?: string;
    onClose: (refresh?: boolean) => void;
}

export const SampleHistoryDetail = ({
    id,
    onClose
}: SampleHistoryDetailProps) => {
    const { operation } = useWorkspace();
    const [sampleCompiled, setSampleCompiled] = useState<SampleCompiled | null>(null);

    const road = useMemo(() => operation.roads.find(r => sampleCompiled?.roads.includes(r._id)), [sampleCompiled]);

    useEffect(() => {
        if (id) {
            getRequest<SampleCompiled>(`/sample/compiled/${id}`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'échantillon' })
                .then(setSampleCompiled)
                .catch(() => onClose());
        } else {
            setSampleCompiled(null);
            onClose();
        }
    }, [id]);

    if (!sampleCompiled) {
        return (null);
    }

    return (
        <ModalRight
            visible={!!sampleCompiled}
            actions={[{ label: 'Fermer', color: 'secondary', onClick: onClose }]}
            title="Echantillon compilé"
        >
            <div id="sample-detail">
                <Card>
                    <div id="sample-detail-header">
                        <SampleCompiledIcon sampleCompiled={sampleCompiled} />
                        <div id="sample-detail-title">
                            <div>
                                <span>{formatDate(sampleCompiled.date)}</span>
                            </div>
                            <div id="road">{road?.label ?? ''}</div>
                            {operation.synoptique === 1 && <div id="position">{floatToPrString(sampleCompiled.pr) ?? ''}, {RoadPositionLabel[sampleCompiled.roadPosition]}</div>}
                        </div>
                    </div>
                    <span><strong>Epaisseur : </strong>{formatNumberToFixedDecimal(sampleCompiled.thickness)} cm</span>
                </Card>
                <div id="sample-detail-compiled">
                    <h4>Structure au sol</h4>
                    <Card className="sample-detail-layers">
                        {sampleCompiled.layers.map(layer => (
                            <SampleLayerDropdown
                                key={layer.order}
                                order={layer.originalLayer?.order ?? -1}
                                problematic={layer.originalLayer?.problematic}
                                analysisPopulated={layer.originalLayer?.analysisPopulated}
                                melangePopulated={layer.originalLayer?.melangePopulated}
                                materialPopulated={layer.materialPopulated}
                                supposedMaterial={layer.originalLayer?.supposedMaterial}
                                thickness={layer.thickness}
                                type={SampleType.CAROTTE}
                                collage={layer.originalLayer?.collage}
                                typeGranulat={layer.originalLayer?.typeGranulat}
                                fissure={layer.originalLayer?.fissure}
                                fracture={layer.originalLayer?.fracture}
                                compiled={true}
                                createdFrom={layer.createdFrom}
                            />
                        ))}
                    </Card>
                </div>
                <div id="sample-detail-compiled">
                    <h4>Echantillon d'étude</h4>
                    {!!sampleCompiled.operationPopulated && (
                        <IdentityCardOperation entity={sampleCompiled.operationPopulated} />
                    )}
                    <Card className="sample-detail-layers">
                        {sampleCompiled.originalLayers?.map(layer => (
                            <SampleLayerDropdown
                                key={layer.order}
                                order={layer.order}
                                problematic={layer.problematic}
                                analysisPopulated={layer.analysisPopulated}
                                melangePopulated={layer.melangePopulated}
                                materialPopulated={layer.materialPopulated}
                                supposedMaterial={layer.supposedMaterial}
                                thickness={layer.thickness}
                                type={SampleType.CAROTTE}
                                collage={layer.collage}
                                typeGranulat={layer.typeGranulat}
                                fissure={layer.fissure}
                                fracture={layer.fracture}
                                comment={layer.comment}
                                compiled={false}
                            />
                        ))}
                    </Card>
                </div>
            </div>
        </ModalRight >
    );
}

export default SampleDetail;