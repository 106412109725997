import { FormEvent, Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Password from '../../components/inputs/Password';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/ui/Button';
import useForm, { FormComparator } from '../../hooks/useForm';
import { UserLogin } from '../../models/user';
import useWorkspace from '../../services/hooks/use-workspace';
import { postRequest } from '../../services/request.service';
import { authenticate } from '../../services/slices/auth.slice';
import { setWorkspace, WorkspaceLabel, WorkspaceType } from '../../services/slices/workspace.slice';

const VALIDATION = {
    email: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.EMAIL }],
    password: [{ comparator: FormComparator.REQUIRED }],
};

const Login = () => {
    const dispatch = useDispatch();
    const { attachInput, validate } = useForm<UserLogin>({});
    const { workspace } = useWorkspace();

    const handleSubmit = useCallback(async (e?: FormEvent) => {
        e?.preventDefault();
        const entity = validate(VALIDATION);
        if (!entity) return;

        postRequest<{ token: string }>('/user/login', entity, { errorMessage: 'Les identifiants sont incorrects', loader: true })
            .then((data) => dispatch(authenticate(data.token)))
            .catch(() => null);
    }, [validate]);

    return (
        <Fragment>
            <h4>Se connecter à l'application</h4>
            <div id="login-menu">
                {[WorkspaceType.CHAUSSES, WorkspaceType.OUVRAGES].map(w => (
                    <div key={w} className={workspace === w ? 'active' : undefined} onClick={() => dispatch(setWorkspace(w))}>
                        {WorkspaceLabel[w]}
                    </div>
                ))}
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <TextInput {...attachInput('email')} autoComplete />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Mot de passe</label>
                    <Password {...attachInput('password')} />
                </div>
                <div className="public-footer">
                    <Button label="Se connecter" type="submit" />
                    <Link to="/recuperation"> Mot de passe oublié ?</Link>
                </div>
            </form>
        </Fragment>
    );
}

export default Login;
