import Card from '../../../components/ui/Card';
import Select from '../../../components/inputs/Select';
import TextInput from '../../../components/inputs/TextInput';
import { CoucheTypes } from '../../../models/lot';
import { MaterialSpecificationsFormProps } from '../EditComponent';

const EnrobeEdit = ({
    attachInput
}: MaterialSpecificationsFormProps) => (
    <Card title="Destination">
        <div className="row">
            <div className="input-column">
                <label htmlFor="specifications.typeCouche">Type de couche *</label>
                <Select
                    items={CoucheTypes}
                    {...attachInput('specifications.typeCouche')}
                />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.couche">Couche</label>
                <TextInput {...attachInput('specifications.couche')} />
            </div>
        </div>
    </Card>
)

export default EnrobeEdit;