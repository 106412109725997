import { Icon } from '@iconify/react';
import { Fragment, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TypeGranulatLabel } from '../../models/material';
import { MelangeIcon } from '../../models/melange';
import { Phases, RoadPositionLabel } from '../../models/operation';
import { Controles, FissureLabel, Sample, SampleType } from '../../models/sample';
import useWorkspace from '../../services/hooks/use-workspace';
import { getRequest } from '../../services/request.service';
import { LayerColors } from '../../synoptique/Synoptique.class';
import { formatDate, formatNumberToFixedDecimal } from '../../utils/format';
import { ActionIcon } from '../../utils/icons';
import { floatToPrString } from '../../utils/pr';
import SampleAnalysisColumn from '../Analysis/SampleAnalysisColumn';
import ValidationCard from '../entities/ValidationCard';
import Card from '../ui/Card';
import Dropdown from '../ui/Dropdown';
import Menu from '../ui/Menu';
import { ModalRight } from '../ui/Modal/ModalRight';
import Tag from '../ui/Tag';
import './index.scss';

const WIDTH = 48;
const HEIGHT = 96;

const SampleIconLayer = ({ height, depth, order }: { height: number; depth: number; order: number; }) => {
    if (isNaN(height)) {
        return (null);
    }

    return (
        <path
            stroke="#ffffff"
            fill={LayerColors[order]}
            strokeWidth="1"
            d={order === 0
                ? `
            M0,${depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)} 0 0,0 ${WIDTH},${depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)} 0 0,0 0,${depth + 10}
            L0,${height + depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)},0 0,0 ${WIDTH},${height + depth + 10}
            L${WIDTH},${depth + 10} 
        `
                : `
            M0,${depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)} 0 0,0 ${WIDTH},${depth + 10}
            L${WIDTH},${height + depth + 10}
            A${Math.floor((WIDTH / 2) - 1)},${Math.floor(WIDTH * 8 / 64)},0 0,1 0,${height + depth + 10}
            L0,${depth + 10} 
        `
            }
        />
    );
}

const SampleIcon = ({ sample }: { sample: Sample }) => (
    <svg viewBox={`0 0 ${WIDTH} ${HEIGHT + 20}`} style={{ width: WIDTH, height: HEIGHT }}>
        {sample.layers.length > 1
            ? sample.layers.map((layer) => {
                const depth = sample.layers.filter((l) => l.order < layer.order).reduce((sum, l) => Number(l.thickness) + sum, 0);
                const layerHeight = (layer.thickness * HEIGHT / sample.thickness) ?? 0;
                const layerDepth = (depth * HEIGHT / sample.thickness) ?? 0;

                return <SampleIconLayer key={layer.order} order={layer.order} height={layerHeight} depth={layerDepth} />
            }
            ) : (
                <SampleIconLayer order={0} height={HEIGHT} depth={0} />
            )
        }
    </svg>
);

interface SampleDetailProps {
    id?: string;
    sample?: Sample;
    compiled?: boolean;
    onClose: (refresh?: boolean) => void;
}

const SampleDetail = ({
    id,
    sample: sampleFromProps,
    compiled = false,
    onClose
}: SampleDetailProps) => {
    const { operation, workspacePermissions } = useWorkspace();
    const [sample, setSample] = useState<Sample | null>(sampleFromProps ?? null);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getRequest<Sample>(!compiled ? `/sample/${id}/analysis` : `/sample/compiled/${id}/analysis`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'échantillon' })
                .then(setSample)
                .catch(() => onClose());
        } else if (sampleFromProps) {
            setSample(sampleFromProps);
        } else {
            setSample(null);
            onClose();
        }
    }, [id, sampleFromProps]);

    if (!sample) {
        return (null);
    }

    return (
        <ModalRight
            visible={!!sample}
            actions={[{ label: 'Fermer', color: 'secondary', onClick: onClose }]}
            title={sample?.name}
            icon={workspacePermissions.write && !sample.readOnly ? <Menu label="Editer" icon={ActionIcon.EDIT} onClick={() => navigate(`/operation/${operation?._id}/${sample.phase}/${sample.type}/${sample._id}/editer`)} /> : undefined}
        >
            <div id="sample-detail">
                <Card>
                    <div id="sample-detail-header">
                        <SampleIcon sample={sample} />
                        <div id="sample-detail-title">
                            <div>
                                <span>{formatDate(sample.date)}</span>
                                <Tag value={sample.controle} items={Controles} />
                                <Tag value={sample.phase} items={Phases} />
                            </div>
                            <div id="road">{sample.location?.road ? operation.roadsObj?.[sample.location?.road]?.label : ''}</div>
                            {operation.synoptique === 1 && <div id="position">{floatToPrString(sample.location?.pr) ?? ''}, {sample.location?.roadPosition ? RoadPositionLabel[sample.location?.roadPosition] : ''}</div>}
                        </div>
                    </div>
                    {!!sample.thickness && <span><strong>Epaisseur : </strong>{formatNumberToFixedDecimal(sample.thickness)} cm</span>}
                    {!!sample.diameter && <span><strong>Diamètre carottage : </strong>{formatNumberToFixedDecimal(sample.diameter, 0)} mm</span>}
                    {!!sample.comment && <span><strong>Commentaire : </strong>{sample.comment}</span>}
                    {!!sample.location?.location && <span><strong>Localisation : </strong>{sample.location?.location}</span>}
                </Card>
                <ValidationCard
                    entity={sample}
                    canValidate={workspacePermissions.validate}
                    endpoint="/sample"
                    onSubmit={() => onClose(true)}
                />
                <Card className="sample-detail-layers">
                    {sample.layers.map(layer => (
                        <Dropdown
                            key={layer.order}
                            title={
                                <div className="sample-detail-layer-title">
                                    {layer.problematic && <Icon icon="fluent:warning-16-regular" className="color-error" />}
                                    {!!layer.analysisPopulated?.data && <Icon icon="ph:test-tube-fill" />}
                                    {!!layer.melangePopulated?.analysisPopulated?.data && <Icon icon={MelangeIcon.MELANGE} className={layer.melangePopulated?.problematic ? 'color-error' : ''} />}
                                    {layer.material ? layer.materialPopulated?.name : layer.supposedMaterial ?? '(Couche sans nom)'}
                                </div>
                            }
                            subTitle={!!layer.thickness ? <span className={layer.isTheoretical ? 'theoretical' : ''}>{formatNumberToFixedDecimal(layer.thickness)} cm</span> : ''}
                            className={`layer${layer.order}`}
                        >
                            <div className="sample-detail-layer">
                                {sample.type === SampleType.CAROTTE && !layer.collage && <span className="sample-detail-layer-collage-error"><Icon icon="fluent:warning-16-regular" className="icon-small color-error" /> Collage manquant</span>}
                                {sample.type === SampleType.CAROTTE && layer.collage && <span>Collage : oui</span>}
                                {layer.typeGranulat && <span>Type granulat : {TypeGranulatLabel[layer.typeGranulat]}</span>}
                                {!!layer.fissure && <span className="sample-detail-layer-collage-error"><Icon icon="fluent:warning-16-regular" className="icon-small color-error" />Fissure {FissureLabel[layer.fissure] ?? ''}</span>}
                                {!!layer.fracture && <span className="sample-detail-layer-collage-error"><Icon icon="fluent:warning-16-regular" className="icon-small color-error" />Fracture {FissureLabel[layer.fracture] ?? ''}</span>}
                                {layer.analysisPopulated?.data && (
                                    <Fragment>
                                        <h4>Analyse</h4>
                                        <SampleAnalysisColumn analysis={layer.analysisPopulated} material={layer.materialPopulated} />
                                    </Fragment>
                                )}
                                {layer.melangePopulated?.analysisPopulated?.data && (
                                    <Fragment>
                                        <h4>{layer.melangePopulated?.problematic && <Icon icon="fluent:warning-16-regular" className="icon-small color-error" />}Mélange : {layer.melangePopulated.name}</h4>
                                        <SampleAnalysisColumn analysis={layer.melangePopulated.analysisPopulated} material={layer.materialPopulated} />
                                    </Fragment>
                                )}
                                {layer.comment && <div className="sample-detail-layer-comment"><h4>Commentaire</h4><span>{layer.comment}</span></div>}
                            </div>
                        </Dropdown>
                    ))}
                </Card>
            </div>
        </ModalRight >
    );
}

export default memo(SampleDetail);