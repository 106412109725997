import { Icon } from '@iconify/react';
import { useCallback, useState } from 'react';
import { FormBaseProps } from '../../../hooks/useForm';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { formatDate } from '../../../utils/format';
import DatePickerCalendar from './DatePickerCalendar';
import './index.scss';

interface DatePickerProps extends FormBaseProps<Date> {
    max?: Date;
    min?: Date;
    hideIcon?: boolean;
}

const DatePicker = ({
    id,
    value,
    onChange,
    max,
    min,
    hideIcon,
    disabled,
    errors
}: DatePickerProps) => {
    const [isDatePickerVisible, setDatePickerVisible] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => setDatePickerVisible(false), []));

    return (
        <div className="form-input-text form-input-datepicker" ref={ref}>
            <input
                id={id}
                type="text"
                readOnly
                value={value ? formatDate(value) : ''}
                onClick={() => !disabled ? setDatePickerVisible(true) : null}
                className="border-input"
            />
            {!!errors?.length && <div className="form-error">{errors.join('. ')}</div>}
            {!hideIcon && <Icon icon="mdi:calendar" onClick={() => !disabled ? setDatePickerVisible(true) : null} />}
            {isDatePickerVisible && (
                <DatePickerCalendar
                    id={id}
                    value={value ?? new Date()}
                    onChange={(date) => { onChange(date); setDatePickerVisible(false) }}
                    max={max}
                    min={min}
                />
            )}
        </div>
    )
}

export default DatePicker;