import { Icon } from "@iconify/react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import Header from "../../../components/ui/Header";
import MenuBar from "../../../components/ui/MenuBar";
import { Diagnostic } from "../../../models/diagnostic";
import { OperationIcon } from "../../../models/operation";
import { OuvrageElement } from "../../../models/ouvrage";
import useWorkspace from "../../../services/hooks/use-workspace";
import { getRequest, putRequest } from "../../../services/request.service";
import { formatDate } from "../../../utils/format";
import { ActionIcon } from "../../../utils/icons";
import { replaceInArray } from "../../../utils/objects";
import { DiagnosticElementForm } from "./DiagnosticElements";
import DiagnosticResults from "./DiagnosticResults";
import DiagnosticView from "./DiagnosticView";


const DiagnosticSubcontractor = () => {
    const { ouvrage } = useWorkspace();
    const navigate = useNavigate();
    const [diagnostic, setDiagnostic] = useState<Diagnostic>();
    const [elementToEdit, setElementToEdit] = useState<OuvrageElement>();
    const [mode, setMode] = useState<string>('view');

    const progress = Math.floor((diagnostic?.elements?.length ?? 0) * 100 / ouvrage.elements.length);

    const handleSubmit = (close?: boolean, send?: boolean, comment?: string) => {
        putRequest<Diagnostic>('/diagnostic/subcontractor' + (send ? '/send' : ''), { ...diagnostic, subcontractorComment: comment }, {
            successMessage: 'Diagnostic mis à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setDiagnostic(data);
                }
            })
            .catch(() => null);
    };

    useEffect(() => {
        getRequest<Diagnostic>(`/diagnostic/subcontractor/${ouvrage._id}`, { errorMessage: 'Une erreur est survenue lors de la récupération du diagnostic.', loader: true })
            .then(setDiagnostic)
            .catch(() => navigate(-1));

    }, [ouvrage._id]);

    if (!diagnostic) return null;

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: ouvrage?.name, href: `/ouvrage/${ouvrage?._id}` },
                    { label: 'Diagnostics', href: `/ouvrage/${ouvrage?._id}/diagnostics` },
                    { label: `Diagnostic du ${formatDate(diagnostic.date)}` }
                ]}
                withBorder
            >
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                <Fragment>
                    <Button
                        label="Enregistrer"
                        icon={ActionIcon.SAVE}
                        onClick={handleSubmit}
                    />
                    <Button

                        label="Enregistrer et fermer"
                        icon={ActionIcon.SAVE_CLOSE}
                        onClick={() => handleSubmit(true)}
                    />
                </Fragment>
            </Header>
            <MenuBar>
                <MenuBar.Item
                    label="Vue"
                    onClick={() => setMode('view')}
                    active={mode === 'view'}
                    icon={OperationIcon.SYNOPTIQUE}
                />
                <MenuBar.Item
                    label="Résultats"
                    onClick={() => setMode('results')}
                    active={mode === 'results'}
                    icon="mdi:file-graph"
                />
                <div id="diagnostic-progress">
                    <Icon icon="mdi:progress-helper" className="icon-small" />
                    <span>Progression</span>
                    <div><div style={ouvrage.elements.length ? { width: progress + '%' } : undefined} /></div>
                </div>
            </MenuBar>
            {mode === 'results' && (
                <DiagnosticResults
                    diagnostic={diagnostic}
                    onElementSelect={setElementToEdit}
                    onValidate={progress === 100 ? (comment) => handleSubmit(true, true, comment) : undefined}
                />
            )}
            {mode === 'view' && (
                <DiagnosticView
                    diagnostic={diagnostic}
                    onElementSelect={setElementToEdit}
                    elementSelected={elementToEdit}
                />
            )}
            {!!elementToEdit && (
                <DiagnosticElementForm
                    element={elementToEdit}
                    diagnosticElement={diagnostic.elements?.find(e => e.elementId === elementToEdit._id)}
                    onClose={() => setElementToEdit(undefined)}
                    onSubmit={(element) => setDiagnostic({ ...diagnostic, elements: replaceInArray(element, diagnostic.elements, (e1, e2) => e1.elementId === e2.elementId, true) })}
                />
            )}
        </Fragment>
    )
}
export default DiagnosticSubcontractor;