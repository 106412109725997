import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Analysis } from '../../models/analysis';
import { Component } from '../../models/component';
import { Material, MaterialCharacteristicElementRequirement, getAnalysisCharac } from '../../models/material';
import { Controle, ControleLabel, Controles } from '../../models/sample';
import { ListItem, RevisionStatus, RevisionStatusLabel, RevisionStatusList } from '../../models/shared';
import useWorkspace from '../../services/hooks/use-workspace';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../services/request.service';
import { formatDate } from '../../utils/format';
import { ActionIcon } from '../../utils/icons';
import { changeFieldValue } from '../../utils/objects';
import AnalysisTable from '../AnalysisTable';
import { IdentityCardMaterial } from '../entities/IdentityCard';
import { default as DatePicker } from '../inputs/DatePicker';
import Select from '../inputs/Select';
import { Textarea } from '../inputs/Textarea';
import './index.scss';

interface ComponentAnalysisProps {
    material: Material;
}

const ComponentAnalysis = ({
    material
}: ComponentAnalysisProps) => {
    const { workspacePermissions, operation } = useWorkspace();
    const [characteristics, setCharacteristics] = useState<MaterialCharacteristicElementRequirement[]>([]);
    const [providers, setProviders] = useState<ListItem[]>([]);
    const [analysis, setAnalysis] = useState<Partial<Component & { isEditing: boolean }>[]>([]);
    const [errors, setErrors] = useState<{ [index: number]: { [key: string]: string[] } } | null>(null);

    const controles = useMemo(() => Controles.filter(c => workspacePermissions.controles?.includes(c.key)), [workspacePermissions]);

    const handleChange = useCallback((index: number, field: string, value: any) => {
        setAnalysis(analysis => {
            if (index >= 0 && index < analysis?.length) {
                const _analysis = [...analysis];
                _analysis[index] = field !== 'analysis' ? changeFieldValue(_analysis[index], field, value) : value;
                return _analysis;
            }
            return analysis;
        });
    }, []);

    const getAnalysis = useCallback(async () => {
        getRequest<Component[]>(`/component/operation/${operation._id}/material/${material._id}`, { loader: true, errorMessage: 'Une erreur est survenue lors de la récupération des analyses' })
            .then(data => setAnalysis([
                {
                    status: workspacePermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
                    analysisPopulated: {
                        controle: workspacePermissions.controles?.[0] ?? Controle.EXTERIEUR,
                        date: new Date(),
                        business: material.business,
                        data: {},
                    } as Analysis,
                    isEditing: true
                },
                ...data.sort((a, b) => (a.analysisPopulated?.date ?? 0) > (b.analysisPopulated?.date ?? 0) ? -1 : 1)
            ]))
            .catch(() => null);
    }, [material]);

    const handleCopy = useCallback((index: number) => {
        setAnalysis(analysis => {
            if (index >= 0 && index < analysis?.length) {
                const _analysis = [...analysis];
                _analysis[0] = {
                    isEditing: true,
                    status: workspacePermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
                    analysisPopulated: {
                        controle: _analysis[index].analysisPopulated?.controle ?? workspacePermissions.controles?.[0] ?? Controle.EXTERIEUR,
                        date: _analysis[index].analysisPopulated?.date ?? new Date(),
                        business: _analysis[index].analysisPopulated?.business ?? material.business,
                        data: { ...analysis[index].analysisPopulated?.data },
                    } as Analysis
                };
                return _analysis;
            }
            return analysis;
        })
    }, [material]);

    const handleSave = useCallback(async (index: number) => {
        const analysisToSave = analysis[index];
        if (!analysisToSave) return;

        const create = !analysisToSave?._id;
        const requestMethod = create ? postRequest : putRequest;
        const dto = create ? {
            material: material?._id,
            type: material?.type,
            operation: material?.operation,
            status: analysisToSave.status,
            ...analysisToSave.analysisPopulated,
        } : {
            status: analysisToSave.status,
            ...analysisToSave.analysisPopulated,
        }

        requestMethod('/component', dto, {
            successMessage: create ? 'Analyse créée avec succès' : 'Analyse mise à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then(() => getAnalysis())
            .catch(() => null);
    }, [getAnalysis, material, analysis]);

    const handleDelete = useCallback(async (index: number) => {
        const analysisToDelete = analysis[index];
        if (!analysisToDelete) return;
        deleteRequest(`/component/${analysisToDelete._id}`, { loader: true, successMessage: 'Analyse supprimée avec succès', errorMessage: 'Une erreur est survenue lors de la suppression' })
            .then(() => {
                getAnalysis();
            })
            .catch(() => null);
    }, [getAnalysis, analysis]);

    useEffect(() => {
        if (material) {
            setCharacteristics(getAnalysisCharac(material));
            getAnalysis();
        }
    }, [material]);

    useEffect(() => {
        getRequest<ListItem[]>('/business/list/provider')
            .then(setProviders)
            .catch(() => null);
    }, []);

    if (!characteristics?.length) return null;

    return (
        <Fragment>
            <IdentityCardMaterial entity={material} />
            <AnalysisTable>
                <AnalysisTable.Separator colsPan={characteristics.length} label="Informations" />
                <AnalysisTable.Row label="Date">
                    {analysis.map((a, index) => (
                        <AnalysisTable.Cell key={a._id ?? ('new-analysis' + index)} isEditing={a.isEditing} value={formatDate(a.analysisPopulated?.date)}>
                            <DatePicker id={index + 'date'} value={a.analysisPopulated?.date} max={new Date()} onChange={(v) => handleChange(index, 'analysisPopulated.date', v)} errors={errors?.[index]?.['analysisPopulated.date']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Statut">
                    {analysis.map((a, index) => (
                        <AnalysisTable.Cell key={a._id ?? ('new-analysis' + index)} isEditing={a.isEditing} value={a.status ? RevisionStatusLabel[a.status] : ''}>
                            <Select id={index + 'status'} items={RevisionStatusList} value={a.status} disabled={!workspacePermissions.validate} onChange={(v) => handleChange(index, 'status', v)} errors={errors?.[index]?.['status']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Controle">
                    {analysis.map((a, index) => (
                        <AnalysisTable.Cell key={a._id ?? ('new-analysis' + index)} isEditing={a.isEditing} value={a.analysisPopulated?.controle ? ControleLabel[a.analysisPopulated?.controle] : ''}>
                            <Select id={index + 'controle'} items={controles} value={a.analysisPopulated?.controle} onChange={(v) => handleChange(index, 'analysisPopulated.controle', v)} errors={errors?.[index]?.['analysisPopulated.controle']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Fournisseur">
                    {analysis.map((a, index) => (
                        <AnalysisTable.Cell key={a._id ?? ('new-analysis' + index)} isEditing={a.isEditing} value={providers.find(p => p.key === a?.analysisPopulated?.business)?.label ?? undefined}>
                            <Select id={index + 'producer'} items={providers} value={a?.analysisPopulated?.business} onChange={(v) => handleChange(index, 'analysisPopulated.business', v)} errors={errors?.[index]?.['analysisPopulated.business']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Separator colsPan={analysis.length} label="Caractéristiques" />
                <AnalysisTable.Characteristics entities={analysis} characteristics={characteristics} onChange={(index, analysis) => handleChange(index, 'analysisPopulated', analysis)} />
                <AnalysisTable.Row label="Commentaire">
                    {analysis.map((a, index) => (
                        <AnalysisTable.Cell key={a._id ?? ('new-analysis' + index)} isEditing={a.isEditing} value={a.analysisPopulated?.comment}>
                            <Textarea id={index + 'comment'} value={a.analysisPopulated?.comment} onChange={(v) => handleChange(index, 'analysisPopulated.comment', v)} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Actions
                    entities={analysis}
                    actionsEditing={[
                        { icon: ActionIcon.EDIT, onClick: (index) => handleChange(index, 'isEditing', true) },
                        { icon: ActionIcon.COPY, onClick: (index) => handleCopy(index) },
                        { icon: ActionIcon.DELETE, onClick: (index) => handleDelete(index) }
                    ]}
                    actions={[
                        { icon: ActionIcon.SAVE, label: 'Sauvegarder', onClick: (index) => handleSave(index) },
                    ]}
                />
            </AnalysisTable>
        </Fragment>
    );
}

export default ComponentAnalysis;