import { BasicEntity } from "./shared";
import { ReadBy, StorageFile } from "./storage";
import { User } from "./user";

export type Message = BasicEntity & {
    message: string;
    private: boolean;
    operation?: string;
    file?: string;
    filePopulated?: StorageFile;
    sentTo: string[];
    sentToPopulated?: User[];
    readBy?: ReadBy[];
};

export enum MessageType {
    FILE = "file",
    MESSAGE = "message",
    OPERATION_UPDATE = "operation_update",
    OUVRAGE_UPDATE = "ouvrage_update",
}
