import React from 'react';
import { useEffect, useState } from 'react';
import { Analysis } from '../../models/analysis';
import { MATERIAL_FORM_PARAMETERS, Material, MaterialCharacteristicElement, UnknownMaterialCharacteristics } from '../../models/material';


interface SampleAnalysisColumnItemProps {
    label: string;
    value: number;
    seuil?: number;
    isBoolean?: boolean;
    problematic: boolean;
}

const SampleAnalysisColumnItem = ({
    label,
    value,
    seuil,
    isBoolean,
    problematic
}: SampleAnalysisColumnItemProps) => (
    <tr className={problematic ? 'problematic' : ''}>
        <td>{label ?? ''}</td>
        <td>{seuil ?? ''}</td>
        <td>{isBoolean ? (value ? 'Oui' : 'Non') : value}</td>
    </tr>
);

interface SampleAnalysisColumnProps {
    material?: Material;
    analysis: Analysis;
}

const SampleAnalysisColumn = ({
    material,
    analysis,
}: SampleAnalysisColumnProps) => {
    const [characteristics, setCharacteristics] = useState<MaterialCharacteristicElement[]>([]);

    useEffect(() => {
        if (material) {
            setCharacteristics(MATERIAL_FORM_PARAMETERS[material.type]?.characteristics?.map(c => c.elements).flat());
        } else {
            setCharacteristics(UnknownMaterialCharacteristics);
        }
    }, [material, analysis]);

    if (!characteristics?.length) {
        return (null);
    }

    return (
        <table className="simple-table sample-analysis-column">
            <thead>
                <tr>
                    <th>Caractéristique</th>
                    <th>Seuil</th>
                    <th>Valeur</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(analysis.data).map(key => {
                    const charac = characteristics.find(c => c.key === key);
                    if (!charac) return (null);

                    const datum = analysis.data[key];
                    if (Array.isArray(datum)) {
                        return datum.map((d, i) => (
                            <SampleAnalysisColumnItem
                                key={key + i}
                                label={charac.label}
                                seuil={d.seuil}
                                value={d.value}
                                problematic={d.problematic}
                            />
                        ));
                    } else {
                        return (
                            <SampleAnalysisColumnItem
                                key={key}
                                label={charac.label}
                                value={datum.value}
                                problematic={datum.problematic}
                                isBoolean={charac.isBoolean}
                            />
                        );
                    }
                }
                )}
            </tbody>
        </table>
    );
}

export default SampleAnalysisColumn;