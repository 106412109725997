import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import StatisticsPicker from '../../../components/data/Statistics/StatisticsPicker';
import { IdentityCardMaterial } from '../../../components/entities/IdentityCard';
import Card from '../../../components/ui/Card';
import Header from '../../../components/ui/Header';
import NoResult from '../../../components/ui/NoResult';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { AnalysisStatistics } from '../../../models/analysis';
import { Material, MaterialType } from '../../../models/material';
import { Permission } from '../../../models/user';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import Granulometrie from './Granulometrie';

const ByMaterialResults = () => {
    const { operation, workspacePermissions } = useWorkspace();
    const { materialId } = useParams();
    const navigate = useNavigate();
    const [material, setMaterial] = useState<Material | null>(null);
    const [statistics, setStatistics] = useState<AnalysisStatistics[] | null>(null);

    useEffect(() => {
        getRequest<Material>(`/material/${materialId}`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'exigence.', loader: true })
            .then(setMaterial)
            .catch(() => navigate(-1));
        getRequest<AnalysisStatistics[]>(`/analysis/material/${materialId}`, { errorMessage: 'Une erreur est survenue lors de la récupération des analyses.' })
            .then(setStatistics)
            .catch(() => setStatistics(null))
    }, [operation, materialId]);

    if (!material) {
        return (null);
    }

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation.name, href: `/operation/${operation._id}` },
                    { label: 'Résultats matériaux', href: `/operation/${operation._id}/resultats/materiaux` },
                    { label: material.name }
                ]}
                withBorder
            />
            <ScrollableContent>
                <Card title="Matériau">
                    <IdentityCardMaterial entity={material} />
                </Card>
                {statistics && !statistics.length
                    ? (
                        <NoResult
                            text="Aucun résultat pour ce matériau"
                        />
                    ) : (
                        <Fragment>
                            <Granulometrie material={material} />
                            {statistics && !!statistics.length && (
                                <Card title="Résultats">
                                    <StatisticsPicker
                                        statistics={statistics}
                                        materialType={material.type}
                                        subtitle={operation.name}
                                        columns={
                                            [MaterialType.ENROBE, MaterialType.SOL, MaterialType.BETON, MaterialType.EMULSION_BITUME].includes(material.type)
                                                ? ['date', 'pr', 'road', 'roadPosition', 'business', 'controle']
                                                : ['date', 'business', 'controle']
                                        }
                                        chartAxis="date"
                                        chartByOptions={['controle', 'business']}
                                        permission={Permission.MATERIAL_RESULTS}
                                        isAuthorized={workspacePermissions.materialResults}
                                    />
                                </Card>
                            )}
                        </Fragment>
                    )}
            </ScrollableContent>
        </Fragment>
    )
}

export default ByMaterialResults;
