import ImageList from "../../../../components/ImageList";
import PanelRight from "../../../../components/ui/PanelRight";
import { OuvrageElement } from "../../../../models/ouvrage";
import useWorkspace from "../../../../services/hooks/use-workspace";


export interface OuvrageViewElementProps {
    element: OuvrageElement;
    onClose: () => void;
}

const OuvrageViewElement = ({ element, onClose }: OuvrageViewElementProps) => {
    const { ouvrage } = useWorkspace();

    return (
        <PanelRight
            id="ouvrage-view-element"
            title={element.name}
            actions={[
                { color: 'secondary', label: 'Fermer', onClick: onClose }
            ]}
        >
            <ImageList column entityId={element._id} url={`/ouvrage/${ouvrage._id}/images/${element._id}`} editable />
        </PanelRight>
    )
}
export default OuvrageViewElement;