import { useSelector } from "react-redux";
import { WorkspaceState } from "../slices/workspace.slice";
import { RootState } from "../store";

const useWorkspace = (): WorkspaceState => {
    const workspaceState = useSelector((state: RootState) => state.workspace);

    return workspaceState;
};

export default useWorkspace;
