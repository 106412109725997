import { Icon } from "@iconify/react";
import { Fragment, useEffect, useState } from "react";
import { SynoptiqueAdvancedFilters, SynoptiqueFilters } from "..";
import PrInput from "../../../../components/inputs/PrInput";
import { Modal } from "../../../../components/ui/Modal";
import Toolbar from "../../../../components/ui/Toolbar";
import { LotIcon } from "../../../../models/lot";
import { UnknownMaterialCharacteristics } from "../../../../models/material";
import { MelangeIcon } from "../../../../models/melange";
import { Phases } from "../../../../models/operation";
import { PopulationTypes } from "../../../../models/population";
import useWorkspace from "../../../../services/hooks/use-workspace";
import { SynoptiqueAction } from "../../../../synoptique/Synoptique.class";
import { ActionIcon } from "../../../../utils/icons";
import { changeFieldValue, toggleInArray } from "../../../../utils/objects";

export interface SynoptiqueMenuProps {
    currentAction?: SynoptiqueAction;
    onActionChange: (a: SynoptiqueAction) => void;
    onGoToPr: (pr: number) => void;
    filters: SynoptiqueFilters;
    onChange: (f: SynoptiqueFilters) => void;
    advancedFilters: SynoptiqueAdvancedFilters;
    toggleFilters: () => void;
    onAdvancedFiltersSubmit: (f: SynoptiqueAdvancedFilters) => void;
}

const SynoptiqueMenu = ({
    currentAction,
    onActionChange,
    onChange,
    onGoToPr,
    toggleFilters,
    filters,
    advancedFilters: advancedFiltersFromProps,
    onAdvancedFiltersSubmit
}: SynoptiqueMenuProps) => {
    const { operation } = useWorkspace();
    const [pr, setPr] = useState<number>();
    const [advancedFiltersVisible, setAdvancedFiltersVisible] = useState<boolean>(false);
    const [advancedFilters, setAdvancedFilters] = useState<SynoptiqueAdvancedFilters>(advancedFiltersFromProps);

    const handleAdvancedFiltersChange = (id: string, value: any) => setAdvancedFilters(changeFieldValue(advancedFilters, id, value));

    const handleGoToPr = (event: any) => {
        if (event?.preventDefault) {
            event.preventDefault();
        }
        if (pr !== undefined) {
            onGoToPr(pr);
        }
    }

    useEffect(() => {
        setAdvancedFilters(advancedFiltersFromProps);
    }, [advancedFiltersFromProps]);


    return (
        <Fragment>
            <Toolbar>
                {operation.synoptique === 1 && (
                    <Toolbar.ToolbarBlock label="Aller au PR">
                        <form id="synoptique-goto" className="synoptique-menu-block" onSubmit={handleGoToPr}>
                            <PrInput id="synoptique-goto" value={pr} onChange={setPr} />
                            <Icon icon={ActionIcon.SEARCH} className="icon-button" onClick={handleGoToPr} onTouchEnd={handleGoToPr} />
                        </form>
                    </Toolbar.ToolbarBlock>
                )}
                <Toolbar.ToolbarBlock label="Phases">
                    {operation.synoptique === 1 && (
                        <Toolbar.ToolbarButton
                            active={filters.history}
                            onClick={() => onChange({ ...filters, history: !filters.history })}
                            label="Historique"
                            icon="mdi:hourglass"
                        />
                    )}
                    {Phases.filter(p => operation.phases.includes(p.key)).map(p => (
                        <Toolbar.ToolbarButton
                            key={p.key}
                            active={filters.phase?.includes(p.key)}
                            onClick={() => onChange({ ...filters, phase: toggleInArray(p.key, filters.phase) })}
                            label={p.label}
                            icon={p.icon}
                        />
                    ))}
                </Toolbar.ToolbarBlock>
                <Toolbar.ToolbarBlock label="Filtrage">
                    <Toolbar.ToolbarButton label="Filtres" icon={ActionIcon.FILTER} onClick={() => toggleFilters()} />
                    <Toolbar.ToolbarButton label="Filtres avancés" icon="mdi:filter-plus-outline" onClick={() => setAdvancedFiltersVisible(true)} />
                </Toolbar.ToolbarBlock>
                <Toolbar.ToolbarBlock label="Sélection">
                    <Toolbar.ToolbarButton label="Couche" icon={LotIcon.LAYER} active={currentAction === SynoptiqueAction.SELECT_LAYER} onClick={() => onActionChange(SynoptiqueAction.SELECT_LAYER)} />
                    <Toolbar.ToolbarButton label="Echantillon" icon="mdi:eyedropper" active={currentAction === SynoptiqueAction.SELECT_ONE} onClick={() => onActionChange(SynoptiqueAction.SELECT_ONE)} />
                    <Toolbar.ToolbarButton label="Un par un" icon="mdi:eyedropper-plus" active={currentAction === SynoptiqueAction.SELECT_ONE_BY_ONE} onClick={() => onActionChange(SynoptiqueAction.SELECT_ONE_BY_ONE)} />
                    <Toolbar.ToolbarButton label="Multiple" icon="mdi:select" active={currentAction === SynoptiqueAction.SELECT_MULTIPLE} onClick={() => onActionChange(SynoptiqueAction.SELECT_MULTIPLE)} />
                    <Toolbar.ToolbarButton label="Mélange" icon={MelangeIcon} active={currentAction === SynoptiqueAction.SELECT_MELANGE} onClick={() => onActionChange(SynoptiqueAction.SELECT_MELANGE)} />
                </Toolbar.ToolbarBlock>
                <Toolbar.ToolbarBlock label="Zoom">
                    <Toolbar.ToolbarButton label="Arrière" icon="mdi:zoom-out-outline" onClick={() => onActionChange(SynoptiqueAction.ZOOM_OUT)} />
                    <Toolbar.ToolbarButton label="Avant" icon="mdi:zoom-in-outline" onClick={() => onActionChange(SynoptiqueAction.ZOOM_IN)} />
                </Toolbar.ToolbarBlock>
                <Toolbar.ToolbarBlock label="Outils">
                    <Toolbar.ToolbarButton label="Imprimer" icon={ActionIcon.PRINT} onClick={() => onActionChange(SynoptiqueAction.PRINT)} />
                </Toolbar.ToolbarBlock>
            </Toolbar>
            {advancedFiltersVisible && (
                <Modal
                    size="small"
                    title="Filtres avancés"
                    id="synoptique-advanced-filters"
                    actions={[
                        { label: 'Annuler', color: 'secondary', onClick: () => { onAdvancedFiltersSubmit({}); setAdvancedFiltersVisible(false); } },
                        { label: 'Confirmer', onClick: () => onAdvancedFiltersSubmit(advancedFilters) },
                    ]}
                >
                    <div className="advanced-filter">
                        <label>Echantillons</label>
                        <select value={advancedFilters?.sampleValue?.key ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.key', e.target.value)}>
                            <option value=""></option>
                            {UnknownMaterialCharacteristics.map(c => <option key={c.key} value={c.key}>{c.label}</option>)}
                        </select>
                        <div>
                            <span>entre</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleValue?.min ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.min', e.target.value)} />
                            <span>et</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleValue?.max ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.max', e.target.value)} />
                            <span>sur la couche</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleValue?.layer ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.layer', e.target.value)} />
                        </div>
                    </div>
                    <div className="advanced-filter">
                        <label>Epaisseur</label>
                        <div>
                            <span>entre</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleThickness?.min ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleThickness.min', e.target.value)} />
                            <span>et</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleThickness?.max ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleThickness.max', e.target.value)} />
                            <span>sur la couche</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleThickness?.layer ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleThickness.layer', e.target.value)} />
                        </div>
                    </div>
                    <div className="advanced-filter">
                        <label>Populations</label>
                        <select value={advancedFilters?.populationValue?.key ?? ''} onChange={(e) => handleAdvancedFiltersChange('populationValue.key', e.target.value)}>
                            <option value=""></option>
                            {PopulationTypes.map(p => <option key={p.key} value={p.key}>{p.label}</option>)}
                        </select>
                        <div>
                            <span>entre</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.populationValue?.min ?? ''} onChange={(e) => handleAdvancedFiltersChange('populationValue.min', e.target.value)} />
                            <span>et</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.populationValue?.max ?? ''} onChange={(e) => handleAdvancedFiltersChange('populationValue.max', e.target.value)} />
                        </div>
                    </div>
                </Modal>
            )}
        </Fragment>
    )
}

export default SynoptiqueMenu;