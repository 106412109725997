import Card from "../../../components/ui/Card";
import Select from "../../../components/inputs/Select";
import TextInput from "../../../components/inputs/TextInput";
import { GradePurs, TypeLiants } from "../../../models/material";
import { MaterialSpecificationsFormProps } from "../EditComponent";

const LiantHydrocarboneEdit = ({
    attachInput
}: MaterialSpecificationsFormProps) => (
    <Card title="Type et grade">
        <div className="row">
            <div className="input-column">
                <label htmlFor="specifications.typeLiant">Type de liant *</label>
                <Select items={TypeLiants} {...attachInput('specifications.typeLiant')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.gradePur">Grade pur</label>
                <Select items={GradePurs} {...attachInput('specifications.gradePur')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.gradeModifie">Grade modifié</label>
                <TextInput {...attachInput('specifications.gradeModifie')} />
            </div>
        </div>
    </Card>
)

export default LiantHydrocarboneEdit;