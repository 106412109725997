import { FilterType, ListColumns } from "../components/data/List";
import { EntityFormRules, FormComparator } from "../hooks/useForm";
import { CoucheType, CoucheTypeLabel, CoucheTypes } from "./lot";

export type Material = {
    _id: string;
    type: MaterialType;
    name: string;
    englishName?: string;
    year?: number;
    isStandard: boolean;
    business?: string;
    standard?: string;
    standardPopulated?: Material;
    operation?: string;
    composition?: Composition;
    specifications?: Specifications;
    characteristics: Characteristics;
    active: boolean;
    comment?: string;
    createdAt: number;
    updatedAt: number;
};

export type Composition = {
    liants?: string[];
    liantsPopulated?: Material[];
    granulats?: string[];
    granulatsPopulated?: Material[];
    additifs?: string[];
    additifsPopulated?: Material[];
};

export type Specifications = {
    origineAdditif?: OrigineAdditif;
    resistanceCompression?: string;
    classeBeton?: string;
    classeEtalement?: ClasseEtalement;
    classeConsistance?: ClasseConsistance;
    ciment?: string;
    chlorureMax?: number;
    dMax?: number;
    dosage?: number;
    dosageGravillons?: number;
    dilution?: number;
    dilutionAutre?: number;
    typeCouche?: CoucheType;
    couche?: string;
    typeGranulat?: TypeGranulat;
    fractionGranulat?: string;
    origineGranulat?: OrigineGranulat;
    destinationGranulat?: DestinationGranulat;
    typeLiant?: TypeLiant;
    gradePur?: GradePur;
    gradeModifie?: string;
    typeSol?: TypeSol;
    destinationSol?: DestinationSol;
    classePlateforme?: ClassePlateforme;
    classeArase?: ClasseArase;
};

export type Compliance = {
    min95?: number;
    max95?: number;
    min100?: number;
    max100?: number;
};

export type ObjectifMinMax = {
    objectif?: number;
    min?: number;
    max?: number;
};

export type SeuilObjectifMinMax = ObjectifMinMax & {
    seuil: number;
};

export type ValConsObjectifMinMax = ObjectifMinMax & {
    nombreValeursConsecutives?: number;
};

export type Characteristics = {
    [field: string]:
        | ObjectifMinMax
        | SeuilObjectifMinMax[]
        | ValConsObjectifMinMax
        | Compliance
        | undefined;
};

export enum MaterialType {
    ADDITIF = "additif",
    BETON = "beton",
    EMULSION_BITUME = "emulsionBitume",
    ENROBE = "enrobe",
    GRANULAT = "granulat",
    LIANT_HYDRAULIQUE = "liantHydraulique",
    LIANT_HYDROCARBONE = "liantHydrocarbone",
    SOL = "sol",
}

export const MaterialTypeLabel: Record<MaterialType, string> = {
    [MaterialType.ADDITIF]: "Additif",
    [MaterialType.ENROBE]: "Enrobé",
    [MaterialType.GRANULAT]: "Granulat",
    [MaterialType.BETON]: "Béton",
    [MaterialType.SOL]: "Sol",
    [MaterialType.LIANT_HYDRAULIQUE]: "Liant hydraulique",
    [MaterialType.LIANT_HYDROCARBONE]: "Liant hydrocarboné",
    [MaterialType.EMULSION_BITUME]: "Émulsion de bitume",
};

export const MaterialTypes = [
    {
        key: MaterialType.GRANULAT,
        label: MaterialTypeLabel[MaterialType.GRANULAT],
        color: "#ffa425",
    },
    {
        key: MaterialType.ENROBE,
        label: MaterialTypeLabel[MaterialType.ENROBE],
        color: "#b57f2e",
    },
    {
        key: MaterialType.BETON,
        label: MaterialTypeLabel[MaterialType.BETON],
        color: "#7e5822",
    },
    {
        key: MaterialType.SOL,
        label: MaterialTypeLabel[MaterialType.SOL],
        color: "#452f11",
    },
    {
        key: MaterialType.LIANT_HYDROCARBONE,
        label: MaterialTypeLabel[MaterialType.LIANT_HYDROCARBONE],
        color: "#695942",
    },
    {
        key: MaterialType.LIANT_HYDRAULIQUE,
        label: MaterialTypeLabel[MaterialType.LIANT_HYDRAULIQUE],
        color: "#3f3425",
    },
    {
        key: MaterialType.ADDITIF,
        label: MaterialTypeLabel[MaterialType.ADDITIF],
        color: "#b4956a",
    },
    {
        key: MaterialType.EMULSION_BITUME,
        label: MaterialTypeLabel[MaterialType.EMULSION_BITUME],
        color: "#443e37",
    },
];

export const Components = [
    {
        key: MaterialType.GRANULAT,
        label: MaterialTypeLabel[MaterialType.GRANULAT],
    },
    {
        key: MaterialType.LIANT_HYDROCARBONE,
        label: MaterialTypeLabel[MaterialType.LIANT_HYDROCARBONE],
    },
    {
        key: MaterialType.LIANT_HYDRAULIQUE,
        label: MaterialTypeLabel[MaterialType.LIANT_HYDRAULIQUE],
    },
    {
        key: MaterialType.ADDITIF,
        label: MaterialTypeLabel[MaterialType.ADDITIF],
    },
];

export enum OrigineAdditif {
    CHIMIQUE = "chimique",
    NATUREL = "naturel",
    PETROLIFERE = "petrolifere",
    AUTRE = "autre",
}
export const OrigineAdditifLabel = {
    [OrigineAdditif.CHIMIQUE]: "Chimique",
    [OrigineAdditif.NATUREL]: "Naturel",
    [OrigineAdditif.PETROLIFERE]: "Pétrolifère",
    [OrigineAdditif.AUTRE]: "Autre",
};

export const OrigineAdditifs = [
    {
        key: OrigineAdditif.CHIMIQUE,
        label: OrigineAdditifLabel[OrigineAdditif.CHIMIQUE],
    },
    {
        key: OrigineAdditif.NATUREL,
        label: OrigineAdditifLabel[OrigineAdditif.NATUREL],
    },
    {
        key: OrigineAdditif.PETROLIFERE,
        label: OrigineAdditifLabel[OrigineAdditif.PETROLIFERE],
    },
    {
        key: OrigineAdditif.AUTRE,
        label: OrigineAdditifLabel[OrigineAdditif.AUTRE],
    },
];

export enum ClasseEtalement {
    SF1 = "SF1",
    SF2 = "SF2",
    SF3 = "SF3",
}

export const ClasseEtalements = [
    { key: ClasseEtalement.SF1, label: ClasseEtalement.SF1 },
    { key: ClasseEtalement.SF2, label: ClasseEtalement.SF2 },
    { key: ClasseEtalement.SF3, label: ClasseEtalement.SF3 },
];

export enum ClasseConsistance {
    S1 = "S1",
    S2 = "S2",
    S3 = "S3",
    S4 = "S4",
    S5 = "S5",
}

export const ClasseConsistances = [
    { key: ClasseConsistance.S1, label: ClasseConsistance.S1 },
    { key: ClasseConsistance.S2, label: ClasseConsistance.S2 },
    { key: ClasseConsistance.S3, label: ClasseConsistance.S3 },
    { key: ClasseConsistance.S4, label: ClasseConsistance.S4 },
    { key: ClasseConsistance.S5, label: ClasseConsistance.S5 },
];

export enum OrigineGranulat {
    SABLES = "sables",
    GRAVES = "graves",
    GRAVILLONS = "gravillons",
    BALLASTS = "ballasts",
    AGREGATS = "agregats",
}

export const OrigineGranulatLabel = {
    [OrigineGranulat.SABLES]: "Sables",
    [OrigineGranulat.GRAVES]: "Graves",
    [OrigineGranulat.GRAVILLONS]: "Gravillons",
    [OrigineGranulat.BALLASTS]: "Ballasts",
    [OrigineGranulat.AGREGATS]: "Agregats d'enrobés",
};

export const OrigineGranulats = [
    {
        key: OrigineGranulat.SABLES,
        label: OrigineGranulatLabel[OrigineGranulat.SABLES],
    },
    {
        key: OrigineGranulat.GRAVES,
        label: OrigineGranulatLabel[OrigineGranulat.GRAVES],
    },
    {
        key: OrigineGranulat.GRAVILLONS,
        label: OrigineGranulatLabel[OrigineGranulat.GRAVILLONS],
    },
    {
        key: OrigineGranulat.BALLASTS,
        label: OrigineGranulatLabel[OrigineGranulat.BALLASTS],
    },
    {
        key: OrigineGranulat.AGREGATS,
        label: OrigineGranulatLabel[OrigineGranulat.AGREGATS],
    },
];

export enum TypeGranulat {
    NATUREL = "naturel",
    ARTIFICIEL = "artificiel",
    ALLUVIONNAIRE = "alluvionnaire",
    RECYCLE = "recycle",
    MASSIF = "massif",
}

export const TypeGranulatLabel = {
    [TypeGranulat.NATUREL]: "Naturel",
    [TypeGranulat.ARTIFICIEL]: "Artificiel",
    [TypeGranulat.ALLUVIONNAIRE]: "Alluvionnaire",
    [TypeGranulat.RECYCLE]: "Recyclé",
    [TypeGranulat.MASSIF]: "Massif",
};

export const TypeGranulats = [
    {
        key: TypeGranulat.NATUREL,
        label: TypeGranulatLabel[TypeGranulat.NATUREL],
    },
    {
        key: TypeGranulat.ARTIFICIEL,
        label: TypeGranulatLabel[TypeGranulat.ARTIFICIEL],
    },
    {
        key: TypeGranulat.ALLUVIONNAIRE,
        label: TypeGranulatLabel[TypeGranulat.ALLUVIONNAIRE],
    },
    {
        key: TypeGranulat.RECYCLE,
        label: TypeGranulatLabel[TypeGranulat.RECYCLE],
    },
    { key: TypeGranulat.MASSIF, label: TypeGranulatLabel[TypeGranulat.MASSIF] },
];

export enum DestinationGranulat {
    BETON_MORTIER = "beton_mortier",
    ROUTIER = "routier",
    GRAVES = "graves",
}

export const DestinationGranulatLabel = {
    [DestinationGranulat.BETON_MORTIER]: "Bétons et mortiers",
    [DestinationGranulat.ROUTIER]: "Routiers",
    [DestinationGranulat.GRAVES]: "Graves",
};

export const DestinationGranulats = [
    {
        key: DestinationGranulat.BETON_MORTIER,
        label: DestinationGranulatLabel[DestinationGranulat.BETON_MORTIER],
    },
    {
        key: DestinationGranulat.ROUTIER,
        label: DestinationGranulatLabel[DestinationGranulat.ROUTIER],
    },
    {
        key: DestinationGranulat.GRAVES,
        label: DestinationGranulatLabel[DestinationGranulat.GRAVES],
    },
];

export enum TypeLiant {
    PUR = "pur",
    MODIFIE = "modifie",
}

export enum GradePur {
    GRADE_5_15 = "5/15",
    GRADE_10_20 = "10/20",
    GRADE_15_25 = "15/25",
    GRADE_20_30 = "20/30",
    GRADE_35_50 = "35/50",
    GRADE_50_70 = "50/70",
    GRADE_70_100 = "70/100",
    GRADE_160_220 = "160/220",
}

export const GradePurs = [
    { key: GradePur.GRADE_5_15, label: GradePur.GRADE_5_15 },
    { key: GradePur.GRADE_10_20, label: GradePur.GRADE_10_20 },
    { key: GradePur.GRADE_15_25, label: GradePur.GRADE_15_25 },
    { key: GradePur.GRADE_20_30, label: GradePur.GRADE_20_30 },
    { key: GradePur.GRADE_35_50, label: GradePur.GRADE_35_50 },
    { key: GradePur.GRADE_50_70, label: GradePur.GRADE_50_70 },
    { key: GradePur.GRADE_70_100, label: GradePur.GRADE_70_100 },
    { key: GradePur.GRADE_160_220, label: GradePur.GRADE_160_220 },
];

export enum DestinationSol {
    COUCHE_FORME = "couche_forme",
    PST = "pst",
    DEBLAI_REMBLAI = "deblai_remblai",
}

export const DestinationSolLabel = {
    [DestinationSol.COUCHE_FORME]: "Couche de forme",
    [DestinationSol.PST]: "PST (épaisseur 1m)",
    [DestinationSol.DEBLAI_REMBLAI]: "Déblai/Remblai",
};

export const DestinationSols = [
    {
        key: DestinationSol.COUCHE_FORME,
        label: DestinationSolLabel[DestinationSol.COUCHE_FORME],
    },
    { key: DestinationSol.PST, label: DestinationSolLabel[DestinationSol.PST] },
    {
        key: DestinationSol.DEBLAI_REMBLAI,
        label: DestinationSolLabel[DestinationSol.DEBLAI_REMBLAI],
    },
];

export enum TypeSol {
    NATUREL = "naturel",
    TRAITE = "traite",
}

export const TypeSolLabel = {
    [TypeSol.NATUREL]: "Naturel",
    [TypeSol.TRAITE]: "Traité",
};

export const TypeSols = [
    { key: TypeSol.NATUREL, label: TypeSolLabel[TypeSol.NATUREL] },
    { key: TypeSol.TRAITE, label: TypeSolLabel[TypeSol.TRAITE] },
];

export enum ClassePlateforme {
    PF1 = "PF1: 20-49 Mpa",
    PF2 = "PF2: 50-119 Mpa",
    PF3 = "PF3: 120-199 Mpa",
    PF4 = "PF4: > 200Mpa",
}

export const ClassePlateformeLabel = {
    [ClassePlateforme.PF1]: "PF1: 20-49 Mpa",
    [ClassePlateforme.PF2]: "PF2: 50-119 Mpa",
    [ClassePlateforme.PF3]: "PF3: 120-199 Mpa",
    [ClassePlateforme.PF4]: "PF4: > 200Mpa",
};

export const ClassePlateformes = [
    {
        key: ClassePlateforme.PF1,
        label: ClassePlateformeLabel[ClassePlateforme.PF1],
    },
    {
        key: ClassePlateforme.PF2,
        label: ClassePlateformeLabel[ClassePlateforme.PF2],
    },
    {
        key: ClassePlateforme.PF3,
        label: ClassePlateformeLabel[ClassePlateforme.PF3],
    },
    {
        key: ClassePlateforme.PF4,
        label: ClassePlateformeLabel[ClassePlateforme.PF4],
    },
];

export enum ClasseArase {
    AR1 = "AR1: 20-49 Mpa",
    AR2 = "AR2: 50-119 Mpa",
    AR3 = "AR3: 120-199 Mpa",
    AR4 = "AR4: > 200Mpa",
}

export const ClasseAraseLabel = {
    [ClasseArase.AR1]: "AR1: 20-49 Mpa",
    [ClasseArase.AR2]: "AR2: 50-119 Mpa",
    [ClasseArase.AR3]: "AR3: 120-199 Mpa",
    [ClasseArase.AR4]: "AR4: > 200Mpa",
};

export const ClasseArases = [
    { key: ClasseArase.AR1, label: ClasseAraseLabel[ClasseArase.AR1] },
    { key: ClasseArase.AR2, label: ClasseAraseLabel[ClasseArase.AR2] },
    { key: ClasseArase.AR3, label: ClasseAraseLabel[ClasseArase.AR3] },
    { key: ClasseArase.AR4, label: ClasseAraseLabel[ClasseArase.AR4] },
];

export const TypeLiantLabel = {
    [TypeLiant.PUR]: "Pur",
    [TypeLiant.MODIFIE]: "Modifié",
};

export const TypeLiants = [
    { key: TypeLiant.PUR, label: TypeLiantLabel[TypeLiant.PUR] },
    { key: TypeLiant.MODIFIE, label: TypeLiantLabel[TypeLiant.MODIFIE] },
];

export const getAnalysisCharac = (
    material: Material
): MaterialCharacteristicElementRequirement[] => {
    const defaultCharacs: (MaterialCharacteristicElement & {
        requirement?: any;
    })[] = MATERIAL_FORM_PARAMETERS[material.type].characteristics
        .sort((c1, c2) => ((c1.order ?? 0) > (c2.order ?? 0) ? 1 : -1))
        .map((c) => c.elements)
        .flat()
        .filter((c) => !c.hideInSample);

    return (defaultCharacs ?? [])
        .map((c) => {
            const requirement = material?.characteristics?.[c.key];
            if (c.seuil) {
                const seuils: SeuilObjectifMinMax[] = [];
                for (const seuil of Array.isArray(requirement)
                    ? requirement
                    : []) {
                    const index = seuils.findIndex(
                        (s) => s.seuil === seuil.seuil
                    );
                    if (index === -1) {
                        seuils.push(seuil);
                    } else {
                        seuils[index] = seuil;
                    }
                }

                return seuils.length
                    ? {
                          ...c,
                          requirement,
                          seuils: seuils.sort((s1, s2) =>
                              s1.seuil > s2.seuil ? 1 : -1
                          ),
                      }
                    : c;
            } else {
                return { ...c, requirement };
            }
        })
        .filter(
            (c) => !!c.requirement
        ) as MaterialCharacteristicElementRequirement[];
};

export interface MaterialCharacteristicElement {
    key: string;
    label: string;
    order?: number;
    unit?: string;
    objectifUnit?: string;
    seuilUnit?: string;
    seuil?: string;
    seuils?: SeuilObjectifMinMax[];
    minMaxUnit?: string;
    onlyMin?: boolean;
    onlyMax?: boolean;
    hideInSample?: boolean;
    withValCons?: boolean;
    compliance?: boolean;
    isBoolean?: boolean;
    selected?: boolean;
}

export interface MaterialCharacteristicElementRequirement
    extends MaterialCharacteristicElement {
    requirement?:
        | ObjectifMinMax
        | SeuilObjectifMinMax[]
        | ValConsObjectifMinMax
        | Compliance;
}

export interface MaterialCharacteristic {
    key: string;
    label: string;
    order?: number;
    elements: MaterialCharacteristicElement[];
}

export const MATERIAL_FORM_PARAMETERS: Record<
    MaterialType,
    {
        validationCatalogue: EntityFormRules;
        characteristics: MaterialCharacteristic[];
    }
> = {
    [MaterialType.ADDITIF]: {
        validationCatalogue: {
            name: [{ comparator: FormComparator.REQUIRED }],
            year: [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_INTEGER },
            ],
            "specifications.origineAdditif": [
                { comparator: FormComparator.REQUIRED },
            ],
        },
        characteristics: [
            {
                key: "general",
                label: "Général",
                elements: [
                    {
                        key: "porosite",
                        label: "Porosité",
                        unit: "%",
                        seuil: "Variation",
                    },
                    {
                        key: "prositeInterGranulaire",
                        label: "Porosité inter granulaire du filler sec compacté (Rigden)",
                        unit: "%",
                    },
                    {
                        key: "masseVolumiqueReelle",
                        label: "Masse volumique réelle d'un filler à 25 °C",
                        unit: "T/m³",
                    },
                    { key: "teneurEau", label: "Teneur en eau - W", unit: "%" },
                    {
                        key: "deltaTemperatureBilleAnneau",
                        label: "Delta Température Bille et Anneau - TBA",
                        unit: "°C",
                    },
                    {
                        key: "surfaceBlaine",
                        label: "Surface Blaine",
                        unit: "%",
                    },
                ],
            },
        ],
    },
    [MaterialType.BETON]: {
        validationCatalogue: {
            name: [{ comparator: FormComparator.REQUIRED }],
            year: [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_INTEGER },
            ],
            "specifications.resistanceCompression": [
                { comparator: FormComparator.REQUIRED },
            ],
            "specifications.classeBeton": [
                { comparator: FormComparator.REQUIRED },
            ],
            "specifications.classeEtalement": [
                { comparator: FormComparator.REQUIRED },
            ],
            "specifications.classeConsistance": [
                { comparator: FormComparator.REQUIRED },
            ],
            "specifications.chlorureMax": [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.NUMBER },
            ],
            "specifications.dMax": [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.NUMBER },
            ],
        },
        characteristics: [
            {
                key: "general",
                label: "Général",
                elements: [
                    {
                        key: "resistanceCompressionSeuil",
                        label: "Résistance en compression - Rc",
                        unit: "Mpa",
                        seuil: "Nbr de jours",
                        onlyMin: true,
                    },
                    {
                        key: "resistanceTraction",
                        label: "Résistance en traction - Rt",
                        unit: "Mpa",
                        seuil: "Nbr de jours",
                        onlyMin: true,
                    },
                    {
                        key: "flexion",
                        label: "Flexion",
                        unit: "Mpa",
                        seuil: "Nbr de jours",
                        onlyMin: true,
                    },
                    {
                        key: "teneurLiant",
                        label: "Teneur en liant éq",
                        unit: "Kg",
                        objectifUnit: "Coef. % ≥",
                        onlyMin: true,
                    },
                    { key: "teneurAir", label: "Teneur en air", unit: "%" },
                    {
                        key: "additionCalcaireCatA",
                        label: "Addition calcaire Cat A",
                    },
                    {
                        key: "additionCalcaireCatB",
                        label: "Addition calcaire Cat B",
                    },
                    {
                        key: "additionSilicieuse",
                        label: "Addition silicieuse de minéralogie QZ",
                    },
                    {
                        key: "dosageCiment",
                        label: "Dosage en ciment",
                        unit: "Kg",
                    },
                    { key: "temperature", label: "Température", unit: "C°" },
                ],
            },
        ],
    },
    [MaterialType.EMULSION_BITUME]: {
        validationCatalogue: {
            name: [{ comparator: FormComparator.REQUIRED }],
            year: [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_INTEGER },
            ],
            "specifications.dosage": [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.NUMBER },
            ],
            "specifications.dilution": [{ comparator: FormComparator.NUMBER }],
            "specifications.dosageGravillons": [
                { comparator: FormComparator.NUMBER },
            ],
        },
        characteristics: [
            {
                key: "general",
                label: "Général",
                elements: [
                    {
                        key: "granulometrie",
                        label: "Granulométrie",
                        seuil: "Passant",
                        seuilUnit: "μ",
                        minMaxUnit: "%",
                    },
                    {
                        key: "teneurEau",
                        label: "Teneur en eau",
                        unit: "%",
                        onlyMax: true,
                    },
                    { key: "teneurLiantValInd", label: "Teneur en liant (L%)" },
                    {
                        key: "indiceRupture",
                        label: "Indice de rupture",
                        unit: "kg/tonne",
                    },
                    {
                        key: "monteeCohesion",
                        label: "Montée en cohésion",
                        unit: "seconde",
                    },
                    { key: "adhesivite", label: "Adhésivité", unit: "%" },
                    {
                        key: "stabiliteStockage",
                        label: "Stabilité au stockage",
                        unit: "seconde",
                    },
                    { key: "ph", label: "PH" },
                    {
                        key: "viscosite",
                        label: "Viscosité",
                        unit: "poises",
                        seuil: "Température",
                        seuilUnit: "°C",
                    },
                ],
            },
        ],
    },
    [MaterialType.ENROBE]: {
        validationCatalogue: {
            name: [{ comparator: FormComparator.REQUIRED }],
            year: [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_INTEGER },
            ],
            "specifications.typeCouche": [
                { comparator: FormComparator.REQUIRED },
            ],
        },
        characteristics: [
            {
                key: "etude_performance",
                label: "Étude et performance",
                order: 3,
                elements: [
                    {
                        key: "teneurVide",
                        label: "Teneur en vide (PCG)",
                        seuil: "Girations",
                    },
                    {
                        key: "sensibiliteEau",
                        label: "Sensibilité à l'eau (ITSR)",
                        unit: "Coef. % ≥",
                    },
                    {
                        key: "deformationPermanante",
                        label: "Déformation Permanente",
                        seuil: "Cycles",
                        unit: "P",
                    },
                    {
                        key: "moduleRigidite",
                        label: "Module de rigidité (Smin)",
                        unit: "E(MPa) ≥",
                    },
                    { key: "fatigue", label: "Fatigue (ε6)", unit: "(µdef) ≥" },
                    {
                        key: "perteMasse",
                        label: "Perte de masse (Cantabro)",
                        unit: "%",
                    },
                ],
            },
            {
                key: "fabrication_production",
                label: "Fabrication et production",
                order: 1,
                elements: [
                    {
                        key: "teneurLiantValInd",
                        label: "Teneur en liant (L%) Val. ind.",
                    },
                    {
                        key: "teneurLiantValMoy",
                        label: "Teneur en liant (L%) Moyenne",
                        hideInSample: true,
                    },
                    {
                        key: "granulometrie",
                        label: "Granulométrie",
                        unit: "%",
                        seuil: "Passant",
                        seuilUnit: "mm",
                    },
                ],
            },
            {
                key: "mise_en_oeuvre",
                label: "Mise en oeuvre et réalisation",
                order: 2,
                elements: [
                    {
                        key: "epaisseurCouche",
                        label: "Épaisseur couche",
                        hideInSample: true,
                    },
                    {
                        key: "dosageCoucheAccrochage",
                        label: "Dosage couche d'accrochage",
                        seuil: "Seuil",
                        unit: "g/m²",
                    },
                    { key: "bouclageKgM2", label: "Bouclage", unit: "kg/m²" },
                    { key: "bouclageMm", label: "Bouclage", unit: "mm" },
                    { key: "supportRabote", label: "Support raboté" },
                    {
                        key: "temperatureMiseEnOeuvre",
                        label: "Température de mise en œuvre (T°)",
                        unit: "°C",
                    },
                    {
                        key: "vitesseMiseEnOeuvre",
                        label: "Vitesse de mise en œuvre",
                        unit: "m/min",
                    },
                    {
                        key: "drainabilite",
                        label: "Drainabilité (Vp)",
                        unit: "cm/sec",
                        withValCons: true,
                        hideInSample: true,
                    },
                    {
                        key: "drainabiliteCompliance",
                        label: "Drainabilité: conformité",
                        unit: "%",
                        hideInSample: true,
                        compliance: true,
                    },
                    {
                        key: "pourcentageVide",
                        label: "Pourcentage de vide (V%)",
                        unit: "%",
                        withValCons: true,
                        hideInSample: true,
                    },
                    {
                        key: "pourcentageVideCompliance",
                        label: "Pourcentage de vide: conformité",
                        unit: "%",
                        hideInSample: true,
                        compliance: true,
                    },
                    {
                        key: "pourcentageVideHydro",
                        label: "Pourcentage de vide hydrostatique",
                        unit: "%",
                    },
                    {
                        key: "macrotexture",
                        label: "Macrotexture (PMT)",
                        withValCons: true,
                        hideInSample: true,
                    },
                    {
                        key: "macrotextureCompliance",
                        label: "Macrotexture: conformité",
                        unit: "%",
                        hideInSample: true,
                        compliance: true,
                    },
                    {
                        key: "profilTravers",
                        label: "Profil en travers",
                        unit: "mm",
                    },
                    {
                        key: "masseVolumiqueApparente",
                        label: "Masse volumique apparente (MVA)",
                        unit: "T/m³",
                        hideInSample: true,
                    },
                ],
            },
        ],
    },
    [MaterialType.GRANULAT]: {
        validationCatalogue: {
            name: [{ comparator: FormComparator.REQUIRED }],
            year: [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_INTEGER },
            ],
            "specifications.origineGranulat": [
                { comparator: FormComparator.REQUIRED },
            ],
        },
        characteristics: [
            {
                key: "physique",
                label: "Physique",
                elements: [
                    {
                        key: "granulometrie",
                        label: "Tamis",
                        unit: "%",
                        seuil: "Passant",
                        seuilUnit: "mm",
                        order: 1,
                    },
                    {
                        key: "teneurEau",
                        label: "Teneur en eau - W",
                        unit: "%",
                        order: 2,
                    },
                    {
                        key: "coefAplatissementForme",
                        label: "Coefficient d'Aplatissement et de forme - Fl / LT",
                        order: 4,
                    },
                    {
                        key: "equivalentSables",
                        label: "Equivalent des sables - SE",
                        unit: "%",
                        order: 9,
                    },
                    {
                        key: "ecoulementGravillons",
                        label: "Ecoulement des gravillons - Ecg",
                        unit: "sec",
                        order: 16,
                    },
                    {
                        key: "grainsSemiCasses",
                        label: "Pourcentage des grains semi cassés - Cx/y",
                        unit: "%",
                        order: 17,
                    },
                    {
                        key: "angularite",
                        label: "Angularité par Ecoulement - Ecs",
                        unit: "sec",
                        order: 18,
                    },
                    {
                        key: "masseVolumiqueVrac",
                        label: "Masse volumique en vrac",
                        unit: "T/m3",
                        order: 19,
                    },
                    {
                        key: "masseVolumiqueReelle",
                        label: "Masse volumique réelle",
                        unit: "T/m3",
                        order: 20,
                    },
                    {
                        key: "masseVolumiqueApparente",
                        label: "Masse volumique Apparente",
                        unit: "T/m3",
                        order: 21,
                    },
                    {
                        key: "masseVolumiqueReelleImbibee",
                        label: "Masse volumique réelle imbibée",
                        unit: "T/m3",
                        order: 22,
                    },
                    {
                        key: "teneurFines",
                        label: "Teneur en fines - f",
                        unit: "%",
                        order: 23,
                    },
                ],
            },
            {
                key: "chimique",
                label: "Chimique",
                elements: [
                    {
                        key: "valeurBleu",
                        label: "Valeur au Bleu de méthylène - MBF",
                        unit: "g/Kg)",
                        order: 3,
                    },
                    {
                        key: "teneurLiant",
                        label: "Teneur en liants - TL",
                        unit: "%",
                        order: 10,
                    },
                    {
                        key: "penetrabilite",
                        label: "Pénétrabilité - PENE",
                        unit: "1/10ème mm",
                        order: 11,
                    },
                    {
                        key: "temperatureBillesAnneaux",
                        label: "Température Bille et Anneau - TBA",
                        unit: "C°",
                        order: 12,
                    },
                    {
                        key: "pointFragilite",
                        label: "Point de fragilité FRASS",
                        unit: "°C",
                        order: 12,
                    },
                    {
                        key: "intervallePlasticite",
                        label: "Intervalle de plasticité",
                        unit: "°C",
                        order: 12,
                    },
                    {
                        key: "amiante",
                        label: "Amiante mastic",
                        unit: "présence",
                        isBoolean: true,
                        order: 13,
                    },
                    {
                        key: "amianteGranulat",
                        label: "Amiante granulat",
                        unit: "présence",
                        isBoolean: true,
                        order: 13,
                    },
                    { key: "hap", label: "HAP", unit: "mg/kg", order: 14 },
                    {
                        key: "hct",
                        label: "HCT C10-C21",
                        unit: "mg/kg",
                        order: 15,
                    },
                    {
                        key: "hct2",
                        label: "HCT C10-C40",
                        unit: "mg/kg",
                        order: 15,
                    },
                    {
                        key: "equivalentSables",
                        label: "Equivalent des sables - SE",
                        unit: "%",
                        order: 19,
                    },
                    {
                        key: "masseBleu",
                        label: "Masse de bleu - MB",
                        order: 20,
                    },

                    {
                        key: "elementCoquilliers",
                        label: "Éléments coquilliers - SC",
                        unit: "%",
                        order: 23,
                    },
                    {
                        key: "sensibiliteGel",
                        label: "Sensibilié au gel/dégel - F",
                        unit: "%",
                        order: 24,
                    },
                    {
                        key: "teneurChaux",
                        label: "Teneur en chaux",
                        unit: "%",
                        order: 25,
                    },
                    {
                        key: "reactAlcaliSilice",
                        label: "Réaction alcali-silice",
                        unit: "%",
                        order: 26,
                    },
                    {
                        key: "chlorureSoluble",
                        label: "Chlorures solubles dans l'eau - C",
                        unit: "%",
                        order: 27,
                    },
                    {
                        key: "teneurSouffre",
                        label: "Teneur en Soufre total - S",
                        unit: "%",
                        order: 28,
                    },
                    {
                        key: "sulfatesSolubles",
                        label: "Sulfates solubles dans l'acide - AS",
                        unit: "%",
                        order: 29,
                    },
                    {
                        key: "teneurSulfatesSolubles",
                        label: "Teneur en sulfates solubles - SS",
                        unit: "%",
                        order: 30,
                    },
                    {
                        key: "teneurCarbonate",
                        label: "Teneur en carbonates des sables",
                        unit: "%",
                        order: 31,
                    },
                    {
                        key: "teneurContaminants",
                        label: "Teneur en contaminants légers - LPC",
                        unit: "%",
                        order: 32,
                    },
                    {
                        key: "resistanceCoupSoleil",
                        label: "Résistance au coup de soleil des basaltes",
                        unit: "%",
                        order: 33,
                    },
                    {
                        key: "teneurElementsCoquilliers",
                        label: "Teneur en éléments coquilliers - SC",
                        unit: "%",
                        order: 34,
                    },
                    {
                        key: "resistanceEcrasement",
                        label: "Résistance à l'écrasement des granulats - Ca, Cb (N/mm²)",
                        order: 35,
                    },
                    {
                        key: "coefVolumique2h",
                        label: "Coefficient volumique d'absorption d'eau à 2h - Wl",
                        unit: "%",
                        order: 36,
                    },
                    {
                        key: "coefVolumique24h",
                        label: "Coefficient volumique d'absorption d'eau à 24h - Wl",
                        unit: "%",
                        order: 37,
                    },
                    {
                        key: "polluantsOrganiques",
                        label: "Polluants organiques - PO",
                        order: 38,
                    },
                    {
                        key: "matieresSolubles",
                        label: "Matières solubles dans l'eau",
                        unit: "%",
                        order: 39,
                    },
                    {
                        key: "perteFeu",
                        label: "Perte au feu",
                        unit: "%",
                        order: 40,
                    },
                    {
                        key: "desintegrationSilicate",
                        label: "Désintégration du silicate bicalcique et du fer",
                        unit: "%",
                        order: 41,
                    },
                    {
                        key: "stabiliteLaiteirs",
                        label: "Stabilité des laitiers d'aciérie",
                        unit: "%",
                        order: 42,
                    },
                    {
                        key: "expansionLaitiers",
                        label: "Expansion des laitiers - V",
                        unit: "%",
                        order: 43,
                    },
                    {
                        key: "absorptionEau",
                        label: "Absorption d'eau - WA",
                        unit: "%",
                        order: 44,
                    },
                    {
                        key: "impurteesProhibees",
                        label: "Impuretées prohibées - Imp",
                        order: 45,
                    },
                    {
                        key: "boulettesArgile",
                        label: "Boulettes d'argile",
                        unit: "%",
                        order: 47,
                    },
                    {
                        key: "bilanAlcalins",
                        label: "Bilan des alcalins actifs",
                        unit: "%",
                        order: 48,
                    },
                ],
            },
            {
                key: "mecanique",
                label: "Mécanique",
                elements: [
                    {
                        key: "microDeval",
                        label: "Micro-Deval - MDE",
                        unit: "%",
                        order: 5,
                    },
                    {
                        key: "losAngeles",
                        label: "Los Angeles - L",
                        unit: "%",
                        order: 6,
                    },
                    { key: "polissage", label: "Polissage - PSV", order: 7 },
                    {
                        key: "friabiliteSables",
                        label: "Friabilité des sables - FS",
                        order: 8,
                    },
                    {
                        key: "resistancePolissage",
                        label: "Résistance au Polissage accéléré - RPA",
                        order: 9,
                    },
                ],
            },
        ],
    },
    [MaterialType.LIANT_HYDRAULIQUE]: {
        validationCatalogue: {
            name: [{ comparator: FormComparator.REQUIRED }],
            year: [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_INTEGER },
            ],
        },
        characteristics: [
            {
                key: "analyses_chimiques",
                label: "Analyses chimiques",
                elements: [
                    {
                        key: "teneurAlcalins",
                        label: "Teneur en alcalins",
                        unit: "%",
                    },
                    {
                        key: "teneurSulfates",
                        label: "Teneur en sulfates",
                        unit: "%",
                    },
                    {
                        key: "teneurChlorures",
                        label: "Teneur en chlorures",
                        unit: "%",
                    },
                    {
                        key: "teneurSulfures",
                        label: "Teneur en sulfures",
                        unit: "%",
                    },
                    {
                        key: "teneurChromeVi",
                        label: "Teneur en chrome VI",
                        unit: "%",
                    },
                    {
                        key: "teneurSiliceReactive",
                        label: "Teneur en silice réactive",
                        unit: "%",
                    },
                    {
                        key: "tempsDebutPrise",
                        label: "Temps de début de prise (Vicat)",
                        unit: "sec",
                    },
                    {
                        key: "eauGachage",
                        label: "Eau nécessaire de gâchage",
                        unit: "litre",
                    },
                ],
            },
            {
                key: "essais_physiques",
                label: "Essais physiques",
                elements: [
                    {
                        key: "consistanceNormalisee",
                        label: "Consistance normalisée",
                        unit: "cm",
                    },
                    {
                        key: "tempsFinPrise",
                        label: "Temps de fin de prise",
                        unit: "sec",
                    },
                    {
                        key: "finesseBlaine",
                        label: "Finesse de blaine (permabilité à l'air)",
                        unit: "%",
                    },
                    {
                        key: "resistanceCompression",
                        label: "Résistance à la compréssion et à la flexion",
                        unit: "Mpa",
                    },
                    {
                        key: "residusInsolubles",
                        label: "Résidus insolubles",
                        unit: "%",
                    },
                ],
            },
            {
                key: "autre",
                label: "Autres",
                elements: [
                    {
                        key: "finesseTamisage",
                        label: "Finesse par tamisage",
                        seuil: "Seuil",
                        unit: "%",
                    },
                    {
                        key: "mesureRetraitGonflement",
                        label: "Mesures du retrait et du gonflement",
                        seuil: "Seuil",
                        unit: "%",
                    },
                    {
                        key: "essaisMecaniquesRetrait",
                        label: "Essais mécaniques - retrait",
                        seuil: "Mesure de retait",
                        unit: "%",
                    },
                    {
                        key: "essaisMecaniquesGonflement",
                        label: "Essais mécaniques - gonflement",
                        seuil: "Mesure de gonflement",
                        unit: "%",
                    },
                ],
            },
        ],
    },
    [MaterialType.LIANT_HYDROCARBONE]: {
        validationCatalogue: {
            name: [{ comparator: FormComparator.REQUIRED }],
            year: [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_INTEGER },
            ],
            "specifications.typeLiant": [
                { comparator: FormComparator.REQUIRED },
            ],
        },
        characteristics: [
            {
                key: "general",
                label: "Général",
                elements: [
                    {
                        key: "penetrabilite",
                        label: "Pénétrabilité",
                        unit: "1/10mm",
                    },
                    {
                        key: "pointRamolissement",
                        label: "Point de ramolissement",
                        unit: "°C",
                    },
                    {
                        key: "pointFragilite",
                        label: "Point de fragilité FRASS",
                        unit: "°C",
                    },
                    {
                        key: "intervallePlasticite",
                        label: "Intervalle de plasticité",
                        unit: "°C",
                    },
                    {
                        key: "pointEclair",
                        label: "Point d’éclair Cleveland",
                        unit: "°C",
                    },
                    { key: "solubilite", label: "Solubilité", unit: "%" },
                    {
                        key: "augmentationPointRamolissement",
                        label: "Augmentation du point de ramolissement",
                        unit: "°C",
                    },

                    {
                        key: "variationMasse",
                        label: "Variation de masse - RTFOT",
                        unit: "%",
                        seuil: "Température",
                        seuilUnit: "°C",
                    },
                    {
                        key: "viscositeCynematique",
                        label: "Viscosité cynématique",
                        unit: "mm²/s",
                        seuil: "Température",
                        seuilUnit: "°C",
                    },
                    {
                        key: "viscositeDynamique",
                        label: "Viscosité dynamique (Pa.s)",
                        seuil: "Température",
                        seuilUnit: "°C",
                    },
                    {
                        key: "rapportViscosite",
                        label: "Rapport de viscosité",
                        unit: "%",
                        seuil: "Température",
                        seuilUnit: "°C",
                    },
                    {
                        key: "moduleComplexe",
                        label: "Module complexe E*",
                        unit: "MPa",
                        seuil: "Module complexe E*",
                        onlyMin: true,
                    },

                    {
                        key: "densiteRelative",
                        label: "Densité relative",
                        unit: "T/m³",
                    },
                    {
                        key: "temperatureEnrobage",
                        label: "Température d’enrobage",
                        unit: "°C",
                    },
                    {
                        key: "densiteRelativeTemperatureEnrobage",
                        label: "Densité relative à la température d’enrobage",
                        unit: "T/m³",
                    },
                    {
                        key: "temperatureStockage",
                        label: "Température de stockage",
                        unit: "°C",
                    },
                    {
                        key: "temperatureStockageProlonge",
                        label: "Température de stockage prolongé (>7 jours)",
                        unit: "°C",
                    },
                    {
                        key: "temperatureCompactage",
                        label: "Température de compactage",
                        unit: "°C",
                    },
                    { key: "traction5c", label: "Traction à 5°C", unit: "%" },

                    {
                        key: "retourElastique",
                        label: "Retour élastique",
                        unit: "%",
                        seuil: "Température",
                        seuilUnit: "°C",
                    },
                    {
                        key: "teneurParaffines",
                        label: "Teneur en paraffines",
                        unit: "%",
                    },
                ],
            },
        ],
    },
    [MaterialType.SOL]: {
        validationCatalogue: {
            name: [{ comparator: FormComparator.REQUIRED }],
            year: [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_INTEGER },
            ],
            "specifications.destinationSol": [
                { comparator: FormComparator.REQUIRED },
            ],
            "specifications.typeSol": [{ comparator: FormComparator.REQUIRED }],
        },
        characteristics: [
            {
                key: "portance_plateforme",
                label: "Portance Plateforme",
                elements: [
                    {
                        key: "westergaard",
                        label: "Module WESTERGAARD",
                        unit: "Mpa",
                        onlyMin: true,
                    },
                    {
                        key: "densiteCompacite",
                        label: "Densité compacité",
                        unit: "% vide",
                        onlyMin: true,
                    },
                    {
                        key: "granulometrie",
                        label: "Granulométrie",
                        seuil: "Granulométrie",
                        seuilUnit: "mm",
                        unit: "%",
                    },
                    {
                        key: "valeurBleu",
                        label: "Valeur au bleu sol - VBS",
                        unit: "g/Kg",
                    },
                    { key: "plasticite", label: "Indice de plasticité - IP" },
                    { key: "teneurEau", label: "Teneur en eau" },
                    {
                        key: "indicePortant",
                        label: "Indice Portant Immédiat - IPI",
                    },
                    {
                        key: "deflexion",
                        label: "Déflexion",
                        seuil: "Age",
                        unit: "1/100mm",
                    },
                    { key: "losAngeles", label: "Los Angeles - LA", unit: "%" },
                    {
                        key: "microDeval",
                        label: "Micro-Deval - MDE",
                        unit: "%",
                    },
                ],
            },
            {
                key: "sol_traite",
                label: "Sol traité",
                elements: [
                    { key: "gonflement", label: "Gonflement" },
                    {
                        key: "resistanceCompression",
                        label: "Résistance en compression - Rc",
                        unit: "%",
                        seuil: "Age",
                    },
                ],
            },
        ],
    },
};

export const UnknownMaterialCharacteristics: MaterialCharacteristicElement[] = [
    {
        key: "epaisseurCouche",
        label: "Épaisseur couche",
        hideInSample: true,
    },
    { key: "teneurLiantValInd", label: "Teneur en liant (L%) Val. ind." },
    {
        key: "granulometrie",
        label: "Granulométrie",
        unit: "%",
        seuil: "Tamis",
        seuilUnit: "mm",
        seuils: [
            { seuil: 25 },
            { seuil: 20 },
            { seuil: 16 },
            { seuil: 14 },
            { seuil: 10 },
            { seuil: 6.3 },
            { seuil: 4 },
            { seuil: 2 },
            { seuil: 0.063 },
        ],
    },
    {
        key: "amiante",
        label: "Amiante mastic",
        unit: "présence",
        isBoolean: true,
    },
    {
        key: "amianteGranulat",
        label: "Amiante granulat",
        unit: "présence",
        isBoolean: true,
    },
    { key: "hap", label: "HAP", unit: "mg/kg" },
    { key: "hct", label: "HCT C10-C21", unit: "mg/kg" },
    { key: "hct2", label: "HCT C10-C40", unit: "mg/kg" },
    {
        key: "penetrabilite",
        label: "Pénétrabilité - PENE",
        unit: "1/10ème mm",
    },
    {
        key: "temperatureBillesAnneaux",
        label: "Température Bille et Anneau - TBA",
        unit: "C°",
    },
    {
        key: "pointFragilite",
        label: "Point de fragilité Fraass",
        unit: "°C",
    },
    {
        key: "moduleRigidite",
        label: "Module de rigidité (Smin)",
        unit: "E(MPa) ≥",
    },
    { key: "fatigue", label: "Fatigue (ε6)", unit: "(µdef) ≥" },
    { key: "perteMasse", label: "Perte de masse (Cantabro)", unit: "%" },
    {
        key: "resistanceCompressionAir",
        label: "Résistance +traction +air",
        unit: "kPa",
    },
    {
        key: "resistanceCompressionEau",
        label: "Résistance +traction +eau",
        unit: "kPa",
    },
    {
        key: "resistanceCompressionAirEau",
        label: "Résistance en compression - Rc (air) / Rc (eau)",
        unit: "%",
    },
    {
        key: "masseVolumiqueApparente",
        label: "Masse volumique apparente (MVA)",
        unit: "T/m³",
    },
    { key: "drainabilite", label: "Drainabilité (Vp)", unit: "cm/sec" },
    {
        key: "pourcentageVide",
        label: "Pourcentage de vides (V%)",
        unit: "%",
    },
    { key: "macrotexture", label: "Macrotexture (PMT (M))" },
    { key: "profilTravers", label: "Profil en travers", unit: "mm" },
];

export const MATERIAL_IDENTITY_FIELDS: Record<
    MaterialType,
    {
        field: string;
        label: string;
        labelRequirement?: string;
        enum?: Record<any, string>;
    }[]
> = {
    [MaterialType.ADDITIF]: [
        {
            field: "year",
            label: "Année de la norme",
            labelRequirement: "Année de l'exigence",
        },
        {
            field: "specifications.origineAdditif",
            label: "Origine",
            enum: OrigineAdditifLabel,
        },
    ],
    [MaterialType.BETON]: [
        {
            field: "year",
            label: "Année de la norme",
            labelRequirement: "Année de l'exigence",
        },
        { field: "specifications.classeBeton", label: "Classe" },
        {
            field: "specifications.classeConsistance",
            label: "Classe consistance",
        },
        {
            field: "specifications.classeEtalement",
            label: "Classe étalement",
        },
        {
            field: "specifications.resistanceCompression",
            label: "Résistance compression",
        },
    ],
    [MaterialType.EMULSION_BITUME]: [
        {
            field: "year",
            label: "Année de la norme",
            labelRequirement: "Année de l'exigence",
        },
        { field: "specifications.dosage", label: "Dosage" },
        {
            field: "specifications.dosageGravillons",
            label: "Dosage gravillons",
        },
        { field: "specifications.dilution", label: "Dilution" },
    ],
    [MaterialType.ENROBE]: [
        {
            field: "year",
            label: "Année de la norme",
            labelRequirement: "Année de l'exigence",
        },
        {
            field: "specifications.typeCouche",
            label: "Type de couche",
            enum: CoucheTypeLabel,
        },
    ],
    [MaterialType.GRANULAT]: [
        {
            field: "year",
            label: "Année de la norme",
            labelRequirement: "Année de l'exigence",
        },
        {
            field: "specifications.typeGranulat",
            label: "Type",
            enum: TypeGranulatLabel,
        },
        {
            field: "specifications.origineGranulat",
            label: "Origine",
            enum: OrigineGranulatLabel,
        },
        {
            field: "specifications.destinationGranulat",
            label: "Destination",
            enum: DestinationGranulatLabel,
        },
    ],
    [MaterialType.LIANT_HYDRAULIQUE]: [
        {
            field: "year",
            label: "Année de la norme",
            labelRequirement: "Année de l'exigence",
        },
    ],
    [MaterialType.LIANT_HYDROCARBONE]: [
        {
            field: "year",
            label: "Année de la norme",
            labelRequirement: "Année de l'exigence",
        },
        {
            field: "specifications.typeLiant",
            label: "Type de liant",
            enum: TypeLiantLabel,
        },
        { field: "specifications.gradePur", label: "Grade pur" },
        {
            field: "specifications.gradeModifie",
            label: "Grade modifié",
        },
    ],
    [MaterialType.SOL]: [
        {
            field: "year",
            label: "Année de la norme",
            labelRequirement: "Année de l'exigence",
        },
        {
            field: "specifications.typeSol",
            label: "Type de sol",
            enum: TypeSolLabel,
        },
        {
            field: "specifications.destinationSol",
            label: "Destination",
            enum: DestinationSolLabel,
        },
        {
            field: "specifications.classePlateforme",
            label: "Classe plateforme",
            enum: ClassePlateformeLabel,
        },
        {
            field: "specifications.classeArase",
            label: "Classe Arase",
            enum: ClasseAraseLabel,
        },
    ],
};

export const MaterialListParams = {
    [MaterialType.ADDITIF]: {
        columns: [
            { key: "name", label: "Nom" },
            { key: "year", label: "Année" },
            {
                key: "specifications.origineAdditif",
                label: "Origine",
                mapper: (element) =>
                    element.specifications?.origineAdditif
                        ? OrigineAdditifLabel[
                              element.specifications?.origineAdditif
                          ]
                        : "",
            },
        ] as ListColumns<Material>[],
        filters: [
            { field: "active", label: "Statut" },
            {
                field: "origineAdditif",
                label: "Origine",
                type: FilterType.MULTIPLE_SELECT,
                items: OrigineAdditifs,
            },
        ],
    },
    [MaterialType.BETON]: {
        columns: [
            { key: "name", label: "Nom" },
            { key: "year", label: "Année" },
            {
                key: "specifications.resistanceCompression",
                label: "Resistance",
            },
            { key: "specifications.classeBeton", label: "Classe" },
            { key: "specifications.classeEtalement", label: "Etalement" },
            { key: "specifications.classeConsistance", label: "Consistance" },
            { key: "specifications.ciment", label: "Ciment" },
        ],
        filters: [
            { field: "active", label: "Statut" },
            {
                field: "classeEtalement",
                label: "Classe détalement",
                type: FilterType.MULTIPLE_SELECT,
                items: ClasseEtalements,
            },
            {
                field: "classeConsistance",
                label: "Classe de consistance",
                type: FilterType.MULTIPLE_SELECT,
                items: ClasseConsistances,
            },
        ],
    },
    [MaterialType.EMULSION_BITUME]: {
        columns: [
            { key: "name", label: "Nom" },
            { key: "year", label: "Année" },
            { key: "specifications.dosage", label: "Dosage en bitume" },
            {
                key: "specifications.dosageGravillon",
                label: "Dosage en gravillon",
            },
            { key: "specifications.dilution", label: "Dilution" },
        ],
        filters: [{ field: "active", label: "Statut" }],
    },
    [MaterialType.ENROBE]: {
        columns: [
            { key: "name", label: "Nom" },
            { key: "year", label: "Année" },
            {
                key: "specifications.typeCouche",
                label: "Type de couche",
                mapper: (element) =>
                    element.specifications?.typeCouche
                        ? CoucheTypeLabel[element.specifications?.typeCouche]
                        : "",
            },
            { key: "specifications.couche", label: "Couche" },
        ] as ListColumns<Material>[],
        filters: [
            { field: "active", label: "Statut" },
            {
                field: "typeCouche",
                label: "Type de couche",
                type: FilterType.MULTIPLE_SELECT,
                items: CoucheTypes,
            },
        ],
    },
    [MaterialType.GRANULAT]: {
        columns: [
            { key: "name", label: "Nom" },
            { key: "year", label: "Année" },
            {
                key: "specifications.fractionGranulat",
                label: "Fraction granulométrique",
            },
            {
                key: "specifications.origineGranulat",
                label: "Origine",
                mapper: (element) =>
                    element.specifications?.origineGranulat
                        ? OrigineGranulatLabel[
                              element.specifications?.origineGranulat
                          ]
                        : "",
            },
            {
                key: "specifications.typeGranulat",
                label: "Type",
                mapper: (element) =>
                    element.specifications?.typeGranulat
                        ? TypeGranulatLabel[
                              element.specifications?.typeGranulat
                          ]
                        : "",
            },
            {
                key: "specifications.destinationGranulat",
                label: "Destination",
                mapper: (element) =>
                    element.specifications?.destinationGranulat
                        ? DestinationGranulatLabel[
                              element.specifications?.destinationGranulat
                          ]
                        : "",
            },
        ] as ListColumns<Material>[],
        filters: [
            { field: "active", label: "Statut" },
            {
                field: "origineGranulat",
                label: "Origine",
                type: FilterType.MULTIPLE_SELECT,
                items: OrigineGranulats,
            },
            {
                field: "typeGranulat",
                label: "Type",
                type: FilterType.MULTIPLE_SELECT,
                items: TypeGranulats,
            },
            {
                field: "destinationGranulat",
                label: "Destination",
                type: FilterType.MULTIPLE_SELECT,
                items: DestinationGranulats,
            },
        ],
    },
    [MaterialType.LIANT_HYDRAULIQUE]: {
        columns: [
            { key: "name", label: "Nom" },
            { key: "year", label: "Année" },
        ],
        filters: [{ field: "active", label: "Statut" }],
    },
    [MaterialType.LIANT_HYDROCARBONE]: {
        columns: [
            { key: "name", label: "Nom" },
            { key: "year", label: "Année" },
            {
                key: "specifications.typeLiant",
                label: "Type",
                mapper: (element) =>
                    element.specifications?.typeLiant
                        ? TypeLiantLabel[element.specifications?.typeLiant]
                        : "",
            },
            { key: "specifications.gradePur", label: "Grade pur" },
            { key: "specifications.gradeModifie", label: "Grade modifié" },
        ] as ListColumns<Material>[],
        filters: [
            { field: "active", label: "Statut" },
            {
                field: "typeLiant",
                label: "Type",
                type: FilterType.MULTIPLE_SELECT,
                items: TypeLiants,
            },
            {
                field: "gradePur",
                label: "Grade pur",
                type: FilterType.MULTIPLE_SELECT,
                items: GradePurs,
            },
        ],
    },
    [MaterialType.SOL]: {
        columns: [
            { key: "name", label: "Nom" },
            { key: "year", label: "Année" },
            {
                key: "specifications.destinationSol",
                label: "Destination",
                mapper: (element) =>
                    element.specifications?.destinationSol
                        ? DestinationSolLabel[
                              element.specifications?.destinationSol
                          ]
                        : "",
            },
            {
                key: "specifications.typeSol",
                label: "Type",
                mapper: (element) =>
                    element.specifications?.typeSol
                        ? TypeSolLabel[element.specifications?.typeSol]
                        : "",
            },
        ] as ListColumns<Material>[],
        filters: [
            { field: "active", label: "Statut" },
            {
                field: "destinationSol",
                label: "Destination",
                type: FilterType.MULTIPLE_SELECT,
                items: DestinationSols,
            },
            {
                field: "typeSol",
                label: "Type",
                type: FilterType.MULTIPLE_SELECT,
                items: TypeSols,
            },
        ],
    },
};

export const MaterialsWithProducer = [
    MaterialType.ENROBE,
    MaterialType.EMULSION_BITUME,
    MaterialType.BETON,
];

export const MaterialIcon = "mdi:bricks";
