import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder';
import { useEffect, useState } from 'react';
import { ControlPosition, IControl, MapInstance, useControl } from 'react-map-gl';
import { Address, Coordinates } from '../../../models/location';

interface GeocoderResult {
    address: string;
    center: [number, number];
    context: {
        id: string,
        language: string,
        language_en: string,
        mapbox_id: string,
        text: string;
        text_en: string;
        wikidata: string;
    }[];
    geometry: { type: string, coordinates: [number, number] }
    place_name: string;
    place_name_en: string;
    place_type: string[];
    properties: { accuracy: string, mapbox_id: string };
    text: string;
    text_en: string;
}

type GeocoderControlrest = Omit<GeocoderOptions, 'accessToken' | 'mapboxgl' | 'marker'> & {
    onSelectResponse?: (gps: Coordinates, address: Address) => void;
    position?: ControlPosition;
};

const Geocoder = ({ onSelectResponse, position, ...rest }: GeocoderControlrest) => {
    const [response, setResponse] = useState<{ gps: Coordinates, address: Address } | null>(null);

    useEffect(() => {
        if (!!response && onSelectResponse) {
            onSelectResponse(response.gps, response.address);
        }
    }, [response])

    useControl<IControl<MapInstance>>(() => {
        const ctrl = new MapboxGeocoder({
            ...rest,
            types: 'address',
            language: 'fr',
            marker: false,
            accessToken: process.env.REACT_APP_MAP_TOKEN ?? '',
            flyTo: { animate: false }
        });
        ctrl.on('result', (evt) => {
            const { result }: { result: GeocoderResult } = evt;
            const location =
                result &&
                (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates));

            if (location && onSelectResponse && result?.context) {
                const city = result.context.find(c => c.id.startsWith('place'))?.text;
                const country = result.context.find(c => c.id.startsWith('country'))?.text ?? result.context.find(c => c.id.startsWith('region'))?.text;

                if (!city || !country) return;
                setResponse({
                    gps: {
                        latitude: result.center[1],
                        longitude: result.center[0],
                    },
                    address: {
                        streetNumber: result.address,
                        route: result.place_type?.includes('address') ? result.text : undefined,
                        city,
                        country,
                        postalCode: result.context.find(c => c.id.startsWith('postcode'))?.text,
                    }
                });
            }
        });
        return ctrl as IControl<MapInstance>;
    },
        { position: position ?? 'top-left' }
    );

    return null;
}

export default Geocoder;